import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { roles } from "../../constants/LabConstants";
import { useQuery } from "@tanstack/react-query";
import { fetchLabMembers, LabCacheParams } from "../../services/lab-api";
import { Loading } from "../../components";
import "../../styles/LabProfileTabs/MembersTab.css";
import React from "react";

const MemberComponent = ({ member }) => {
    const navigate = useNavigate();

    return (
        <div className="lab-profile-content-members-tab-member">
            <div
                className="lab-profile-content-members-tab-member-image"
                onClick={() => navigate(`/profile/${member.id}`)}
            >
                <img
                    src={member.profilePicture}
                    alt={`${member.firstName} ${member.lastName}`}
                />
            </div>
            <h4 onClick={() => navigate(`/profile/${member.id}`)}>
                {member.firstName} {member.lastName}
            </h4>
            <h5>{roles[member.role]}</h5>
            <p>{member.description}</p>
        </div>
    );
};

const MembersTab = () => {
    const { labId } = useParams();

    const { data: members, isLoading: membersLoading } = useQuery({
        queryKey: [
            "lab",
            labId,
            "members",
            { groupDescription: true, personalDescription: true },
        ],
        queryFn: fetchLabMembers,
        enabled: !!labId,
        ...LabCacheParams,
    });

    const principalInvestigator = useMemo(() => {
        return members.members.find((member) => member.role === "pi");
    }, [members]);

    const otherMembers = useMemo(() => {
        return members.members.filter((member) => member.role !== "pi");
    }, [members]);

    if (membersLoading) return <Loading />;

    return (
        <div className="lab-profile-content">
            <div className="lab-profile-content-members-section-wrapper">
                <h3>Our Team</h3>
                <div className="lab-top-section">
                    {members.groupDescription && (
                        <div className="group-description">
                            {members.groupDescription ? (
                                <p>
                                    {members.groupDescription.split("\n").map(
                                        (paragraph, index) =>
                                            paragraph.trim() && (
                                                <React.Fragment key={index}>
                                                    {paragraph}
                                                    <br />
                                                    <br />
                                                </React.Fragment>
                                            )
                                    )}
                                </p>
                            ) : (
                                <p className="no-description">
                                    No description available
                                </p>
                            )}
                        </div>
                    )}
                    {members.groupPicture && (
                        <div className="lab-group-picture-container">
                            <img
                                src={members.groupPicture}
                                alt="Lab Group"
                                className="lab-group-picture"
                            />
                        </div>
                    )}
                </div>
                <div className="lab-members-subheader">
                    <h3>Members</h3>
                </div>
                <div className="lab-profile-content-members-tab-members">
                    {principalInvestigator && (
                        <MemberComponent member={principalInvestigator} />
                    )}
                    {otherMembers.map((member) => (
                        <MemberComponent key={member.id} member={member} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MembersTab;
