import { useEffect, useState } from "react";
import { Spinner } from "../components";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/auth-context";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import "../styles/Signup.css";

const Login = () => {
    const { register, handleSubmit } = useForm();
    const [invalidCredentials, setInvalidCredentials] = useState(false);
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();
    const { login, user } = UserAuth();

    const handleLogin = async (data) => {
        setLoading(true);
        try {
            await login(data.email, data.password);
        } catch (error) {
            setLoading(false);
            if (
                error.code === "auth/user-not-found" ||
                error.code === "auth/wrong-password" ||
                error.code === "auth/invalid-email"
            ) {
                setInvalidCredentials(true);
            }
            console.log(error.code);
        }
    };

    useEffect(() => {
        if (user) {
            navigate(`/profile/${user.uid}`);
        }
    }, [user]);

    useEffect(() => {
        document.title = "Get Started | LabGiant";
        document
            .querySelector('meta[name="description"]')
            .setAttribute(
                "content",
                "Login to your account to access your profile and listings."
            );
    }, []);
    return (
        <>
            <Helmet>
                <link
                    rel="canonical"
                    href={`${process.env.REACT_APP_FRONTEND_URL}/?mode=login`}
                />
            </Helmet>
            <div className="login-container">
                <form
                    className="login-form login-specific"
                    onSubmit={handleSubmit(handleLogin)}
                >
                    <div>
                        <label>Email</label>
                        <input
                            className="input login"
                            type="email"
                            placeholder="Work Email"
                            {...register("email", { required: true })}
                        />
                    </div>
                    <div>
                        <label>Password</label>
                        <input
                            className="input login"
                            type="password"
                            placeholder="Password"
                            {...register("password", { required: true })}
                        />
                    </div>
                    {invalidCredentials && (
                        <div className="error login">Invalid Credentials.</div>
                    )}

                    <div className="clickable">Forgot your password?</div>
                    <div className="login-button-container">
                        <button className="standard-button" disabled={loading}>
                            {loading ? <Spinner /> : "Login"}
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Login;
