import React, { useState } from "react";
import {
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Fade,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

const PricingFAQ = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const faqItems = [
        {
            question:
                "Can labs get an additional discount for listing equipment and resources?",
            answer: "Yes, labs that list their equipment and resources on our platform can receive an additional 2% discount per listing added, up to a maximum of 100% off your monthly subscription fee. This makes it beneficial for labs to share their resources while also potentially eliminating your subscription costs entirely.",
        },
        {
            question: "When will the Institution tier be available?",
            answer: "We're currently finalizing the details for our Institution tier. Contact us for more information about enterprise features, custom integrations, and volume pricing for larger research organizations.",
        },
        {
            question: "Is there a free trial available?",
            answer: "We don't currently offer a free trial, but we do have a satisfaction guarantee. If you're not completely satisfied with your subscription within the first 14 days, contact us for a full refund.",
        },
        {
            question: "Can I cancel my subscription at any time?",
            answer: "Yes, you can cancel your subscription at any time from your account settings. You'll continue to have access until the end of your current billing period, after which your account will revert to limited functionality. However, please note that if you subscribed with a discounted price, you won't be able to resubscribe at that same discounted rate if you cancel.",
        },
        {
            question:
                "What happens to my individual subscription if my PI starts a lab subscription?",
            answer: "If you're a member of a lab and your Principal Investigator (PI) starts a lab subscription that includes you, your individual subscription will be automatically canceled immediately. Your access will continue seamlessly under the lab's subscription.",
        },
        {
            question:
                "What happens to lab subscriptions when my institution subscribes?",
            answer: "When your institution purchases an institutional subscription that covers your lab, all existing lab subscriptions within the institution will be automatically canceled immediately. All labs will maintain their data and configurations, and access will continue seamlessly under the institution's subscription with additional institution-level features.",
        },
    ];

    return (
        <Box className="pricing-faq">
            <Fade in={true} timeout={800}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "40px",
                        flexDirection: { xs: "column", sm: "row" },
                        gap: { xs: "10px", sm: "0" },
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: "rgba(63, 5, 5, 0.08)",
                            borderRadius: "50%",
                            width: "60px",
                            height: "60px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginRight: { xs: "0", sm: "16px" },
                            boxShadow: "0 4px 10px rgba(63, 5, 5, 0.1)",
                        }}
                    >
                        <QuestionAnswerIcon
                            sx={{
                                color: "rgb(63, 05, 05)",
                                fontSize: "2rem",
                            }}
                        />
                    </Box>
                    <Typography
                        variant="h4"
                        className="pricing-faq-title"
                        sx={{
                            fontFamily:
                                '"poppins", Helvetica, Arial, sans-serif',
                            fontWeight: 700,
                            color: "#333",
                            margin: 0,
                            padding: 0,
                            position: "relative",
                            display: "inline-block",
                            width: "auto",
                            textAlign: "center",
                        }}
                    >
                        Frequently Asked Questions
                    </Typography>
                </Box>
            </Fade>

            <Box sx={{ maxWidth: "800px", margin: "0 auto" }}>
                {faqItems.map((item, index) => (
                    <Fade in={true} timeout={1000 + index * 200} key={index}>
                        <Accordion
                            expanded={expanded === `panel${index}`}
                            onChange={handleChange(`panel${index}`)}
                            sx={{
                                backgroundColor: "transparent",
                                boxShadow: "none",
                                "&:before": {
                                    display: "none",
                                },
                                mb: 2,
                                border: "1px solid rgba(63, 5, 5, 0.1)",
                                borderRadius: "10px !important",
                                overflow: "hidden",
                                transition: "all 0.3s ease",
                                "&:hover": {
                                    boxShadow:
                                        "0 4px 15px rgba(63, 5, 5, 0.08)",
                                    borderColor: "rgba(63, 5, 5, 0.2)",
                                },
                                ...(expanded === `panel${index}` && {
                                    boxShadow: "0 6px 20px rgba(63, 5, 5, 0.1)",
                                    borderColor: "rgba(63, 5, 5, 0.25)",
                                }),
                            }}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <ExpandMoreIcon
                                        sx={{
                                            color: "rgb(63, 05, 05)",
                                            fontSize: "1.5rem",
                                            transition: "transform 0.3s ease",
                                            ...(expanded ===
                                                `panel${index}` && {
                                                transform: "rotate(180deg)",
                                            }),
                                        }}
                                    />
                                }
                                sx={{
                                    backgroundColor:
                                        expanded === `panel${index}`
                                            ? "rgba(63, 5, 5, 0.05)"
                                            : "rgba(63, 5, 5, 0.02)",
                                    transition: "background-color 0.3s ease",
                                    "&:hover": {
                                        backgroundColor: "rgba(63, 5, 5, 0.05)",
                                    },
                                    "&.Mui-expanded": {
                                        borderBottom:
                                            "1px solid rgba(63, 5, 5, 0.1)",
                                    },
                                    minHeight: "64px",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <HelpOutlineIcon
                                        sx={{
                                            color: "rgb(63, 05, 05)",
                                            mr: 2,
                                            fontSize: "1.2rem",
                                            opacity: 0.8,
                                        }}
                                    />
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontFamily:
                                                '"poppins", Helvetica, Arial, sans-serif',
                                            fontWeight: 600,
                                            color: "#333",
                                            fontSize: "1.1rem",
                                        }}
                                    >
                                        {item.question}
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails
                                sx={{
                                    padding: "20px 24px 24px 56px",
                                    backgroundColor: "#fff",
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontFamily:
                                            '"poppins", Helvetica, Arial, sans-serif',
                                        color: "#555",
                                        lineHeight: 1.7,
                                    }}
                                >
                                    {item.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Fade>
                ))}
            </Box>
        </Box>
    );
};

export default PricingFAQ;
