import ScienceIcon from "@mui/icons-material/Science";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import LockClockIcon from "@mui/icons-material/LockClock";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";

export const TypeBadge = ({ type, style, iconOnly }) => {
    const getIcon = () => {
        switch (type) {
            case "specific-item":
                return <ScienceIcon style={style} />;
            case "equipment-rental":
                return <HomeRepairServiceIcon style={style} />;
            case "service":
                return <LockClockIcon style={style} />;
            case "digital-good":
                return <CloudQueueIcon style={style} />;
            default:
                return null;
        }
    };

    const getLabel = () => {
        switch (type) {
            case "specific-item":
                return "Specific Item";
            case "equipment-rental":
                return "Equipment Rental";
            case "service":
                return "Service";
            case "digital-good":
                return "Digital Good";
            default:
                return "";
        }
    };

    if (iconOnly) {
        return getIcon();
    }

    switch (type) {
        case "specific-item":
            return (
                <>
                    <ScienceIcon style={style} />
                    Specific Item
                </>
            );
        case "equipment-rental":
            return (
                <>
                    <HomeRepairServiceIcon style={style} />
                    Equipment
                </>
            );
        case "service":
            return (
                <>
                    <LockClockIcon style={style} />
                    Service
                </>
            );
        case "digital-good":
            return (
                <>
                    <CloudQueueIcon style={style} />
                    Digital Good
                </>
            );
        default:
            return null;
    }
};

export const StatusBadge = ({ status, style }) => {
    switch (status) {
        case "Pending":
            return (
                <>
                    <HourglassBottomIcon style={style} />
                    Pending
                </>
            );
        case "Approved":
            return (
                <>
                    <CheckCircleIcon style={style} />
                    Approved
                </>
            );
        case "Rejected":
            return (
                <>
                    <CancelIcon style={style} />
                    Rejected
                </>
            );
        case "Fulfilled":
            return { backgroundColor: "grey" };
        case "Cancelled":
            return (
                <>
                    <DeleteIcon style={style} />
                    Cancelled
                </>
            );
    }
};

export const unitsToJson = (units) => {
    return [
        ...units.map((unit) => ({
            value: unit,
            label: unit,
        })),
    ];
};

/** Deserialize: Turns FB format into events */
export const deserializeEvents = (events) => {
    return events.map((event) => {
        return {
            ...event,
            start: new Date(
                event.start.seconds * 1000 + event.start.nanoseconds / 1000000
            ),
            end: new Date(
                event.end.seconds * 1000 + event.end.nanoseconds / 1000000
            ),
        };
    });
};

export const deserializeEventsTemp = (events) => {
    // Handle undefined or null events
    if (!events) {
        return [];
    }

    // Extract dates and days with defaults for undefined values
    const { dates = [], days = [] } = events;

    const parseTime = (date, time) => {
        const [timePart, modifier] = time.split(" ");
        let [hours, minutes] = timePart.split(":").map(Number);

        if (modifier === "PM" && hours < 12) {
            hours += 12;
        } else if (modifier === "AM" && hours === 12) {
            hours = 0;
        }

        const [year, month, day] = date.split("-").map(Number);
        return new Date(year, month - 1, day, hours, minutes);
    };

    const deserializeDates = () => {
        return dates
            .filter((date) => date.date && date.start && date.end)
            .map((date) => ({
                ...date,
                start: parseTime(date.date, date.start),
                end: parseTime(date.date, date.end),
            }));
    };

    const deserializeDays = () => {
        return days
            .filter((day) => day.day && day.start && day.end)
            .flatMap((day) => {
                const events = [];
                const start = parseTime(
                    new Date().toISOString().split("T")[0],
                    day.start
                ); // Today's date
                const end = parseTime(
                    new Date().toISOString().split("T")[0],
                    day.end
                ); // Today's date

                // Adjust start date to the correct day of the week
                const dayOfWeek = day.day; // Assuming day.day is 0 (Sunday) to 6 (Saturday)
                const currentDayOfWeek = start.getDay();
                const dayDifference = (dayOfWeek - currentDayOfWeek + 7) % 7;
                start.setDate(start.getDate() + dayDifference);
                end.setDate(end.getDate() + dayDifference);

                // Generate events for each week
                for (let i = 0; i < 52; i++) {
                    // Assuming 52 weeks in a year
                    const weeklyStart = new Date(start);
                    weeklyStart.setDate(start.getDate() + i * 7);
                    const weeklyEnd = new Date(end);
                    weeklyEnd.setDate(end.getDate() + i * 7);
                    events.push({
                        ...day,
                        start: weeklyStart,
                        end: weeklyEnd,
                    });
                }
                return events;
            });
    };

    return [...deserializeDays(), ...deserializeDates()];
};

/** Serialize: Turns events into FB format */
export const serializeEvents = () => {};

export const ErrorMessages = ({ message }) => {
    return <p className="field-error-message">&#9888; {message}</p>;
};

export const formatDate = (date) => {
    return new Date(
        date.seconds * 1000 + date.nanoseconds / 1000000
    ).toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
    });
};
