import {
    AccountBalance as AccountBalanceIcon,
    ArrowBackIosNew as ArrowBackIosNewIcon,
    ArrowForwardIos as ArrowForwardIosIcon,
    Close as CloseIcon,
    FavoriteBorder as FavoriteBorderIcon,
    Favorite as FavoriteIcon,
    MenuBook as MenuBookIcon,
    Newspaper as NewspaperIcon,
    Science as ScienceIcon,
    AddPhotoAlternate as AddPhotoAlternateIcon,
    Link as LinkIcon,
} from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import {
    collection,
    getDocs,
    limit,
    orderBy,
    query,
    startAfter,
    Timestamp,
    doc,
    updateDoc,
    increment,
} from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loading, ModalComponent } from "../components";
import { UserAuth } from "../context/auth-context";
import { db, storage } from "../firebase-config";
import { fetchGet, fetchPost } from "../services/data-service";
import { fetchLabs, LabCacheParams } from "../services/lab-api";
import { fetchListings, ListingCacheParams } from "../services/listing-api";
import { Helmet } from "react-helmet";
import "../styles/Newsfeed.css";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useQueryClient, useMutation } from "@tanstack/react-query";

const ITEMS_PER_PAGE = 5;

const NewsImageCarousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    if (!images || images.length === 0) return null;
    if (images.length === 1) {
        return <img src={images[0]} alt="" className="nf-content-image" />;
    }

    return (
        <div className="nf-carousel-container">
            <button
                className="nf-carousel-arrow nf-left"
                onClick={(e) => {
                    e.stopPropagation();
                    setCurrentIndex((prev) =>
                        prev === 0 ? images.length - 1 : prev - 1
                    );
                }}
            >
                <ArrowBackIosNewIcon />
            </button>
            <img
                src={images[currentIndex]}
                alt=""
                className="nf-content-image"
            />
            <button
                className="nf-carousel-arrow nf-right"
                onClick={(e) => {
                    e.stopPropagation();
                    setCurrentIndex((prev) =>
                        prev === images.length - 1 ? 0 : prev + 1
                    );
                }}
            >
                <ArrowForwardIosIcon />
            </button>
        </div>
    );
};

const NewsEditorContent = ({ newsItem }) => {
    const { register, reset, watch, setValue } = useFormContext();
    const [linkModalOpen, setLinkModalOpen] = useState(false);
    const [linkDetails, setLinkDetails] = useState({ text: "", url: "" });
    const [selectionRange, setSelectionRange] = useState(null);

    useEffect(() => {
        reset({
            news: {
                ...(newsItem?.id && { id: newsItem?.id }),
                ...(newsItem?.createdAt && { createdAt: newsItem?.createdAt }),
                ...(newsItem?.updatedAt && { updatedAt: newsItem?.updatedAt }),
                title: newsItem?.title || "",
                content: newsItem?.content || "",
                images: newsItem?.images || [],
            },
        });
    }, [newsItem]);

    const images = watch("news.images", []);

    const handleAddLink = () => {
        const textarea = document.querySelector(".news-item-editor textarea");
        const selectedText = textarea.value.substring(
            textarea.selectionStart,
            textarea.selectionEnd
        );

        if (selectedText) {
            setSelectionRange({
                start: textarea.selectionStart,
                end: textarea.selectionEnd,
            });
            setLinkDetails({ text: selectedText, url: "" });
            setLinkModalOpen(true);
        } else {
            alert("Please select some text to add a link");
        }
    };

    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files);
        const newImages = files.map((file) => ({
            file,
            url: URL.createObjectURL(file),
            shouldAdd: true,
        }));

        setValue("news.images", [...images, ...newImages], {
            shouldDirty: true,
        });
    };

    const handleDeleteImage = (index) => {
        let updatedImages = [];

        images.forEach((img, i) => {
            if (i === index) {
                if (img.ref) {
                    updatedImages.push({ ...img, shouldDelete: true });
                }
            } else {
                updatedImages.push(img);
            }
        });

        setValue("news.images", updatedImages, {
            shouldDirty: true,
        });
    };

    const handleLinkSubmit = () => {
        if (linkDetails.url) {
            const linkMarkdown = `[${linkDetails.text}](${linkDetails.url})`;
            const content = watch("news.content") || "";

            const newContent =
                content.substring(0, selectionRange.start) +
                linkMarkdown +
                content.substring(selectionRange.end);

            setValue("news.content", newContent, { shouldDirty: true });
        }
        setLinkModalOpen(false);
    };

    return (
        <>
            <ModalComponent
                title="Add Link"
                isOpen={linkModalOpen}
                toggle={() => setLinkModalOpen(false)}
                submitText="Insert Link"
                onSubmit={handleLinkSubmit}
            >
                <div className="link-modal-content">
                    <input
                        type="text"
                        placeholder="Link Text"
                        value={linkDetails.text}
                        onChange={(e) =>
                            setLinkDetails({
                                ...linkDetails,
                                text: e.target.value,
                            })
                        }
                    />
                    <input
                        type="url"
                        placeholder="URL (https://...)"
                        value={linkDetails.url}
                        onChange={(e) =>
                            setLinkDetails({
                                ...linkDetails,
                                url: e.target.value,
                            })
                        }
                    />
                </div>
            </ModalComponent>
            <div className="news-item-editor">
                <input
                    type="text"
                    placeholder="News Title"
                    {...register("news.title")}
                />
                <div className="textarea-container">
                    <textarea
                        placeholder="News Content"
                        {...register("news.content")}
                    />
                    <button className="add-link-button" onClick={handleAddLink}>
                        <LinkIcon /> Add Link
                    </button>
                </div>
                <div className="news-item-image-upload">
                    {images.map(
                        (image, index) =>
                            !image.shouldDelete && (
                                <div
                                    key={index}
                                    className="news-item-image-preview"
                                    style={{
                                        backgroundImage: `url(${image.url})`,
                                    }}
                                >
                                    <button
                                        className="delete-image-button"
                                        onClick={() => handleDeleteImage(index)}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </button>
                                </div>
                            )
                    )}
                    {images.filter((image) => !image.shouldDelete).length <
                        9 && (
                        <label className="news-item-image-preview image-upload-placeholder">
                            <input
                                type="file"
                                accept="image/*"
                                multiple
                                style={{ display: "none" }}
                                onChange={handleImageUpload}
                            />
                            <AddPhotoAlternateIcon />
                            <span>Add Image</span>
                        </label>
                    )}
                </div>
            </div>
        </>
    );
};

const Newsfeed = () => {
    const navigate = useNavigate();
    const [displayedLabs, setDisplayedLabs] = useState([]);
    const [randomizedListings, setRandomizedListings] = useState([]);
    const [currentListingIndex, setCurrentListingIndex] = useState(0);
    const [feedItems, setFeedItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [lastDoc, setLastDoc] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [likedPosts, setLikedPosts] = useState(new Set());
    const [postLikes, setPostLikes] = useState({});
    const [isPostModalOpen, setIsPostModalOpen] = useState(false);
    const [isPosting, setIsPosting] = useState(false);
    const methods = useForm();
    const { user } = UserAuth();

    const { data: labs, isLoading: labsLoading } = useQuery({
        queryKey: ["labs", { status: "approved" }],
        queryFn: fetchLabs,
        ...LabCacheParams,
    });

    const { data: listings, isLoading: listingsLoading } = useQuery({
        queryKey: ["newsfeed", "listings"],
        queryFn: fetchListings,
        ...ListingCacheParams,
    });

    const queryClient = useQueryClient();

    const fetchSuggestedConnections = async () => {
        try {
            const users = await fetchGet("/users", false);
            // Fetch individual user data to get profile pictures
            const usersWithPictures = await Promise.all(
                users.map(async (user) => {
                    const fullUserData = await fetchGet(
                        `/users/${user.id}`,
                        false
                    );
                    return {
                        ...user,
                        profilePicture: fullUserData.profilePicture || "",
                    };
                })
            );
            const shuffled = [...usersWithPictures].sort(
                () => 0.5 - Math.random()
            );
            return shuffled.slice(0, 5);
        } catch (error) {
            console.error("Error fetching suggested connections:", error);
            return [];
        }
    };

    const {
        data: suggestedConnections,
        isLoading: suggestedConnectionsLoading,
    } = useQuery({
        queryKey: ["connections"],
        queryFn: fetchSuggestedConnections,
        ...LabCacheParams,
    });

    const formatDisplayDate = (timestamp) => {
        if (!timestamp) return "Unknown Date";

        try {
            // Handle Firestore Timestamp object with _seconds and _nanoseconds
            if (timestamp._seconds) {
                const date = new Date(timestamp._seconds * 1000);
                return date.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                });
            }

            // Handle Firestore Timestamp with toDate method
            if (timestamp?.toDate) {
                return timestamp.toDate().toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                });
            }

            // Handle regular Date object or timestamp number
            const date = new Date(timestamp);
            if (!isNaN(date.getTime())) {
                return date.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                });
            }

            return "Unknown Date";
        } catch (error) {
            console.error("Error formatting date:", error, timestamp);
            return "Unknown Date";
        }
    };

    const fetchFeedItems = async (isInitial = false) => {
        if (isLoading || (!isInitial && !hasMore)) return;

        try {
            setIsLoading(true);

            // Create the initial query
            let newsQuery = query(
                collection(db, "News"),
                orderBy("timestamp", "desc"),
                limit(ITEMS_PER_PAGE)
            );

            // If not initial load and we have a last document, start after it
            if (!isInitial && lastDoc) {
                newsQuery = query(
                    collection(db, "News"),
                    orderBy("timestamp", "desc"),
                    startAfter(lastDoc),
                    limit(ITEMS_PER_PAGE)
                );
            }

            const newsSnapshot = await getDocs(newsQuery);

            // Store the last document for next pagination
            const lastVisible = newsSnapshot.docs[newsSnapshot.docs.length - 1];
            setLastDoc(lastVisible);

            // Check if we have more items to load
            setHasMore(newsSnapshot.docs.length === ITEMS_PER_PAGE);

            const newsList = [];

            // Process news items
            for (const doc of newsSnapshot.docs) {
                const newsData = {
                    id: doc.id,
                    ...doc.data(),
                    type: "news",
                    likes: doc.data().likes || 0,
                };
                const labData = await fetchGet(
                    `/labs/${newsData.labId}`,
                    false
                );
                newsData.labLogo = labData.logo;
                newsData.labName = labData.name;

                // Handle multiple images
                if (newsData.imageRefs && Array.isArray(newsData.imageRefs)) {
                    const imageUrls = await Promise.all(
                        newsData.imageRefs.map(async (imageRef) => {
                            try {
                                return await getDownloadURL(
                                    ref(storage, imageRef)
                                );
                            } catch (error) {
                                console.error("Error fetching image:", error);
                                return null;
                            }
                        })
                    );
                    newsData.imageUrls = imageUrls.filter(
                        (url) => url !== null
                    );
                }

                newsList.push(newsData);
            }

            // Fetch all publications that are set to display in lab profiles
            const publicationsQuery = query(collection(db, "Publications"));
            const publicationsSnapshot = await getDocs(publicationsQuery);
            const publicationsList = [];

            // Process publications
            for (const doc of publicationsSnapshot.docs) {
                const pubData = {
                    id: doc.id,
                    ...doc.data(),
                    type: "publication",
                };

                // Only include publications that are set to display in any lab profile
                if (pubData.displayInLabs?.length > 0) {
                    // Get date 3 months ago
                    const threeMonthsAgo = new Date();
                    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

                    // Check publication date
                    const pubDate =
                        pubData.publicationDate instanceof Timestamp
                            ? pubData.publicationDate.toDate()
                            : pubData.publicationDate?._seconds
                            ? new Date(pubData.publicationDate._seconds * 1000)
                            : new Date(pubData.publicationDate);

                    if (pubDate >= threeMonthsAgo) {
                        // For each lab that should display this publication
                        for (const labId of pubData.displayInLabs) {
                            try {
                                const labData = await fetchGet(
                                    `/labs/${labId}`,
                                    false
                                );
                                publicationsList.push({
                                    ...pubData,
                                    labId,
                                    labName: labData.name,
                                    labLogo: labData.logo,
                                    timestamp:
                                        pubData.displayInProfileTimestamp,
                                    author: pubData.creatorName,
                                    authorId: pubData.creatorId,
                                    title: pubData.title,
                                    content: pubData.description,
                                    imageUrls: pubData.images || null,
                                });
                            } catch (error) {
                                console.error(
                                    "Error fetching lab data for publication:",
                                    error
                                );
                            }
                        }
                    }
                }
            }

            // Combine all items and sort by timestamp
            const getTimestamp = (item) => {
                const timestamp =
                    item.type === "publication"
                        ? item.displayInProfileTimestamp
                        : item.timestamp;

                // Handle Firestore Timestamp
                if (timestamp instanceof Timestamp) {
                    return timestamp.toMillis();
                }

                // Handle timestamp with _seconds
                if (timestamp?._seconds) {
                    return timestamp._seconds * 1000;
                }

                // Handle Date object or timestamp number
                if (timestamp instanceof Date) {
                    return timestamp.getTime();
                }

                // Handle string date
                if (typeof timestamp === "string") {
                    return new Date(timestamp).getTime();
                }

                return 0; // fallback
            };

            const allItems = [...newsList, ...publicationsList].sort((a, b) => {
                return getTimestamp(b) - getTimestamp(a);
            });

            // Update feedItems based on whether it's initial load or not
            setFeedItems((prevItems) =>
                isInitial ? allItems : [...prevItems, ...allItems]
            );
        } catch (error) {
            console.error("Error fetching feed items:", error);
        } finally {
            setIsLoading(false);
        }
    };

    // Initial load
    useEffect(() => {
        fetchFeedItems(true);
    }, []);

    const SCROLL_DELAY = 500;

    useEffect(() => {
        let timeoutId;

        const handleScroll = () => {
            if (
                window.innerHeight + window.scrollY >=
                    document.documentElement.scrollHeight - 1000 &&
                !isLoading &&
                hasMore
            ) {
                // Clear any existing timeout
                if (timeoutId) clearTimeout(timeoutId);

                // Set a new timeout
                timeoutId = setTimeout(() => {
                    fetchFeedItems(false);
                }, SCROLL_DELAY);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [isLoading, hasMore, lastDoc]);

    // Updated to randomize listings initially, then scroll through them
    useEffect(() => {
        if (listings?.documents?.length > 0) {
            // Randomize the listings order initially
            const shuffled = [...listings.documents].sort(
                () => 0.5 - Math.random()
            );

            // Don't add additional padding entries
            setRandomizedListings(shuffled);
            setCurrentListingIndex(0);

            // Set up interval to scroll through the listings
            const interval = setInterval(() => {
                setCurrentListingIndex((prevIndex) => {
                    // Calculate how many complete sets of 3 we can show
                    const maxSets = Math.floor(shuffled.length / 3);
                    const nextIndex = prevIndex + 3;

                    // If we've reached or exceeded the last complete set, return to start
                    if (prevIndex >= (maxSets - 1) * 3) {
                        return 0;
                    }
                    return nextIndex;
                });
            }, 10000);

            return () => clearInterval(interval);
        }
    }, [listings]);

    // Function to get the current visible listings
    const getVisibleListings = () => {
        if (!randomizedListings.length) return [];
        if (randomizedListings.length <= 3) return randomizedListings;

        // Ensure we always have 3 items, handling edge cases
        const result = [];
        for (let i = 0; i < 3; i++) {
            const index = currentListingIndex + i;
            // Make sure we don't go beyond the array bounds
            if (index < randomizedListings.length) {
                result.push(randomizedListings[index]);
            }
        }
        return result;
    };

    useEffect(() => {
        const updateDisplayedLabs = () => {
            const shuffled = [...labs.documents].sort(
                () => 0.5 - Math.random()
            );
            setDisplayedLabs(shuffled.slice(0, 3));
        };

        if (labs?.documents?.length > 0) {
            updateDisplayedLabs();
            const interval = setInterval(updateDisplayedLabs, 10000);
            return () => clearInterval(interval);
        }
    }, [labs]);

    const handleListingClick = (listing) => {
        if (
            listing.type === "equipment-rental" ||
            listing.type === "training-expertise"
        ) {
            navigate(`/listingrental/${listing.id}`);
        } else {
            navigate(`/listing/${listing.id}`);
        }
    };

    const renderContent = (content) => {
        if (!content) return "";

        // First handle markdown links with target="_blank"
        let processedContent = content.replace(
            /\[([^\]]+)\]\(([^)]+)\)/g,
            (match, text, url) => {
                const fullUrl = url.startsWith("http") ? url : `https://${url}`;
                return `<a href="${fullUrl}" target="_blank" rel="noopener noreferrer" class="nf-content-link" onclick="event.stopPropagation()">${text}</a>`;
            }
        );

        // Handle line breaks
        processedContent = processedContent
            // Replace double line breaks with paragraph tags
            .replace(/\n\n+/g, "</p><p>")
            // Replace single line breaks with <br>
            .replace(/\n/g, "<br>")
            // Bold text
            .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
            // Italic text
            .replace(/\*(.*?)\*/g, "<em>$1</em>");

        // Wrap in paragraph tags if not already wrapped
        if (!processedContent.startsWith("<p>")) {
            processedContent = `<p>${processedContent}</p>`;
        }

        return processedContent;
    };

    const handleLike = async (itemId, e) => {
        e.stopPropagation();
        const newsRef = doc(db, "News", itemId);
        const isLiked = likedPosts.has(itemId);

        try {
            // Update the likes count in the database
            await updateDoc(newsRef, {
                likes: increment(isLiked ? -1 : 1),
            });

            // Update local state
            setLikedPosts((prev) => {
                const newLiked = new Set(prev);
                if (isLiked) {
                    newLiked.delete(itemId);
                } else {
                    newLiked.add(itemId);
                }
                return newLiked;
            });

            // Update the likes count in the feed items
            setFeedItems((prev) =>
                prev.map((item) => {
                    if (item.id === itemId) {
                        return {
                            ...item,
                            likes: item.likes + (isLiked ? -1 : 1),
                        };
                    }
                    return item;
                })
            );
        } catch (error) {
            console.error("Error updating likes:", error);
        }
    };

    useEffect(() => {
        document.title = "Newsfeed | LabGiant";
        document
            .querySelector('meta[name="description"]')
            .setAttribute(
                "content",
                "Stay updated with the latest news, research findings, and publications across all areas of research."
            );
    }, []);

    // Modify the handlePostSubmit function
    const handlePostSubmit = async () => {
        try {
            setIsPosting(true);
            const data = methods.getValues("news");
            const { images, ...newsData } = data;

            const formData = new FormData();
            const postData = {
                ...newsData,
                timestamp: new Date(),
                createdAt: new Date(),
                updatedAt: new Date(),
                labId: user.labId,
                type: "news",
                author: user.firstName + " " + user.lastName,
                authorId: user.uid,
            };

            formData.append("news", JSON.stringify(postData));
            images.forEach((image, idx) => {
                formData.append(`image_${idx}`, image.file);
            });

            await fetchPost(`/labs/${user.labId}/news`, formData);

            // Refresh feed items
            await fetchFeedItems(true);
            setIsPostModalOpen(false);
            methods.reset();
        } catch (error) {
            console.error("Error creating post:", error);
        } finally {
            setIsPosting(false);
        }
    };

    const { mutate: postNewsMutation } = useMutation({
        mutationFn: () => handlePostSubmit(),
        onSuccess: () => {
            if (user?.labId) {
                queryClient.invalidateQueries({
                    queryKey: ["lab", user.labId, "news"],
                });
            }
        },
    });

    if (labsLoading || listingsLoading || suggestedConnectionsLoading)
        return <Loading />;

    return (
        <>
            <Helmet>
                <link
                    rel="canonical"
                    href={`${process.env.REACT_APP_FRONTEND_URL}/newsfeed`}
                />
            </Helmet>
            <div className="nf-newsfeed-container">
                <main className="nf-newsfeed-main">
                    <div className="nf-newsfeed-grid">
                        <div className="nf-feed-column">
                            <div className="nf-feed-header">
                                <h1 className="nf-section-title">
                                    Giant Board
                                </h1>
                                <h2 className="nf-section-subtitle">
                                    The latest news, research findings, and
                                    publications across all areas of research.
                                </h2>
                                <div
                                    className={`nf-create-post ${
                                        !user?.labId ? "disabled" : ""
                                    }`}
                                    onClick={() =>
                                        user?.labId && setIsPostModalOpen(true)
                                    }
                                    title={
                                        !user?.labId
                                            ? "You must be associated with a lab to post."
                                            : ""
                                    }
                                    disabled={!user?.labId}
                                >
                                    <div className="nf-post-avatar">
                                        {user?.profilePicture ? (
                                            <img
                                                src={user.profilePicture}
                                                alt=""
                                            />
                                        ) : (
                                            <div className="nf-connection-avatar" />
                                        )}
                                    </div>
                                    <div className="nf-post-input">
                                        <span className="nf-post-placeholder">
                                            What's new in your lab?
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {feedItems.map((item, index) => (
                                <div key={index} className="nf-feed-card">
                                    <div className="nf-card-header">
                                        <div className="nf-header-content">
                                            <div
                                                className="nf-avatar"
                                                onClick={() =>
                                                    navigate(
                                                        `/lab/${item.labId}/overview`
                                                    )
                                                }
                                                style={{ cursor: "pointer" }}
                                            >
                                                <img
                                                    src={item.labLogo}
                                                    alt=""
                                                    className="nf-avatar-image"
                                                />
                                            </div>
                                            <div className="nf-header-text">
                                                <h3>{item.title}</h3>
                                                <div className="nf-author-info">
                                                    {item.author && (
                                                        <p
                                                            className="nf-subtitle"
                                                            onClick={() =>
                                                                navigate(
                                                                    `/profile/${item.authorId}`
                                                                )
                                                            }
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            {item.author}
                                                        </p>
                                                    )}
                                                    <span
                                                        className="nf-lab-name"
                                                        onClick={() =>
                                                            navigate(
                                                                `/lab/${item.labId}/overview`
                                                            )
                                                        }
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        @{item.labName}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="nf-badge">
                                            {item.type === "news" ? (
                                                <>
                                                    <NewspaperIcon /> News
                                                </>
                                            ) : (
                                                <>
                                                    <MenuBookIcon /> Publication
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="nf-card-content">
                                        {item.type === "publication" &&
                                            item.imageUrls && (
                                                <NewsImageCarousel
                                                    images={item.imageUrls}
                                                />
                                            )}
                                        <div
                                            style={{
                                                marginTop:
                                                    item.type ===
                                                        "publication" &&
                                                    item.imageUrl
                                                        ? "16px"
                                                        : "0",
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: renderContent(
                                                    item.content
                                                ),
                                            }}
                                            className="nf-card-content-text"
                                        />
                                        {item.type === "publication" && (
                                            <p className="nf-publication-meta">
                                                Published on{" "}
                                                {new Date(
                                                    item.publicationDate
                                                ).toLocaleDateString()}{" "}
                                                in
                                                <span className="nf-publication-journal">
                                                    {" "}
                                                    {item.journal}
                                                </span>
                                            </p>
                                        )}
                                        {item.type === "news" && (
                                            <NewsImageCarousel
                                                images={item.imageUrls}
                                            />
                                        )}
                                    </div>
                                    <div className="nf-card-footer">
                                        <span>
                                            {formatDisplayDate(
                                                item.type === "publication"
                                                    ? item.displayInProfileTimestamp
                                                    : item.timestamp
                                            )}
                                        </span>
                                        <div className="nf-interaction-buttons">
                                            <button
                                                className={`nf-like-button ${
                                                    likedPosts.has(item.id)
                                                        ? "nf-liked"
                                                        : ""
                                                }`}
                                                onClick={(e) =>
                                                    handleLike(item.id, e)
                                                }
                                            >
                                                <span className="nf-like-icon">
                                                    {likedPosts.has(item.id) ? (
                                                        <FavoriteIcon
                                                            style={{
                                                                color: "#ff4081",
                                                            }}
                                                        />
                                                    ) : (
                                                        <FavoriteBorderIcon />
                                                    )}
                                                </span>
                                                <span className="nf-like-count">
                                                    {item.likes || 0}
                                                </span>
                                            </button>
                                            {item.type === "publication" && (
                                                <div className="nf-publication-links">
                                                    <a
                                                        href={item.htmlLink}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="nf-publication-link"
                                                    >
                                                        Article HTML
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="nf-sidebar-column">
                            {/* Marketplace Card */}
                            <div className="nf-sidebar-card">
                                <h3
                                    className="nf-marketplace-title"
                                    onClick={() => navigate("/listings")}
                                >
                                    Accessible Resources & Technologies
                                </h3>
                                <div className="nf-marketplace-items-container">
                                    <div
                                        className="nf-marketplace-items-wrapper"
                                        style={{
                                            transform:
                                                randomizedListings.length > 0
                                                    ? `translateY(-${
                                                          Math.floor(
                                                              currentListingIndex /
                                                                  3
                                                          ) * 110
                                                      }px)`
                                                    : "translateY(0)",
                                            transition:
                                                "transform 0.5s ease-in-out",
                                        }}
                                    >
                                        {randomizedListings.map(
                                            (item, index) => (
                                                <div
                                                    key={`listing-${index}`}
                                                    className="nf-marketplace-item"
                                                    onClick={() =>
                                                        handleListingClick(item)
                                                    }
                                                >
                                                    <img
                                                        src={item.img}
                                                        alt={item.title}
                                                        className="nf-marketplace-image"
                                                    />
                                                    <div className="nf-marketplace-item-content">
                                                        <h4>{item.title}</h4>
                                                        <p className="nf-lab-name">
                                                            {item.labName}
                                                        </p>
                                                        <div className="nf-price">
                                                            {item.type ===
                                                            "digital-good" ? (
                                                                <div className="nf-listing-price">
                                                                    Free access
                                                                </div>
                                                            ) : item.price
                                                                  .value ===
                                                              0 ? (
                                                                <div className="nf-listing-price">
                                                                    Free access
                                                                </div>
                                                            ) : item.pricingType ===
                                                                  "CUSTOM" ||
                                                              item.price
                                                                  .value >=
                                                                  9999 ? (
                                                                <div className="nf-listing-price">
                                                                    Contact for
                                                                    pricing
                                                                </div>
                                                            ) : (
                                                                <div className="nf-listing-price">
                                                                    <span
                                                                        style={{
                                                                            fontWeight: 600,
                                                                        }}
                                                                    >
                                                                        $
                                                                        {
                                                                            item
                                                                                .price
                                                                                .value
                                                                        }
                                                                    </span>{" "}
                                                                    <span className="nf-listing-unit">
                                                                        per{" "}
                                                                        {item
                                                                            .price
                                                                            .customUnits
                                                                            ? item
                                                                                  .price
                                                                                  .customUnits
                                                                            : item
                                                                                  .price
                                                                                  .units}
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* Suggested Labs Card */}
                            <div className="nf-sidebar-card">
                                <h3
                                    className="nf-marketplace-title"
                                    onClick={() => navigate("/labs")}
                                >
                                    Labs to Explore
                                </h3>
                                <ul className="nf-suggestion-list">
                                    {displayedLabs.map((lab) => (
                                        <li
                                            key={lab.id}
                                            className="nf-suggestion-item"
                                            onClick={() =>
                                                navigate(
                                                    `/lab/${lab.id}/overview`
                                                )
                                            }
                                        >
                                            <div className="nf-lab-avatar">
                                                <img
                                                    src={lab.logo}
                                                    alt={lab.name}
                                                />
                                            </div>
                                            <div className="nf-suggestion-content">
                                                <p className="nf-lab-title">
                                                    {lab.name}
                                                </p>
                                                <div className="nf-lab-details">
                                                    <span>
                                                        <AccountBalanceIcon
                                                            style={{
                                                                fontSize:
                                                                    "12px",
                                                            }}
                                                        />
                                                        {lab.institutionName}
                                                    </span>
                                                    <span>
                                                        <ScienceIcon
                                                            style={{
                                                                fontSize:
                                                                    "12px",
                                                            }}
                                                        />
                                                        {lab.departmentName}
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* Suggested Connections Card */}
                            <div className="nf-sidebar-card">
                                <h3 className="nf-marketplace-title">
                                    Suggested Connections
                                </h3>
                                <ul className="nf-suggestion-list">
                                    {suggestedConnections.map((connection) => (
                                        <li
                                            key={connection.id}
                                            className="nf-suggestion-item"
                                            onClick={() =>
                                                navigate(
                                                    `/profile/${connection.id}`
                                                )
                                            }
                                        >
                                            <div className="nf-connection-avatar">
                                                <img
                                                    src={
                                                        connection.profilePicture ||
                                                        ""
                                                    }
                                                    alt={`${connection.firstName} ${connection.lastName}`}
                                                    onError={(e) => {
                                                        e.target.style.display =
                                                            "none";
                                                    }}
                                                />
                                            </div>
                                            <div className="nf-suggestion-content">
                                                <p className="nf-connection-name">
                                                    {connection.firstName}{" "}
                                                    {connection.lastName}
                                                </p>
                                                <p className="nf-connection-lab">
                                                    {connection.labName}
                                                </p>
                                                <p className="nf-connection-institution">
                                                    {connection.institutionName}
                                                </p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>

                    {hasMore && (
                        <div
                            className={`nf-loading ${
                                isLoading ? "visible" : ""
                            }`}
                        >
                            <CircularProgress size={24} />
                            <span>Loading more news...</span>
                        </div>
                    )}

                    {!hasMore && (
                        <div className="nf-no-more">No more items to load</div>
                    )}
                </main>
            </div>

            <FormProvider {...methods}>
                <ModalComponent
                    title="Create Post"
                    isOpen={isPostModalOpen}
                    toggle={() => {
                        setIsPostModalOpen(false);
                        methods.reset();
                    }}
                    submitText={
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                            }}
                        >
                            {isPosting && (
                                <CircularProgress size={16} color="inherit" />
                            )}
                            Post
                        </div>
                    }
                    onSubmit={postNewsMutation}
                    disabled={isPosting}
                >
                    <NewsEditorContent newsItem={null} />
                </ModalComponent>
            </FormProvider>
        </>
    );
};

export default Newsfeed;
