import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
    ArrowBack as ArrowBackIcon,
    ArrowForward as ArrowForwardIcon,
    Psychology as PsychologyIcon,
    ExpandMore as ExpandMoreIcon,
    LinkedIn as LinkedInIcon,
    Email as EmailIcon,
    Science as ScienceIcon,
    Newspaper as NewspaperIcon,
    School as SchoolIcon,
    ViewList as ViewListIcon,
} from "@mui/icons-material";
import Signup from "./Signup";
import brainstormImage from "../assets/brainstorm.png";
import experimentImage from "../assets/experiment.png";
import shareImage from "../assets/share.png";
import { Helmet } from "react-helmet";
import { motion, AnimatePresence, useScroll, useInView } from "framer-motion";

import "../styles/Home.css";

const Home = () => {
    const [isLogin, setIsLogin] = useState(false);
    const [triggerHighlight, setTriggerHighlight] = useState(false);
    const [expandedFaq, setExpandedFaq] = useState(null);
    const location = useLocation();
    let navigate = useNavigate();
    const signupRef = useRef(null);
    const heroRef = useRef(null);
    const howItWorksRef = useRef(null);
    const researchAssistantRef = useRef(null);
    const faqRef = useRef(null);

    const heroInView = useInView(heroRef, { once: false, amount: 0.3 });
    const howItWorksInView = useInView(howItWorksRef, {
        once: false,
        amount: 0.3,
    });
    const researchAssistantInView = useInView(researchAssistantRef, {
        once: false,
        amount: 0.3,
    });
    const faqInView = useInView(faqRef, { once: false, amount: 0.3 });

    const { scrollYProgress } = useScroll();

    const isMobile = window.innerWidth <= 768;

    const getAnimationProps = (inView, direction) => {
        if (isMobile) {
            return {
                initial: { y: 20, opacity: 0 },
                animate: inView ? { y: 0, opacity: 1 } : { y: 20, opacity: 0 },
                transition: { duration: 0.4 },
            };
        } else {
            if (direction === "left") {
                return {
                    initial: { x: -50, opacity: 0 },
                    animate: inView
                        ? { x: 0, opacity: 1 }
                        : { x: -50, opacity: 0 },
                    transition: { duration: 0.5 },
                };
            } else if (direction === "right") {
                return {
                    initial: { x: 50, opacity: 0 },
                    animate: inView
                        ? { x: 0, opacity: 1 }
                        : { x: 50, opacity: 0 },
                    transition: { duration: 0.5 },
                };
            } else {
                return {
                    initial: { y: 50, opacity: 0 },
                    animate: inView
                        ? { y: 0, opacity: 1 }
                        : { y: 50, opacity: 0 },
                    transition: { duration: 0.6 },
                };
            }
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const mode = params.get("mode");
        if (mode === "login" || mode === "signup") {
            setIsLogin(mode === "login");
            scrollToSignup();
            setTriggerHighlight((prev) => !prev); // Toggle to trigger highlight
        }
    }, [location]);

    const scrollToSignup = () => {
        if (signupRef.current) {
            setTimeout(() => {
                const yOffset = window.innerWidth <= 986 ? -100 : -200; // Adjusted offset for mobile
                const element = signupRef.current;
                const y =
                    element.getBoundingClientRect().top +
                    window.pageYOffset +
                    yOffset;

                window.scrollTo({ top: y, behavior: "smooth" });
            }, 100);
        }
    };

    useEffect(() => {
        document.title = "Get Started | LabGiant";
        document
            .querySelector('meta[name="description"]')
            .setAttribute(
                "content",
                "Discover and connect through our collaboration engine for leading research lab resources and technologies."
            );
    }, []);

    const handleFaqClick = (index) => {
        setExpandedFaq(expandedFaq === index ? null : index);
    };

    const faqItems = [
        {
            question: "What is LabGiant?",
            answer: "LabGiant is a collaboration engine connecting researchers with leading lab resources and technologies. Our platform enables discovery, connection, and collaboration between research labs worldwide.",
        },
        {
            question: "How do I join a lab on LabGiant?",
            answer: "After signing up, you can search for your lab and request to join. Lab administrators will approve your request, granting you access to your lab's profile and resources.",
        },
        {
            question: "Can I list my lab's equipment or services?",
            answer: "Yes! Principal investigators and lab managers can create a lab profile and list available equipment, services, and expertise that other researchers can discover and utilize.",
        },
        {
            question: "Is LabGiant free to use?",
            answer: "LabGiant offers both free and premium tiers. Basic discovery and profile creation are free, while advanced collaboration features and Research Assistant AI capabilities are available in our premium plans.",
        },
        {
            question: "How does the Research Assistant work?",
            answer: "Our AI-powered Research Assistant analyzes your research goals and connects you with relevant equipment, methods, and expertise you might not have discovered otherwise. It generates novel research directions and creative solutions based on resources available across our platform.",
        },
    ];

    return (
        <>
            <Helmet>
                <link
                    rel="canonical"
                    href={`${process.env.REACT_APP_FRONTEND_URL}/pricing`}
                />
            </Helmet>
            <div className="home-container">
                <motion.div
                    className="hero-image"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1.5 }}
                >
                    {/* Hero image will be set as background in CSS */}
                    <motion.div
                        className="scroll-indicator"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                            delay: 2,
                            duration: 1,
                            repeat: Infinity,
                            repeatType: "reverse",
                        }}
                        onClick={() => {
                            if (howItWorksRef.current) {
                                howItWorksRef.current.scrollIntoView({
                                    behavior: "smooth",
                                });
                            }
                        }}
                    >
                        <ExpandMoreIcon fontSize="large" />
                        <span>Scroll to learn more</span>
                    </motion.div>
                </motion.div>
                <div className="scrollable-content">
                    <motion.div className="hero-content" ref={heroRef}>
                        <motion.div
                            className="hero-text"
                            initial={{ x: -100, opacity: 0 }}
                            animate={
                                heroInView
                                    ? { x: 0, opacity: 1 }
                                    : { x: -100, opacity: 0 }
                            }
                            transition={{ duration: 0.8, ease: "easeOut" }}
                        >
                            <h1>Stand on the shoulders of GIANTS</h1>
                            <h2>
                                Discover and connect through our collaboration
                                engine for leading research lab resources and
                                technologies
                            </h2>
                            <motion.div
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <Button
                                    variant="contained"
                                    className="hero-image-button"
                                    onClick={() => navigate("/labs")}
                                >
                                    Browse research labs
                                </Button>
                            </motion.div>
                        </motion.div>
                        <motion.div
                            className="signup-section"
                            ref={signupRef}
                            initial={{ x: 100, opacity: 0 }}
                            animate={
                                heroInView
                                    ? { x: 0, opacity: 1 }
                                    : { x: 100, opacity: 0 }
                            }
                            transition={{
                                duration: 0.8,
                                delay: 0.2,
                                ease: "easeOut",
                            }}
                        >
                            <div className="signup-content">
                                <div className="signup-hero-container">
                                    <Signup
                                        isLogin={isLogin}
                                        setIsLogin={setIsLogin}
                                        triggerHighlight={triggerHighlight}
                                    />
                                </div>
                                <AnimatePresence mode="wait">
                                    {!isLogin ? (
                                        <motion.div
                                            className="signup-cta"
                                            key="signup-cta"
                                            initial={{ y: "100%", opacity: 0 }}
                                            animate={{ y: 0, opacity: 1 }}
                                            exit={{ y: "-100%", opacity: 0 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <div className="signup-cta-steps">
                                                <h4>Next Steps:</h4>
                                                <ol>
                                                    <li>
                                                        <span className="step-icon">
                                                            <span className="step-number">
                                                                1
                                                            </span>
                                                            <ArrowBackIcon className="step-arrow" />
                                                        </span>
                                                        Join your lab.
                                                    </li>
                                                    <li>
                                                        <span className="step-icon">
                                                            <span className="step-number">
                                                                2
                                                            </span>
                                                        </span>
                                                        Create your personal
                                                        research profile.
                                                    </li>
                                                </ol>
                                            </div>
                                            <p className="signup-cta-text">
                                                Principal investigator or core
                                                facility lab manager?
                                            </p>
                                            <a
                                                className="create-lab-link"
                                                onClick={() =>
                                                    navigate("/create-lab-pi")
                                                }
                                            >
                                                <ArrowForwardIcon /> Create your
                                                lab profile
                                            </a>
                                        </motion.div>
                                    ) : (
                                        <motion.div
                                            className="signup-cta"
                                            key="login-cta"
                                            initial={{ y: "100%", opacity: 0 }}
                                            animate={{ y: 0, opacity: 1 }}
                                            exit={{ y: "-100%", opacity: 0 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <p className="signup-cta-text">
                                                Already a member?
                                            </p>
                                            <h4>Dive back into innovation!</h4>
                                            <p className="login-cta-text">
                                                <ArrowBackIcon className="login-arrow" />
                                                Log in to continue.
                                            </p>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                        </motion.div>
                    </motion.div>

                    <motion.section
                        className="landingPageSection howItWorksSection"
                        ref={howItWorksRef}
                    >
                        <motion.div
                            className="howItWorksContent"
                            initial={{ y: 50, opacity: 0 }}
                            animate={
                                howItWorksInView
                                    ? { y: 0, opacity: 1 }
                                    : { y: 50, opacity: 0 }
                            }
                            transition={{ duration: 0.6 }}
                        >
                            <div className="landingPageTitleText">
                                <h3>Your Research Journey</h3>
                                <p className="journey-subtitle">
                                    From ideas to impact, seamlessly powered by
                                    LabGiant
                                </p>
                            </div>

                            <div className="journey-container">
                                <div className="journey-background"></div>

                                <motion.div
                                    className="journey-card"
                                    {...getAnimationProps(
                                        howItWorksInView,
                                        "left"
                                    )}
                                    whileHover={{
                                        y: -10,
                                        boxShadow:
                                            "0 15px 30px rgba(0, 0, 0, 0.1)",
                                    }}
                                >
                                    <div className="journey-card-left">
                                        <div className="journey-card-number">
                                            1
                                        </div>
                                        <h4>Brainstorm & Ideate</h4>
                                        <p className="journey-card-subtitle">
                                            Fuel your next breakthrough with
                                            creative solutions drawn from your
                                            surroundings.
                                        </p>
                                        <div className="journey-card-text">
                                            <p>
                                                Our in-house AI goes beyond idea
                                                generation. It helps you think
                                                creatively by surfacing nearby
                                                expertise, overlooked tools, and
                                                unexpected strategies. Whether
                                                you're developing a new
                                                hypothesis or working through a
                                                research challenge, we guide you
                                                toward practical solutions using
                                                the resources already around
                                                you.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="journey-illustration">
                                        <img
                                            src={brainstormImage}
                                            alt="Brainstorm"
                                            className="journey-svg"
                                        />
                                    </div>

                                    <motion.div
                                        className="journey-card-button-container"
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                    >
                                        <Button
                                            variant="outlined"
                                            className="journey-step-button"
                                            onClick={() =>
                                                navigate("/labassistantchat")
                                            }
                                        >
                                            Spark Your Research
                                        </Button>
                                    </motion.div>
                                </motion.div>

                                <motion.div
                                    className="journey-card"
                                    {...getAnimationProps(
                                        howItWorksInView,
                                        "right"
                                    )}
                                    whileHover={{
                                        y: -10,
                                        boxShadow:
                                            "0 15px 30px rgba(0, 0, 0, 0.1)",
                                    }}
                                >
                                    <div className="journey-card-left">
                                        <div className="journey-card-number">
                                            2
                                        </div>
                                        <h4>Collaborate & Experiment</h4>
                                        <p className="journey-card-subtitle">
                                            Find the right tools, experts, and
                                            resources to bring your research to
                                            life.
                                        </p>
                                        <div className="journey-card-text">
                                            <p>
                                                Once you have a viable idea,
                                                access the equipment, reagents,
                                                and expertise needed to conduct
                                                your experiments. Connect with
                                                researchers, secure lab
                                                resources, and streamline your
                                                workflow—all in one place.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="journey-illustration">
                                        <img
                                            src={experimentImage}
                                            alt="Experiment"
                                            className="journey-svg"
                                        />
                                    </div>

                                    <motion.div
                                        className="journey-card-button-container"
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                    >
                                        <Button
                                            variant="outlined"
                                            className="journey-step-button"
                                            onClick={() =>
                                                navigate("/listings")
                                            }
                                        >
                                            Discover Resources
                                        </Button>
                                    </motion.div>
                                </motion.div>

                                <motion.div
                                    className="journey-card"
                                    {...getAnimationProps(
                                        howItWorksInView,
                                        "left"
                                    )}
                                    whileHover={{
                                        y: -10,
                                        boxShadow:
                                            "0 15px 30px rgba(0, 0, 0, 0.1)",
                                    }}
                                >
                                    <div className="journey-card-left">
                                        <div className="journey-card-number">
                                            3
                                        </div>
                                        <h4>Share & Impact</h4>
                                        <p className="journey-card-subtitle">
                                            Showcase your research and amplify
                                            its reach.
                                        </p>
                                        <div className="journey-card-text">
                                            <p>
                                                Disseminate your findings
                                                through our built-in research
                                                community. Share publications,
                                                lab updates, and breakthroughs
                                                on our platform, ensuring your
                                                work gets the visibility and
                                                recognition it deserves.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="journey-illustration">
                                        <img
                                            src={shareImage}
                                            alt="Share"
                                            className="journey-svg"
                                        />
                                    </div>

                                    <motion.div
                                        className="journey-card-button-container"
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                    >
                                        <Button
                                            variant="outlined"
                                            className="journey-step-button"
                                            onClick={() =>
                                                navigate("/newsfeed")
                                            }
                                        >
                                            Amplify Your Impact
                                        </Button>
                                    </motion.div>
                                </motion.div>

                                <motion.div
                                    className="journey-start-button"
                                    initial={{ opacity: 0, scale: 0.8 }}
                                    animate={
                                        howItWorksInView
                                            ? { opacity: 1, scale: 1 }
                                            : { opacity: 0, scale: 0.8 }
                                    }
                                    transition={{ duration: 0.5, delay: 0.8 }}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    <Button
                                        variant="contained"
                                        className="start-journey-button"
                                        onClick={() => navigate("/labs")}
                                    >
                                        Begin Your Research Journey
                                    </Button>
                                </motion.div>
                            </div>
                        </motion.div>
                    </motion.section>

                    <motion.section
                        className="landingPageSection researchAssistantSection"
                        ref={researchAssistantRef}
                    >
                        <motion.div
                            className="researchAssistantContent"
                            initial={{ y: 50, opacity: 0 }}
                            animate={
                                researchAssistantInView
                                    ? { y: 0, opacity: 1 }
                                    : { y: 50, opacity: 0 }
                            }
                            transition={{ duration: 0.6 }}
                        >
                            <div className="landingPageTitleText">
                                <h3>Your AI Research Assistant</h3>
                                <h4>Revolutionizing how research is done</h4>
                            </div>

                            <div className="researchAssistantFeaturesContainer">
                                <motion.div
                                    className="researchAssistantVisual"
                                    initial={{ scale: 0.8, opacity: 0 }}
                                    animate={
                                        researchAssistantInView
                                            ? { scale: 1, opacity: 1 }
                                            : { scale: 0.8, opacity: 0 }
                                    }
                                    transition={{ duration: 0.7, delay: 0.2 }}
                                >
                                    <div className="researchAssistantIcon">
                                        <PsychologyIcon
                                            style={{
                                                fontSize: "150px",
                                                color: "#3f51b5",
                                            }}
                                        />
                                    </div>
                                </motion.div>

                                <div className="researchAssistantFeatures">
                                    <motion.div
                                        className="featureItem"
                                        initial={{ x: 50, opacity: 0 }}
                                        animate={
                                            researchAssistantInView
                                                ? { x: 0, opacity: 1 }
                                                : { x: 50, opacity: 0 }
                                        }
                                        transition={{
                                            duration: 0.5,
                                            delay: 0.3,
                                        }}
                                    >
                                        <h5>
                                            Generate New Research Directions
                                        </h5>
                                        <p>
                                            Our AI analyzes research patterns
                                            and suggests novel paths and
                                            methodologies you might not have
                                            considered, expanding your research
                                            horizons.
                                        </p>
                                    </motion.div>

                                    <motion.div
                                        className="featureItem"
                                        initial={{ x: 50, opacity: 0 }}
                                        animate={
                                            researchAssistantInView
                                                ? { x: 0, opacity: 1 }
                                                : { x: 50, opacity: 0 }
                                        }
                                        transition={{
                                            duration: 0.5,
                                            delay: 0.5,
                                        }}
                                    >
                                        <h5>Find Creative Solutions</h5>
                                        <p>
                                            Stuck with a challenging research
                                            problem? Our AI provides creative
                                            approaches by leveraging resources
                                            already around you, analyzing
                                            similar challenges across
                                            disciplines, and connecting
                                            solutions from your local research
                                            environment that you might have
                                            overlooked.
                                        </p>
                                    </motion.div>

                                    <motion.div
                                        className="featureItem"
                                        initial={{ x: 50, opacity: 0 }}
                                        animate={
                                            researchAssistantInView
                                                ? { x: 0, opacity: 1 }
                                                : { x: 50, opacity: 0 }
                                        }
                                        transition={{
                                            duration: 0.5,
                                            delay: 0.7,
                                        }}
                                    >
                                        <h5>
                                            Discover Unknown Equipment Needs
                                        </h5>
                                        <p>
                                            Based on your research goals, our AI
                                            recommends equipment and
                                            technologies you might not know you
                                            need, enhancing experimental
                                            efficiency and outcomes.
                                        </p>
                                    </motion.div>

                                    <motion.div
                                        className="featureItem"
                                        initial={{ x: 50, opacity: 0 }}
                                        animate={
                                            researchAssistantInView
                                                ? { x: 0, opacity: 1 }
                                                : { x: 50, opacity: 0 }
                                        }
                                        transition={{
                                            duration: 0.5,
                                            delay: 0.9,
                                        }}
                                    >
                                        <h5>Connect with Local Expertise</h5>
                                        <p>
                                            Our platform identifies labs and
                                            researchers in your area with
                                            complementary expertise,
                                            facilitating collaborations that
                                            might otherwise never happen.
                                        </p>
                                    </motion.div>

                                    <motion.div
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        initial={{ y: 20, opacity: 0 }}
                                        animate={
                                            researchAssistantInView
                                                ? { y: 0, opacity: 1 }
                                                : { y: 20, opacity: 0 }
                                        }
                                        transition={{
                                            duration: 0.5,
                                            delay: 1.1,
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            className="try-assistant-button"
                                            color="secondary"
                                            onClick={() =>
                                                navigate("/labassistantchat")
                                            }
                                        >
                                            Try the Research Assistant
                                        </Button>
                                    </motion.div>
                                </div>
                            </div>
                        </motion.div>
                    </motion.section>

                    <motion.section
                        className="landingPageSection faqSection"
                        ref={faqRef}
                    >
                        <motion.div
                            className="faqContent"
                            initial={{ y: 50, opacity: 0 }}
                            animate={
                                faqInView
                                    ? { y: 0, opacity: 1 }
                                    : { y: 50, opacity: 0 }
                            }
                            transition={{ duration: 0.6 }}
                        >
                            <div className="landingPageTitleText">
                                <h3>Frequently Asked Questions</h3>
                            </div>

                            <div className="faqContainer">
                                {faqItems.map((item, index) => (
                                    <motion.div
                                        key={index}
                                        className={`faqItem ${
                                            expandedFaq === index
                                                ? "expanded"
                                                : ""
                                        }`}
                                        initial={{ y: 20, opacity: 0 }}
                                        animate={
                                            faqInView
                                                ? { y: 0, opacity: 1 }
                                                : { y: 20, opacity: 0 }
                                        }
                                        transition={{
                                            duration: 0.5,
                                            delay: 0.1 * index,
                                        }}
                                        whileHover={{
                                            backgroundColor:
                                                "rgba(63, 81, 181, 0.05)",
                                        }}
                                    >
                                        <div
                                            className="faqQuestion"
                                            onClick={() =>
                                                handleFaqClick(index)
                                            }
                                        >
                                            <h5>{item.question}</h5>
                                            <motion.div
                                                animate={{
                                                    rotate:
                                                        expandedFaq === index
                                                            ? 180
                                                            : 0,
                                                }}
                                                transition={{ duration: 0.3 }}
                                            >
                                                <ExpandMoreIcon />
                                            </motion.div>
                                        </div>
                                        <AnimatePresence>
                                            {expandedFaq === index && (
                                                <motion.div
                                                    className="faqAnswer"
                                                    initial={{
                                                        height: 0,
                                                        opacity: 0,
                                                    }}
                                                    animate={{
                                                        height: "auto",
                                                        opacity: 1,
                                                    }}
                                                    exit={{
                                                        height: 0,
                                                        opacity: 0,
                                                    }}
                                                    transition={{
                                                        duration: 0.3,
                                                    }}
                                                >
                                                    <p>{item.answer}</p>
                                                </motion.div>
                                            )}
                                        </AnimatePresence>
                                    </motion.div>
                                ))}
                            </div>

                            <motion.div
                                className="faqCta"
                                initial={{ y: 20, opacity: 0 }}
                                animate={
                                    faqInView
                                        ? { y: 0, opacity: 1 }
                                        : { y: 20, opacity: 0 }
                                }
                                transition={{ duration: 0.5, delay: 0.7 }}
                            >
                                <p>
                                    <strong>Have more questions? </strong>
                                    Connect with us and send us your feedback!
                                </p>
                            </motion.div>
                        </motion.div>
                    </motion.section>

                    {/* New Footer Section */}
                    <motion.footer
                        className="home-footer"
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.8, delay: 0.2 }}
                    >
                        <div className="home-footer-content">
                            <div className="home-footer-logo">
                                <h2>LabGiant</h2>
                                <p>Connecting research labs worldwide</p>
                            </div>

                            <div className="home-footer-links">
                                <div className="home-footer-nav">
                                    <h3>Explore</h3>
                                    <ul>
                                        <li
                                            onClick={() =>
                                                navigate("/newsfeed")
                                            }
                                        >
                                            <NewspaperIcon />
                                            <span>Giant Board</span>
                                        </li>
                                        <li
                                            onClick={() =>
                                                navigate("/listings")
                                            }
                                        >
                                            <ViewListIcon />
                                            <span>Technologies</span>
                                        </li>
                                        <li onClick={() => navigate("/labs")}>
                                            <SchoolIcon />
                                            <span>Research Labs</span>
                                        </li>
                                        <li
                                            onClick={() =>
                                                navigate("/labassistantchat")
                                            }
                                        >
                                            <PsychologyIcon />
                                            <span>Lab Assistant</span>
                                        </li>
                                    </ul>
                                </div>

                                <div className="home-footer-contact">
                                    <h3>Connect With Us</h3>
                                    <div className="home-footer-contact-item">
                                        <EmailIcon />
                                        <span>labgiant2021@gmail.com</span>
                                    </div>
                                    <div
                                        className="home-footer-contact-item linkedin"
                                        onClick={() =>
                                            window.open(
                                                "https://linkedin.com/company/labgiant",
                                                "_blank"
                                            )
                                        }
                                    >
                                        <LinkedInIcon />
                                        <span>LinkedIn</span>
                                    </div>

                                    <div className="home-footer-join">
                                        <Button
                                            variant="contained"
                                            className="home-footer-join-button"
                                            onClick={scrollToSignup}
                                        >
                                            Join LabGiant
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="home-footer-bottom">
                            <p>
                                &copy; {new Date().getFullYear()} LabGiant. All
                                rights reserved.
                            </p>
                            <div className="home-footer-bottom-links">
                                <span>Terms of Service</span>
                                <span>Privacy Policy</span>
                            </div>
                        </div>
                    </motion.footer>
                </div>
            </div>
        </>
    );
};

export default Home;
