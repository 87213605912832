import { useMemo } from "react";
import { useFormContext, Controller, useWatch } from "react-hook-form";
import Select from "react-select";
import "../styles/CreateListingsChooseCat.css";

const SelectUnit = ({
    isError,
    isCustomError,
    unitOptions,
    required,
    fieldName,
    customFieldName,
}) => {
    const { register, control, getValues } = useFormContext();

    // Use useWatch instead of direct watch calls
    const selectedUnit = useWatch({
        control,
        name: fieldName,
    });

    // Only render the custom unit input when needed
    const showCustomInput = useMemo(() => {
        return selectedUnit === "other";
    }, [selectedUnit]);

    return (
        <div className="unit-section">
            <div className="unit-input-container">
                <Controller
                    name={fieldName}
                    control={control}
                    rules={{
                        required: {
                            value: required,
                            message: "The above units are required.",
                        },
                    }}
                    render={({ field }) => (
                        <Select
                            {...field}
                            className={`unit-select ${
                                isError && "input-error"
                            }`}
                            options={unitOptions}
                            isSearchable
                            placeholder="unit"
                            onChange={(option) =>
                                field.onChange(option ? option.value : null)
                            }
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    border: "none",
                                    boxShadow: "none",
                                }),
                            }}
                            value={
                                field.value
                                    ? unitOptions.find(
                                          (option) =>
                                              option.value === field.value
                                      ) ||
                                      unitOptions.find(
                                          (option) => option.value === "unit"
                                      )
                                    : null
                            }
                        />
                    )}
                />
                {showCustomInput && (
                    <input
                        type="text"
                        name={customFieldName}
                        id={customFieldName}
                        placeholder="custom unit"
                        className={`custom-unit-input ${
                            isCustomError && "input-error"
                        }`}
                        maxLength={10}
                        {...register(customFieldName, {
                            required: {
                                value: selectedUnit === "other",
                                message: "The above custom units are required.",
                            },
                        })}
                    />
                )}
            </div>
        </div>
    );
};

export default SelectUnit;
