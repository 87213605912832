import { useState, useEffect } from "react";
import { fetchGet, fetchPost } from "../../../services/data-service";
import { useNavigate, useParams } from "react-router-dom";
import "../../../styles/Admin/Admin.css";
import ModalComponent from "../../../components/ModalComponent";

const AdminLabTransfer = () => {
    const { labId } = useParams();
    const [lab, setLab] = useState(null);
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [labTransferInvitations, setLabTransferInvitations] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchLab = async () => {
            const lab = await fetchGet(`/admin/lab/${labId}`, true);
            setLab(lab);
        };
        const fetchLabTransferInvitations = async () => {
            const labTransferInvitations = await fetchGet(
                `/admin/lab/${labId}/transfer-invitations`,
                true
            );
            setLabTransferInvitations(labTransferInvitations);
        };
        fetchLab();
        fetchLabTransferInvitations();
    }, [labId]);

    const handleSendInvitation = async () => {
        if (!email) return;

        if (!showConfirmation) {
            setShowConfirmation(true);
            return;
        }

        setIsLoading(true);
        try {
            // Generate a token and send email invitation
            await fetchPost(`/admin/lab/${labId}/send-transfer-invitation`, {
                email: email,
                labName: lab?.name,
            });

            setEmailSent(true);

            // Fetch updated invitations after successful submission
            const updatedInvitations = await fetchGet(
                `/admin/lab/${labId}/transfer-invitations`,
                true
            );
            setLabTransferInvitations(updatedInvitations);
        } catch (error) {
            console.error("Error sending invitation:", error);
            alert("Failed to send invitation. Please try again.");
            setShowConfirmation(false);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseConfirmation = () => {
        setShowConfirmation(false);
        if (emailSent) {
            setEmail("");
            navigate(`/admin/labs`);
        }
    };

    return (
        <div className="admin-lab-transfer">
            <div className="admin-lab-transfer-header">
                <h1>Transfer Lab Ownership</h1>
                <h2>{lab?.name}</h2>
                <p>
                    Send an invitation to a Principal Investigator to take
                    ownership of this lab
                </p>
                <h4>Current PI: {lab?.piId ? lab?.piId : "None"}</h4>
            </div>

            <div className="admin-lab-transfer-form">
                <div className="admin-lab-transfer-email-input">
                    <label htmlFor="admin-lab-transfer-email">
                        Principal Investigator's Email
                    </label>
                    <div className="admin-lab-transfer-input-wrapper">
                        <input
                            id="admin-lab-transfer-email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter email address..."
                            autoComplete="off"
                        />
                    </div>
                    <p className="admin-lab-transfer-email-hint">
                        An invitation will be sent to this email with
                        instructions to sign up and accept lab ownership.
                    </p>
                </div>

                <button
                    className="admin-lab-transfer-button"
                    onClick={handleSendInvitation}
                    disabled={!email || isLoading}
                >
                    {isLoading ? "Sending..." : "Send Transfer Invitation"}
                </button>
            </div>

            {/* Display Lab Transfer Invitations */}
            <div className="admin-lab-transfer-invitations">
                <h3>Lab Transfer Invitations</h3>
                {labTransferInvitations.length > 0 ? (
                    <table className="admin-lab-transfer-table">
                        <thead>
                            <tr>
                                <th>Email</th>
                                <th>Date Sent</th>
                                <th>Expiry Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {labTransferInvitations.map((invitation, index) => (
                                <tr key={index}>
                                    <td>{invitation.email}</td>
                                    <td>
                                        {new Date(
                                            invitation.createdAt
                                        ).toLocaleDateString()}
                                    </td>
                                    <td>
                                        {new Date(
                                            invitation.expiresAt
                                        ).toLocaleDateString()}
                                    </td>
                                    <td>{invitation.status || "Pending"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No invitations have been sent for this lab.</p>
                )}
            </div>

            {/* Confirmation Modal */}
            <ModalComponent
                title={emailSent ? "Invitation Sent" : "Confirm Transfer"}
                isOpen={showConfirmation}
                toggle={handleCloseConfirmation}
                submitText={
                    emailSent
                        ? null
                        : isLoading
                        ? "Sending..."
                        : "Send Invitation"
                }
                disabled={isLoading}
                onSubmit={emailSent ? null : handleSendInvitation}
                cancelText={emailSent ? "Done" : "Cancel"}
                onCancel={handleCloseConfirmation}
            >
                {!emailSent ? (
                    <p>
                        Are you sure you want to send a lab transfer invitation
                        to <strong>{email}</strong>?
                    </p>
                ) : (
                    <>
                        <p>
                            A lab transfer invitation has been successfully sent
                            to <strong>{email}</strong>.
                        </p>
                        <p>The recipient will need to:</p>
                        <ol>
                            <li>Click the link in the email</li>
                            <li>
                                Create an account using the same email address
                            </li>
                            <li>Accept the lab transfer to gain ownership</li>
                        </ol>
                    </>
                )}
            </ModalComponent>
        </div>
    );
};

export default AdminLabTransfer;

// import React from "react";

// const AdminLabTransfer = () => {
//     /**
//      *
//      * How to transfer a lab to a current user?
//      *
//      * Requirements
//      * - User must have a valid Users document
//      * - User must have a NULL labId in the Users document
//      *
//      *
//      * Retrieving Users Steps
//      * 1. Retrieve all Users with a NULL labId in the Users document.
//      * 2. Choose the user to transfer the lab to.
//      *
//      *
//      * Transferring Lab to User Steps
//      * 1. Update the labId in the Users document to the labId of the lab to be transferred.
//      * 2. Find the current piId of the lab, find the member document of that piId and delete it.
//      * 3. Add the user document to the members collection of the Lab document and update the piId field to the userId of the user to be transferred.
//      * 4. For each listings of that lab, update the labMemberInCharge field to the userId of the user to be transferred.
//      * 5. Change the status of the lab to be transferred to "approved" if not already.
//      * 6. Change the temporary field to false.
//      *
//      */

//     return <div>Transfer Lab to a current user.</div>;
// };

// export default AdminLabTransfer;
