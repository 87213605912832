import { useEffect } from "react";
import { Container, Grid, Alert, Box, Typography, Fade } from "@mui/material";
import PricingHeader from "./PricingHeader";
import PricingTier from "./PricingTier";
import PricingFAQ from "./PricingFAQ";
import pricingTiers from "./pricingData";
import { Helmet } from "react-helmet";
import "../../styles/Pricing.css";

const Pricing = () => {
    useEffect(() => {
        document.title = "Pricing | LabGiant";
        document
            .querySelector('meta[name="description"]')
            .setAttribute(
                "content",
                "Discover LabGiant's flexible pricing plans designed for researchers at every level. From basic research tools to advanced enterprise solutions, we offer transparent pricing, unlimited storage, and comprehensive support. Compare features, choose your ideal plan, and accelerate your research journey with our cutting-edge laboratory management platform."
            );
    }, []);

    return (
        <>
            <Helmet>
                <link
                    rel="canonical"
                    href={`${process.env.REACT_APP_FRONTEND_URL}/pricing`}
                />
            </Helmet>
            <div className="pricing-page">
                <Container maxWidth="lg">
                    <PricingHeader />

                    <Box
                        sx={{
                            position: "relative",
                            py: 4,
                            "&::before": {
                                content: '""',
                                position: "absolute",
                                top: 0,
                                left: "50%",
                                transform: "translateX(-50%)",
                                width: "200px",
                                height: "1px",
                                background:
                                    "linear-gradient(to right, transparent, rgba(63, 5, 5, 0.2), transparent)",
                            },
                            "&::after": {
                                content: '""',
                                position: "absolute",
                                bottom: 0,
                                left: "50%",
                                transform: "translateX(-50%)",
                                width: "200px",
                                height: "1px",
                                background:
                                    "linear-gradient(to right, transparent, rgba(63, 5, 5, 0.2), transparent)",
                            },
                        }}
                    >
                        <Grid
                            container
                            spacing={4}
                            className="pricing-grid"
                            justifyContent="center"
                            alignItems="stretch"
                            sx={{ height: "100%" }}
                        >
                            {pricingTiers.map((tier, index) => (
                                <Fade
                                    in={true}
                                    timeout={1000 + index * 300}
                                    key={index}
                                    style={{
                                        transitionDelay: `${index * 100}ms`,
                                        height: "100%",
                                        display: "flex",
                                    }}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={10}
                                        md={4}
                                        key={index}
                                        sx={{
                                            display: "flex",
                                            height: "100%",
                                        }}
                                    >
                                        <PricingTier {...tier} />
                                    </Grid>
                                </Fade>
                            ))}
                        </Grid>
                    </Box>

                    <PricingFAQ />

                    <Box
                        sx={{
                            textAlign: "center",
                            mt: 8,
                            mb: 4,
                            p: 4,
                            backgroundColor: "rgba(63, 5, 5, 0.02)",
                            borderRadius: "12px",
                            border: "1px solid rgba(63, 5, 5, 0.05)",
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                fontFamily:
                                    '"poppins", Helvetica, Arial, sans-serif',
                                color: "#555",
                                fontWeight: 500,
                                mb: 2,
                            }}
                        >
                            Need help choosing the right plan?
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                fontFamily:
                                    '"poppins", Helvetica, Arial, sans-serif',
                                color: "#666",
                                maxWidth: "700px",
                                margin: "0 auto",
                                lineHeight: 1.6,
                            }}
                        >
                            Our team is here to help you find the perfect
                            solution for your research needs. Contact us at{" "}
                            <Box
                                component="span"
                                sx={{
                                    color: "rgb(63, 05, 05)",
                                    fontWeight: 600,
                                }}
                            >
                                labgiant2021@gmail.com
                            </Box>{" "}
                            for personalized assistance.
                        </Typography>
                    </Box>
                </Container>
            </div>
        </>
    );
};

export default Pricing;
