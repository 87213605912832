import React, { useState, useEffect, useRef } from "react";
import {
    Close as CloseIcon,
    Search as SearchIcon,
    Chat as ChatIcon,
    MoreVert as MoreVertIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Check as CheckIcon,
    Close as CancelIcon,
} from "@mui/icons-material";
import ReactDOM from "react-dom";

/**
 * Sidebar component for displaying chat threads
 */
const Sidebar = ({
    user,
    userChats,
    isLoadingChats,
    currentChatId,
    createNewChat,
    loadChat,
    deleteChat,
    navigate,
    renameChat,
}) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [localChats, setLocalChats] = useState([]);
    const [activeMenu, setActiveMenu] = useState(null);
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const [editingChatId, setEditingChatId] = useState(null);
    const [editedTitle, setEditedTitle] = useState("");
    const editInputRef = useRef(null);

    // Update local state when userChats changes
    useEffect(() => {
        setLocalChats(userChats);
    }, [userChats]);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (
                activeMenu &&
                !e.target.closest(".lab-assistant-chat-thread-menu-btn") &&
                !e.target.closest(".lab-assistant-chat-thread-dropdown")
            ) {
                setActiveMenu(null);
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [activeMenu]);

    // Focus input when editing starts
    useEffect(() => {
        if (editingChatId && editInputRef.current) {
            editInputRef.current.focus();
        }
    }, [editingChatId]);

    // Toggle dropdown menu
    const toggleMenu = (chatId, e) => {
        e.stopPropagation();

        if (activeMenu === chatId) {
            setActiveMenu(null);
        } else {
            // Calculate position for the floating menu
            const button = e.currentTarget;
            const rect = button.getBoundingClientRect();

            // Position the menu below the button, aligned to left edge
            setMenuPosition({
                top: rect.bottom + 5, // 5px below the button
                left: rect.left, // Align with left edge of button
            });

            setActiveMenu(chatId);
        }
    };

    // Start editing a chat title
    const startEditing = (chatId, currentTitle, e) => {
        e.stopPropagation();
        setActiveMenu(null);
        setEditingChatId(chatId);
        setEditedTitle(currentTitle || "New conversation");
    };

    // Save edited title
    const saveEditedTitle = (chatId, e) => {
        e?.stopPropagation();
        if (editedTitle.trim() !== "") {
            renameChat(chatId, editedTitle.trim());
        }
        setEditingChatId(null);
    };

    // Cancel editing
    const cancelEditing = (e) => {
        e?.stopPropagation();
        setEditingChatId(null);
    };

    // Handle edit input changes
    const handleEditInputChange = (e) => {
        setEditedTitle(e.target.value);
    };

    // Handle edit input key press
    const handleEditInputKeyPress = (chatId, e) => {
        if (e.key === "Enter") {
            saveEditedTitle(chatId, e);
        } else if (e.key === "Escape") {
            cancelEditing(e);
        }
    };

    // Handle delete action
    const handleDelete = (chatId, e) => {
        e.stopPropagation();
        setActiveMenu(null);
        deleteChat(chatId, e);
    };

    // Render floating dropdown menu using portal
    const renderFloatingMenu = () => {
        if (!activeMenu) return null;

        const menuContent = (
            <div
                className="lab-assistant-chat-thread-dropdown"
                style={{
                    position: "fixed",
                    top: `${menuPosition.top}px`,
                    left: `${menuPosition.left}px`,
                }}
            >
                <button
                    className="lab-assistant-chat-thread-dropdown-item"
                    onClick={(e) => {
                        const chat = localChats.find(
                            (c) => c.id === activeMenu
                        );
                        startEditing(activeMenu, chat?.title, e);
                    }}
                >
                    <EditIcon fontSize="small" />
                    <span>Rename</span>
                </button>
                <button
                    className="lab-assistant-chat-thread-dropdown-item lab-assistant-chat-thread-dropdown-delete"
                    onClick={(e) => handleDelete(activeMenu, e)}
                >
                    <DeleteIcon fontSize="small" />
                    <span>Delete</span>
                </button>
            </div>
        );

        return ReactDOM.createPortal(menuContent, document.body);
    };

    // Filter chats based on search query
    const filteredChats = localChats.filter((chat) => {
        const title = chat.title?.toLowerCase() || "";
        const preview = chat.preview?.toLowerCase() || "";
        const query = searchQuery.toLowerCase().trim();

        // Return true if the query is empty or if it matches either title or preview
        return !query || title.includes(query) || preview.includes(query);
    });

    // Group chats by time periods
    const groupChatsByTimePeriod = (chats) => {
        const now = new Date();
        const todayStart = new Date(now);
        todayStart.setHours(0, 0, 0, 0);

        const yesterday = new Date(now);
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0);

        const sevenDaysAgo = new Date(now);
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

        const thirtyDaysAgo = new Date(now);
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

        const groups = {
            today: { title: "Today", chats: [] },
            yesterday: { title: "Yesterday", chats: [] },
            previous7Days: { title: "Previous 7 Days", chats: [] },
            previous30Days: { title: "Previous 30 Days", chats: [] },
            older: {},
        };

        // Month groups will be added dynamically
        const monthGroups = {};

        chats.forEach((chat) => {
            try {
                // Parse the timestamp
                let chatDate;
                const timestamp = chat.updatedAt;

                if (!timestamp) {
                    // If no date, put in today's group
                    groups.today.chats.push(chat);
                    return;
                }

                if (timestamp.seconds) {
                    // Firebase Timestamp format
                    chatDate = new Date(timestamp.seconds * 1000);
                } else if (
                    timestamp.toDate &&
                    typeof timestamp.toDate === "function"
                ) {
                    // Firebase Timestamp with toDate method
                    chatDate = timestamp.toDate();
                } else if (timestamp instanceof Date) {
                    // Already a Date object
                    chatDate = timestamp;
                } else if (typeof timestamp === "string") {
                    // String timestamp
                    chatDate = new Date(timestamp);
                } else if (typeof timestamp === "number") {
                    // Unix timestamp in milliseconds
                    chatDate = new Date(timestamp);
                } else {
                    // Try generic constructor as last resort
                    chatDate = new Date(timestamp);
                }

                // Check if date is valid
                if (isNaN(chatDate.getTime())) {
                    // If invalid date, put in today's group
                    groups.today.chats.push(chat);
                    return;
                }

                // Group by time periods
                if (chatDate >= todayStart) {
                    groups.today.chats.push(chat);
                } else if (chatDate >= yesterday) {
                    groups.yesterday.chats.push(chat);
                } else if (chatDate >= sevenDaysAgo) {
                    groups.previous7Days.chats.push(chat);
                } else if (chatDate >= thirtyDaysAgo) {
                    groups.previous30Days.chats.push(chat);
                } else {
                    // Group by month for older chats
                    const monthYear = chatDate.toLocaleString("default", {
                        month: "long",
                        year: "numeric",
                    });
                    if (!monthGroups[monthYear]) {
                        monthGroups[monthYear] = {
                            title: monthYear,
                            chats: [],
                        };
                    }
                    monthGroups[monthYear].chats.push(chat);
                }
            } catch (error) {
                console.error("Error grouping chat by date:", error);
                // In case of error, add to today's group
                groups.today.chats.push(chat);
            }
        });

        // Convert month groups to array and sort by date (newest first)
        const sortedMonthGroups = Object.values(monthGroups).sort((a, b) => {
            const dateA = new Date(a.title);
            const dateB = new Date(b.title);
            return dateB - dateA;
        });

        // Remove empty groups
        const result = [
            groups.today,
            groups.yesterday,
            groups.previous7Days,
            groups.previous30Days,
        ].filter((group) => group.chats.length > 0);

        // Sort chats within each group by date (newest first)
        const sortChats = (chats) => {
            return [...chats].sort((a, b) => {
                // Parse dates safely
                let dateA, dateB;

                try {
                    if (a.updatedAt && a.updatedAt.seconds) {
                        dateA = new Date(a.updatedAt.seconds * 1000);
                    } else if (a.updatedAt && a.updatedAt.toDate) {
                        dateA = a.updatedAt.toDate();
                    } else {
                        dateA = new Date(a.updatedAt || 0);
                    }
                } catch (e) {
                    dateA = new Date(0);
                }

                try {
                    if (b.updatedAt && b.updatedAt.seconds) {
                        dateB = new Date(b.updatedAt.seconds * 1000);
                    } else if (b.updatedAt && b.updatedAt.toDate) {
                        dateB = b.updatedAt.toDate();
                    } else {
                        dateB = new Date(b.updatedAt || 0);
                    }
                } catch (e) {
                    dateB = new Date(0);
                }

                return dateB - dateA;
            });
        };

        // Sort chats within each group
        result.forEach((group) => {
            group.chats = sortChats(group.chats);
        });

        // Sort month groups chats too
        sortedMonthGroups.forEach((group) => {
            group.chats = sortChats(group.chats);
        });

        // Add month groups
        return [...result, ...sortedMonthGroups];
    };

    // Format the chat date for display (only for specific cases like time on Today)
    const formatChatTime = (timestamp) => {
        if (!timestamp) return "";

        try {
            // Handle different timestamp formats
            let date;
            if (timestamp.seconds) {
                date = new Date(timestamp.seconds * 1000);
            } else if (
                timestamp.toDate &&
                typeof timestamp.toDate === "function"
            ) {
                date = timestamp.toDate();
            } else if (timestamp instanceof Date) {
                date = timestamp;
            } else if (typeof timestamp === "string") {
                date = new Date(timestamp);
            } else if (typeof timestamp === "number") {
                date = new Date(timestamp);
            } else {
                date = new Date(timestamp);
            }

            // Check if date is valid before proceeding
            if (isNaN(date.getTime())) {
                return "";
            }

            // For today's messages, show the time
            const now = new Date();
            if (
                date.getDate() === now.getDate() &&
                date.getMonth() === now.getMonth() &&
                date.getFullYear() === now.getFullYear()
            ) {
                return date.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                });
            }

            return "";
        } catch (error) {
            console.error("Error formatting time:", error);
            return "";
        }
    };

    // Group the filtered chats
    const groupedChats = groupChatsByTimePeriod(filteredChats);

    // Parse entity references in text (e.g., [[lab:My Lab]] -> My Lab)
    const parseEntityReferences = (text) => {
        if (!text) return "";

        // Replace entity references with double brackets [[type:id:name]]
        let parsed = text.replace(
            /\[\[(lab|listing|equipment|user):[^:]*:([^\]]+)\]\]/g,
            "$2"
        );

        // Also replace entity references with single brackets [type:name]
        parsed = parsed.replace(
            /\[(lab|listing|equipment|user):([^\]]+)\]/g,
            "$2"
        );

        // Also replace simple bracketed names [Name]
        parsed = parsed.replace(/\[([^\]]+)\]/g, "$1");

        return parsed;
    };

    return (
        <div className="lab-assistant-chat-sidebar">
            <div className="lab-assistant-chat-sidebar-header">
                <h2>Lab Assistant</h2>
                <button
                    className="lab-assistant-chat-new-btn"
                    onClick={createNewChat}
                    disabled={!user}
                >
                    New Chat
                </button>
            </div>
            <div className="lab-assistant-chat-sidebar-search">
                <SearchIcon className="lab-assistant-chat-search-icon" />
                <input
                    type="text"
                    placeholder="Search your threads..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>

            <div className="lab-assistant-chat-threads">
                {isLoadingChats ? (
                    <div className="lab-assistant-chat-loading">
                        Loading chats...
                    </div>
                ) : user && filteredChats.length > 0 ? (
                    <>
                        {groupedChats.map((group, groupIndex) => (
                            <div
                                key={`group-${groupIndex}`}
                                className="lab-assistant-chat-thread-group"
                            >
                                <h3 className="lab-assistant-chat-thread-group-title">
                                    {group.title}
                                </h3>
                                {group.chats.map((chat) => (
                                    <div
                                        key={chat.id}
                                        className={`lab-assistant-chat-thread ${
                                            currentChatId === chat.id
                                                ? "active"
                                                : ""
                                        }`}
                                        onClick={() => {
                                            if (editingChatId !== chat.id) {
                                                loadChat(chat.id);
                                            }
                                        }}
                                    >
                                        <div className="lab-assistant-chat-thread-content">
                                            {editingChatId === chat.id ? (
                                                <div className="lab-assistant-chat-thread-edit">
                                                    <input
                                                        ref={editInputRef}
                                                        type="text"
                                                        value={editedTitle}
                                                        onChange={
                                                            handleEditInputChange
                                                        }
                                                        onClick={(e) =>
                                                            e.stopPropagation()
                                                        }
                                                        onKeyDown={(e) =>
                                                            handleEditInputKeyPress(
                                                                chat.id,
                                                                e
                                                            )
                                                        }
                                                        className="lab-assistant-chat-thread-edit-input"
                                                    />
                                                    <div className="lab-assistant-chat-thread-edit-actions">
                                                        <button
                                                            onClick={(e) =>
                                                                saveEditedTitle(
                                                                    chat.id,
                                                                    e
                                                                )
                                                            }
                                                            className="lab-assistant-chat-thread-edit-btn save"
                                                        >
                                                            <CheckIcon fontSize="small" />
                                                        </button>
                                                        <button
                                                            onClick={
                                                                cancelEditing
                                                            }
                                                            className="lab-assistant-chat-thread-edit-btn cancel"
                                                        >
                                                            <CancelIcon fontSize="small" />
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <span className="lab-assistant-chat-thread-title">
                                                        {parseEntityReferences(
                                                            chat.title
                                                        ) || "New conversation"}
                                                    </span>
                                                    {group.title === "Today" &&
                                                        formatChatTime(
                                                            chat.updatedAt
                                                        ) &&
                                                        !chat._justRenamed && (
                                                            <span className="lab-assistant-chat-thread-time">
                                                                {formatChatTime(
                                                                    chat.updatedAt
                                                                )}
                                                            </span>
                                                        )}
                                                </>
                                            )}
                                        </div>
                                        <div className="lab-assistant-chat-thread-actions">
                                            <button
                                                className="lab-assistant-chat-thread-menu-btn"
                                                onClick={(e) =>
                                                    toggleMenu(chat.id, e)
                                                }
                                                aria-label="Thread options"
                                            >
                                                <MoreVertIcon fontSize="small" />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </>
                ) : user ? (
                    <div className="lab-assistant-chat-empty">
                        <p>No chat history found.</p>
                        <p>Start a new conversation!</p>
                    </div>
                ) : (
                    <div className="lab-assistant-chat-empty">
                        <p>Sign in to view your chat history.</p>
                    </div>
                )}
            </div>
            <div className="lab-assistant-chat-sidebar-footer">
                {!user ? (
                    <button
                        className="lab-assistant-chat-login-btn"
                        onClick={() =>
                            navigate(
                                `${process.env.REACT_APP_FRONTEND_URL}/?mode=login`
                            )
                        }
                    >
                        <span>Login</span>
                    </button>
                ) : (
                    <div className="lab-assistant-chat-user-info">
                        <span>{user.displayName || user.email}</span>
                    </div>
                )}
            </div>

            {/* Render floating menu outside sidebar */}
            {renderFloatingMenu()}
        </div>
    );
};

export default React.memo(Sidebar, (prevProps, nextProps) => {
    // Return false if we want to re-render
    // Check if userChats have changed
    const chatsChanged =
        prevProps.userChats.length !== nextProps.userChats.length ||
        JSON.stringify(prevProps.userChats) !==
            JSON.stringify(nextProps.userChats);

    // Check if currentChatId changed
    const chatIdChanged = prevProps.currentChatId !== nextProps.currentChatId;

    // Check if loading state changed
    const loadingChanged =
        prevProps.isLoadingChats !== nextProps.isLoadingChats;

    // Only prevent re-render if nothing important changed
    return !chatsChanged && !chatIdChanged && !loadingChanged;
});
