import { useCallback, useEffect, useState } from "react";
import {
    AccountBalance as AccountBalanceIcon,
    Add as AddIcon,
    Cancel as CancelIcon,
    Close as CloseIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    ImportContacts as ImportContactsIcon,
    Save as SaveIcon,
    School as SchoolIcon,
    Link as LinkIcon,
} from "@mui/icons-material";
import {
    deleteObject,
    getDownloadURL,
    ref,
    uploadBytes,
} from "firebase/storage";
import { useParams } from "react-router-dom";
import { Loading, ModalComponent } from "../../components";
import { storage } from "../../firebase-config";
import { fetchDelete, fetchGet, fetchPut } from "../../services/data-service";
import { getBase64 } from "../../utils/FileUtils";
import imagePlaceholder from "../../assets/image-placeholder.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { marked } from "marked";

marked.setOptions({
    gfm: true,
    breaks: true,
    pedantic: false,
    sanitize: false,
    smartLists: true,
    smartypants: false,
});

const OverviewManager = () => {
    const { labId } = useParams();
    const [editing, setEditing] = useState(false);
    const [lab, setLab] = useState({});
    const [overview, setOverview] = useState([]);
    const [tempSections, setTempSections] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [linkModalOpen, setLinkModalOpen] = useState(false);
    const [linkDetails, setLinkDetails] = useState({ text: "", url: "" });
    const [selectionRange, setSelectionRange] = useState(null);
    const [activeTextArea, setActiveTextArea] = useState(null);
    const [editorStates, setEditorStates] = useState({});

    const toggleModal = (title, index) => {
        setModalData({ title, index });
        setIsModalOpen(!isModalOpen);
    };

    useEffect(() => {
        fetchGet(`/labs/${labId}?overview=true&banner=true`).then(
            async (data) => {
                setIsLoading(true);

                const labData = {
                    ...data,
                    logo: {
                        current: {
                            url: data.logo,
                        },
                        editing: {
                            url: data.logo,
                            file: null,
                        },
                    },
                    banner: {
                        current: {
                            url: data.banner,
                        },
                        editing: {
                            url: data.banner,
                            file: null,
                        },
                    },
                };

                const updatedData = await Promise.all(
                    labData.sections.map(async (section) => {
                        const files = await Promise.all(
                            section.files.map(async (file) => {
                                const imageRef = ref(storage, file.ref);
                                const url = await getDownloadURL(imageRef);
                                return { ...file, url: url };
                            })
                        );

                        return { ...section, files };
                    })
                );

                setOverview(updatedData);
                setTempSections(updatedData);
                setLab(labData);
                setIsLoading(false);
            }
        );
    }, [labId]);

    const handleImageChange = (event, sectionIndex, fileIndex) => {
        if (event.target.files && event.target.files[0]) {
            const newFile = {
                file: event.target.files[0],
                url: URL.createObjectURL(event.target.files[0]),
            };

            setTempSections((prevSections) =>
                prevSections.map((section, index) => {
                    if (index !== sectionIndex) return section;

                    const newFiles = [...section.files];
                    if (fileIndex < newFiles.length) {
                        newFiles[fileIndex] = newFile;
                    } else {
                        newFiles.push(newFile);
                    }

                    return {
                        ...section,
                        files: newFiles,
                    };
                })
            );
        }
    };

    const onDrop = useCallback((acceptedFiles, sectionIndex) => {
        setTempSections((prevSections) =>
            prevSections.map((section, index) => {
                if (index !== sectionIndex) {
                    return section;
                }

                const newFiles = acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        width: "auto", // changed here
                        height: "auto", // changed here
                    })
                );

                return {
                    ...section,
                    files: [...section.files, ...newFiles],
                };
            })
        );
    }, []);

    const moveImageUp = (sectionIndex, fileIndex) => {
        setTempSections((prevSections) =>
            prevSections.map((section, index) => {
                if (index !== sectionIndex) {
                    return section;
                }

                const filesCopy = [...section.files];
                [filesCopy[fileIndex - 1], filesCopy[fileIndex]] = [
                    filesCopy[fileIndex],
                    filesCopy[fileIndex - 1],
                ];

                return {
                    ...section,
                    files: filesCopy,
                };
            })
        );
    };

    const moveImageDown = (sectionIndex, fileIndex) => {
        setTempSections((prevSections) =>
            prevSections.map((section, index) => {
                if (index !== sectionIndex) {
                    return section;
                }

                const filesCopy = [...section.files];
                [filesCopy[fileIndex], filesCopy[fileIndex + 1]] = [
                    filesCopy[fileIndex + 1],
                    filesCopy[fileIndex],
                ];

                return {
                    ...section,
                    files: filesCopy,
                };
            })
        );
    };

    const updateImageDimensions = useCallback(
        (sectionIndex, fileIndex, dimensions) => {
            setTempSections((prevSections) =>
                prevSections.map((section, index) => {
                    if (index !== sectionIndex) {
                        return section;
                    }

                    const filesCopy = [...section.files];
                    filesCopy[fileIndex].width = dimensions.width;
                    filesCopy[fileIndex].height = dimensions.height;

                    return {
                        ...section,
                        files: filesCopy,
                    };
                })
            );
        },
        []
    );

    const deleteImage = (sectionIndex, fileIndex) => {
        setTempSections((prevSections) =>
            prevSections.map((section, index) => {
                if (index !== sectionIndex) return section;

                const filesCopy = [...section.files];
                filesCopy.splice(fileIndex, 1);

                return { ...section, files: filesCopy };
            })
        );
    };

    const handleBannerChange = (e) => {
        const file = e.target.files[0];
        setLab({
            ...lab,
            banner: {
                ...lab.banner,
                editing: {
                    file: file,
                    url: URL.createObjectURL(file),
                },
            },
        });
    };

    const handleDeleteBanner = () => {
        setLab({
            ...lab,
            banner: {
                ...lab.banner,
                editing: {
                    file: null,
                    url: null,
                },
            },
        });
    };

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        setLab({
            ...lab,
            logo: {
                ...lab.logo,
                editing: {
                    file: file,
                    url: URL.createObjectURL(file),
                },
            },
        });
    };

    const handleSectionChange = (e, index, type) => {
        const newSections = [...tempSections];
        newSections[index][type] = e.target.value;
        setTempSections(newSections);
    };

    const addSection = () => {
        const newSection = { title: "", content: "", files: [] };
        setTempSections((prevSections) => [...prevSections, newSection]);
    };

    const saveLogo = async () => {
        if (lab.logo.editing.file) {
            const imageRef = ref(storage, `labs/${labId}/images/logo`);
            await uploadBytes(imageRef, lab.logo.editing.file);
        }
    };

    const saveBanner = async () => {
        if (lab.banner.editing.file) {
            const imageRef = ref(storage, `labs/${labId}/images/banner`);
            await uploadBytes(imageRef, lab.banner.editing.file);
        } else if (lab.banner.current.url && !lab.banner.editing.url) {
            const imageRef = ref(storage, `labs/${labId}/images/banner`);
            await deleteObject(imageRef);
        }
    };

    const saveSections = async () => {
        const originalIds = overview.map((section) => section.id);
        const updatedIds = new Set(tempSections.map((section) => section.id));
        const removedIds = originalIds.filter((id) => !updatedIds.has(id));

        // 1. If a tempSection file has an id, then that means it is an existing file.
        // 2. If a tempSection file does not have an id, then that means it is a new file. For that, we need to upload the file.
        // 3. If an overview file has an id, but the tempSection file does not, then that means the file has been removed.
        const tempFileIds = new Set();
        tempSections.forEach((section) => {
            section.files.forEach((file) => {
                if (file.id) {
                    tempFileIds.add(file.id);
                }
            });
        });

        for (let i = 0; i < overview.length; i++) {
            const section = overview[i];
            const removedFiles = section.files.filter(
                (file) => !tempFileIds.has(file.id)
            );

            for (const file of removedFiles) {
                const fileRef = ref(storage, file.ref);
                console.log("fileId", file.id);
                await deleteObject(fileRef).catch((error) => {
                    console.error("Error deleting file:", error);
                });
            }
        }

        for (let i = 0; i < tempSections.length; i++) {
            const section = tempSections[i];
            section.files = await Promise.all(
                section.files.map(async (file) => {
                    if (file.id) {
                        return file;
                    }
                    return {
                        id: file.id,
                        ref: file.ref,
                        data: await getBase64(file.file),
                    };
                })
            );
            section.order = i;
        }

        await fetchPut(`/labs/${labId}/overviews`, tempSections, true);

        for (const id of removedIds) {
            await fetchDelete(`/labs/${labId}/overviews/${id}`, true);
        }

        setEditing(false);
    };

    const deleteSection = (index) => {
        const newSections = [...tempSections];
        newSections.splice(index, 1);
        setTempSections(newSections);
    };

    const convertMarkdownToHTML = (html) => {
        if (!html) return "";

        // First ensure we're working with proper HTML content
        let content = html.trim();

        // Add target="_blank" and rel attributes to links while preserving other attributes
        content = content.replace(
            /<a ([^>]*)href="([^"]+)"([^>]*)>/g,
            (match, before, url, after) => {
                const absoluteUrl =
                    url.startsWith("http://") || url.startsWith("https://")
                        ? url
                        : `https://${url}`;
                return `<a ${before}href="${absoluteUrl}"${after} target="_blank" rel="noopener noreferrer">`;
            }
        );

        // Preserve code block formatting
        content = content.replace(
            /<pre class="ql-syntax".*?>(.*?)<\/pre>/gs,
            (match, code) => {
                return `<pre class="ql-syntax">${code}</pre>`;
            }
        );

        return `<div class="quill-content">${content}</div>`;
    };

    const handleEditorChange = (content, sectionIndex) => {
        setTempSections((prev) =>
            prev.map((section, index) =>
                index === sectionIndex ? { ...section, content } : section
            )
        );
    };

    const handlePasteImage = (sectionIndex, fileIndex) => {
        navigator.clipboard
            .read()
            .then((clipboardItems) => {
                for (const clipboardItem of clipboardItems) {
                    for (const type of clipboardItem.types) {
                        if (type.startsWith("image/")) {
                            clipboardItem.getType(type).then((blob) => {
                                const newFile = {
                                    file: blob,
                                    url: URL.createObjectURL(blob),
                                };

                                setTempSections((prevSections) =>
                                    prevSections.map((section, index) => {
                                        if (index !== sectionIndex)
                                            return section;

                                        const newFiles = [...section.files];
                                        if (fileIndex < newFiles.length) {
                                            newFiles[fileIndex] = newFile;
                                        } else {
                                            newFiles.push(newFile);
                                        }

                                        return {
                                            ...section,
                                            files: newFiles,
                                        };
                                    })
                                );
                            });
                            return;
                        }
                    }
                }
            })
            .catch((err) => {
                console.error("Failed to read clipboard:", err);
            });
    };

    if (isLoading) {
        return <Loading />;
    }

    return editing ? (
        <div className="overview-manager">
            <div className="overview-manager-editor">
                <div className="overview-manager-editor-header">
                    <div
                        className="overview-manager-editor-banner"
                        style={
                            lab.banner.editing.url && {
                                backgroundImage: `url(${lab.banner.editing.url})`,
                            }
                        }
                    />
                    <div className="overview-manager-editor-banner-top-buttons">
                        <button
                            className="overview-manager-editor-save-button"
                            onClick={() => {
                                saveSections().then(() => {
                                    saveLogo().then(() => {
                                        saveBanner().then(() => {
                                            window.location.reload();
                                        });
                                    });
                                });
                            }}
                        >
                            <SaveIcon style={{ fontSize: "16px" }} />
                            Save
                        </button>
                    </div>
                    <div className="overview-manager-editor-banner-buttons">
                        {lab.banner.editing.url && (
                            <button
                                className="overview-manager-editor-banner-button"
                                onClick={handleDeleteBanner}
                            >
                                <DeleteIcon style={{ fontSize: "16px" }} />
                                Delete Banner
                            </button>
                        )}
                        <label className="overview-manager-editor-banner-button">
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleBannerChange}
                                style={{ display: "none" }}
                            />
                            {lab.banner.editing.url ? (
                                <>
                                    <EditIcon style={{ fontSize: "16px" }} />
                                    Edit Banner
                                </>
                            ) : (
                                <>
                                    <AddIcon style={{ fontSize: "16px" }} />
                                    Add Banner
                                </>
                            )}
                        </label>
                    </div>
                    <div className="overview-manager-editor-content">
                        <div className="overview-manager-editor-logo">
                            <img src={lab.logo.editing.url} alt="Lab Logo" />
                            <label className="overview-manager-editor-logo-button">
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleLogoChange}
                                    style={{ display: "none" }}
                                />
                                <EditIcon style={{ fontSize: "16px" }} />
                            </label>
                        </div>
                        <div className="overview-manager-editor-banner-info">
                            <div className="lab-profile-banner-info-badges">
                                <div>
                                    <AccountBalanceIcon
                                        style={{ fontSize: "12px" }}
                                    />
                                    {lab.institutionName}
                                </div>
                                <div>
                                    <SchoolIcon style={{ fontSize: "12px" }} />
                                    {lab.facultyName}
                                </div>
                                <div>
                                    <ImportContactsIcon
                                        style={{ fontSize: "12px" }}
                                    />
                                    {lab.departmentName}
                                </div>
                            </div>
                            <h1>{lab.name}</h1>
                            <h2>{lab.keywords.join(", ")}</h2>
                            <button
                                className="overview-manager-editor-edit-button"
                                onClick={() => {
                                    setLab((prev) => ({
                                        ...prev,
                                        logo: {
                                            ...prev.logo,
                                            editing: {
                                                file: null,
                                                url: prev.logo.current.url,
                                            },
                                        },
                                        banner: {
                                            ...prev.banner,
                                            editing: {
                                                file: null,
                                                url: prev.banner.current.url,
                                            },
                                        },
                                    }));
                                    setEditing(false);
                                }}
                            >
                                <CancelIcon style={{ fontSize: "16px" }} />
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
                <div className="overview-manager-editor-sections">
                    {tempSections.map((section, index) => (
                        <>
                            <div
                                key={index}
                                className="overview-manager-editor-section-wrapper"
                            >
                                <h3 style={{ fontWeight: "600" }}>
                                    Section {index + 1}
                                </h3>
                                <div className="overview-manager-editor-section">
                                    <div className="overview-manager-editor-input-wrapper">
                                        <input
                                            placeholder="Section Title"
                                            value={section.title}
                                            onChange={(e) =>
                                                handleSectionChange(
                                                    e,
                                                    index,
                                                    "title"
                                                )
                                            }
                                        />
                                        <div className="textarea-container">
                                            <ReactQuill
                                                value={section.content}
                                                onChange={(content) =>
                                                    handleEditorChange(
                                                        content,
                                                        index
                                                    )
                                                }
                                                modules={{
                                                    toolbar: [
                                                        [
                                                            "bold",
                                                            "italic",
                                                            "underline",
                                                            "strike",
                                                        ],
                                                        [
                                                            "blockquote",
                                                            "code-block",
                                                        ],
                                                        [
                                                            { header: 1 },
                                                            { header: 2 },
                                                        ],
                                                        [
                                                            { list: "ordered" },
                                                            { list: "bullet" },
                                                        ],
                                                        [
                                                            { script: "sub" },
                                                            { script: "super" },
                                                        ],
                                                        [
                                                            { indent: "-1" },
                                                            { indent: "+1" },
                                                        ],
                                                        [
                                                            { color: [] },
                                                            { background: [] },
                                                        ],
                                                        ["link"],
                                                        ["clean"],
                                                    ],
                                                    clipboard: {
                                                        matchVisual: false,
                                                    },
                                                }}
                                                formats={[
                                                    "bold",
                                                    "italic",
                                                    "underline",
                                                    "strike",
                                                    "blockquote",
                                                    "code-block",
                                                    "header",
                                                    "list",
                                                    "bullet",
                                                    "script",
                                                    "indent",
                                                    "color",
                                                    "background",
                                                    "link",
                                                ]}
                                            />
                                            <div className="link-warning">
                                                Note: For external links, please
                                                include the full URL (e.g.,
                                                https://www.example.com)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="overview-manager-editor-section-images">
                                    {Array.from({ length: 3 }).map(
                                        (_, fileIndex) => (
                                            <label
                                                key={fileIndex}
                                                className="overview-manager-editor-section-images-image-upload-label"
                                            >
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) =>
                                                        handleImageChange(
                                                            e,
                                                            index,
                                                            fileIndex
                                                        )
                                                    }
                                                    style={{ display: "none" }}
                                                />
                                                <div
                                                    className="overview-manager-editor-section-images-image-upload-square"
                                                    style={{
                                                        backgroundImage: `url(${
                                                            section.files[
                                                                fileIndex
                                                            ]
                                                                ? section.files[
                                                                      fileIndex
                                                                  ].url
                                                                : imagePlaceholder
                                                        })`,
                                                    }}
                                                >
                                                    {!section.files[
                                                        fileIndex
                                                    ] && (
                                                        <div className="image-upload-instructions">
                                                            <span>
                                                                Click to upload
                                                            </span>
                                                            <button
                                                                type="button"
                                                                className="paste-image-button"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    handlePasteImage(
                                                                        index,
                                                                        fileIndex
                                                                    );
                                                                }}
                                                            >
                                                                Paste Image
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                                {section.files[fileIndex] && (
                                                    <button
                                                        className="overview-manager-editor-section-images-delete-image"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            deleteImage(
                                                                index,
                                                                fileIndex
                                                            );
                                                        }}
                                                    >
                                                        <CloseIcon
                                                            style={{
                                                                fontSize:
                                                                    "16px",
                                                            }}
                                                        />
                                                    </button>
                                                )}
                                            </label>
                                        )
                                    )}
                                </div>
                                <button
                                    onClick={() =>
                                        toggleModal(section.title, index)
                                    }
                                >
                                    <DeleteIcon style={{ fontSize: "12px" }} />
                                    Delete section
                                </button>
                            </div>
                        </>
                    ))}
                    <ModalComponent
                        title={`Delete ${modalData.title}`}
                        isOpen={isModalOpen}
                        toggle={() => toggleModal()}
                        submitText="Delete"
                        onSubmit={() => {
                            deleteSection(modalData.index);
                            setIsModalOpen(false);
                        }}
                    >
                        Are you sure you want to delete this section?
                    </ModalComponent>
                    <div className="overview-manager-editor-buttons">
                        <button
                            className="overview-manager-editor-add-section-button"
                            onClick={addSection}
                        >
                            <AddIcon style={{ fontSize: "16px" }} /> Add section
                        </button>
                        <button
                            className="overview-manager-editor-save-button"
                            onClick={() => {
                                saveSections().then(() => {
                                    saveLogo().then(() => {
                                        saveBanner().then(() => {
                                            window.location.reload();
                                        });
                                    });
                                });
                            }}
                        >
                            <SaveIcon style={{ fontSize: "16px" }} />
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div className="overview-content">
            <div className="overview-manager-editor-header">
                <div
                    className="overview-manager-editor-banner"
                    style={
                        lab.banner.current.url && {
                            backgroundImage: `url(${lab.banner.current.url})`,
                        }
                    }
                />
                <div className="overview-manager-editor-content">
                    <div className="overview-manager-editor-logo">
                        <img src={lab.logo.current.url} alt="Lab Logo" />
                    </div>
                    <div className="overview-manager-editor-banner-info">
                        <div className="lab-profile-banner-info-badges">
                            <div>
                                <AccountBalanceIcon
                                    style={{ fontSize: "12px" }}
                                />
                                {lab.institutionName}
                            </div>
                            <div>
                                <SchoolIcon style={{ fontSize: "12px" }} />
                                {lab.facultyName}
                            </div>
                            <div>
                                <ImportContactsIcon
                                    style={{ fontSize: "12px" }}
                                />
                                {lab.departmentName}
                            </div>
                        </div>
                        <h1>{lab.name}</h1>
                        <h2>{lab.keywords.join(", ")}</h2>
                        <button
                            className="overview-manager-editor-edit-button"
                            onClick={() => setEditing(true)}
                        >
                            <EditIcon style={{ fontSize: "16px" }} />
                            Edit Profile
                        </button>
                    </div>
                </div>
            </div>

            <div className="overview-manager-editor-sections">
                {overview.map((section, index) => (
                    <div key={index} className="overview-section-wrapper">
                        <div className="overview-section-title">
                            <h3>{section.title}</h3>
                        </div>
                        <div
                            className="markdown-content"
                            dangerouslySetInnerHTML={{
                                __html: convertMarkdownToHTML(
                                    section.content || ""
                                ),
                            }}
                        />
                        <div className="overview-section-images">
                            {section.files.map((file) => (
                                <div
                                    key={file.url}
                                    className="overview-section-images-container"
                                >
                                    <img
                                        src={file.url}
                                        alt={file.name}
                                        style={{
                                            width: file.width,
                                            height: file.height,
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OverviewManager;
