import React, { useEffect, useState } from "react";
import { fetchGet } from "../../services/data-service";
import { useNavigate, Outlet } from "react-router-dom";
import {
    ExpandMore as ExpandMoreIcon,
    NewReleases as NewReleasesIcon,
    Person as PersonIcon,
} from "@mui/icons-material";
import "../../styles/Admin/Admin.css";

const AdminLabs = () => {
    const [temporaryLabs, setTemporaryLabs] = useState({});
    const [approvedLabs, setApprovedLabs] = useState({});
    const [activeLab, setActiveLab] = useState(null);
    const [temporarySectionOpen, setTemporarySectionOpen] = useState(true);
    const [approvedSectionOpen, setApprovedSectionOpen] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchLabs = async () => {
            const labs = await fetchGet("/admin/labs", true);
            const tempLabsObj = {};
            const approvedLabsObj = {};

            labs.forEach((lab) => {
                const targetObj = lab.temporary ? tempLabsObj : approvedLabsObj;

                if (!targetObj[lab.departmentName]) {
                    targetObj[lab.departmentName] = {
                        isOpen: true,
                        labs: [],
                    };
                }
                targetObj[lab.departmentName].labs.push(lab);
            });

            setTemporaryLabs(tempLabsObj);
            setApprovedLabs(approvedLabsObj);
        };
        fetchLabs();
    }, []);

    const toggleDepartment = (e, departmentName, isTemporary) => {
        e.stopPropagation();
        const setLabsFn = isTemporary ? setTemporaryLabs : setApprovedLabs;
        setLabsFn((prev) => ({
            ...prev,
            [departmentName]: {
                ...prev[departmentName],
                isOpen: !prev[departmentName].isOpen,
            },
        }));
    };

    const renderLabSection = (labsObj, isTemporary) => {
        const isSectionOpen = isTemporary
            ? temporarySectionOpen
            : approvedSectionOpen;
        const setSectionOpen = isTemporary
            ? setTemporarySectionOpen
            : setApprovedSectionOpen;

        return (
            <div className="admin-labs-section">
                <div
                    className="admin-labs-section-header"
                    onClick={() => setSectionOpen(!isSectionOpen)}
                >
                    <h1>
                        {isTemporary ? (
                            <>
                                <NewReleasesIcon
                                    sx={{
                                        color: "#FF9800",
                                        marginRight: "10px",
                                    }}
                                />
                                Awaiting PI Transfer
                            </>
                        ) : (
                            <>
                                <PersonIcon
                                    sx={{
                                        color: "#4CAF50",
                                        marginRight: "10px",
                                    }}
                                />
                                PI-Managed Labs
                            </>
                        )}
                        <ExpandMoreIcon
                            className={`section-expand-icon ${
                                isSectionOpen ? "expanded" : "collapsed"
                            }`}
                            sx={{ marginLeft: "auto" }}
                        />
                    </h1>
                </div>
                {isSectionOpen && Object.entries(labsObj).length > 0 ? (
                    Object.entries(labsObj).map(
                        ([departmentName, lab], idx) => (
                            <div key={idx} className="admin-labs-department">
                                <div
                                    className="admin-labs-department-header"
                                    onClick={(e) =>
                                        toggleDepartment(
                                            e,
                                            departmentName,
                                            isTemporary
                                        )
                                    }
                                >
                                    <h2>
                                        {departmentName} ({lab.labs.length})
                                        <ExpandMoreIcon
                                            className={`department-expand-icon ${
                                                lab.isOpen
                                                    ? "expanded"
                                                    : "collapsed"
                                            }`}
                                        />
                                    </h2>
                                </div>
                                {lab.isOpen && (
                                    <div className="admin-labs-list">
                                        {lab.labs.map((lab, idx) => (
                                            <div
                                                key={idx}
                                                className={`admin-labs-component ${
                                                    lab.temporary
                                                        ? "temporary"
                                                        : "approved"
                                                }`}
                                                onClick={() => {
                                                    navigate(
                                                        `/admin/labs/${lab.id}/overview`
                                                    );
                                                    setActiveLab(lab.id);
                                                }}
                                            >
                                                <h2>{lab.name}</h2>
                                                {lab.temporary && (
                                                    <span className="lab-status pending">
                                                        No PI
                                                    </span>
                                                )}
                                                {!lab.temporary && (
                                                    <span className="lab-status approved">
                                                        PI Transferred
                                                    </span>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )
                    )
                ) : isSectionOpen ? (
                    <div className="admin-labs-empty">
                        <p>
                            No{" "}
                            {isTemporary
                                ? "labs awaiting PI transfer"
                                : "PI-managed labs"}{" "}
                            found.
                        </p>
                    </div>
                ) : null}
            </div>
        );
    };

    return (
        <div className="admin-labs">
            <div className="admin-labs-container">
                {renderLabSection(temporaryLabs, true)}
                {renderLabSection(approvedLabs, false)}
            </div>
            <Outlet />
        </div>
    );
};

export default AdminLabs;
