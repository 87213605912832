import React from "react";
import Message from "./Message";
import { KeyboardArrowDown as ArrowDownIcon } from "@mui/icons-material";

/**
 * MessageList component for displaying all messages
 */
const MessageList = ({
    messages,
    isTyping,
    toggleReasoningVisibility,
    messagesEndRef,
    messagesContainerRef,
    userHasScrolled,
    handleScroll,
    scrollToBottom,
    handleResourceClick,
    handleEditMessage,
    autoScrollEnabled,
    setAutoScrollEnabled,
}) => {
    return (
        <div
            className="lab-assistant-chat-messages-container"
            ref={messagesContainerRef}
            onScroll={handleScroll}
        >
            <div className="lab-assistant-chat-messages-wrapper">
                {messages.length === 0 ? (
                    <div className="lab-assistant-chat-welcome">
                        <h1>How can I help you?</h1>
                        <div className="lab-assistant-chat-sample-questions">
                            <button className="lab-assistant-chat-sample-question">
                                Find me a lab with advanced microscopy equipment
                            </button>
                            <button className="lab-assistant-chat-sample-question">
                                What labs at McGill work on renewable energy?
                            </button>
                            <button className="lab-assistant-chat-sample-question">
                                Tell me about fluorescence microscopy
                                applications
                            </button>
                            <button className="lab-assistant-chat-sample-question">
                                Find equipment for DNA sequencing
                            </button>
                        </div>
                    </div>
                ) : (
                    messages.map((message, index) => (
                        <Message
                            key={index}
                            message={message}
                            index={index}
                            toggleReasoningVisibility={
                                toggleReasoningVisibility
                            }
                            handleResourceClick={handleResourceClick}
                            handleEditMessage={handleEditMessage}
                        />
                    ))
                )}
                {isTyping && (
                    <div className="lab-assistant-chat-typing-indicator">
                        <div className="lab-assistant-chat-typing-dots">
                            <span className="lab-assistant-chat-typing-dot"></span>
                            <span className="lab-assistant-chat-typing-dot"></span>
                            <span className="lab-assistant-chat-typing-dot"></span>
                        </div>
                    </div>
                )}
                <div
                    ref={messagesEndRef}
                    style={{
                        height: "1px",
                        width: "100%",
                        marginTop: "8px",
                        float: "left",
                        clear: "both",
                    }}
                ></div>
            </div>

            {userHasScrolled && (
                <button
                    className="lab-assistant-chat-scroll-btn"
                    onClick={() => {
                        setAutoScrollEnabled(true);
                        scrollToBottom();
                    }}
                >
                    <ArrowDownIcon />
                </button>
            )}
        </div>
    );
};

export default MessageList;
