// Pricing tier data for Individual, Lab, and Institution subscription plans

const pricingTiers = [
    {
        title: "Individual",
        price: 20,
        discountedPrice: null,
        features: [
            "Access to next-gen AI research tools trained on resources around you",
            "Become a 10X more efficient researcher",
            "All basic platform features",
            "Email support",
        ],
        isComingSoon: false,
        ctaText: "Subscribe Now",
    },
    {
        title: "Lab",
        subtitle: "For Principal Investigators only",
        price: 100,
        discountedPrice: null,
        features: [
            "All Individual features",
            "AI tools for everyone in your lab",
            "Custom Lab Profile",
            "Collaborative research environment",
            "Priority support",
        ],
        isComingSoon: false,
        ctaText: "Subscribe Now",
    },
    {
        title: "Institution",
        price: null,
        discountedPrice: null,
        features: [
            "All Lab features",
            "AI tools for everyone in your institution",
            "Custom integrations",
            "Enterprise-grade security",
            "Dedicated account manager",
            "24/7 premium support",
        ],
        isComingSoon: true,
        ctaText: "Stay Tuned",
    },
];

export default pricingTiers;
