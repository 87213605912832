import { Route, Routes } from "react-router-dom";
import {
    AccountSettings,
    BrowseLabs,
    BrowseListings,
    CreateLabPI,
    CreateListing,
    EditListing,
    EmailVerification,
    ErrorPage,
    Home,
    LabProfile,
    LabSignup,
    LabTransfer,
    Listing,
    ListingRental,
    Manager,
    Newsfeed,
    Pricing,
    Profile,
    SubscriptionSuccess,
    LabAssistantChatPage,
} from "../../pages";
import {
    ImportOrcidPublications,
    MyProfile,
    MyPublications,
    MySettings,
} from "../../pages/Account";
import {
    Admin,
    AdminCreateLab,
    AdminLabManager,
    AdminLabRegistrations,
    AdminLabs,
} from "../../pages/Admin";
import {
    AdminLabTransfer,
    AdminListings,
    AdminNews,
    AdminOverview,
} from "../../pages/Admin/AdminLabManagerTabs";
import UserFeedback from "../../pages/Admin/UserFeedback";
import {
    Finances,
    Inventory,
    LabSettings,
    ListingsManager,
    MembersManager,
    Messages,
    MyOrders,
    NewsManager,
    Orders,
    OverviewManager,
    PublicationsManager,
    Statistics,
} from "../../pages/LabManagerTabs";
import {
    LabListings,
    MembersTab,
    News,
    Publications,
    ResearchOverview,
} from "../../pages/LabProfileTabs";

import AdminRoute from "./AdminRoute";
import ApprovedLabRoute from "./ApprovedLabRoute";
import LabSignupRoute from "./LabSignupRoute";
import ManagerRoute from "./ManagerRoute";
import MemberRoute from "./MemberRoute";
import ProtectedRoute from "./ProtectedRoute";
import UnverifiedUserRoute from "./UnverifiedUserRoute";

const NavRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route
                path="/labassistantchat"
                element={<LabAssistantChatPage />}
            />
            <Route
                path="/subscription-success"
                element={<SubscriptionSuccess />}
            />
            <Route
                path="/profile/:userId"
                element={
                    <UnverifiedUserRoute>
                        <Profile />
                    </UnverifiedUserRoute>
                }
            />
            <Route
                path="/account-settings"
                element={
                    <ProtectedRoute>
                        <UnverifiedUserRoute>
                            <AccountSettings />
                        </UnverifiedUserRoute>
                    </ProtectedRoute>
                }
            >
                {/* <Route index element={<Navigate to="profile" />} /> */}
                <Route path="profile" element={<MyProfile />} />
                <Route path="publications" element={<MyPublications />}>
                    <Route
                        path="import-orcid-publications"
                        element={
                            <ProtectedRoute>
                                <ImportOrcidPublications />
                            </ProtectedRoute>
                        }
                    />
                </Route>
                <Route path="settings" element={<MySettings />} />
            </Route>
            <Route
                path="/lab/:labId"
                element={
                    <ApprovedLabRoute>
                        <LabProfile />
                    </ApprovedLabRoute>
                }
            >
                {/* <Route index element={<Navigate to="overview" />} /> */}
                <Route path="overview" element={<ResearchOverview />} />
                <Route path="members" element={<MembersTab />} />
                <Route path="publications" element={<Publications />} />
                <Route path="news" element={<News />} />
                <Route path="listings" element={<LabListings />} />
            </Route>
            <Route path="/listings/" element={<BrowseListings />} />
            <Route
                path="/labsignup/"
                element={
                    <ProtectedRoute>
                        <LabSignup />
                    </ProtectedRoute>
                }
            />
            <Route path="/lab-transfer/accept" element={<LabTransfer />} />
            <Route path="/labs/" element={<BrowseLabs />} />
            <Route path="/listing/:listingId" element={<Listing />} />
            <Route
                path="/listingrental/:listingId"
                element={<ListingRental />}
            />
            <Route
                path="/create-lab-pi/"
                element={
                    <LabSignupRoute>
                        <CreateLabPI />
                    </LabSignupRoute>
                }
            />
            <Route
                path="/manager/:labId/listings/create-listing/"
                element={
                    <ProtectedRoute>
                        <CreateListing />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/admin/labs/:labId/listings/create-listing/"
                element={
                    <ProtectedRoute>
                        <CreateListing />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/manager/:labId/listings/edit-listing/:listingId"
                element={
                    <ProtectedRoute>
                        <EditListing />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/manager/:labId/"
                element={
                    <MemberRoute>
                        <Manager />
                    </MemberRoute>
                }
            >
                {/* <Route index element={<Navigate to="overview" />} /> */}
                <Route
                    path="overview"
                    element={
                        <ManagerRoute>
                            <OverviewManager />
                        </ManagerRoute>
                    }
                />
                <Route
                    path="members"
                    element={
                        <ManagerRoute>
                            <MembersManager />
                        </ManagerRoute>
                    }
                />
                <Route path="listings" element={<ListingsManager />} />
                <Route path="messages" element={<Messages />} />
                <Route path="orders" element={<Orders />} />
                <Route
                    path="statistics"
                    element={
                        <ManagerRoute>
                            <Statistics />
                        </ManagerRoute>
                    }
                />
                <Route
                    path="finances"
                    element={
                        <ManagerRoute>
                            <Finances />
                        </ManagerRoute>
                    }
                />
                <Route path="inventory" element={<Inventory />} />
                <Route
                    path="settings"
                    element={
                        <ManagerRoute>
                            <LabSettings />
                        </ManagerRoute>
                    }
                />
                <Route
                    path="publications"
                    element={
                        <ManagerRoute>
                            <PublicationsManager />
                        </ManagerRoute>
                    }
                />
                <Route
                    path="news"
                    element={
                        <ManagerRoute>
                            <NewsManager />
                        </ManagerRoute>
                    }
                />
            </Route>
            <Route
                path="/myorders"
                element={
                    <ProtectedRoute>
                        <MyOrders />
                    </ProtectedRoute>
                }
            />
            {/* <Route path="/shoppingcart" element={<ShoppingCart />} /> */}
            <Route path="/email-verification" element={<EmailVerification />} />
            <Route
                path="/admin"
                element={
                    <AdminRoute>
                        <Admin />
                    </AdminRoute>
                }
            >
                {/* <Route index element={<Navigate to="registrations" />} /> */}
                <Route
                    path="registrations"
                    element={<AdminLabRegistrations />}
                />
                <Route path="create-lab" element={<AdminCreateLab />} />
                <Route path="labs" element={<AdminLabs />}>
                    <Route path=":labId" element={<AdminLabManager />}>
                        {/* <Route index element={<Navigate to="overview" />} /> */}
                        <Route path="overview" element={<AdminOverview />} />
                        <Route path="listings" element={<AdminListings />} />
                        <Route path="news" element={<AdminNews />} />
                        <Route
                            path="lab-transfer"
                            element={<AdminLabTransfer />}
                        />
                    </Route>
                </Route>
                <Route path="feedback" element={<UserFeedback />} />
            </Route>
            <Route
                path="/admin/labs/:labId/listings/edit-listing/:listingId"
                element={
                    <ProtectedRoute>
                        <EditListing />
                    </ProtectedRoute>
                }
            />
            <Route path="/newsfeed" element={<Newsfeed />} />
            <Route path="/page-not-found" element={<ErrorPage />} />
        </Routes>
    );
};

export default NavRoutes;
