import { useState, useEffect } from "react";
import axios from "axios";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    CircularProgress,
    Box,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../styles/Admin/Admin.css";

// Define the API URL - use a consistent method for all components
const API_BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

const UserFeedback = () => {
    const [feedbackItems, setFeedbackItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [deleteLoading, setDeleteLoading] = useState(false);

    useEffect(() => {
        fetchFeedback();
    }, []);

    const fetchFeedback = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${API_BASE_URL}/api/ai/feedback`);

            if (response.data && response.data.success) {
                const feedback = response.data.feedback || [];
                setFeedbackItems(feedback);
            } else {
                setError("Failed to load feedback data");
            }
        } catch (err) {
            setError(
                "Failed to load feedback: " + (err.message || "Unknown error")
            );
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (timestamp) => {
        if (!timestamp) return "Unknown date";

        let date;
        try {
            // Handle different timestamp formats
            date = timestamp instanceof Date ? timestamp : new Date(timestamp);

            return date.toLocaleString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
            });
        } catch (e) {
            return "Invalid date";
        }
    };

    const retryFetch = () => {
        setError(null);
        fetchFeedback();
    };

    const handleDeleteClick = (item) => {
        setItemToDelete(item);
        setDeleteDialogOpen(true);
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
        setItemToDelete(null);
    };

    const handleDeleteConfirm = async () => {
        if (!itemToDelete || !itemToDelete.id) return;

        try {
            setDeleteLoading(true);
            const response = await axios.delete(
                `${API_BASE_URL}/api/ai/feedback/${itemToDelete.id}`
            );

            if (response.data && response.data.success) {
                // Remove item from local state
                setFeedbackItems(
                    feedbackItems.filter((item) => item.id !== itemToDelete.id)
                );
            } else {
                setError("Failed to delete feedback");
            }
        } catch (err) {
            setError(
                "Failed to delete feedback: " + (err.message || "Unknown error")
            );
        } finally {
            setDeleteLoading(false);
            setDeleteDialogOpen(false);
            setItemToDelete(null);
        }
    };

    if (loading) {
        return (
            <Box className="user-feedback-loading">
                <CircularProgress />
                <Typography variant="body1">Loading feedback...</Typography>
            </Box>
        );
    }

    if (error) {
        return (
            <Box className="user-feedback-error">
                <Typography variant="h6" color="error">
                    Error
                </Typography>
                <Typography variant="body1">{error}</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={retryFetch}
                    sx={{ mt: 2 }}
                >
                    Retry
                </Button>
            </Box>
        );
    }

    return (
        <div className="user-feedback-container">
            <Typography variant="h4" className="user-feedback-title">
                User Feedback
            </Typography>
            <Typography variant="body1" className="user-feedback-description">
                This page displays feedback submitted by users through the
                chatbot interface.
            </Typography>

            <div className="user-feedback-actions">
                <Button variant="outlined" onClick={retryFetch}>
                    Refresh Feedback
                </Button>
            </div>

            {feedbackItems.length === 0 ? (
                <Typography className="user-feedback-empty">
                    No feedback has been submitted yet.
                </Typography>
            ) : (
                <TableContainer
                    component={Paper}
                    className="user-feedback-table"
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell width="15%">User</TableCell>
                                <TableCell width="25%">Feedback</TableCell>
                                <TableCell width="15%">Category</TableCell>
                                <TableCell width="15%">Context</TableCell>
                                <TableCell width="15%">Date</TableCell>
                                <TableCell width="10%">Source</TableCell>
                                <TableCell width="5%">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {feedbackItems.map((item) => (
                                <TableRow
                                    key={item.id}
                                    className="user-feedback-row"
                                >
                                    <TableCell className="user-feedback-user">
                                        <Typography
                                            variant="body2"
                                            fontWeight="bold"
                                        >
                                            {item.userName || "Anonymous"}{" "}
                                            {item.userLastName || ""}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                        >
                                            {item.userEmail
                                                ? `${item.userEmail}`
                                                : ""}
                                            {item.userEmail &&
                                            item.userId &&
                                            item.userId !== "anonymous"
                                                ? " | "
                                                : ""}
                                            {item.userId &&
                                            item.userId !== "anonymous"
                                                ? `ID: ${item.userId}`
                                                : item.userEmail
                                                ? ""
                                                : "Anonymous User"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className="user-feedback-message">
                                        {item.message}
                                    </TableCell>
                                    <TableCell className="user-feedback-category">
                                        <Typography
                                            variant="body2"
                                            fontWeight="bold"
                                            sx={{
                                                color:
                                                    item.category ===
                                                    "Bug Report"
                                                        ? "error.main"
                                                        : item.category ===
                                                          "Feature Request"
                                                        ? "primary.main"
                                                        : "text.primary",
                                            }}
                                        >
                                            {item.category || "Other"}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                color:
                                                    item.sentiment ===
                                                    "Positive"
                                                        ? "success.main"
                                                        : item.sentiment ===
                                                          "Negative"
                                                        ? "error.main"
                                                        : "text.secondary",
                                            }}
                                        >
                                            {item.sentiment || "Neutral"} ·
                                            Priority: {item.urgency || "Low"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className="user-feedback-summary">
                                        <Typography variant="body2">
                                            {item.summary ||
                                                "No summary available"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className="user-feedback-date">
                                        {formatDate(item.timestamp)}
                                    </TableCell>
                                    <TableCell className="user-feedback-source">
                                        {item.source || "chatbot"}
                                    </TableCell>
                                    <TableCell className="user-feedback-actions">
                                        <IconButton
                                            aria-label="delete"
                                            color="error"
                                            onClick={() =>
                                                handleDeleteClick(item)
                                            }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete Feedback?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this feedback? This
                        action cannot be undone.
                    </DialogContentText>
                    {itemToDelete && (
                        <Box
                            sx={{
                                mt: 2,
                                p: 2,
                                bgcolor: "background.paper",
                                borderRadius: 1,
                            }}
                        >
                            <Typography variant="body2" gutterBottom>
                                <strong>Message:</strong> {itemToDelete.message}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                <strong>Category:</strong>{" "}
                                {itemToDelete.category || "Other"}
                            </Typography>
                            <Typography variant="body2">
                                <strong>From:</strong>{" "}
                                {itemToDelete.userName || "Anonymous"}
                            </Typography>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDeleteCancel}
                        disabled={deleteLoading}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDeleteConfirm}
                        color="error"
                        variant="contained"
                        disabled={deleteLoading}
                        startIcon={
                            deleteLoading ? (
                                <CircularProgress size={20} />
                            ) : null
                        }
                    >
                        {deleteLoading ? "Deleting..." : "Delete"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UserFeedback;
