import { useState, useRef, useEffect, useCallback } from "react";
import {
    SmartToy as SmartToyIcon,
    Fullscreen as FullscreenIcon,
    FullscreenExit as FullscreenExitIcon,
    Close as CloseIcon,
    Send as SendIcon,
    Search as SearchIcon,
    InfoOutlined as InfoOutlinedIcon,
    PhotoCamera as PhotoCameraIcon,
    CancelOutlined as CancelOutlinedIcon,
} from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { UserAuth } from "../../context/auth-context";
import "../../styles/components/LabAssistantChatbot.css";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSubscription } from "../../context/subscription-context";

const LabAssistantChatBot = () => {
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [isMaximized, setIsMaximized] = useState(false);
    const [chatMessage, setChatMessage] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const [conversationHistory, setConversationHistory] = useState([]);
    const [model, setModel] = useState("flash");
    const [uploadedImage, setUploadedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const messagesEndRef = useRef(null);
    const messagesContainerRef = useRef(null);
    const textareaRef = useRef(null);
    const imageInputRef = useRef(null);
    const [userHasScrolled, setUserHasScrolled] = useState(false);
    const { user } = UserAuth();
    const location = useLocation();

    const [chatMessages, setChatMessages] = useState([]);
    const { subscription, remainingQueries, updateRemainingQueries } =
        useSubscription();
    const navigate = useNavigate();

    // Set up textarea ref handling
    const setTextareaRef = useCallback((element) => {
        if (element) {
            textareaRef.current = element;
            // Initialize with the correct height
            element.style.height = "20px";
        }
    }, []);

    useEffect(() => {
        if (user) {
            setChatMessages([
                {
                    sender: "ai",
                    text: "Hi there! I'm your Lab Assistant. I can help you find research equipment, labs, or answer questions about scientific resources. You can also provide feedback or suggestions about our platform to help us improve. What can I help you with today?",
                },
            ]);
        } else {
            setChatMessages([
                {
                    sender: "ai",
                    text: `Hi there! I'm your Lab Assistant. I can help you find research equipment, labs, or answer questions about scientific resources. To use this feature, please <strong><a href='${process.env.REACT_APP_FRONTEND_URL}/?mode=signup' rel='noopener noreferrer'>sign up for a free account</a></strong> or <strong><a href='${process.env.REACT_APP_FRONTEND_URL}/?mode=login' rel='noopener noreferrer'>sign in</a></strong>. It only takes a minute and gives you access to all our platform features!`,
                },
            ]);
        }
    }, [user]);

    useEffect(() => {
        if (location.pathname === "/") {
            setIsChatOpen(false);
        }
    }, [location]);

    // Function to handle sending a message to the AI
    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!chatMessage.trim() && !uploadedImage) return;

        // Check if trying to send an image with DeepThink model
        if (model === "reasoner" && uploadedImage) {
            setChatMessages((prev) => [
                ...prev,
                {
                    sender: "user",
                    text: chatMessage,
                    image: imagePreview,
                },
                {
                    sender: "ai",
                    text: "I'm sorry, but the DeepThink model cannot process images. Please switch to the standard model or Pro Knowledge to send images.",
                },
            ]);
            setChatMessage("");
            setUploadedImage(null);
            setImagePreview(null);
            return;
        }

        // Check subscription status and remaining queries
        if (
            !user?.isAdmin &&
            subscription?.status !== "active" &&
            remainingQueries <= 0
        ) {
            setChatMessages((prev) => [
                ...prev,
                {
                    sender: "user",
                    text: chatMessage,
                    image: imagePreview,
                },
                {
                    sender: "ai",
                    text: `You've reached your free message limit. To continue using the research assistant, please subscribe to one of our plans at <strong><a href='${process.env.REACT_APP_FRONTEND_URL}/pricing' rel='noopener noreferrer'>our pricing page</a></strong>.`,
                },
            ]);
            setChatMessage("");
            setUploadedImage(null);
            setImagePreview(null);

            // Add a slight delay before redirecting
            setTimeout(() => {
                navigate("/pricing");
            }, 3000);

            return;
        }

        // If user doesn't have subscription, update remaining queries
        if (subscription?.status !== "active") {
            try {
                await updateRemainingQueries();
            } catch (error) {
                console.error("Error updating query count:", error);
                return;
            }
        }

        // Add additional check to prevent non-logged in users from sending messages
        if (!user) {
            setChatMessages((prev) => [
                ...prev,
                {
                    sender: "user",
                    text: chatMessage,
                    image: imagePreview,
                },
                {
                    sender: "ai",
                    text: `To use this feature, please <strong><a href='${process.env.REACT_APP_FRONTEND_URL}/?mode=signup' rel='noopener noreferrer'>sign up for a free account</a></strong> or <strong><a href='${process.env.REACT_APP_FRONTEND_URL}/?mode=login' rel='noopener noreferrer'>sign in</a></strong>. It only takes a minute and gives you access to all our platform features!`,
                },
            ]);
            setChatMessage("");
            setUploadedImage(null);
            setImagePreview(null);
            return;
        }

        // Add user message to chat
        const userMessage = {
            sender: "user",
            text: chatMessage,
            image: imagePreview,
            // Add user metadata to the message if user is logged in
            metadata: user
                ? {
                      userId: user.uid,
                      userName: user.displayName?.split(" ")[0] || "User",
                      userLastName:
                          user.displayName?.split(" ").slice(1).join(" ") || "",
                      userEmail: user.email,
                  }
                : null,
        };
        setChatMessages((prev) => [...prev, userMessage]);

        // Update conversation history
        const updatedHistory = [
            ...conversationHistory,
            {
                role: "user",
                content: chatMessage,
                // Add user metadata to the message if user is logged in
                metadata: user
                    ? {
                          userId: user.uid,
                          userName: user.displayName?.split(" ")[0] || "User",
                          userLastName:
                              user.displayName?.split(" ").slice(1).join(" ") ||
                              "",
                          userEmail: user.email,
                      }
                    : null,
            },
        ];
        setConversationHistory(updatedHistory);

        // Clear input and show typing indicator
        const messageToSend = chatMessage;
        setChatMessage("");
        setIsTyping(true);

        // Get image data if available
        let imageData = null;
        let mimeType = null;

        if (uploadedImage) {
            // Convert image to base64
            try {
                const reader = new FileReader();
                const imageDataPromise = new Promise((resolve, reject) => {
                    reader.onload = (e) => resolve(e.target.result);
                    reader.onerror = (e) => reject(e);
                    reader.readAsDataURL(uploadedImage);
                });

                const dataUrl = await imageDataPromise;
                // Format: "data:image/jpeg;base64,/9j/4AAQSkZJRg..."

                mimeType = uploadedImage.type;
                // Extract the base64 part (remove the "data:image/jpeg;base64," prefix)
                imageData = dataUrl.split(",")[1];
            } catch (error) {
                console.error("Error converting image to base64:", error);
            }
        }

        // Clear the uploaded image
        setUploadedImage(null);
        setImagePreview(null);

        // Reset textarea height
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
        }

        // Add an immediate thinking message that will be updated with real content
        setChatMessages((prev) => [
            ...prev,
            {
                sender: "ai",
                text: "I'm thinking about your question...",
                isCurrentResponse: true,
                isThinking: true,
            },
        ]);

        // Start a timer to update the thinking message with more engaging content
        let thinkingStep = 0;
        const thinkingMessages = [
            "I'm searching for relevant information...",
            "Analyzing your request...",
            "Looking through available resources...",
            "Almost there, putting together a helpful response for you...",
        ];

        const thinkingInterval = setInterval(() => {
            thinkingStep = (thinkingStep + 1) % thinkingMessages.length;
            setChatMessages((prev) => {
                const newMessages = [...prev];
                const thinkingMsgIndex = newMessages.findIndex(
                    (msg) => msg.isThinking && msg.isCurrentResponse
                );

                if (thinkingMsgIndex !== -1) {
                    newMessages[thinkingMsgIndex].text =
                        thinkingMessages[thinkingStep];
                }

                return newMessages;
            });
        }, 2000);

        try {
            // Create a controller to abort the fetch if needed
            const controller = new AbortController();
            const signal = controller.signal;

            // Prepare the request body
            const requestBody = {
                message: messageToSend,
                conversationHistory: conversationHistory,
                model: model,
            };

            // Add image data if available
            if (imageData) {
                requestBody.image = {
                    data: imageData,
                    mimeType: mimeType,
                };
            }

            // Make the POST request with the appropriate headers for SSE
            const response = await fetch(
                `${
                    process.env.REACT_APP_BACKEND_BASE_URL ||
                    "http://localhost:8080/api"
                }/ai/chat`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "text/event-stream",
                        ...(user && {
                            Authorization: `Bearer ${await user.getIdToken()}`,
                        }),
                    },
                    body: JSON.stringify(requestBody),
                    signal: signal,
                }
            );

            // Clear the thinking interval once we get a response
            clearInterval(thinkingInterval);

            // Create a reader from the response body stream
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let aiResponse = "";
            let searchData = null;
            let buffer = ""; // Add a buffer to handle split JSON data

            // Function to process the stream
            const processStream = async () => {
                try {
                    while (true) {
                        const { done, value } = await reader.read();

                        if (done) {
                            console.log(
                                "Stream ended - finalizing any pending responses"
                            );
                            // If we have a current response that hasn't been marked as complete
                            setChatMessages((prev) => {
                                const hasPendingResponse = prev.some(
                                    (msg) => msg.isCurrentResponse
                                );
                                if (hasPendingResponse) {
                                    console.log(
                                        "Found pending response - marking as complete"
                                    );
                                    return prev.map((msg) => {
                                        if (msg.isCurrentResponse) {
                                            // Always remove thinking flag
                                            return {
                                                ...msg,
                                                isThinking: false,
                                                isCurrentResponse: false,
                                                // Add search data if available
                                                ...(searchData
                                                    ? {
                                                          hasReferencedEntities: true,
                                                          searchData:
                                                              searchData,
                                                          referencedEntities:
                                                              searchData.searchType ===
                                                              "both"
                                                                  ? [
                                                                        ...(searchData
                                                                            ?.analyzedResults
                                                                            ?.labs
                                                                            ?.recommendations ||
                                                                            []),
                                                                        ...(searchData
                                                                            ?.analyzedResults
                                                                            ?.listings
                                                                            ?.recommendations ||
                                                                            []),
                                                                    ]
                                                                  : searchData
                                                                        ?.analyzedResults
                                                                        ?.recommendations ||
                                                                    [],
                                                      }
                                                    : {}),
                                            };
                                        }
                                        return msg;
                                    });
                                }
                                return prev;
                            });
                            break;
                        }

                        // Decode the chunk and add to buffer
                        const chunk = decoder.decode(value, { stream: true });
                        buffer += chunk;

                        // Find complete SSE events and process them
                        const events = buffer.split("\n\n");
                        // Keep the last potentially incomplete event in the buffer
                        buffer = events.pop() || "";

                        for (const event of events) {
                            if (!event.trim() || !event.startsWith("data: "))
                                continue;

                            try {
                                const jsonStr = event.substring(6);
                                const eventData = JSON.parse(jsonStr);

                                if (eventData.status === "connected") {
                                    console.log("SSE connection established");
                                    continue;
                                }

                                if (eventData.chunk) {
                                    // Only append to aiResponse if it's not a reasoning chunk
                                    if (eventData.chunkType !== "reasoning") {
                                        aiResponse += eventData.chunk;
                                    }

                                    // Update the AI message in real-time as chunks arrive
                                    setChatMessages((prev) => {
                                        const newMessages = [...prev];
                                        // Check if we already have an AI message for this response
                                        const aiMessageIndex =
                                            newMessages.findIndex(
                                                (msg) =>
                                                    msg.sender === "ai" &&
                                                    msg.isCurrentResponse
                                            );

                                        if (aiMessageIndex !== -1) {
                                            // Update existing message based on chunk type
                                            if (
                                                eventData.chunkType ===
                                                "reasoning"
                                            ) {
                                                // For reasoning content, we replace the entire content
                                                // instead of appending to prevent duplicated words
                                                newMessages[
                                                    aiMessageIndex
                                                ].reasoning = eventData.chunk;
                                                newMessages[
                                                    aiMessageIndex
                                                ].hasReasoning = true;
                                                newMessages[
                                                    aiMessageIndex
                                                ].showReasoning = true; // Show reasoning by default during generation
                                                newMessages[
                                                    aiMessageIndex
                                                ].isGenerating = true; // Mark as still generating
                                            } else {
                                                // Regular content
                                                newMessages[
                                                    aiMessageIndex
                                                ].text = aiResponse;
                                            }
                                            // Remove the thinking flag
                                            newMessages[
                                                aiMessageIndex
                                            ].isThinking = false;
                                        } else {
                                            // Add new AI message
                                            const newMessage = {
                                                sender: "ai",
                                                text:
                                                    eventData.chunkType ===
                                                    "reasoning"
                                                        ? ""
                                                        : aiResponse,
                                                isCurrentResponse: true,
                                                isGenerating: true, // Mark as still generating
                                            };

                                            // Add reasoning if this is a reasoning chunk
                                            if (
                                                eventData.chunkType ===
                                                "reasoning"
                                            ) {
                                                newMessage.reasoning =
                                                    eventData.chunk;
                                                newMessage.hasReasoning = true;
                                                newMessage.showReasoning = true; // Show reasoning by default during generation
                                            }

                                            newMessages.push(newMessage);
                                        }

                                        return newMessages;
                                    });
                                }

                                if (eventData.status === "complete") {
                                    console.log("Received complete status");

                                    // Mark the response as complete - always remove thinking flag
                                    setChatMessages((prev) => {
                                        const newMessages = prev.map((msg) => {
                                            if (msg.isCurrentResponse) {
                                                // Always remove thinking flag and mark as not generating anymore
                                                const updatedMsg = {
                                                    ...msg,
                                                    isThinking: false,
                                                    isCurrentResponse: false,
                                                    isGenerating: false, // Mark as done generating
                                                };
                                                return updatedMsg;
                                            }
                                            return msg;
                                        });

                                        return newMessages;
                                    });

                                    // Update conversation history with AI's response
                                    setConversationHistory((prev) => {
                                        let finalResponse = aiResponse;

                                        // If we have search results, add them to the conversation history
                                        if (
                                            searchData &&
                                            searchData.searchType &&
                                            // Handle single search type results
                                            ((searchData.analyzedResults &&
                                                searchData.analyzedResults
                                                    .recommendations &&
                                                searchData.analyzedResults
                                                    .recommendations.length >
                                                    0) ||
                                                // Handle "both" search type results
                                                (searchData.analyzedResults &&
                                                    ((searchData.analyzedResults
                                                        .labs &&
                                                        searchData
                                                            .analyzedResults
                                                            .labs
                                                            .recommendations) ||
                                                        (searchData
                                                            .analyzedResults
                                                            .listings &&
                                                            searchData
                                                                .analyzedResults
                                                                .listings
                                                                .recommendations))))
                                        ) {
                                            // Extract the AI's initial response
                                            let initialResponse = aiResponse;
                                            let recommendations = [];

                                            // Extract recommendations based on search type
                                            if (
                                                searchData.searchType === "both"
                                            ) {
                                                // Handle combined search results
                                                const labRecs =
                                                    searchData.analyzedResults
                                                        .labs
                                                        ?.recommendations || [];
                                                const listingRecs =
                                                    searchData.analyzedResults
                                                        .listings
                                                        ?.recommendations || [];
                                                recommendations = [
                                                    ...labRecs,
                                                    ...listingRecs,
                                                ];
                                            } else {
                                                // Handle single search type
                                                recommendations =
                                                    searchData.analyzedResults
                                                        .recommendations || [];
                                            }

                                            // Check if the response already contains any of the search result titles
                                            const hasReferencedEntities =
                                                recommendations.some((result) =>
                                                    initialResponse.includes(
                                                        result.title
                                                    )
                                                );

                                            if (
                                                !hasReferencedEntities &&
                                                recommendations.length > 0
                                            ) {
                                                // Add a line break if needed
                                                if (
                                                    initialResponse
                                                        .trim()
                                                        .endsWith(".") ||
                                                    initialResponse
                                                        .trim()
                                                        .endsWith("!") ||
                                                    initialResponse
                                                        .trim()
                                                        .endsWith("?")
                                                ) {
                                                    finalResponse =
                                                        initialResponse +
                                                        "\n\n";
                                                } else {
                                                    finalResponse =
                                                        initialResponse;
                                                }

                                                finalResponse +=
                                                    "Here are some options based on your query:\n\n";

                                                // List all search results
                                                recommendations.forEach(
                                                    (result, idx) => {
                                                        finalResponse += `${
                                                            idx + 1
                                                        }. **${
                                                            result.title
                                                        }**\n${
                                                            result.highlights
                                                        }\n\n`;
                                                    }
                                                );
                                            }
                                        }

                                        return [
                                            ...prev,
                                            {
                                                role: "assistant",
                                                content: finalResponse,
                                            },
                                        ];
                                    });

                                    // Stop typing indicator
                                    setIsTyping(false);
                                    break;
                                }

                                if (eventData.status === "error") {
                                    console.error(
                                        "Error from AI service:",
                                        eventData.message
                                    );
                                    // Clear the thinking interval
                                    clearInterval(thinkingInterval);
                                    setChatMessages((prev) => [
                                        ...prev,
                                        {
                                            sender: "ai",
                                            text: "I'm sorry, I encountered an error while processing your request. Please try again in a moment.",
                                            isError: true,
                                        },
                                    ]);
                                    setIsTyping(false);
                                    break;
                                }
                            } catch (parseError) {
                                console.error(
                                    "Error parsing SSE data:",
                                    parseError,
                                    event
                                );
                            }
                        }
                    }
                } catch (streamError) {
                    console.error("Error processing stream:", streamError);
                    // Clear the thinking interval
                    clearInterval(thinkingInterval);
                    setChatMessages((prev) => [
                        ...prev,
                        {
                            sender: "ai",
                            text: "I apologize, but I'm having trouble connecting to the server. Please try again in a moment.",
                            isError: true,
                        },
                    ]);
                    setIsTyping(false);
                }
            };

            // Start processing the stream
            processStream();
        } catch (error) {
            console.error("Error sending message to AI:", error);
            // Clear the thinking interval
            clearInterval(thinkingInterval);
            setChatMessages((prev) => [
                ...prev,
                {
                    sender: "ai",
                    text: "I'm sorry, I couldn't connect to the AI service right now. Please try again in a moment.",
                    isError: true,
                },
            ]);
            setIsTyping(false);
        }
    };

    // Function to handle clicking on a resource in AI responses
    const handleResourceClick = async (result) => {
        if (!result) return;

        console.log("Clicked resource:", result);
        console.log("Resource type:", result.type);

        // For lab results, we can directly navigate
        if (result.type === "lab" || result.name) {
            const url = `/lab/${result.id}/overview`;
            console.log("Using lab URL:", url);
            window.open(url, "_blank");
            return;
        }

        // For listings, verify the type from backend first
        try {
            console.log(
                "Verifying listing type from backend for ID:",
                result.id
            );
            const response = await fetch(
                `${
                    process.env.REACT_APP_API_URL || "http://localhost:8080"
                }/api/ai/verify-listing-type/${result.id}`
            );

            if (!response.ok) {
                throw new Error(
                    `Error verifying listing type: ${response.statusText}`
                );
            }

            const data = await response.json();
            console.log("Verified listing data:", data);

            // Use the URL format provided by the backend
            const url = data.urlFormat;
            console.log("Using verified URL:", url);
            window.open(url, "_blank");
        } catch (error) {
            console.error("Error verifying listing type:", error);

            // Fallback to default URL if verification fails
            let url = `/listingrental/${result.id}`;
            if (
                result.type &&
                result.type !== "equipment-rental" &&
                result.type !== "training-expertise"
            ) {
                url = `/listing/${result.id}`;
            }

            console.log("Using fallback URL:", url);
            window.open(url, "_blank");
        }
    };

    /**
     * Determines the appropriate emoji for an entity based on its type and name
     * @param {string} type - The entity type (lab, listing, equipment, user, etc.)
     * @param {string} name - The name of the entity
     * @returns {string} - The emoji character
     */
    const getEmojiForEntity = (type, name) => {
        // Convert name to lowercase for case-insensitive matching
        const nameLower = name.toLowerCase();

        // Default emojis based on type
        const defaultEmojis = {
            lab: "📍", // Changed from microscope to red pin
            listing: "📦",
            "specific-item": "📦",
            service: "🛠️",
            "digital-good": "💾",
            equipment: "⚙️", // Changed from wrench to gear
            user: "👤",
        };

        // For labs, always return the red pin emoji
        if (type === "lab") {
            return "📍"; // Always use red pin for labs
        }

        // Pattern matching for equipment
        else if (type === "equipment") {
            // Imaging and optical equipment
            if (/microscope|micros|microsc/i.test(nameLower)) return "🔬"; // Changed to microscope emoji
            if (/telescope|lens|optic/i.test(nameLower)) return "🔭";
            if (/spectr|photo/i.test(nameLower)) return "📸";
            if (/laser|light|illuminat/i.test(nameLower)) return "🔆";
            if (/x-ray|xray|radiograph/i.test(nameLower)) return "📡";
            if (/mri|magnetic resonance/i.test(nameLower)) return "🧲";

            // Lab processing equipment
            if (/centrifuge|spinner/i.test(nameLower)) return "🌀";
            if (/mixer|blend|stir/i.test(nameLower)) return "🔄";
            if (/shaker|agitat/i.test(nameLower)) return "♻️";
            if (/homogeniz|disrupt/i.test(nameLower)) return "💥";
            if (/grind|mill|crush/i.test(nameLower)) return "⚒️";
            if (/filter|separat|purif/i.test(nameLower)) return "🧹";

            // Analytical equipment
            if (/spectro|chroma|mass spec/i.test(nameLower)) return "📊";
            if (/nmr|nuclear magnetic/i.test(nameLower)) return "🧪";
            if (/gc|gas chroma/i.test(nameLower)) return "📈";
            if (/hplc|liquid chroma/i.test(nameLower)) return "💧";
            if (/electro|voltage|current/i.test(nameLower)) return "⚡";
            if (/meter|measure|scale|balance/i.test(nameLower)) return "⚖️";
            if (/counter|detect/i.test(nameLower)) return "🔢";
            if (/sensor|probe/i.test(nameLower)) return "📡";
            if (/analyzer|analysis/i.test(nameLower)) return "🔬";

            // Temperature equipment
            if (/incubat|oven|furnace|heat/i.test(nameLower)) return "🔥";
            if (/freez|cold|cryo|refriger/i.test(nameLower)) return "❄️";
            if (/therm|temperature|climate/i.test(nameLower)) return "🌡️";
            if (/bath|water/i.test(nameLower)) return "💦";
            if (/dry|dehydrat/i.test(nameLower)) return "☀️";

            // Computing and automation
            if (/comput|server|process/i.test(nameLower)) return "💻";
            if (/printer|3d print/i.test(nameLower)) return "🖨️";
            if (/robot|automat/i.test(nameLower)) return "🤖";
            if (/control|monitor/i.test(nameLower)) return "🎛️";
            if (/software|program/i.test(nameLower)) return "💿";

            // Biological equipment
            if (/sequenc|genom/i.test(nameLower)) return "🧬";
            if (/pcr|polymer/i.test(nameLower)) return "🧫";
            if (/culture|cell|tissue/i.test(nameLower)) return "🦠";
            if (/bio|life/i.test(nameLower)) return "🌱";
            if (/flow cytom/i.test(nameLower)) return "📊";

            // Medical equipment
            if (/medical|clinic|patient/i.test(nameLower)) return "🏥";
            if (/surgery|surgical/i.test(nameLower)) return "🔪";
            if (/monitor|vital/i.test(nameLower)) return "💓";
            if (/inject|syringe/i.test(nameLower)) return "💉";

            // Workshop tools
            if (/drill|bore/i.test(nameLower)) return "🔩";
            if (/saw|cut/i.test(nameLower)) return "🪚";
            if (/hammer|pound/i.test(nameLower)) return "🔨";
            if (/wrench|spanner/i.test(nameLower)) return "🔧";
            if (/screw|bolt|fastener/i.test(nameLower)) return "🔩";
            if (/weld|solder|join/i.test(nameLower)) return "🔗";
            if (/tool|kit|set/i.test(nameLower)) return "🧰";

            // Safety equipment
            if (/safety|protect/i.test(nameLower)) return "🛡️";
            if (/hood|cabinet|enclos/i.test(nameLower)) return "🚪";
            if (/glove|box/i.test(nameLower)) return "🧤";
            if (/mask|respirat/i.test(nameLower)) return "😷";
            if (/clean|steril/i.test(nameLower)) return "🧼";
            if (/waste|dispos/i.test(nameLower)) return "🗑️";

            // Storage equipment
            if (/storage|container|tank/i.test(nameLower)) return "🗄️";
            if (/cabinet|shelf|rack/i.test(nameLower)) return "🗃️";
            if (/bottle|flask|vial/i.test(nameLower)) return "🧪";
        }

        // Pattern matching for listings/items
        else if (type === "listing" || type === "specific-item") {
            if (/reagent|chemical|solution/i.test(nameLower)) return "🧪";
            if (/kit|assay|test/i.test(nameLower)) return "📦";
            if (/antibod|protein|enzyme/i.test(nameLower)) return "🧫";
            if (/glass|tube|container|flask/i.test(nameLower)) return "🧪";
            if (/tool|instrument/i.test(nameLower)) return "🧰";
            if (/book|manual|guide/i.test(nameLower)) return "📚";
            if (/software|program|app/i.test(nameLower)) return "💻";
            if (/data|dataset/i.test(nameLower)) return "📊";
            if (/sample|specimen/i.test(nameLower)) return "🧪";
            if (/material|substance/i.test(nameLower)) return "🧱";
            if (/consumable|dispos/i.test(nameLower)) return "🗑️";
            if (/part|component/i.test(nameLower)) return "🔩";
            if (/device|gadget/i.test(nameLower)) return "📱";
            if (/supply|resource/i.test(nameLower)) return "📋";
            if (/media|culture/i.test(nameLower)) return "🧫";
            if (/buffer|solution/i.test(nameLower)) return "💧";
            if (/primer|oligo/i.test(nameLower)) return "🧬";
            if (/dye|stain|color/i.test(nameLower)) return "🎨";
        }

        // Pattern matching for services
        else if (type === "service") {
            if (/analy|test/i.test(nameLower)) return "📋";
            if (/consult|advis/i.test(nameLower)) return "💬";
            if (/train|teach|educat/i.test(nameLower)) return "🎓";
            if (/design|develop/i.test(nameLower)) return "✏️";
            if (/sequenc|genom/i.test(nameLower)) return "🧬";
            if (/synth|produc/i.test(nameLower)) return "⚗️";
            if (/repair|mainten/i.test(nameLower)) return "🔧";
            if (/calibrat|valid/i.test(nameLower)) return "📏";
            if (/install|setup/i.test(nameLower)) return "🔌";
            if (/protocol|method/i.test(nameLower)) return "📝";
            if (/research|study/i.test(nameLower)) return "🔍";
            if (/collaborat|partner/i.test(nameLower)) return "🤝";
            if (/access|use|rent/i.test(nameLower)) return "🔑";
            if (/process|workflow/i.test(nameLower)) return "⚙️";
            if (/data|analytic/i.test(nameLower)) return "📊";
            if (/compute|calcul/i.test(nameLower)) return "🖥️";
            if (/support|help/i.test(nameLower)) return "🆘";
        }

        // Pattern matching for digital goods
        else if (type === "digital-good") {
            if (/data|dataset/i.test(nameLower)) return "📊";
            if (/software|app|program/i.test(nameLower)) return "💻";
            if (/model|algorithm/i.test(nameLower)) return "🧮";
            if (/image|photo|picture/i.test(nameLower)) return "🖼️";
            if (/video|movie/i.test(nameLower)) return "🎬";
            if (/audio|sound/i.test(nameLower)) return "🔊";
            if (/document|paper|report/i.test(nameLower)) return "📄";
            if (/book|manual|guide/i.test(nameLower)) return "📚";
            if (/code|script/i.test(nameLower)) return "👨‍💻";
            if (/template|framework/i.test(nameLower)) return "🏗️";
            if (/protocol|method/i.test(nameLower)) return "📝";
            if (/license|permit/i.test(nameLower)) return "🔐";
            if (/course|class|lesson/i.test(nameLower)) return "🎓";
            if (/simulation|virtual/i.test(nameLower)) return "🎮";
            if (/database|repository/i.test(nameLower)) return "🗄️";
        }

        // Pattern matching for users
        else if (type === "user") {
            if (/professor|pi|lead|director/i.test(nameLower)) return "👨‍🏫";
            if (/student|grad|phd/i.test(nameLower)) return "👨‍🎓";
            if (/tech|assist/i.test(nameLower)) return "👨‍💻";
            if (/research|scientist/i.test(nameLower)) return "👨‍🔬";
            if (/admin|manager/i.test(nameLower)) return "👨‍💼";
            if (/doctor|md|physician/i.test(nameLower)) return "👨‍⚕️";
            if (/engineer|developer/i.test(nameLower)) return "👷";
            if (/fellow|post-?doc/i.test(nameLower)) return "🧑‍🔬";
            if (/staff|employee/i.test(nameLower)) return "👥";
            if (/collaborator|partner/i.test(nameLower)) return "🤝";
            if (/visitor|guest/i.test(nameLower)) return "🧳";
            if (/dean|chair|head/i.test(nameLower)) return "👑";
        }

        // Try to extract an emoji based on the name if no pattern matches
        // This is a fallback to provide more diverse emojis
        if (nameLower.includes("microscope")) return "🔬";
        if (nameLower.includes("computer")) return "💻";
        if (nameLower.includes("dna") || nameLower.includes("rna")) return "🧬";
        if (nameLower.includes("brain")) return "🧠";
        if (nameLower.includes("heart")) return "❤️";
        if (nameLower.includes("eye")) return "👁️";
        if (nameLower.includes("ear")) return "👂";
        if (nameLower.includes("hand")) return "✋";
        if (nameLower.includes("foot")) return "🦶";
        if (nameLower.includes("bone")) return "🦴";
        if (nameLower.includes("tooth")) return "🦷";
        if (nameLower.includes("blood")) return "🩸";
        if (nameLower.includes("water")) return "💧";
        if (nameLower.includes("fire")) return "🔥";
        if (nameLower.includes("earth")) return "🌍";
        if (nameLower.includes("air")) return "💨";
        if (nameLower.includes("plant")) return "🌱";
        if (nameLower.includes("animal")) return "🐾";
        if (nameLower.includes("bacteria")) return "🦠";
        if (nameLower.includes("virus")) return "🦠";
        if (nameLower.includes("cell")) return "🧫";
        if (nameLower.includes("molecule")) return "⚛️";
        if (nameLower.includes("atom")) return "⚛️";
        if (nameLower.includes("star")) return "⭐";
        if (nameLower.includes("moon")) return "🌙";
        if (nameLower.includes("sun")) return "☀️";
        if (nameLower.includes("cloud")) return "☁️";
        if (nameLower.includes("rain")) return "🌧️";
        if (nameLower.includes("snow")) return "❄️";
        if (nameLower.includes("wind")) return "💨";
        if (nameLower.includes("mountain")) return "🏔️";
        if (nameLower.includes("ocean")) return "🌊";
        if (nameLower.includes("river")) return "🏞️";
        if (nameLower.includes("forest")) return "🌲";
        if (nameLower.includes("desert")) return "🏜️";
        if (nameLower.includes("city")) return "🏙️";
        if (nameLower.includes("building")) return "🏢";
        if (nameLower.includes("house")) return "🏠";
        if (nameLower.includes("car")) return "🚗";
        if (nameLower.includes("train")) return "🚆";
        if (nameLower.includes("plane")) return "✈️";
        if (nameLower.includes("boat")) return "🚢";
        if (nameLower.includes("rocket")) return "🚀";
        if (nameLower.includes("satellite")) return "🛰️";
        if (nameLower.includes("telescope")) return "🔭";
        if (nameLower.includes("camera")) return "📷";
        if (nameLower.includes("phone")) return "📱";
        if (nameLower.includes("book")) return "📚";
        if (nameLower.includes("pen")) return "🖊️";
        if (nameLower.includes("pencil")) return "✏️";
        if (nameLower.includes("paper")) return "📄";
        if (nameLower.includes("scissors")) return "✂️";
        if (nameLower.includes("key")) return "🔑";
        if (nameLower.includes("lock")) return "🔒";
        if (nameLower.includes("hammer")) return "🔨";
        if (nameLower.includes("wrench")) return "🔧";
        if (nameLower.includes("screwdriver")) return "🪛";
        if (nameLower.includes("knife")) return "🔪";
        if (nameLower.includes("fork")) return "🍴";
        if (nameLower.includes("spoon")) return "🥄";
        if (nameLower.includes("plate")) return "🍽️";
        if (nameLower.includes("cup")) return "🥤";
        if (nameLower.includes("glass")) return "🥃";
        if (nameLower.includes("bottle")) return "🍾";
        if (nameLower.includes("food")) return "🍲";
        if (nameLower.includes("fruit")) return "🍎";
        if (nameLower.includes("vegetable")) return "🥦";
        if (nameLower.includes("meat")) return "🥩";
        if (nameLower.includes("fish")) return "🐟";
        if (nameLower.includes("bread")) return "🍞";
        if (nameLower.includes("cheese")) return "🧀";
        if (nameLower.includes("egg")) return "🥚";
        if (nameLower.includes("milk")) return "🥛";
        if (nameLower.includes("coffee")) return "☕";
        if (nameLower.includes("tea")) return "🍵";
        if (nameLower.includes("wine")) return "🍷";
        if (nameLower.includes("beer")) return "🍺";
        if (nameLower.includes("cocktail")) return "🍸";
        if (nameLower.includes("ice")) return "🧊";
        if (nameLower.includes("cake")) return "🍰";
        if (nameLower.includes("cookie")) return "🍪";
        if (nameLower.includes("candy")) return "🍬";
        if (nameLower.includes("chocolate")) return "🍫";
        if (nameLower.includes("ice cream")) return "🍦";

        // Return default emoji if no pattern matches
        return defaultEmojis[type] || "📌";
    };

    // Function to parse message text and make entity references clickable
    const parseMessageWithClickableEntities = (
        text,
        referencedEntities,
        isLabReference = false
    ) => {
        if (!text) return "";

        // Special case for welcome/error messages with pre-formatted HTML
        if (
            text.startsWith("<strong><a href=") ||
            text.startsWith("Hi there! I'm your Lab Assistant.") || // Allow initial default message
            text.includes("please <strong><a href=") ||
            text.includes("free message limit")
        ) {
            return text;
        }

        // --- New Implementation using React-Markdown approach ---

        // Helper function to escape HTML
        const escapeHtml = (str) => {
            if (typeof str !== "string") return str;
            return str
                .replace(/&/g, "&amp;")
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(/"/g, "&quot;")
                .replace(/'/g, "&#039;");
        };

        // 1. Pre-processing: Handle special entity links with standard markdown format
        let processedText = text;

        // Convert [[lab:id:name]] format to markdown links with data attributes
        processedText = processedText.replace(
            /\[\[(lab|listing|equipment|user|specific-item|service|digital-good):([^:]+):([^\]]+)\]\]/g,
            (match, type, id, name) => {
                let url = "";
                let dataType = type;
                let emoji = getEmojiForEntity(type, name);

                switch (type) {
                    case "lab":
                        url = `${
                            process.env.REACT_APP_FRONTEND_URL || ""
                        }/lab/${id}/overview`;
                        break;
                    case "listing":
                    case "specific-item":
                    case "service":
                    case "digital-good":
                        url = `${
                            process.env.REACT_APP_FRONTEND_URL || ""
                        }/listing/${id}`;
                        break;
                    case "equipment":
                        url = `${
                            process.env.REACT_APP_FRONTEND_URL || ""
                        }/listingrental/${id}`;
                        break;
                    case "user":
                        url = `${
                            process.env.REACT_APP_FRONTEND_URL || ""
                        }/profile/${id}`;
                        break;
                    default:
                        break;
                }

                // Create a simple markdown link with data attributes embedded as comments
                return `<a href="${url}" class="lab-assistant-chatbot-entity-link ${type}-link" data-emoji="${emoji}" data-id="${id}" data-type="${type}" target="_blank" rel="noopener noreferrer">${name}</a>`;
            }
        );

        // Replace with a safer, two-step approach to handle nested brackets and malformed links

        // First, let's identify and extract all valid entity links with a unique placeholder
        const entityLinks = [];
        let linkId = 0;

        // Function to process a single entity link match
        function processEntityLink(match, type, id, name) {
            // Clean the parameters - trim and remove any trailing brackets or parentheses
            type = type.trim();
            id = id.trim();
            name = name.trim().replace(/[\]]+$/, "");

            // Remove any HTML tags or suspicious characters from parameters
            id = id.replace(/<[^>]*>/g, "").replace(/["']/g, "");

            let url = "";
            let emoji = getEmojiForEntity(type, name);

            switch (type) {
                case "lab":
                    url = `${
                        process.env.REACT_APP_FRONTEND_URL || ""
                    }/lab/${encodeURIComponent(id)}/overview`;
                    break;
                case "listing":
                case "specific-item":
                case "service":
                case "digital-good":
                    url = `${
                        process.env.REACT_APP_FRONTEND_URL || ""
                    }/listing/${encodeURIComponent(id)}`;
                    break;
                case "equipment":
                    url = `${
                        process.env.REACT_APP_FRONTEND_URL || ""
                    }/listingrental/${encodeURIComponent(id)}`;
                    break;
                case "user":
                    url = `${
                        process.env.REACT_APP_FRONTEND_URL || ""
                    }/profile/${encodeURIComponent(id)}`;
                    break;
                default:
                    break;
            }

            const placeholder = `__ENTITY_LINK_${linkId++}__`;
            entityLinks.push({
                placeholder,
                html: `<a href="${url}" class="lab-assistant-chatbot-entity-link ${type}-link" data-emoji="${escapeHtml(
                    emoji
                )}" data-id="${escapeHtml(id)}" data-type="${escapeHtml(
                    type
                )}" target="_blank" rel="noopener noreferrer">${escapeHtml(
                    name
                )}</a>`,
            });

            return placeholder;
        }

        // First pass: extract clean entity links with a balanced regex
        let safeText = processedText;
        safeText = safeText.replace(
            /\[\[(lab|listing|equipment|user|specific-item|service|digital-good):([^:]+):([^\]]*?)\]\]/g,
            processEntityLink
        );

        // Second pass: try to recover malformed entity links with a more permissive regex
        safeText = safeText.replace(
            /\[\[(lab|listing|equipment|user|specific-item|service|digital-good):([^:]+):([^]*?)(?:\]\]|\]\s|\n|$)/g,
            (match, type, id, name, offset, string) => {
                // Skip if this is already a placeholder from the first pass
                if (match.startsWith("__ENTITY_LINK_")) return match;

                // Prevent capturing too much text by limiting name length
                const maxNameLength = 200;
                name = name.substring(0, maxNameLength);

                // Clean up the name - remove any content after closing brackets if present
                const bracketMatch = name.match(/(.*?)\]\]/);
                if (bracketMatch) {
                    name = bracketMatch[1];
                }

                return processEntityLink(match, type, id, name);
            }
        );

        // 2. Process markdown with sanitization and smart entity handling
        let htmlOutput = safeText;

        // Process @mentions
        htmlOutput = htmlOutput.replace(
            /(?<![a-zA-Z0-9])@([a-zA-Z0-9_]+)/g,
            (match, username) => {
                const userEmoji = getEmojiForEntity("user", username);
                return `<span class="lab-assistant-chatbot-user-mention" data-username="${escapeHtml(
                    username
                )}" data-emoji="${escapeHtml(userEmoji)}">${escapeHtml(
                    username
                )}</span>`;
            }
        );

        // Hide raw IDs (done early to prevent accidental parsing in later steps)
        htmlOutput = htmlOutput.replace(/\(ID:\s*([a-zA-Z0-9]{20,})\)/g, "()");
        htmlOutput = htmlOutput.replace(/ID:\s*([a-zA-Z0-9]{20,})/g, "");

        // Process Markdown (if not already HTML)
        if (!htmlOutput.startsWith("<")) {
            // Use the clean markdown processor
            htmlOutput = processMarkdown(htmlOutput);
        }

        // 3. Final cleanup and safety checks

        // Make sure all content in code blocks is escaped properly
        htmlOutput = htmlOutput.replace(
            /<code>([\s\S]*?)<\/code>/g,
            (match, codeContent) => {
                return `<code>${escapeHtml(codeContent)}</code>`;
            }
        );

        // Process user mentions in list items (runs on final HTML)
        htmlOutput = htmlOutput.replace(
            /<li>([^<]+?)(?:<br>)?\s*\((?:member|PI|Lab Manager|Researcher|Student)\)<\/li>/g,
            (match, userNameText) => {
                const cleanName = userNameText.trim();
                if (
                    !cleanName ||
                    match.includes("<a href") ||
                    match.includes(
                        '<span class="lab-assistant-chatbot-user-mention">'
                    )
                )
                    return match;
                return `<li><span class="lab-assistant-chatbot-user-mention">${cleanName}</span> (member)</li>`;
            }
        );

        // Final step: restore all entity links
        entityLinks.forEach(({ placeholder, html }) => {
            htmlOutput = htmlOutput.replace(placeholder, html);
        });

        return htmlOutput;
    };

    // Scroll to bottom whenever messages change, but only if user hasn't scrolled up
    const scrollToBottom = () => {
        if (!userHasScrolled) {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    };

    // Handle scroll events in the messages container
    const handleScroll = () => {
        if (!messagesContainerRef.current) return;

        const { scrollTop, scrollHeight, clientHeight } =
            messagesContainerRef.current;
        const isScrolledUp = scrollHeight - scrollTop - clientHeight > 50; // 50px threshold

        setUserHasScrolled(isScrolledUp);

        // If user scrolls to bottom, reset the userHasScrolled flag
        if (scrollHeight - scrollTop - clientHeight < 10) {
            setUserHasScrolled(false);
        }
    };

    useEffect(() => {
        if (isChatOpen) {
            scrollToBottom();
        }
    }, [chatMessages, isChatOpen, userHasScrolled]);

    // Add scroll event listener
    useEffect(() => {
        const messagesContainer = messagesContainerRef.current;
        if (messagesContainer) {
            messagesContainer.addEventListener("scroll", handleScroll);
            return () => {
                messagesContainer.removeEventListener("scroll", handleScroll);
            };
        }
    }, [isChatOpen]);

    // Reset userHasScrolled when a new message is sent
    useEffect(() => {
        setUserHasScrolled(false);
    }, [chatMessages.length]);

    // Function to extract lab names from message text
    const extractLabNamesFromMessage = (messageText) => {
        if (!messageText) return [];

        // First, try to extract lab names from bullet point or numbered list format
        // This pattern looks for lines starting with bullet points or numbers followed by lab names
        const bulletPointPattern =
            /(?:^|\n)\s*(?:[•\*-]|\d+\.)\s+(.*?)(?:\s*:|$)/gm;
        let extractedNames = [];

        const bulletMatches = [...messageText.matchAll(bulletPointPattern)];
        bulletMatches.forEach((match) => {
            if (match[1] && match[1].trim().length > 3) {
                // For each bullet point match, extract the lab name part
                // This handles formats like "* McGill Nanofactory : They have..."
                const labNamePart = match[1].split(/\s*:/)[0].trim();
                extractedNames.push(labNamePart);
            }
        });

        // If we didn't find any bullet points, try more general patterns
        if (extractedNames.length === 0) {
            // Common lab name patterns in the AI's response
            const labPatterns = [
                /\b((?:McGill|MIT|Stanford|Harvard|Berkeley|Cornell|Princeton|Yale|Oxford|Cambridge)[\w\s\-&]+(?:Lab|Laboratory|Research|Group|Center|Centre|Institute|Facility|Nanofactory|Engineering|Design)?)\b/g, // Matches university-affiliated labs
                /\b(Lab of [\w\s\-&]+)\b/g, // Matches "Lab of X" pattern
                /\b([\w\s\-&]+(?:Lab|Laboratory|Research|Group|Center|Centre|Institute|Facility|Nanofactory|Engineering|Design))\b/g, // General lab naming patterns
            ];

            // Apply each pattern and collect matches
            labPatterns.forEach((pattern) => {
                const matches = [...messageText.matchAll(pattern)];
                matches.forEach((match) => {
                    if (match[1] && match[1].trim().length > 3) {
                        // Avoid very short matches
                        extractedNames.push(match[1].trim());
                    }
                });
            });
        }

        // Add specific lab names from the example if they're in the message
        const specificLabNames = [
            "McGill Nanofactory",
            "Lab of Biomaterials Engineering",
            "Mongeau Research Group",
            "Cellular Microenvironment Design Lab",
            "McGill Advanced Therapies Research and Education Centre",
            "MATREC",
        ];

        specificLabNames.forEach((labName) => {
            if (messageText.includes(labName)) {
                extractedNames.push(labName);
            }
        });

        // Remove duplicates
        return [...new Set(extractedNames)];
    };

    // Function to extract equipment names from message text
    const extractEquipmentNamesFromMessage = (messageText) => {
        if (!messageText) return [];

        console.log(
            "Extracting equipment names from:",
            messageText.substring(0, 100) + "..."
        );

        // First, try to extract equipment names from bullet point or numbered list format
        // This pattern looks for lines starting with bullet points or numbers followed by equipment names
        const bulletPointPattern =
            /(?:^|\n)\s*(?:[•\*-]|\d+\.)\s+(.*?)(?:\s*:|$)/gm;
        let extractedNames = [];

        const bulletMatches = [...messageText.matchAll(bulletPointPattern)];
        bulletMatches.forEach((match) => {
            if (match[1] && match[1].trim().length > 3) {
                // For each bullet point match, extract the equipment name part
                // This handles formats like "* Confocal Microscope : This equipment..."
                const equipmentNamePart = match[1].split(/\s*:/)[0].trim();
                extractedNames.push(equipmentNamePart);
            }
        });

        // Common equipment name patterns in the AI's response
        const equipmentPatterns = [
            /\b([\w\s\-&]+(?:Microscope|Spectrometer|Analyzer|Scanner|Imager|Sequencer|Centrifuge|Reactor|Printer|System|Device|Machine|Equipment|Instrument|Apparatus))\b/g, // Matches equipment with common suffixes
            /\b((?:3D|Confocal|Electron|Scanning|Transmission|Fluorescence|Mass|NMR|PCR|HPLC|GC|LC|MS)[\w\s\-&]+)\b/g, // Matches equipment with common prefixes
            /\b([\w\s\-&]+(?:for|to) (?:analysis|imaging|testing|measurement|characterization|synthesis|fabrication|processing))\b/g, // Matches equipment described by function
            /\b([\w\s\-&]+ (?:platform|tool|technology|setup|station|workstation|kit|array|sensor))\b/g, // Additional common equipment terms
            /\b((?:Advanced|High-Resolution|Automated|Integrated|Portable|Digital|Smart|Precision)[\w\s\-&]+)\b/g, // Equipment with quality/feature prefixes
        ];

        // Apply each pattern and collect matches
        equipmentPatterns.forEach((pattern) => {
            const matches = [...messageText.matchAll(pattern)];
            matches.forEach((match) => {
                if (match[1] && match[1].trim().length > 3) {
                    // Avoid very short matches
                    extractedNames.push(match[1].trim());
                }
            });
        });

        // Extract quoted text as potential equipment names
        const quotedTextPattern = /"([^"]+)"|'([^']+)'/g;
        const quotedMatches = [...messageText.matchAll(quotedTextPattern)];
        quotedMatches.forEach((match) => {
            const quotedText = (match[1] || match[2]).trim();
            if (quotedText.length > 3) {
                extractedNames.push(quotedText);
            }
        });

        // Extract text after "such as", "like", "including", "offers", "provides", "features", "using", "with"
        const contextualPatterns = [
            /(?:such as|like|including|offers|provides|features|using|with) ([\w\s\-&,]+?)(?:\.|\n|and|which|that|to|for|$)/gi,
        ];

        contextualPatterns.forEach((pattern) => {
            const matches = [...messageText.matchAll(pattern)];
            matches.forEach((match) => {
                if (match[1]) {
                    // Split by commas to get individual items
                    const items = match[1]
                        .split(/,|\band\b/)
                        .map((item) => item.trim());
                    items.forEach((item) => {
                        if (item.length > 3) {
                            extractedNames.push(item);
                        }
                    });
                }
            });
        });

        // Remove duplicates
        const uniqueNames = [...new Set(extractedNames)];
        console.log("Extracted equipment names:", uniqueNames);
        return uniqueNames;
    };

    // Function to check if a lab result should be displayed based on message text
    const shouldDisplayLabResult = (labResult, messageText) => {
        if (!labResult || !labResult.title || !messageText) return false;

        console.log(`Checking lab result: "${labResult.title}"`);

        // STRICT: Only show labs that are explicitly mentioned by name in the AI's response
        if (messageText.toLowerCase().includes(labResult.title.toLowerCase())) {
            console.log(
                `✅ Direct lab title match found for: "${labResult.title}"`
            );
            return true;
        }

        // Extract lab names from the message
        const mentionedLabNames = extractLabNamesFromMessage(messageText);

        // Check for exact matches with extracted lab names
        for (const labName of mentionedLabNames) {
            const normalizedLabName = labName.toLowerCase().trim();
            const normalizedResultTitle = labResult.title.toLowerCase().trim();

            // Check for exact match or very significant overlap (one contains the other completely)
            if (
                normalizedResultTitle === normalizedLabName ||
                (normalizedResultTitle.includes(normalizedLabName) &&
                    normalizedLabName.length > 5) ||
                (normalizedLabName.includes(normalizedResultTitle) &&
                    normalizedResultTitle.length > 5)
            ) {
                console.log(
                    `✅ Strong lab name match between "${labResult.title}" and "${labName}"`
                );
                return true;
            }
        }

        // Only show highly relevant labs as a fallback if no direct matches
        if (
            labResult.relevance &&
            labResult.relevance.includes("highly relevant") &&
            mentionedLabNames.length === 0
        ) {
            console.log(
                `✅ Showing highly relevant lab as fallback: "${labResult.title}"`
            );
            return true;
        }

        console.log(`❌ No match found for lab: "${labResult.title}"`);
        return false;
    };

    // Function to check if an equipment result should be displayed based on message text
    const shouldDisplayEquipmentResult = (equipmentResult, messageText) => {
        if (!equipmentResult || !equipmentResult.title || !messageText)
            return false;

        console.log(`Checking equipment: "${equipmentResult.title}"`);

        // STRICT: Direct title match - highest priority and most restrictive
        // Check if the equipment title appears directly in the message text
        if (
            messageText
                .toLowerCase()
                .includes(equipmentResult.title.toLowerCase())
        ) {
            console.log(
                `✅ Direct title match found for: "${equipmentResult.title}"`
            );
            return true;
        }

        // Extract equipment names from the message
        const mentionedEquipmentNames =
            extractEquipmentNamesFromMessage(messageText);

        // Check for exact matches with extracted equipment names
        for (const equipmentName of mentionedEquipmentNames) {
            const normalizedEquipmentName = equipmentName.toLowerCase().trim();
            const normalizedResultTitle = equipmentResult.title
                .toLowerCase()
                .trim();

            // Check for exact match or very significant overlap (one contains the other completely)
            if (
                normalizedResultTitle === normalizedEquipmentName ||
                (normalizedResultTitle.includes(normalizedEquipmentName) &&
                    normalizedEquipmentName.length > 5) ||
                (normalizedEquipmentName.includes(normalizedResultTitle) &&
                    normalizedResultTitle.length > 5)
            ) {
                console.log(
                    `✅ Strong equipment name match between "${equipmentResult.title}" and "${equipmentName}"`
                );
                return true;
            }
        }

        // Check for significant words from the title in the message - but be more strict
        const titleWords = equipmentResult.title
            .toLowerCase()
            .split(/\s+/)
            .filter((word) => word.length > 3);

        // Only consider it a match if ALL significant words appear in the message
        // AND there are at least 2 significant words
        if (titleWords.length >= 2) {
            const matchingWords = titleWords.filter((word) =>
                messageText.toLowerCase().includes(word)
            );
            if (matchingWords.length === titleWords.length) {
                console.log(
                    `✅ ALL title keywords (${matchingWords.length}/${titleWords.length}) match for: "${equipmentResult.title}"`
                );
                return true;
            }
        }

        // Only show highly relevant equipment as a fallback if no direct matches and very few results
        if (
            equipmentResult.relevance &&
            equipmentResult.relevance.includes("highly relevant") &&
            mentionedEquipmentNames.length === 0
        ) {
            // Check if this is one of the top 3 most relevant results
            if (
                equipmentResult.relevanceScore &&
                equipmentResult.relevanceScore >= 0.8
            ) {
                console.log(
                    `✅ Showing top highly relevant equipment as fallback: "${equipmentResult.title}"`
                );
                return true;
            }
        }

        console.log(
            `❌ No match found for equipment: "${equipmentResult.title}"`
        );
        return false;
    };

    // Toggle chat open/closed
    const toggleChat = () => {
        const newChatState = !isChatOpen;
        setIsChatOpen(newChatState);

        // Reset user scroll state when opening chat
        if (!isChatOpen) {
            setUserHasScrolled(false);
        }

        // When opening chat, reset the textarea height on next render cycle
        if (!isChatOpen && textareaRef.current) {
            setTimeout(() => {
                if (textareaRef.current) {
                    textareaRef.current.style.height = "20px";
                }
            }, 50);
        }
    };

    // Toggle maximize/minimize
    const toggleMaximize = () => {
        setIsMaximized(!isMaximized);
        // Reset user scroll state when changing view
        setUserHasScrolled(false);
    };

    // Handle wheel events to stop propagation only within the chatbot
    const handleWheelEvent = (e) => {
        // Only prevent propagation, don't stop the event completely
        // This allows the chatbot to scroll while preventing the outside from scrolling
        // only when the mouse is over the chatbot
        e.stopPropagation();
    };

    // Toggle visibility of reasoning trace for a message
    const toggleReasoningVisibility = (index) => {
        setChatMessages((prev) => {
            const newMessages = [...prev];
            if (newMessages[index]) {
                newMessages[index] = {
                    ...newMessages[index],
                    showReasoning: !newMessages[index].showReasoning,
                };
            }
            return newMessages;
        });
    };

    // Effect to clean up when component unmounts
    useEffect(() => {
        return () => {
            // Ensure body overflow is restored on unmount
            document.body.style.overflow = "";
        };
    }, []);

    // Add event listener for clicks on chat messages
    useEffect(() => {
        const handleChatClick = (e) => {
            // Handle clicks on user mentions
            if (
                e.target.classList.contains(
                    "lab-assistant-chatbot-user-mention"
                )
            ) {
                const userName = e.target.textContent.trim();
                console.log("Clicked on user mention:", userName);

                // Open user search in a new tab
                window.open(
                    `${
                        process.env.REACT_APP_FRONTEND_URL || ""
                    }/search?q=${encodeURIComponent(userName)}`,
                    "_blank"
                );
            }
        };

        const messagesContainer = messagesContainerRef.current;
        if (messagesContainer) {
            messagesContainer.addEventListener("click", handleChatClick);

            return () => {
                messagesContainer.removeEventListener("click", handleChatClick);
            };
        }
    }, [isChatOpen]);

    // Add remaining queries indicator in the chat interface
    const renderRemainingQueries = () => {
        if (!user) return null;
        if (subscription) return null;
        if (remainingQueries === null) return null;

        return (
            <div className="lab-assistant-chatbot-remaining-queries">
                {remainingQueries > 0
                    ? `${remainingQueries} free ${
                          remainingQueries === 1 ? "message" : "messages"
                      } remaining`
                    : "No free messages remaining. Please subscribe to continue."}
            </div>
        );
    };

    // Focus the textarea when chat opens
    useEffect(() => {
        if (isChatOpen && textareaRef.current && !isTyping && user) {
            // Use a small timeout to ensure the chat is fully rendered
            setTimeout(() => {
                textareaRef.current.focus();
                // Set explicit initial height to prevent tall textarea on initial render
                textareaRef.current.style.height = "auto";
            }, 100);
        }
    }, [isChatOpen, isTyping, user]);

    // Handle image upload
    const handleImageUpload = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];

            // Check file size (limit to 5MB)
            if (file.size > 5 * 1024 * 1024) {
                alert("Image size must be less than 5MB");
                return;
            }

            // Check file type
            if (!file.type.match("image.*")) {
                alert("Only image files are allowed");
                return;
            }

            setUploadedImage(file);
            setImagePreview(URL.createObjectURL(file));
        }
    };

    // Remove uploaded image
    const handleRemoveImage = () => {
        setUploadedImage(null);
        setImagePreview(null);
        if (imageInputRef.current) {
            imageInputRef.current.value = "";
        }
    };

    // Add a new function to handle paste events before the return statement
    // Handle paste events on textarea to capture images
    const handlePaste = (e) => {
        // Return early if user is not logged in or message is being typed
        // Also prevent image paste if DeepThink (reasoner) is selected
        if (!user || isTyping || model === "reasoner") return;

        const clipboardData = e.clipboardData;
        if (!clipboardData || !clipboardData.items) return;

        // Check for image items in the clipboard
        const items = clipboardData.items;
        let imageItem = null;

        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf("image") !== -1) {
                imageItem = items[i];
                break;
            }
        }

        // Process the image if found
        if (imageItem) {
            const file = imageItem.getAsFile();

            // Check file size (limit to 5MB)
            if (file.size > 5 * 1024 * 1024) {
                alert("Image size must be less than 5MB");
                return;
            }

            // Process the pasted image
            setUploadedImage(file);
            setImagePreview(URL.createObjectURL(file));

            // Prevent the default paste behavior for images
            e.preventDefault();
        }
    };

    // Effect to handle model changes when there's an uploaded image
    useEffect(() => {
        // If the user switches to reasoner model while having an image uploaded, remove the image
        if (model === "reasoner" && uploadedImage) {
            setUploadedImage(null);
            setImagePreview(null);
            if (imageInputRef.current) {
                imageInputRef.current.value = "";
            }
            // Alert the user that images aren't supported with DeepThink
            alert(
                "Images are not supported with DeepThink. Your uploaded image has been removed."
            );
        }
    }, [model, uploadedImage]);

    // Process markdown formatting in a clean way, handling all emphasis correctly
    const processMarkdown = (text) => {
        if (!text || text.startsWith("<")) return text;

        let html = text;

        // Headers
        html = html
            .replace(/^### (.*?)$/gm, "<h3>$1</h3>")
            .replace(/^## (.*?)$/gm, "<h2>$1</h2>")
            .replace(/^# (.*?)$/gm, "<h1>$1</h1>");

        // Handle bold and italic with both * and _ styles
        // -- Bold (** and __)
        html = html
            .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
            .replace(/__(.*?)__/g, "<strong>$1</strong>");

        // -- Italic (* and _)
        // First, handle asterisk italic
        html = html.replace(/\*([^\s*][^*]*?[^\s*])\*/g, "<em>$1</em>");
        html = html.replace(/\*([^\s*])\*/g, "<em>$1</em>");

        // Then, handle underscore italic (with lookbehind/lookahead for boundaries)
        // Need to be careful with handling underscores in words
        html = html.replace(
            /(\s|^)_([^_\s][^_]*?[^_\s])_(\s|$|[.,;:!?])/g,
            "$1<em>$2</em>$3"
        );
        html = html.replace(
            /(\s|^)_([^_\s])_(\s|$|[.,;:!?])/g,
            "$1<em>$2</em>$3"
        );

        // Add specific patterns for common cases
        html = html.replace(
            /_([\w\s\-.,;:!?'"\(\)\/&]+)_/g,
            (match, content) => {
                // Skip if it has HTML tags already
                if (content.includes("<") || content.includes(">"))
                    return match;

                // Skip if it looks like a filename, variable, or has internal underscores
                if (content.includes("_") || /^[a-z0-9_]+$/i.test(content))
                    return match;

                // If it looks like a phrase that should be emphasized, convert it
                if (content.includes(" ") && content.length > 3) {
                    return `<em>${content}</em>`;
                }

                return match;
            }
        );

        // Lists
        html = html
            .replace(/^\s*[\*\-]\s+(.*?)$/gm, "<li>$1</li>")
            .replace(/^\s*(\d+)\.\s+(.*?)$/gm, '<li value="$1">$2</li>');

        // Group consecutive list items
        html = html.replace(
            /(<li[^>]*>.*?<\/li>)(?:\s*\n\s*)?(<li[^>]*>)/g,
            "$1$2"
        );
        html = html.replace(
            /(<li value="[^"]*">.*?<\/li>(?:\s*\n\s*)?)+/g,
            "<ol>$&</ol>"
        );
        html = html.replace(/(<li>.*?<\/li>(?:\s*\n\s*)?)+/g, "<ul>$&</ul>");

        // Code blocks
        html = html.replace(/```([\s\S]*?)```/g, "<pre><code>$1</code></pre>");

        // Inline code
        html = html.replace(/`([^`]+)`/g, "<code>$1</code>");

        // Blockquotes
        html = html.replace(/^\s*>\s+(.*?)$/gm, "<blockquote>$1</blockquote>");

        // Tables - handle pipe-based tables
        if (html.includes("|")) {
            // Extract potential tables (lines with | that form a group)
            const tableRegex = /((?:^|\n)(?:[^\n]*?\|[^\n]*?(?:\n|$)){2,})/g;

            html = html.replace(tableRegex, (tableText) => {
                if (tableText.includes("<table") || !tableText.includes("|")) {
                    return tableText;
                }

                // Split into rows
                const rows = tableText.trim().split("\n");

                // Check for header separator
                const hasHeaderSeparator =
                    rows.length > 1 &&
                    /^\s*[\|]?[\s-:]*\|[\s-:]*(?:\|[\s-:]*)*$/.test(rows[1]);

                let headerRow = hasHeaderSeparator ? rows[0] : null;
                const dataRows = rows
                    .slice(hasHeaderSeparator ? 2 : 0)
                    .filter(
                        (row) =>
                            row.includes("|") &&
                            !/^\s*[\|]?[\s-:]*\|[\s-:]*(?:\|[\s-:]*)*$/.test(
                                row
                            )
                    );

                if (dataRows.length === 0) return tableText;

                // Build table HTML
                let tableHtml = "<table>";

                // Add header if present
                if (headerRow) {
                    const headerCells = headerRow
                        .trim()
                        .split("|")
                        .map((cell) => cell.trim())
                        .filter((cell) => cell !== "");

                    if (headerCells.length > 0) {
                        tableHtml += "<thead><tr>";
                        headerCells.forEach((cell) => {
                            tableHtml += `<th>${cell}</th>`;
                        });
                        tableHtml += "</tr></thead>";
                    }
                }

                // Add data rows
                tableHtml += "<tbody>";
                dataRows.forEach((row) => {
                    if (
                        /^\s*[\|]?[\s-:]*\|[\s-:]*(?:\|[\s-:]*)*$/.test(row) ||
                        !row.includes("|")
                    ) {
                        return;
                    }

                    const cells = row
                        .trim()
                        .split("|")
                        .map((cell) => cell.trim())
                        .filter((cell) => cell !== "");

                    if (cells.length > 0) {
                        tableHtml += "<tr>";
                        cells.forEach((cell) => {
                            tableHtml += `<td>${cell}</td>`;
                        });
                        tableHtml += "</tr>";
                    }
                });

                tableHtml += "</tbody></table>";
                return tableHtml;
            });
        }

        // Links (standard markdown format)
        html = html.replace(
            /\[([^\]]+)\]\(([^)]+)\)/g,
            '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>'
        );

        // Line breaks and paragraphs
        html = html.replace(/\n\n+/g, "</p><p>");
        html = html.replace(/\n/g, "<br>");

        // Wrap in paragraph tags if needed
        if (!html.startsWith("<")) {
            html = `<p>${html}</p>`;
        }

        // Final cleanup for any remaining underscore emphasis
        html = html.replace(
            />([^<]*?)_([^_<]+)_([^<]*?)</g,
            function (match, before, content, after) {
                // Only convert if it looks like a proper phrase for emphasis
                if (
                    content.length > 1 &&
                    !content.includes("_") &&
                    /[a-z]/.test(content)
                ) {
                    return `>${before}<em>${content}</em>${after}<`;
                }
                return match;
            }
        );

        return html;
    };

    return (
        <>
            <button
                className={`lab-assistant-chatbot-add-button ${
                    isChatOpen ? "lab-assistant-chatbot-add-button-active" : ""
                }`}
                onClick={toggleChat}
                aria-label={
                    isChatOpen
                        ? "Close Lab Assistant Chat"
                        : "Open Lab Assistant Chat"
                }
                title={isChatOpen ? "Close Chat" : "Open Chat"}
            >
                <SmartToyIcon />
            </button>

            {isChatOpen && (
                <div
                    className={`lab-assistant-chatbot-chatbox ${
                        isMaximized
                            ? "lab-assistant-chatbot-chatbox-maximized"
                            : ""
                    }`}
                    onWheel={handleWheelEvent}
                >
                    <div className="lab-assistant-chatbot-chatbox-header">
                        <div className="lab-assistant-chatbot-chatbox-title">
                            <span>
                                <SmartToyIcon fontSize="small" /> Lab Giant
                                Assistant
                            </span>
                            {renderRemainingQueries()}
                        </div>
                        <div className="lab-assistant-chatbot-chatbox-controls">
                            <button
                                className="lab-assistant-chatbot-chatbox-maximize"
                                onClick={toggleMaximize}
                                aria-label={
                                    isMaximized
                                        ? "Minimize Chat"
                                        : "Maximize Chat"
                                }
                                title={isMaximized ? "Minimize" : "Maximize"}
                            >
                                {isMaximized ? (
                                    <FullscreenExitIcon />
                                ) : (
                                    <FullscreenIcon />
                                )}
                            </button>
                            <button
                                className="lab-assistant-chatbot-chatbox-close"
                                onClick={toggleChat}
                                aria-label="Close Chat"
                                title="Close"
                            >
                                <CloseIcon />
                            </button>
                        </div>
                    </div>

                    <div
                        className="lab-assistant-chatbot-chatbox-messages"
                        ref={messagesContainerRef}
                        onScroll={handleScroll}
                    >
                        {chatMessages.map((message, index) => (
                            <div
                                key={index}
                                className={`lab-assistant-chatbot-chat-message lab-assistant-chatbot-${
                                    message.sender
                                }-message ${
                                    message.isError
                                        ? "lab-assistant-chatbot-error-message"
                                        : ""
                                } ${
                                    message.hasReferencedEntities
                                        ? "has-referenced-entities"
                                        : ""
                                }`}
                            >
                                <div
                                    className="lab-assistant-chatbot-message-text"
                                    dangerouslySetInnerHTML={{
                                        __html: message.isThinking
                                            ? message.text
                                            : parseMessageWithClickableEntities(
                                                  message.text,
                                                  message.referencedEntities,
                                                  message.hasLabReferences
                                              ),
                                    }}
                                ></div>

                                {/* Show image if message has one */}
                                {message.image && (
                                    <div className="lab-assistant-chatbot-message-image">
                                        <img
                                            src={message.image}
                                            alt="User uploaded"
                                            className="lab-assistant-chatbot-uploaded-image"
                                        />
                                    </div>
                                )}

                                {/* Display reasoning trace if available */}
                                {message.hasReasoning && (
                                    <div
                                        className={`lab-assistant-chatbot-reasoning ${
                                            message.isGenerating
                                                ? "lab-assistant-chatbot-reasoning-generating"
                                                : ""
                                        }`}
                                    >
                                        <div
                                            className="lab-assistant-chatbot-reasoning-header"
                                            onClick={() =>
                                                toggleReasoningVisibility(index)
                                            }
                                        >
                                            <span>
                                                Reasoning{" "}
                                                {message.showReasoning
                                                    ? "(Click to hide)"
                                                    : "(Click to show)"}
                                            </span>
                                        </div>
                                        {message.showReasoning && (
                                            <div className="lab-assistant-chatbot-reasoning-content">
                                                {message.reasoning}
                                            </div>
                                        )}
                                    </div>
                                )}

                                {/* Render referenced entities if available */}
                                {message.hasReferencedEntities &&
                                    message.referencedEntities &&
                                    message.referencedEntities.length > 0 && (
                                        <div className="lab-assistant-chatbot-referenced-entities">
                                            {message.referencedEntities.map(
                                                (result, idx) => (
                                                    <div
                                                        key={idx}
                                                        className="lab-assistant-chatbot-entity-item"
                                                        onClick={() =>
                                                            handleResourceClick(
                                                                result
                                                            )
                                                        }
                                                    >
                                                        <h4>{result.title}</h4>

                                                        {/* Show lab/entity info if available */}
                                                        {result.labName && (
                                                            <div
                                                                className="lab-assistant-chatbot-result-lab"
                                                                data-emoji={getEmojiForEntity(
                                                                    "lab",
                                                                    result.labName
                                                                )}
                                                            >
                                                                {result.labName}
                                                            </div>
                                                        )}

                                                        {/* Show matched equipment if available */}
                                                        {result.matchedEquipment &&
                                                            result
                                                                .matchedEquipment
                                                                .length > 0 &&
                                                            result.matchedEquipment.map(
                                                                (
                                                                    equipment,
                                                                    eqIdx
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            eqIdx
                                                                        }
                                                                        className="lab-assistant-chatbot-result-equipment"
                                                                        data-emoji={getEmojiForEntity(
                                                                            "equipment",
                                                                            equipment.title
                                                                        )}
                                                                    >
                                                                        <strong>
                                                                            {
                                                                                equipment.title
                                                                            }
                                                                        </strong>
                                                                        {equipment.description && (
                                                                            <div className="lab-assistant-chatbot-result-equipment-desc">
                                                                                {
                                                                                    equipment.description
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}

                                                        {/* Relevance explanation */}
                                                        {result.relevance && (
                                                            <div className="lab-assistant-chatbot-result-relevance">
                                                                {
                                                                    result.relevance
                                                                }
                                                            </div>
                                                        )}

                                                        {/* Highlights */}
                                                        {result.highlights && (
                                                            <div className="lab-assistant-chatbot-result-highlights">
                                                                {
                                                                    result.highlights
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}
                            </div>
                        ))}

                        {isTyping && (
                            <div className="lab-assistant-chatbot-typing-indicator">
                                <div className="lab-assistant-chatbot-typing-text">
                                    Lab Assistant is typing
                                </div>
                                <span className="lab-assistant-chatbot-dot"></span>
                                <span className="lab-assistant-chatbot-dot"></span>
                                <span className="lab-assistant-chatbot-dot"></span>
                            </div>
                        )}
                        {/* Show scroll paused indicator when user has scrolled up during generation */}
                        {userHasScrolled && isTyping && (
                            <div className="lab-assistant-chatbot-scroll-paused">
                                Auto-scroll paused
                            </div>
                        )}
                        <div ref={messagesEndRef} />
                    </div>
                    <form
                        className="lab-assistant-chatbot-chatbox-input"
                        onSubmit={handleSendMessage}
                    >
                        <div className="lab-assistant-chatbot-input-container">
                            {imagePreview && (
                                <div className="lab-assistant-chatbot-image-preview-container">
                                    <img
                                        src={imagePreview}
                                        alt="Preview"
                                        className="lab-assistant-chatbot-image-preview"
                                    />
                                    <button
                                        type="button"
                                        className="lab-assistant-chatbot-remove-image-btn"
                                        onClick={handleRemoveImage}
                                        aria-label="Remove image"
                                    >
                                        <CancelOutlinedIcon />
                                    </button>
                                </div>
                            )}
                            <div className="lab-assistant-chatbot-message-area">
                                <textarea
                                    placeholder="Type your message..."
                                    value={chatMessage}
                                    onChange={(e) => {
                                        setChatMessage(e.target.value);
                                        // Reset height before calculating new height
                                        e.target.style.height = "auto";
                                        // Set height based on scrollHeight
                                        e.target.style.height = `${Math.min(
                                            e.target.scrollHeight,
                                            150
                                        )}px`;
                                    }}
                                    onKeyDown={(e) => {
                                        // Submit on Enter, but not with Shift+Enter (which should add a new line)
                                        if (e.key === "Enter" && !e.shiftKey) {
                                            e.preventDefault();
                                            handleSendMessage(e);
                                        }
                                    }}
                                    onPaste={handlePaste}
                                    disabled={!user || isTyping}
                                    rows="1"
                                    className="lab-assistant-chatbot-textarea"
                                    ref={setTextareaRef}
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                    style={{ display: "none" }}
                                    ref={imageInputRef}
                                    disabled={
                                        !user ||
                                        isTyping ||
                                        model === "reasoner"
                                    }
                                />
                                <Tooltip
                                    title={
                                        model === "reasoner"
                                            ? "Image upload is not available with DeepThink"
                                            : "Upload image"
                                    }
                                    arrow
                                >
                                    <span>
                                        <button
                                            type="button"
                                            className="lab-assistant-chatbot-image-btn"
                                            onClick={() =>
                                                imageInputRef.current?.click()
                                            }
                                            disabled={
                                                !user ||
                                                isTyping ||
                                                imagePreview ||
                                                model === "reasoner"
                                            }
                                        >
                                            <PhotoCameraIcon />
                                        </button>
                                    </span>
                                </Tooltip>
                                <button
                                    type="submit"
                                    disabled={
                                        isTyping ||
                                        (!chatMessage.trim() && !imagePreview)
                                    }
                                >
                                    <SendIcon />
                                </button>
                            </div>
                            <div className="lab-assistant-chatbot-search-toggles">
                                <button
                                    type="button"
                                    className={`lab-assistant-chatbot-search-toggle ${
                                        model === "pro" ? "active" : ""
                                    }`}
                                    onClick={() =>
                                        model !== "pro"
                                            ? setModel("pro")
                                            : setModel("flash")
                                    }
                                    title="Use Pro Model"
                                    disabled={!user}
                                >
                                    <SearchIcon fontSize="small" /> Pro
                                    Knowledge
                                </button>
                                <button
                                    type="button"
                                    className={`lab-assistant-chatbot-search-toggle ${
                                        model === "reasoner" ? "active" : ""
                                    }`}
                                    onClick={() =>
                                        model !== "reasoner"
                                            ? setModel("reasoner")
                                            : setModel("flash")
                                    }
                                    title="Use Reasoner Model"
                                    disabled={!user}
                                >
                                    <SearchIcon fontSize="small" /> DeepThink
                                    (Very Slow)
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
};

export default LabAssistantChatBot;
