import React, { useRef, useState, useEffect, useMemo } from "react";
import Select from "react-select";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Checkbox, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ErrorMessages } from "../../helpers/ListingHelpers";
import {
    AvailabilityCalendar,
    ListingField,
    Loading,
    SelectPricing,
    SelectUnit,
    ImageUpload,
} from "../../components";
import { unitsToJson } from "../../helpers/ListingHelpers";
import { quantityUnits, timeUnits } from "../../constants/ListingConstants";
import { useParams } from "react-router-dom";
import { getLabById } from "../../firebase/crud";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../styles/CreateListingStyles.css";

const Service = ({ labMembers, listing }) => {
    const {
        register,
        control,
        formState: { errors },
        getValues,
        watch,
        setValue,
        reset,
    } = useFormContext();

    const pageRef = {
        about: useRef(null),
        pricing: useRef(null),
        delivery: useRef(null),
        misc: useRef(null),
    };

    // Effect to scroll to the first error when validation errors occur
    useEffect(() => {
        if (Object.keys(errors?.fields || {}).length > 0) {
            // Find the first error field
            const firstErrorField = document.querySelector(
                '.input-error, [aria-invalid="true"]'
            );
            if (firstErrorField) {
                // Find the closest section or field container to scroll to
                const errorSection =
                    firstErrorField.closest(".create-listing-section") ||
                    firstErrorField.closest(".create-listing-field") ||
                    firstErrorField;

                errorSection.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }
    }, [errors]);

    const scrollToSection = (ref) => {
        window.scrollTo({
            top: ref.current.offsetTop,
            behavior: "smooth",
        });
    };

    const watchLab = useWatch({
        name: "fields.lab",
        control,
        defaultValue: "myLab",
    });

    const showLabLocation = useMemo(() => {
        return watchLab === "otherLab";
    }, [watchLab]);

    const [editorContent, setEditorContent] = useState(
        getValues("fields.description") || ""
    );

    const watchIsCollaborative = useWatch({
        name: "fields.isCollaborative",
        control,
        defaultValue: false,
    });

    const watchServiceType = useWatch({
        name: "fields.serviceType",
        control,
        defaultValue: "quantityBased",
    });

    const watchPricingType = useWatch({
        name: "fields.pricingType",
        control,
        defaultValue: "FIXED",
    });

    const isPriceRequired =
        watchIsCollaborative && watchPricingType !== "CUSTOM";

    const { labId } = useParams();
    const [lab, setLab] = useState(null);

    useEffect(() => {
        const setListingValues = async () => {
            if (listing) {
                reset({
                    fields: {
                        title: listing.title,
                        description: listing.description,
                        labMember: listing.labMember,
                        licensingType: {
                            value: listing.licensingType,
                            label: listing.licensingType,
                        },
                        relevantLink: listing.relevantLink,
                        isCollaborative: listing.isCollaborative,
                        pricingType: listing.pricingType || "FIXED",
                        imgs: [
                            ...(listing.imgs || []),
                            ...Array(3 - (listing.imgs?.length || 0)).fill({}),
                        ].slice(0, 3),
                        serviceType: listing.serviceType || "quantityBased",
                        ...(listing.isCollaborative && {
                            price: {
                                value: listing.price?.value || 0,
                                units: listing.price?.unit || "unit",
                                customUnits: listing.price?.customUnits || "",
                            },
                            quantity: listing.quantity,
                            delivery: listing.delivery || "pickup",
                            location: listing.location || "",
                            unavailabilities: listing.unavailabilities || {
                                dates: [],
                                days: [],
                            },
                        }),
                    },
                });
                setEditorContent(listing.description || "");
            }
        };
        setListingValues();
    }, [listing, reset]);

    useEffect(() => {
        getLabById(labId).then((lab) => setLab(lab));
    }, [labId]);

    useEffect(() => {
        if (!listing) {
            setValue("fields.price.units", "unit");
            if (!getValues("fields.imgs")) {
                setValue("fields.imgs", [{}, {}, {}]);
            }
        }
    }, [listing, setValue, getValues]);

    const handleEditorChange = (content) => {
        setEditorContent(content);
        setValue("fields.description", content, { shouldDirty: true });
    };

    if (!Array.isArray(getValues("fields.imgs"))) {
        return <Loading />;
    }

    const imageSlots = [...Array(3).keys()];

    return (
        <>
            <h1 className="create-listing-header">Service</h1>
            <div className="create-listing-container">
                <div className="create-listing-content">
                    <div ref={pageRef.about} className="create-listing-section">
                        <h2 className="create-listing-subheader">
                            Service Overview
                        </h2>
                        <ListingField
                            field="title"
                            title="Service Name"
                            description="This is the name of the service that will be offered."
                            required={true}
                        />
                        <div className="create-listing-field">
                            <h3>
                                Service Description
                                <span style={{ color: "red" }}>*</span>
                            </h3>
                            <h4>
                                Describe your service in terms of functionality,
                                process, requirements, etc.
                            </h4>
                            <div className="textarea-container">
                                <ReactQuill
                                    value={editorContent}
                                    onChange={handleEditorChange}
                                    modules={{
                                        toolbar: [
                                            [
                                                "bold",
                                                "italic",
                                                "underline",
                                                "strike",
                                            ],
                                            ["blockquote", "code-block"],
                                            [{ header: 1 }, { header: 2 }],
                                            [
                                                { list: "ordered" },
                                                { list: "bullet" },
                                            ],
                                            [
                                                { script: "sub" },
                                                { script: "super" },
                                            ],
                                            [
                                                { indent: "-1" },
                                                { indent: "+1" },
                                            ],
                                            [{ color: [] }, { background: [] }],
                                            ["link"],
                                            ["clean"],
                                        ],
                                        clipboard: {
                                            matchVisual: false,
                                            matchers: [],
                                        },
                                        keyboard: {
                                            bindings: {},
                                        },
                                    }}
                                    formats={[
                                        "bold",
                                        "italic",
                                        "underline",
                                        "strike",
                                        "blockquote",
                                        "code-block",
                                        "header",
                                        "list",
                                        "bullet",
                                        "script",
                                        "indent",
                                        "color",
                                        "background",
                                        "link",
                                    ]}
                                    preserveWhitespace={true}
                                />
                                <div className="link-warning">
                                    Note: For external links, please include the
                                    full URL (e.g., https://www.example.com)
                                </div>
                            </div>
                            {errors?.fields?.description && (
                                <ErrorMessages
                                    message={errors.fields.description.message}
                                />
                            )}
                        </div>
                        <div className="create-listing-field">
                            <h3>
                                Lab Member in charge
                                <span style={{ color: "red" }}>*</span>
                            </h3>
                            <h4>
                                The lab member will be in charge of offering the
                                service.
                            </h4>
                            <Controller
                                name="fields.labMember"
                                control={control}
                                defaultValue={null}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            {...field}
                                            className={
                                                errors?.fields?.labMember
                                                    ? "input-error labmember-select"
                                                    : "labmember-select"
                                            }
                                            options={labMembers}
                                            isSearchable
                                            placeholder="Assign lab member"
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    border: "none",
                                                    boxShadow: "none",
                                                }),
                                            }}
                                        />
                                        {errors?.fields?.labMember && (
                                            <ErrorMessages message="The above field is required." />
                                        )}
                                    </>
                                )}
                            />
                        </div>
                        <div className="create-listing-field">
                            <h3>Collaboration</h3>
                            <h4>
                                Collaborative items will be shown in the
                                marketplace.
                            </h4>
                            <Controller
                                name="fields.isCollaborative"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <FormControlLabel
                                        className="checkbox-label"
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={field.value || false}
                                                onChange={(e) =>
                                                    field.onChange(
                                                        e.target.checked
                                                    )
                                                }
                                                style={{
                                                    marginRight: "5px",
                                                    paddingRight: "0",
                                                    paddingTop: "0",
                                                    paddingBottom: "0",
                                                }}
                                            />
                                        }
                                        label="Allow collaboration"
                                    />
                                )}
                            />
                        </div>

                        {watchIsCollaborative && (
                            <div className="create-listing-field">
                                <h3>Type of Service</h3>
                                <h4>Select a type of service.</h4>
                                <RadioGroup
                                    defaultValue={
                                        getValues("fields.serviceType") ||
                                        "quantityBased"
                                    }
                                    row
                                >
                                    <FormControlLabel
                                        className="checkbox-label"
                                        control={
                                            <Controller
                                                name="fields.serviceType"
                                                control={control}
                                                rules={{
                                                    required:
                                                        watchIsCollaborative,
                                                }}
                                                render={({ field }) => (
                                                    <Radio
                                                        {...field}
                                                        value="quantityBased"
                                                        style={{
                                                            color: "var(--primary-color)",
                                                        }}
                                                        onChange={(e) =>
                                                            field.onChange(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                )}
                                            />
                                        }
                                        label="Quantity-Based Service"
                                    />
                                    <FormControlLabel
                                        className="checkbox-label"
                                        control={
                                            <Controller
                                                name="fields.serviceType"
                                                control={control}
                                                rules={{
                                                    required:
                                                        watchIsCollaborative,
                                                }}
                                                render={({ field }) => (
                                                    <Radio
                                                        {...field}
                                                        value="timeBased"
                                                        style={{
                                                            color: "var(--primary-color)",
                                                        }}
                                                        onChange={(e) =>
                                                            field.onChange(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                )}
                                            />
                                        }
                                        label="Time-Based Service"
                                    />
                                </RadioGroup>
                            </div>
                        )}
                    </div>

                    <div ref={pageRef.misc} className="create-listing-section">
                        <h2 className="create-listing-subheader">
                            Miscellaneous
                        </h2>
                        <div className="create-listing-field">
                            <h3>Listing Images</h3>
                            <h4>
                                Add an image of the item that will be sold. You
                                can add up to 3 images.
                            </h4>
                            <div className="image-input-container">
                                {imageSlots.map((index) => (
                                    <ImageUpload key={index} index={index} />
                                ))}
                            </div>
                        </div>
                        <div className="create-listing-field">
                            <h3>Relevant Link</h3>
                            <h4>
                                Add a link to a relevant website, article,
                                publication, etc.
                            </h4>
                            <input
                                className={
                                    errors?.fields?.relevantLink
                                        ? "input input-error"
                                        : "input"
                                }
                                type="text"
                                name="relevantLink"
                                id="relevantLink"
                                placeholder="Relevant Link"
                                {...register("fields.relevantLink", {
                                    pattern:
                                        //eslint-disable-next-line
                                        /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g,
                                })}
                            />
                            {errors?.fields?.relevantLink && (
                                <ErrorMessages message="The above link is invalid." />
                            )}
                        </div>
                    </div>

                    {watchIsCollaborative && (
                        <>
                            <div
                                ref={pageRef.pricing}
                                className="create-listing-section"
                            >
                                <h2 className="create-listing-subheader">
                                    Pricing & Availabilities
                                </h2>
                                <div className="create-listing-field">
                                    <h3>Pricing Type</h3>
                                    <h4>
                                        Select how pricing will be handled for
                                        this service.
                                    </h4>
                                    <Controller
                                        name="fields.pricingType"
                                        control={control}
                                        defaultValue="FIXED"
                                        render={({ field }) => (
                                            <RadioGroup
                                                {...field}
                                                row
                                                onChange={(e) =>
                                                    field.onChange(
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <FormControlLabel
                                                    value="FIXED"
                                                    control={
                                                        <Radio
                                                            style={{
                                                                color: "var(--primary-color)",
                                                            }}
                                                        />
                                                    }
                                                    label="Fixed Price"
                                                />
                                                <FormControlLabel
                                                    value="CUSTOM"
                                                    control={
                                                        <Radio
                                                            style={{
                                                                color: "var(--primary-color)",
                                                            }}
                                                        />
                                                    }
                                                    label="Contact for Pricing"
                                                />
                                            </RadioGroup>
                                        )}
                                    />
                                </div>
                                <div className="create-listing-field">
                                    <h3>
                                        Price
                                        {isPriceRequired && (
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        )}
                                    </h3>
                                    <h4>Set the price of the service.</h4>
                                    <div className="price-quantity-container">
                                        <SelectPricing
                                            isError={
                                                errors?.fields?.price?.value
                                            }
                                            fieldName="fields.price.value"
                                            required={isPriceRequired}
                                        />
                                        <div className="price-per-text">
                                            per
                                        </div>
                                        <SelectUnit
                                            isError={
                                                errors?.fields?.price?.units
                                            }
                                            isCustomError={
                                                errors?.fields?.price
                                                    ?.customUnits
                                            }
                                            fieldName="fields.price.units"
                                            customFieldName="fields.price.customUnits"
                                            unitOptions={unitsToJson(
                                                watchServiceType === "timeBased"
                                                    ? timeUnits
                                                    : quantityUnits
                                            )}
                                            required={isPriceRequired}
                                        />
                                    </div>
                                    {(errors?.fields?.price?.value ||
                                        errors?.fields?.price?.units ||
                                        errors?.fields?.price?.customUnits) && (
                                        <ErrorMessages message="The above field is required." />
                                    )}
                                </div>

                                <div className="create-listing-field">
                                    <AvailabilityCalendar />
                                </div>
                            </div>

                            <div
                                ref={pageRef.delivery}
                                className="create-listing-section"
                            >
                                <h2 className="create-listing-subheader">
                                    Service Location
                                </h2>

                                <div className="create-listing-field">
                                    <h3>
                                        Location Options
                                        <span style={{ color: "red" }}>*</span>
                                    </h3>
                                    <h4>Select the location of the service.</h4>
                                    <RadioGroup defaultValue="myLab" row>
                                        <FormControlLabel
                                            className="delivery-option"
                                            control={
                                                <Controller
                                                    name="fields.lab"
                                                    defaultValue="myLab"
                                                    control={control}
                                                    rules={{
                                                        required:
                                                            "Lab location is required",
                                                    }}
                                                    render={({ field }) => (
                                                        <Radio
                                                            {...field}
                                                            value="myLab"
                                                            style={{
                                                                color: "var(--primary-color)",
                                                            }}
                                                            onChange={(e) =>
                                                                field.onChange(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    )}
                                                />
                                            }
                                            label="At my lab"
                                        />
                                        <FormControlLabel
                                            className="delivery-option"
                                            control={
                                                <Controller
                                                    name="fields.lab"
                                                    control={control}
                                                    defaultValue="otherLab"
                                                    rules={{
                                                        required:
                                                            "Lab location is required",
                                                    }}
                                                    render={({ field }) => (
                                                        <Radio
                                                            {...field}
                                                            value="otherLab"
                                                            style={{
                                                                color: "var(--primary-color)",
                                                            }}
                                                            onChange={(e) =>
                                                                field.onChange(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    )}
                                                />
                                            }
                                            label="At another lab"
                                        />
                                    </RadioGroup>
                                </div>

                                {showLabLocation && (
                                    <div className="create-listing-field">
                                        <h3>Service Location</h3>
                                        <h4>
                                            The address where the service will
                                            be offered if not at your lab.
                                        </h4>
                                        <input
                                            defaultValue={lab?.addresses?.lab}
                                            className="input"
                                            type="text"
                                            placeholder="Lab Location"
                                            {...register("fields.location")}
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Service;
