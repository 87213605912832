import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import GridViewIcon from "@mui/icons-material/GridView";
import ViewListIcon from "@mui/icons-material/ViewList";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loading, ModalComponent, Spinner } from "../../components";
import { UserAuth } from "../../context/auth-context";
import { TypeBadge } from "../../helpers/ListingHelpers";
import { fetchDelete, fetchGet } from "../../services/data-service";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import { CircularProgress } from "@mui/material";
import { fetchLabListings, LabCacheParams } from "../../services/lab-api";
import { showSuccessToast, showErrorToast } from "../../components/Toast";
import "../../styles/LabManagerTabs/ListingsManager.css";

const stripHtmlTags = (html) => {
    if (!html) return "";
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
};

const ListingsManager = () => {
    const [listingToDelete, setListingToDelete] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [viewMode, setViewMode] = useState("grid");
    const navigate = useNavigate();
    const { labId } = useParams();
    const { user } = UserAuth();
    const [activeTab, setActiveTab] = useState("all");
    const [canEdit, setCanEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const { ref, inView } = useInView();
    const queryClient = useQueryClient();

    // State for column widths (use percentages or fr units)
    const [columnWidths, setColumnWidths] = useState([
        "20%",
        "15%",
        "1fr",
        "auto",
    ]);
    const isResizing = useRef(null); // Stores the index of the separator being dragged
    const startX = useRef(0);
    const initialWidths = useRef([]);
    const tableRef = useRef(null); // Ref for the table container

    const {
        data: listings,
        isLoading: listingsLoading,
        fetchNextPage: fetchNextPaginatedListings,
        isFetchingNextPage,
    } = useInfiniteQuery({
        queryKey: ["lab", labId, "listings"],
        queryFn: ({ pageParam }) =>
            fetchLabListings({
                pageParam,
                labId,
            }),
        ...LabCacheParams,
        getNextPageParam: (lastPage) =>
            lastPage.hasMore ? lastPage.lastVisible : null,
    });

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                fetchNextPaginatedListings();
            }, 500);
        }
    }, [inView, fetchNextPaginatedListings]);

    const toggleModal = () => {
        if (isModalOpen) {
            setListingToDelete(null);
        }
        setIsModalOpen(!isModalOpen);
    };

    const toggleViewMode = () => {
        setViewMode(viewMode === "grid" ? "list" : "grid");
    };

    useEffect(() => {
        const checkUserRole = async () => {
            try {
                const member = await fetchGet(
                    `/labs/${labId}/members/${user.uid}`,
                    true
                );
                if (member?.role === "pi" || member?.role === "manager") {
                    setCanEdit(true);
                }
            } catch (error) {
                console.error("Error checking user role:", error);
            }
        };

        checkUserRole();
    }, [labId, user.uid]);

    const deleteListing = async (id) => {
        setLoading(true);
        try {
            await fetchDelete(`/listings/${id}`);

            // Instead of reloading the page, show success toast and update the listing data
            showSuccessToast("Listing deleted successfully!");

            // Invalidate the query to refresh listing data
            queryClient.invalidateQueries({
                queryKey: ["lab", labId, "listings"],
            });

            // Close the modal and reset state
            setIsModalOpen(false);
            setListingToDelete(null);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error deleting listing or images folder: ", error);
            showErrorToast("Error deleting listing. Please try again.");
        }
    };

    const filterListings = (tab, pages) => {
        if (!pages) return [];

        const allListings = pages.flatMap((page) => page.documents);

        switch (tab) {
            case "all":
                return allListings;
            case "items":
                return allListings.filter(
                    (listing) => listing.type === "specific-item"
                );
            case "equipment":
                return allListings.filter(
                    (listing) => listing.type === "equipment-rental"
                );
            case "services":
                return allListings.filter(
                    (listing) => listing.type === "service"
                );
            case "collaborations":
                return allListings.filter(
                    (listing) => listing.isCollaborative === true
                );
            case "digital-goods":
                return allListings.filter(
                    (listing) => listing.type === "digital-good"
                );
            default:
                return [];
        }
    };

    const handleMouseDown = (index, event) => {
        isResizing.current = index;
        startX.current = event.clientX;
        // Capture initial widths in pixels for accurate calculation
        const thElements = tableRef.current?.querySelector(
            ".listings-manager-table-header"
        )?.children;
        if (thElements) {
            initialWidths.current = Array.from(thElements).map(
                (th) => th.offsetWidth
            );
        }
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
        document.body.style.cursor = "col-resize"; // Optional: change cursor globally
        event.preventDefault(); // Prevent text selection during drag
    };

    const handleMouseMove = (event) => {
        if (isResizing.current === null || !tableRef.current) return;

        const currentIndex = isResizing.current;
        const currentX = event.clientX;
        const deltaX = currentX - startX.current;

        const newWidthsPx = [...initialWidths.current];
        const minWidth = 50; // Minimum column width in pixels

        const widthLeft = initialWidths.current[currentIndex];
        const widthRight = initialWidths.current[currentIndex + 1];

        // Calculate new widths, ensuring they don't drop below minWidth
        let newWidthLeft = widthLeft + deltaX;
        let newWidthRight = widthRight - deltaX;

        if (newWidthLeft < minWidth) {
            newWidthLeft = minWidth;
            newWidthRight = widthLeft + widthRight - minWidth;
        }
        if (newWidthRight < minWidth) {
            newWidthRight = minWidth;
            newWidthLeft = widthLeft + widthRight - minWidth;
        }

        newWidthsPx[currentIndex] = newWidthLeft;
        newWidthsPx[currentIndex + 1] = newWidthRight;

        // Convert pixel widths back to a format for grid-template-columns (e.g., pixels or fr)
        // Using pixels directly is simpler for dynamic updates
        const newGridTemplateColumns = newWidthsPx
            .map((w) => `${w}px`)
            .join(" ");

        // Update state with pixel values (or convert back to %/fr if needed, though complex)
        // For simplicity, we'll use the pixel string directly for the style
        // Note: This approach deviates from the state `columnWidths` structure.
        // A more robust solution would update state consistently.
        if (tableRef.current) {
            const header = tableRef.current.querySelector(
                ".listings-manager-table-header"
            );
            const rows = tableRef.current.querySelectorAll(
                ".listings-manager-table-row"
            );
            if (header)
                header.style.gridTemplateColumns = newGridTemplateColumns;
            rows.forEach((row) => {
                row.style.gridTemplateColumns = newGridTemplateColumns;
            });
        }
    };

    const handleMouseUp = () => {
        // Optional: Update the state `columnWidths` here if you want persistence
        // or need the state for other reasons. This requires converting pixels back to %/fr.
        isResizing.current = null;
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        document.body.style.cursor = "default"; // Restore default cursor
    };

    useEffect(() => {
        // Cleanup listeners if component unmounts while resizing
        return () => {
            if (isResizing.current !== null) {
                document.removeEventListener("mousemove", handleMouseMove);
                document.removeEventListener("mouseup", handleMouseUp);
                document.body.style.cursor = "default";
            }
        };
    }, []);

    const EditableListings = ({ listings }) => {
        if (viewMode === "grid") {
            return (
                <>
                    {listings.map((listing, _) => (
                        <div
                            key={listing.id}
                            className="listings-manager-listing"
                            onClick={() => {
                                if (listing.type === "equipment-rental")
                                    navigate(`/listingrental/${listing.id}`);
                                else {
                                    navigate(`/listing/${listing.id}`);
                                }
                            }}
                        >
                            <img
                                src={listing.img}
                                className="listings-manager-listing-img"
                                alt=""
                            />
                            <div className="listings-manager-listing-info">
                                <div className="listings-manager-listing-name">
                                    {listing.title}
                                </div>
                                <div className="listings-manager-listing-description">
                                    {stripHtmlTags(
                                        listing.description
                                    ).substring(0, 300)}
                                    {stripHtmlTags(listing.description).length >
                                    300
                                        ? "..."
                                        : ""}
                                </div>
                                <div className="listings-manager-listing-footer">
                                    <div className="listings-manager-listing-type">
                                        <TypeBadge
                                            type={listing.type}
                                            style={{ fontSize: "20px" }}
                                        />
                                    </div>

                                    {canEdit && (
                                        <div className="listings-manager-listing-buttons">
                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    navigate(
                                                        `/manager/${labId}/listings/edit-listing/${listing.id}`
                                                    );
                                                }}
                                            >
                                                <EditIcon
                                                    style={{ fontSize: "20px" }}
                                                />
                                            </button>
                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setListingToDelete({
                                                        id: listing.id,
                                                        title: listing.title,
                                                    });
                                                    toggleModal();
                                                }}
                                            >
                                                <DeleteIcon
                                                    style={{ fontSize: "20px" }}
                                                />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            );
        } else {
            // Generate grid-template-columns style from state
            const tableStyle = {
                gridTemplateColumns: columnWidths.join(" "),
            };

            return (
                <div ref={tableRef} className="listings-manager-table-view">
                    <div
                        className="listings-manager-table-header"
                        style={tableStyle}
                    >
                        <div className="listings-manager-table-title header-cell">
                            Title
                            <div
                                className="resizer"
                                onMouseDown={(e) => handleMouseDown(0, e)}
                            ></div>
                        </div>
                        <div className="listings-manager-table-type header-cell">
                            Type
                            <div
                                className="resizer"
                                onMouseDown={(e) => handleMouseDown(1, e)}
                            ></div>
                        </div>
                        <div className="listings-manager-table-description header-cell">
                            Description
                            {/* No resizer for the last column edge by default */}
                            {/* {canEdit && <div className="resizer" onMouseDown={(e) => handleMouseDown(2, e)}></div>} */}
                        </div>
                        {canEdit && (
                            <div className="listings-manager-table-actions header-cell">
                                Actions
                            </div>
                        )}
                    </div>
                    {listings.map((listing, index) => (
                        <div
                            key={listing.id}
                            className="listings-manager-table-row"
                            style={tableStyle} // Apply same column widths to rows
                            onClick={() => {
                                if (listing.type === "equipment-rental")
                                    navigate(`/listingrental/${listing.id}`);
                                else {
                                    navigate(`/listing/${listing.id}`);
                                }
                            }}
                        >
                            {/* Table row cells - apply cell class if needed for specific styling */}
                            <div
                                className="listings-manager-table-title cell"
                                title={listing.title}
                            >
                                {listing.title}
                            </div>
                            <div className="listings-manager-table-type cell">
                                <TypeBadge
                                    type={listing.type}
                                    style={{ fontSize: "14px" }}
                                />
                            </div>
                            <div
                                className="listings-manager-table-description cell"
                                title={stripHtmlTags(listing.description)}
                            >
                                {stripHtmlTags(listing.description).substring(
                                    0,
                                    100
                                )}
                                {stripHtmlTags(listing.description).length > 100
                                    ? "..."
                                    : ""}
                            </div>

                            {canEdit && (
                                <div className="listings-manager-table-actions cell">
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            navigate(
                                                `/manager/${labId}/listings/edit-listing/${listing.id}`
                                            );
                                        }}
                                    >
                                        <EditIcon
                                            style={{ fontSize: "18px" }}
                                        />
                                    </button>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setListingToDelete({
                                                id: listing.id,
                                                title: listing.title,
                                            });
                                            toggleModal();
                                        }}
                                    >
                                        <DeleteIcon
                                            style={{ fontSize: "18px" }}
                                        />
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            );
        }
    };

    if (listingsLoading) {
        return <Loading />;
    }

    const filteredListings = filterListings(activeTab, listings?.pages);

    return (
        <div className="listings-manager-container">
            <div className="listings-manager-filter">
                <h1 className="listings-manager-title">
                    Technologies & Resources
                </h1>
                <div className="listings-manager-actions">
                    <button
                        className="listings-manager-view-toggle"
                        onClick={toggleViewMode}
                        title={
                            viewMode === "grid"
                                ? "Switch to list view"
                                : "Switch to grid view"
                        }
                    >
                        {viewMode === "grid" ? (
                            <ViewListIcon />
                        ) : (
                            <GridViewIcon />
                        )}
                    </button>
                    {canEdit && (
                        <button
                            className="listings-manager-create-listing"
                            onClick={() =>
                                navigate(
                                    `/manager/${labId}/listings/create-listing`
                                )
                            }
                        >
                            <AddIcon />
                            New Listing
                        </button>
                    )}
                </div>
            </div>

            <div className="listings-manager-tabs">
                <button
                    className={`listings-manager-tab-button ${
                        activeTab === "all" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("all")}
                >
                    All
                </button>
                <button
                    className={`listings-manager-tab-button ${
                        activeTab === "collaborations" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("collaborations")}
                >
                    Collaborative
                </button>
                <button
                    className={`listings-manager-tab-button ${
                        activeTab === "items" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("items")}
                >
                    Specific Item
                </button>
                <button
                    className={`listings-manager-tab-button ${
                        activeTab === "equipment" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("equipment")}
                >
                    Equipment
                </button>
                <button
                    className={`listings-manager-tab-button ${
                        activeTab === "services" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("services")}
                >
                    Service
                </button>
                <button
                    className={`listings-manager-tab-button ${
                        activeTab === "digital-goods" ? "active" : ""
                    }`}
                    onClick={() => setActiveTab("digital-goods")}
                >
                    Digital Good
                </button>
            </div>

            <div className="listings-manager-content">
                <div
                    className={
                        viewMode === "grid" ? "listings-manager-grid" : ""
                    }
                >
                    <EditableListings listings={filteredListings} />
                </div>
            </div>

            <div ref={ref} className="listings-manager-pagination">
                {isFetchingNextPage && (
                    <div
                        className="listings-manager-pagination-loading"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            padding: "20px 0",
                            gap: "10px",
                        }}
                    >
                        <CircularProgress
                            size={24}
                            style={{
                                color: "var(--primary-color)",
                                border: "10px",
                            }}
                        />
                        <span>Loading listings...</span>
                    </div>
                )}
            </div>

            <ModalComponent
                isOpen={isModalOpen}
                toggle={toggleModal}
                title="Delete Listing"
                submitText={
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                        }}
                    >
                        {loading && <Spinner />}
                        Delete
                    </div>
                }
                onSubmit={() => deleteListing(listingToDelete.id)}
            >
                <div style={{ display: "flex", gap: "5px" }}>
                    Are you sure you want to delete
                    <span style={{ fontWeight: 600 }}>
                        {listingToDelete?.title}
                    </span>
                    ?
                </div>
            </ModalComponent>
        </div>
    );
};

export default ListingsManager;
