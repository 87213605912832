import React, { useContext, useEffect, useState } from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Loading } from "../components";
import {
    EquipmentRental,
    SpecificItem,
    Service,
    DigitalGood,
} from "./ListingCategories";
import { fetchGet } from "../services/data-service";
import { updateListing } from "../services/listing-api";
import { fieldsToStringify } from "../constants/ListingConstants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { showSuccessToast, showErrorToast } from "../components/Toast";

// Create a new context for EditListing
export const EditListingContext = React.createContext();

export const EditListingProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [labMembers, setLabMembers] = useState([]);
    const [listing, setListing] = useState(null);

    const value = {
        isLoading,
        setIsLoading,
        labMembers,
        setLabMembers,
        listing,
        setListing,
    };

    return (
        <EditListingContext.Provider value={value}>
            {children}
        </EditListingContext.Provider>
    );
};

const ListingCategories = () => {
    const { listing, labMembers } = useContext(EditListingContext);

    if (!listing) {
        return <Loading />;
    }
    if (listing.type === "equipment-rental") {
        return <EquipmentRental labMembers={labMembers} listing={listing} />;
    } else if (listing.type === "specific-item") {
        return <SpecificItem labMembers={labMembers} listing={listing} />;
    } else if (listing.type === "service") {
        return <Service labMembers={labMembers} listing={listing} />;
    } else if (listing.type === "digital-good") {
        return <DigitalGood labMembers={labMembers} listing={listing} />;
    }
};

const ListingEditor = () => {
    const { listingId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { isLoading, setIsLoading, setLabMembers, setListing, listing } =
        useContext(EditListingContext);

    const methods = useFormContext();
    const queryClient = useQueryClient();

    const { mutate: updateListingMutation } = useMutation({
        mutationFn: async () => {
            if (await methods.trigger()) {
                handleSave();
            } else {
                console.log(methods.formState.errors);
                showErrorToast("Please check form for errors and try again.");
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["listing", listingId],
            });
        },
        onError: () => {
            console.error("Failed to send join request to lab");
            showErrorToast("Failed to update listing. Please try again.");
        },
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const listing = await fetchGet(`/listings/${listingId}`, true);
                const members = await fetchGet(
                    `/labs/${listing.labId}/members`,
                    true
                );

                const membersData = members.members.map((member) => {
                    const memberName = member.firstName + " " + member.lastName;
                    return {
                        value: memberName,
                        label: memberName,
                        id: member.id,
                    };
                });
                setLabMembers(membersData);
                setListing(listing);

                methods.reset({
                    fields: {
                        title: listing.title,
                        description: listing.description,
                        labMember:
                            typeof listing.labMember === "object" &&
                            listing.labMember !== null
                                ? listing.labMember
                                : {
                                      value: listing.labMember || "",
                                      label: listing.labMember || "",
                                  },
                        licensingType: {
                            value: listing.licensingType,
                            label: listing.licensingType,
                        },
                        price: {
                            value: listing.price?.value || 0,
                            units: listing.price?.units || "unit",
                        },
                        unavailabilities: listing.unavailabilities || {
                            dates: [],
                            days: [],
                        },
                        retrievalMethods: listing.retrievalMethods,
                        relevantLink: listing.relevantLink,
                        isCollaborative:
                            listing.isCollaborative === true ||
                            listing.isCollaborative === "true"
                                ? true
                                : false,
                        pricingType:
                            listing.pricingType === "CUSTOM"
                                ? "CUSTOM"
                                : "FIXED",
                        directBuying:
                            listing.directBuying === true ||
                            listing.directBuying === "true"
                                ? true
                                : false,
                        imgs: [
                            ...(listing.imgs || []),
                            ...Array(3 - (listing.imgs?.length || 0)).fill({}),
                        ],
                    },
                });
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [listingId]);

    if (isLoading) {
        return <Loading />;
    }

    const handleCancel = () => {
        const currentPath = location.pathname;
        const newPath = currentPath.split("/").slice(0, -2).join("/");
        navigate(newPath);
    };

    const handleSave = () => {
        const saveData = async () => {
            try {
                const { dirtyFields } = methods.formState;
                if (Object.keys(dirtyFields).length === 0) {
                    // No changes made, just navigate back
                    const currentPath = location.pathname;
                    const newPath = currentPath
                        .split("/")
                        .slice(0, -2)
                        .join("/");
                    navigate(newPath);
                    return;
                }

                // Create FormData object for the multipart request
                const formData = new FormData();

                // Add fields that have been modified
                Object.keys(dirtyFields.fields || {}).forEach((key) => {
                    if (key === "imgs") {
                        const images = methods.getValues("fields.imgs");
                        const imageRefsForBackend = images.map((img) => {
                            const imgData = {};
                            if (img.ref) imgData.ref = img.ref;
                            if (img.file) imgData.shouldAdd = true;
                            if (img.shouldDelete) imgData.shouldDelete = true;
                            return imgData;
                        });

                        // Append files separately
                        images.forEach((img, index) => {
                            if (img.file) {
                                formData.append(`files[${index}]`, img.file);
                            }
                        });

                        formData.append(
                            "imgRefs",
                            JSON.stringify(imageRefsForBackend)
                        );
                    } else {
                        formData.append(
                            key,
                            fieldsToStringify.includes(key)
                                ? JSON.stringify(
                                      methods.getValues(`fields.${key}`)
                                  )
                                : methods.getValues(`fields.${key}`)
                        );
                    }
                });

                // Call the API to update the listing
                await updateListing(listingId, formData);

                // Success message
                showSuccessToast("Listing updated successfully!");

                // Navigate back
                const currentPath = location.pathname;
                const newPath = currentPath.split("/").slice(0, -2).join("/");
                navigate(newPath);
            } catch (error) {
                console.error("Error updating listing:", error);
                showErrorToast("Error updating listing. Please try again.");
            }
        };

        saveData();
    };

    return (
        <div className="container-create-listing">
            <form>
                <ListingCategories />
                <div
                    className="button-container-listing"
                    style={{
                        justifyContent: "space-between",
                    }}
                >
                    <button
                        className="button-listing"
                        type="button"
                        onClick={handleCancel}
                    >
                        Cancel
                    </button>
                    <button
                        className="button-listing"
                        type="button"
                        onClick={updateListingMutation}
                    >
                        Save Changes
                    </button>
                </div>
            </form>
        </div>
    );
};

const EditListing = () => {
    const methods = useForm({
        shouldUnregister: false,
    });

    return (
        <EditListingProvider>
            <FormProvider {...methods}>
                <ListingEditor />
            </FormProvider>
        </EditListingProvider>
    );
};

export default EditListing;
