import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFormContext, Controller } from "react-hook-form";
import Select from "react-select";
import {
    Checkbox,
    FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    IconButton,
    LinearProgress,
    Typography,
    Tooltip,
    Chip,
} from "@mui/material";
import {
    Delete as DeleteIcon,
    CloudUpload as CloudUploadIcon,
    CheckCircle as CheckCircleIcon,
    Info as InfoIcon,
    Edit as EditIcon,
    Description as DescriptionIcon,
    Stop as StopIcon,
    Search as SearchIcon,
    Image as ImageIcon,
    Close as CloseIcon,
} from "@mui/icons-material";
import { Loading } from "../../components";
import { fetchData, fetchWithAuth } from "../../services/data-service";
import { getLabMembersByLabId } from "../../firebase/crud";
import { licensingTypes } from "../../constants/ListingConstants";
import "../../styles/ImportListingsStyles.css";
import { auth } from "../../firebase-config";

const ImportListings = ({ labMembers }) => {
    const { labId } = useParams();
    const navigate = useNavigate();
    const [uploadedFile, setUploadedFile] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [webUrl, setWebUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isExtracting, setIsExtracting] = useState(false);
    const [extractedListings, setExtractedListings] = useState([]);
    const [error, setError] = useState(null);
    const [defaultLabMember, setDefaultLabMember] = useState(null);
    const [progress, setProgress] = useState(0);
    const [statusMessage, setStatusMessage] = useState("");
    const [inputMethod, setInputMethod] = useState("file"); // "file" or "url" or "image"
    const progressIntervalRef = useRef(null);
    const abortControllerRef = useRef(null);
    const fileInputRef = useRef(null);
    const imageInputRef = useRef(null);
    const { control } = useFormContext();
    const [columnResizing, setColumnResizing] = useState(null);
    const tableRef = useRef(null);

    // Find the lab manager or PI to use as default for lab member selections
    useEffect(() => {
        const findDefaultMember = async () => {
            try {
                // Find lab manager or PI in lab members
                let defaultMember = labMembers.find(
                    (member) =>
                        member.role === "Lab Manager" ||
                        member.role === "Manager"
                );

                // If no lab manager found, try to find the PI
                if (!defaultMember) {
                    defaultMember = labMembers.find(
                        (member) => member.role === "PI"
                    );
                }

                // If still no default found, use the first lab member
                if (!defaultMember && labMembers.length > 0) {
                    defaultMember = labMembers[0];
                }

                setDefaultLabMember(defaultMember);
            } catch (error) {
                console.error("Error finding default lab member:", error);
            }
        };

        if (labMembers && labMembers.length > 0) {
            findDefaultMember();
        }
    }, [labMembers]);

    // Effect to prevent form submission when within this component
    useEffect(() => {
        // Find the parent form element
        const parentForm = document.querySelector(
            ".container-create-listing form"
        );

        if (parentForm) {
            // Store the original onSubmit handler
            const originalOnSubmit = parentForm.onsubmit;

            // Create a new handler to prevent submission
            const preventSubmitHandler = (e) => {
                e.preventDefault();
                e.stopPropagation();
                return false;
            };

            // Set our handler
            parentForm.onsubmit = preventSubmitHandler;

            // Clean up when component unmounts
            return () => {
                // Restore original handler
                parentForm.onsubmit = originalOnSubmit;
            };
        }
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setUploadedFile(file);
            setWebUrl("");
            setUploadedImage(null);
            setImagePreviewUrl(null);
            setInputMethod("file");
            setError(null);
        }
    };

    const handleImageChange = (event) => {
        const image = event.target.files[0];
        if (image) {
            setUploadedImage(image);
            setUploadedFile(null);
            setWebUrl("");
            setInputMethod("image");
            setError(null);

            // Create URL for preview
            const previewUrl = URL.createObjectURL(image);
            setImagePreviewUrl(previewUrl);
        }
    };

    const handleImagePaste = async () => {
        try {
            const clipboardItems = await navigator.clipboard.read();
            for (const clipboardItem of clipboardItems) {
                for (const type of clipboardItem.types) {
                    if (type.startsWith("image/")) {
                        const blob = await clipboardItem.getType(type);
                        // Convert blob to file
                        const file = new File(
                            [blob],
                            `pasted_image_${Date.now()}.${type.split("/")[1]}`,
                            { type }
                        );
                        setUploadedImage(file);
                        setUploadedFile(null);
                        setWebUrl("");
                        setInputMethod("image");
                        setError(null);

                        // Create URL for preview
                        const previewUrl = URL.createObjectURL(file);
                        setImagePreviewUrl(previewUrl);
                        return; // Stop after finding the first image
                    }
                }
            }
            alert("No image found in clipboard.");
        } catch (err) {
            console.error("Failed to read clipboard:", err);
            alert(
                "Could not paste image. Please ensure you have granted clipboard permissions or try uploading the file directly."
            );
        }
    };

    const handleRemoveImage = () => {
        setUploadedImage(null);
        setImagePreviewUrl(null);

        // Reset the file input
        if (imageInputRef.current) {
            imageInputRef.current.value = "";
        }
    };

    const handleUrlChange = (event) => {
        setWebUrl(event.target.value);
        setUploadedFile(null);
        setUploadedImage(null);
        setImagePreviewUrl(null);
        setInputMethod("url");
        setError(null);
    };

    // Function to add default fields to listings
    const addDefaultsToListings = (listings) => {
        return listings.map((listing) => ({
            ...listing,
            labMember: defaultLabMember,
            isCollaborative: true,
            // Add unavailabilities for equipment-rental type listings
            ...(listing.type === "equipment-rental" && !listing.unavailabilities
                ? {
                      unavailabilities: {
                          dates: [],
                          days: [],
                      },
                  }
                : {}),
        }));
    };

    const handleStopExtraction = () => {
        // Abort the fetch request if still in progress
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
            abortControllerRef.current = null;
        }

        // Clear progress interval if active
        if (progressIntervalRef.current) {
            clearInterval(progressIntervalRef.current);
            progressIntervalRef.current = null;
        }

        setIsExtracting(false);
        setStatusMessage("Extraction stopped by user");

        // If we have listings, keep them displayed for review
        // Otherwise reset the loading state
        if (extractedListings.length === 0) {
            setIsLoading(false);
        } else {
            // Add a user notification that they can continue with the already extracted listings
            setError(
                "Extraction was stopped. You can proceed with the listings that have already been extracted or start over with a different file."
            );
        }
    };

    const handleProcessFile = async () => {
        if (inputMethod === "file" && !uploadedFile) {
            setError("Please select a file to upload");
            return;
        }

        if (inputMethod === "image" && !uploadedImage) {
            setError("Please upload or paste an image");
            return;
        }

        if (inputMethod === "url" && !webUrl) {
            setError("Please enter a website URL");
            return;
        }

        if (inputMethod === "url" && !isValidUrl(webUrl)) {
            setError("Please enter a valid URL (e.g., https://example.com)");
            return;
        }

        setIsLoading(true);
        setIsExtracting(true);
        setError(null);
        setProgress(0);
        setStatusMessage(
            inputMethod === "file"
                ? "Preparing to upload file..."
                : inputMethod === "image"
                ? "Preparing to upload image..."
                : "Preparing to fetch website content..."
        );
        setExtractedListings([]); // Clear previous listings

        // Clear any existing interval
        if (progressIntervalRef.current) {
            clearInterval(progressIntervalRef.current);
            progressIntervalRef.current = null;
        }

        // Create a new AbortController
        abortControllerRef.current = new AbortController();
        const signal = abortControllerRef.current.signal;

        try {
            // Get auth token
            const user = auth.currentUser;
            if (!user) {
                throw new Error("No user is signed in");
            }
            const idToken = await user.getIdToken();

            // Get the base URL
            const baseUrl =
                process.env.REACT_APP_BACKEND_BASE_URL ||
                "http://localhost:8080/api";

            if (inputMethod === "file") {
                const formData = new FormData();
                formData.append("document", uploadedFile);

                console.log(
                    "Sending request to endpoint:",
                    "/ai/extract-listings"
                );
                setStatusMessage("Uploading file and starting extraction...");
                setProgress(5);

                // First, send the document using POST
                const uploadUrl = `${baseUrl}/ai/extract-listings`;

                // Check if the browser supports EventSource
                const isEventSourceSupported =
                    typeof EventSource !== "undefined";

                if (isEventSourceSupported) {
                    try {
                        // Use fetch to send the file, but specify Accept header for SSE
                        const response = await fetch(uploadUrl, {
                            method: "POST",
                            headers: {
                                Authorization: `Bearer ${idToken}`,
                                Accept: "text/event-stream",
                            },
                            body: formData,
                            signal: signal,
                        });

                        if (!response.ok) {
                            throw new Error(
                                `HTTP error! status: ${response.status}`
                            );
                        }

                        // Process with SSE
                        await processSSEResponse(response);
                    } catch (error) {
                        handleFetchError(error);
                    }
                } else {
                    // EventSource not supported, use regular fetch
                    await handleFallbackFetchForUrl(webUrl, idToken);
                }
            } else if (inputMethod === "image") {
                const formData = new FormData();
                formData.append("image", uploadedImage);

                console.log(
                    "Sending request to endpoint:",
                    "/ai/extract-listings-from-image"
                );
                setStatusMessage("Uploading image and starting extraction...");
                setProgress(5);

                // Send the image using POST
                const uploadUrl = `${baseUrl}/ai/extract-listings-from-image`;

                // Check if the browser supports EventSource
                const isEventSourceSupported =
                    typeof EventSource !== "undefined";

                if (isEventSourceSupported) {
                    try {
                        // Use fetch to send the image, but specify Accept header for SSE
                        const response = await fetch(uploadUrl, {
                            method: "POST",
                            headers: {
                                Authorization: `Bearer ${idToken}`,
                                Accept: "text/event-stream",
                            },
                            body: formData,
                            signal: signal,
                        });

                        if (!response.ok) {
                            throw new Error(
                                `HTTP error! status: ${response.status}`
                            );
                        }

                        // Process with SSE
                        await processSSEResponse(response);
                    } catch (error) {
                        handleFetchError(error);
                    }
                } else {
                    // EventSource not supported, use regular fetch
                    const response = await fetch(uploadUrl, {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${idToken}`,
                        },
                        body: formData,
                        signal: signal,
                    });

                    if (!response.ok) {
                        throw new Error(
                            `HTTP error! status: ${response.status}`
                        );
                    }

                    const result = await response.json();

                    if (result.success) {
                        setProgress(100);
                        setStatusMessage(
                            `Successfully extracted ${result.listings.length} listings`
                        );

                        // Add labMember and isCollaborative defaults to each listing
                        const listingsWithDefaults = addDefaultsToListings(
                            result.listings || []
                        );
                        setExtractedListings(listingsWithDefaults);
                        setIsExtracting(false);

                        // If no listings were found, show a message but stay on this page
                        if (result.listings.length === 0) {
                            setError(
                                "No listings were found in this image. Try a different image or upload a file instead."
                            );
                            setIsLoading(false);
                        }
                    } else {
                        setError(
                            result.message ||
                                "Failed to extract listings from the image"
                        );
                        setIsExtracting(false);
                        setIsLoading(false);
                    }
                }
            } else {
                // URL input method
                console.log(
                    "Sending request to endpoint:",
                    "/ai/extract-listings-from-url"
                );
                setStatusMessage("Fetching website content...");
                setProgress(5);

                // URL for fetching website content
                const extractUrl = `${baseUrl}/ai/extract-listings-from-url`;

                // Check if the browser supports EventSource
                const isEventSourceSupported =
                    typeof EventSource !== "undefined";

                if (isEventSourceSupported) {
                    try {
                        // Use fetch to send the URL, but specify Accept header for SSE
                        const response = await fetch(extractUrl, {
                            method: "POST",
                            headers: {
                                Authorization: `Bearer ${idToken}`,
                                "Content-Type": "application/json",
                                Accept: "text/event-stream",
                            },
                            body: JSON.stringify({ url: webUrl }),
                            signal: signal,
                        });

                        if (!response.ok) {
                            throw new Error(
                                `HTTP error! status: ${response.status}`
                            );
                        }

                        // Process with SSE
                        await processSSEResponse(response);
                    } catch (error) {
                        handleFetchError(error);
                    }
                } else {
                    // EventSource not supported, use regular fetch
                    await handleFallbackFetchForUrl(webUrl, idToken);
                }
            }
        } catch (error) {
            // Clear the progress interval
            if (progressIntervalRef.current) {
                clearInterval(progressIntervalRef.current);
                progressIntervalRef.current = null;
            }

            console.error("Error processing input:", error);

            // Provide more detailed error message to help with debugging
            let errorMessage = `An error occurred while processing the ${
                inputMethod === "file"
                    ? "file"
                    : inputMethod === "image"
                    ? "image"
                    : "URL"
            }. `;

            if (error.message) {
                if (error.message.includes("404")) {
                    errorMessage +=
                        "The AI extraction endpoint was not found. Please check server configuration.";
                } else if (
                    error.message.includes("401") ||
                    error.message.includes("403")
                ) {
                    errorMessage +=
                        "Authentication error. Please ensure you're logged in.";
                } else {
                    errorMessage += error.message;
                }
            } else {
                errorMessage += "Please try again.";
            }

            setError(errorMessage);
            setIsExtracting(false);
            setIsLoading(false);
        }
    };

    // Helper function to process SSE responses
    const processSSEResponse = async (response) => {
        // Check if we got an event stream response
        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("text/event-stream")) {
            // Process the event stream
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let buffer = "";

            while (true) {
                try {
                    const { done, value } = await reader.read();
                    if (done) break;

                    // Decode the chunk and add to buffer
                    buffer += decoder.decode(value, {
                        stream: true,
                    });

                    // Process complete events
                    const events = buffer.split("\n\n");
                    buffer = events.pop() || ""; // Keep incomplete event in buffer

                    for (const event of events) {
                        if (event.startsWith("data:")) {
                            try {
                                const data = JSON.parse(
                                    event.substring(5).trim()
                                );
                                console.log("SSE update:", data);

                                if (data.status === "processing") {
                                    // Update progress UI
                                    setProgress(data.progress || 0);
                                    setStatusMessage(
                                        data.message || "Processing..."
                                    );

                                    // Check if this update includes new listings
                                    if (
                                        data.extractedCount &&
                                        data.extractedCount > 0
                                    ) {
                                        // Update with new listings if available
                                        if (
                                            data.newListings &&
                                            Array.isArray(data.newListings)
                                        ) {
                                            const newListingsWithDefaults =
                                                addDefaultsToListings(
                                                    data.newListings
                                                );
                                            setExtractedListings((current) => [
                                                ...current,
                                                ...newListingsWithDefaults,
                                            ]);
                                        }
                                        // Otherwise, just update the status to show extraction is happening
                                        else if (
                                            data.chunkNumber &&
                                            data.totalExtracted
                                        ) {
                                            setStatusMessage(
                                                `Processing part ${data.chunkNumber}: Extracted ${data.extractedCount} listings...`
                                            );
                                        }
                                    }
                                } else if (data.status === "complete") {
                                    // Extraction completed successfully
                                    setProgress(100);
                                    setStatusMessage(
                                        `Successfully extracted ${data.listings.length} listings`
                                    );

                                    // Add labMember and isCollaborative defaults to each listing
                                    const listingsWithDefaults =
                                        addDefaultsToListings(
                                            data.listings || []
                                        );
                                    setExtractedListings(listingsWithDefaults);
                                    setIsExtracting(false);

                                    // If no listings were found, show a message but stay on this page
                                    if (data.listings.length === 0) {
                                        setError(
                                            "No listings were found. Try a different input method."
                                        );
                                        setIsLoading(false);
                                    }
                                    return;
                                } else if (data.status === "error") {
                                    // Handle error
                                    setError(
                                        data.message ||
                                            "An error occurred during processing"
                                    );
                                    setIsExtracting(false);
                                    setIsLoading(false);
                                    return;
                                }
                            } catch (error) {
                                console.error(
                                    "Error parsing event data:",
                                    error
                                );
                            }
                        }
                    }
                } catch (err) {
                    // Handle AbortError gracefully
                    if (err.name === "AbortError") {
                        console.log("Fetch aborted by user");
                        break;
                    } else {
                        throw err;
                    }
                }
            }
        } else {
            // Not an event stream, fall back to regular JSON response
            const responseText = await response.text();
            try {
                const result = JSON.parse(responseText);
                if (result.success) {
                    setProgress(100);
                    setStatusMessage(
                        `Successfully extracted ${result.listings.length} listings`
                    );

                    // Add labMember and isCollaborative defaults to each listing
                    const listingsWithDefaults = addDefaultsToListings(
                        result.listings || []
                    );
                    setExtractedListings(listingsWithDefaults);
                    setIsExtracting(false);

                    // If no listings were found, show a message but stay on this page
                    if (result.listings.length === 0) {
                        setError(
                            "No listings were found. Try a different input method."
                        );
                        setIsLoading(false);
                    }
                } else {
                    setError(
                        result.message ||
                            "Failed to extract listings from the input"
                    );
                    setIsExtracting(false);
                    setIsLoading(false);
                }
            } catch (e) {
                console.error("Error parsing response:", e);
                setError("Failed to parse response from server");
                setIsExtracting(false);
                setIsLoading(false);
            }
        }
    };

    // Helper function to handle fetch errors
    const handleFetchError = (error) => {
        console.error("Error with fetch:", error);
        if (error.name !== "AbortError") {
            setError(`Error: ${error.message}`);
            setIsExtracting(false);
            setIsLoading(false);
        }
    };

    // Fallback to regular fetch if SSE doesn't work for URL input
    const handleFallbackFetchForUrl = async (url, idToken) => {
        // Set up a simulated progress interval
        progressIntervalRef.current = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 90) {
                    return prevProgress;
                }
                // Move progress forward in increasingly smaller increments
                const increment = Math.max(
                    1,
                    10 - Math.floor(prevProgress / 10)
                );
                const newProgress = Math.min(90, prevProgress + increment);

                // Update status message based on progress
                if (newProgress > 80) {
                    setStatusMessage("Almost there! Finalizing extraction...");
                } else if (newProgress > 50) {
                    setStatusMessage("AI is analyzing the website contents...");
                } else if (newProgress > 20) {
                    setStatusMessage("Processing website content...");
                } else if (newProgress > 10) {
                    setStatusMessage("Fetching content from the website...");
                }

                return newProgress;
            });
        }, 600);

        try {
            // Use direct fetch with regular JSON response
            const baseUrl =
                process.env.REACT_APP_BACKEND_BASE_URL ||
                "http://localhost:8080/api";
            const apiUrl = `${baseUrl}/ai/extract-listings-from-url`;

            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${idToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ url }),
                signal: abortControllerRef.current.signal,
            });

            // Check if the response is ok
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            // Get the response text first
            const responseText = await response.text();

            // Try to parse as JSON
            const result = JSON.parse(responseText);

            // Clear the progress interval
            clearInterval(progressIntervalRef.current);
            progressIntervalRef.current = null;

            // Handle the response
            if (result.success) {
                setProgress(100);
                setStatusMessage(
                    `Successfully extracted ${result.listings.length} listings`
                );

                // Add labMember and isCollaborative defaults to each listing
                const listingsWithDefaults = addDefaultsToListings(
                    result.listings || []
                );
                setExtractedListings(listingsWithDefaults);
                setIsExtracting(false);

                // If no listings were found, show a message but stay on this page
                if (result.listings.length === 0) {
                    setError(
                        "No listings were found on this web page. Try a different URL or upload a file instead."
                    );
                    setIsLoading(false);
                }
            } else {
                setError(
                    result.message ||
                        "Failed to extract listings from the website"
                );
                setIsExtracting(false);
                setIsLoading(false);
            }
        } catch (error) {
            // Clear the progress interval
            clearInterval(progressIntervalRef.current);
            progressIntervalRef.current = null;

            console.error("Fallback fetch error:", error);
            if (error.name !== "AbortError") {
                setError(`Error: ${error.message}`);
                setIsExtracting(false);
                setIsLoading(false);
            }
        }
    };

    // Validate URL format
    const isValidUrl = (string) => {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    };

    const handleRemoveListing = (index) => {
        setExtractedListings((prev) => prev.filter((_, i) => i !== index));
    };

    const handleUpdateListing = (index, field, value) => {
        setExtractedListings((prev) => {
            const updated = [...prev];
            updated[index] = {
                ...updated[index],
                [field]: value,
            };
            return updated;
        });
    };

    const handleImportListings = async () => {
        setIsLoading(true);
        setError(null);

        try {
            // Process each listing
            for (const listing of extractedListings) {
                const formData = new FormData();

                // Add mandatory fields
                formData.append("description", listing.description);
                formData.append("isCollaborative", listing.isCollaborative);
                formData.append("labId", labId);
                formData.append("labMember", JSON.stringify(listing.labMember));
                formData.append("licensingType", listing.licensingType);
                formData.append("pricingType", "CUSTOM"); // Set to "Contact for pricing"
                formData.append("title", listing.name);
                formData.append("type", listing.type);

                // Add price property with default values (but won't be displayed since pricingType is CUSTOM)
                formData.append(
                    "price",
                    JSON.stringify({
                        value: 0,
                        units: "unit",
                    })
                );

                // Add unavailabilities field - required for equipment rentals to avoid calendar errors
                formData.append(
                    "unavailabilities",
                    JSON.stringify({
                        dates: [],
                        days: [],
                    })
                );

                // Send request to create listing - ensure correct endpoint
                await fetchWithAuth("/listings", {
                    method: "POST",
                    body: formData,
                });
            }

            // Show success message and redirect
            alert(
                `Successfully imported ${extractedListings.length} listings!`
            );
            // Navigate to the lab page
            const currentPath = window.location.pathname;
            const newPath = currentPath.split("/").slice(0, -1).join("/");
            navigate(newPath);
        } catch (error) {
            console.error("Error importing listings:", error);
            setError(
                "An error occurred while importing the listings. Please try again."
            );
        } finally {
            setIsLoading(false);
        }
    };

    // Clean up interval and abort controller on unmount
    useEffect(() => {
        return () => {
            if (progressIntervalRef.current) {
                clearInterval(progressIntervalRef.current);
            }
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, []);

    // Render a chip/badge for the listing type
    const getListingTypeChip = (type) => {
        const typeConfig = {
            "specific-item": { color: "primary", label: "Specific Item" },
            "equipment-rental": {
                color: "secondary",
                label: "Equipment Rental",
            },
            service: { color: "success", label: "Service" },
            "digital-good": { color: "info", label: "Digital Good" },
        };

        const config = typeConfig[type] || { color: "default", label: type };

        return (
            <Chip
                label={config.label}
                color={config.color}
                size="small"
                variant="outlined"
                style={{ fontWeight: 500 }}
            />
        );
    };

    // Display file upload UI if no listings extracted yet and not currently extracting
    // But stay on this page if we've completed extraction but found zero listings
    const showInitialUI =
        extractedListings.length === 0 && !isExtracting && !isLoading;

    // Column resize functionality
    const startResizing = useCallback(
        (e, columnIndex) => {
            e.preventDefault();
            const startX = e.pageX;
            const table = tableRef.current;
            if (!table) return;

            // Get all the header cells
            const headerCells = Array.from(
                table.querySelectorAll("thead tr th")
            );
            if (!headerCells || !headerCells[columnIndex]) return;

            // Get information about all columns
            const columnWidths = headerCells.map(
                (cell) => cell.getBoundingClientRect().width
            );

            // Column being resized
            const columnCell = headerCells[columnIndex];
            const initialWidth = columnWidths[columnIndex];

            // Add a class to the column being resized for visual feedback
            columnCell.classList.add("resizing");

            // Set up resize state
            setColumnResizing({
                columnIndex,
                startX,
                initialWidth,
            });

            // Define handlers within the callback to ensure they have access to the most current state
            const handleMouseMove = (moveEvent) => {
                const deltaX = moveEvent.pageX - startX;
                const newWidth = Math.max(50, initialWidth + deltaX); // Minimum width of 50px

                // Apply new width to current column
                columnCell.style.width = `${newWidth}px`;

                // If we're not at the last column, adjust only the next column
                if (columnIndex < headerCells.length - 1) {
                    const nextColumnIndex = columnIndex + 1;
                    const nextColumnInitialWidth =
                        columnWidths[nextColumnIndex];
                    const nextColumnNewWidth = Math.max(
                        50,
                        nextColumnInitialWidth - deltaX
                    );
                    headerCells[
                        nextColumnIndex
                    ].style.width = `${nextColumnNewWidth}px`;
                }
            };

            const stopResizing = () => {
                document.removeEventListener("mousemove", handleMouseMove);
                document.removeEventListener("mouseup", stopResizing);
                // Remove resizing class
                columnCell.classList.remove("resizing");
                setColumnResizing(null);
            };

            // Add global event listeners for moving and stopping
            document.addEventListener("mousemove", handleMouseMove);
            document.addEventListener("mouseup", stopResizing);
        },
        [] // Empty dependency array - we don't want to recreate this function
    );

    // Custom table header cell with resizer
    const renderResizableHeader = (label, columnIndex) => (
        <TableCell key={columnIndex}>
            {label}
            <div
                className={`resizer ${
                    columnResizing?.columnIndex === columnIndex
                        ? "resizing"
                        : ""
                }`}
                onMouseDown={(e) => startResizing(e, columnIndex)}
            />
        </TableCell>
    );

    if (showInitialUI) {
        return (
            <>
                <h1 className="create-listing-header">Import Listings</h1>
                <div className="import-container">
                    <div className="import-instructions">
                        <h2>Upload a file, image, or enter a website URL</h2>
                        <p>
                            You can upload a document containing information
                            about your listings, upload/paste an image of your
                            website or listings, or enter a URL to a web page
                            with your equipment and resources.
                        </p>
                        <p>
                            Our AI will extract the listings and allow you to
                            review them before importing.
                        </p>
                    </div>

                    <div className="import-tabs">
                        <button
                            type="button"
                            className={`import-tab ${
                                inputMethod === "file" ? "active" : ""
                            }`}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation(); // Stop event bubbling
                                setInputMethod("file");
                            }}
                        >
                            Upload File
                        </button>
                        <button
                            type="button"
                            className={`import-tab ${
                                inputMethod === "url" ? "active" : ""
                            }`}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation(); // Stop event bubbling
                                setInputMethod("url");
                            }}
                        >
                            Enter Website URL
                        </button>
                        <button
                            type="button"
                            className={`import-tab ${
                                inputMethod === "image" ? "active" : ""
                            }`}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation(); // Stop event bubbling
                                setInputMethod("image");
                            }}
                        >
                            Upload Image
                        </button>
                    </div>

                    {inputMethod === "file" ? (
                        <>
                            <div className="file-upload-container">
                                <input
                                    type="file"
                                    id="file-upload"
                                    className="file-input"
                                    onChange={handleFileChange}
                                    accept=".xlsx,.xls,.docx,.doc,.csv,.txt"
                                    ref={fileInputRef}
                                />
                                <label
                                    htmlFor="file-upload"
                                    className="file-upload-label"
                                >
                                    <CloudUploadIcon />
                                    <span>Choose a file</span>
                                </label>
                                {uploadedFile && (
                                    <div className="selected-file">
                                        <p>
                                            <DescriptionIcon
                                                style={{
                                                    fontSize: 18,
                                                    verticalAlign: "middle",
                                                    marginRight: "8px",
                                                }}
                                            />
                                            {uploadedFile.name}
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="import-info">
                                <p>
                                    <strong>Supported file types:</strong>{" "}
                                    .xlsx, .xls, .docx, .doc, .csv, .txt
                                </p>
                            </div>
                        </>
                    ) : inputMethod === "image" ? (
                        <>
                            <div className="image-upload-container">
                                <input
                                    type="file"
                                    id="image-upload"
                                    className="file-input"
                                    onChange={handleImageChange}
                                    accept="image/*"
                                    ref={imageInputRef}
                                />
                                {!imagePreviewUrl ? (
                                    <label
                                        htmlFor="image-upload"
                                        className="file-upload-label"
                                    >
                                        <ImageIcon
                                            style={{
                                                fontSize: 56,
                                                marginBottom: 20,
                                                opacity: 0.9,
                                            }}
                                        />
                                        <span>Choose an image</span>
                                    </label>
                                ) : (
                                    <div className="image-preview-container">
                                        <div className="image-preview-wrapper">
                                            <img
                                                src={imagePreviewUrl}
                                                alt="Preview"
                                                className="image-preview"
                                            />
                                            <button
                                                type="button"
                                                className="remove-image-button"
                                                onClick={handleRemoveImage}
                                            >
                                                <CloseIcon />
                                            </button>
                                        </div>
                                        <p className="image-filename">
                                            {uploadedImage &&
                                                uploadedImage.name}
                                        </p>
                                    </div>
                                )}
                                {!imagePreviewUrl && (
                                    <div className="paste-image-container">
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleImagePaste}
                                            className="paste-image-button"
                                        >
                                            Paste Image from Clipboard
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <div className="import-info">
                                <p>
                                    <strong>Supported image types:</strong>{" "}
                                    .jpg, .jpeg, .png, .gif, .webp, .bmp
                                </p>
                                <p className="image-tip">
                                    <InfoIcon
                                        style={{
                                            fontSize: 16,
                                            verticalAlign: "middle",
                                            marginRight: 5,
                                        }}
                                    />
                                    Upload a screenshot of your listings or take
                                    a picture of a catalog page to extract
                                    listings.
                                </p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="url-input-container">
                                <div className="url-input-field">
                                    <input
                                        type="url"
                                        id="website-url"
                                        className="url-input"
                                        value={webUrl}
                                        onChange={handleUrlChange}
                                        placeholder="https://example.com/lab-equipment"
                                        onKeyDown={(e) => {
                                            // Prevent form submission on Enter
                                            if (e.key === "Enter") {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </div>
                                {webUrl && (
                                    <div className="selected-url">
                                        <p>
                                            <InfoIcon
                                                style={{
                                                    fontSize: 18,
                                                    verticalAlign: "middle",
                                                    marginRight: "8px",
                                                }}
                                            />
                                            The AI will analyze this web page
                                            for listing information
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="import-info">
                                <p>
                                    <strong>Tips:</strong> Use URLs pointing
                                    directly to pages with equipment lists,
                                    catalogs, or services information.
                                </p>
                            </div>
                        </>
                    )}

                    {progress > 0 && progress < 100 ? (
                        <div className="extraction-progress">
                            <h4>{statusMessage}</h4>
                            <div className="custom-progress-container">
                                <div
                                    className="custom-progress-bar"
                                    style={{ width: `${progress}%` }}
                                ></div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginTop: "5px",
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {progress}%
                                </Typography>
                            </div>
                        </div>
                    ) : (
                        <div className="process-button-container">
                            <Button
                                className="process-file-button"
                                variant="contained"
                                color="primary"
                                onClick={handleProcessFile}
                                disabled={
                                    (inputMethod === "file" && !uploadedFile) ||
                                    (inputMethod === "image" &&
                                        !uploadedImage) ||
                                    (inputMethod === "url" && !webUrl) ||
                                    isLoading
                                }
                                startIcon={
                                    inputMethod === "file" ? (
                                        <CloudUploadIcon />
                                    ) : inputMethod === "image" ? (
                                        <ImageIcon />
                                    ) : (
                                        <SearchIcon />
                                    )
                                }
                            >
                                {inputMethod === "file"
                                    ? "Process File"
                                    : inputMethod === "image"
                                    ? "Process Image"
                                    : "Analyze Website"}
                            </Button>
                        </div>
                    )}

                    {error && <div className="error-message">{error}</div>}
                </div>
            </>
        );
    }

    // Display extraction in progress with the listings that are already extracted
    return (
        <>
            <h1 className="create-listing-header">
                {isExtracting
                    ? "Extracting Listings..."
                    : "Review Extracted Listings"}
            </h1>
            <div className="review-listings-container">
                {isExtracting && (
                    <div className="extraction-in-progress">
                        <div className="extraction-status">
                            <h3>{statusMessage}</h3>
                            <div className="custom-progress-container">
                                <div
                                    className="custom-progress-bar"
                                    style={{ width: `${progress}%` }}
                                ></div>
                            </div>
                            <div className="extraction-progress-info">
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {progress}% complete
                                </Typography>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    startIcon={<StopIcon />}
                                    onClick={handleStopExtraction}
                                    className="stop-extraction-button"
                                >
                                    Stop Extraction
                                </Button>
                            </div>
                        </div>
                    </div>
                )}

                <p>
                    {isExtracting
                        ? "Listings are being extracted in real-time. You can review and edit while waiting for extraction to complete."
                        : "Review the extracted listings below. You can edit any field before importing."}
                    <Chip
                        icon={<CheckCircleIcon style={{ fontSize: 14 }} />}
                        label={`${extractedListings.length} listings ${
                            isExtracting ? "extracted so far" : "found"
                        }`}
                        color="success"
                        size="small"
                        style={{ marginLeft: 16, fontWeight: 500 }}
                    />
                </p>

                <p
                    style={{
                        fontSize: "0.9rem",
                        color: "var(--text-light)",
                        marginTop: "-20px",
                        marginBottom: "20px",
                    }}
                >
                    <InfoIcon
                        style={{
                            fontSize: 14,
                            verticalAlign: "middle",
                            marginRight: 4,
                        }}
                    />
                    Hover between column headers and drag to adjust column
                    width.
                </p>

                {error && <div className="error-message">{error}</div>}

                <TableContainer
                    component={Paper}
                    className="listings-table"
                    style={{ position: "relative", zIndex: 10 }}
                >
                    <Table ref={tableRef}>
                        <TableHead>
                            <TableRow>
                                {renderResizableHeader("Name", 0)}
                                {renderResizableHeader("Listing Type", 1)}
                                {renderResizableHeader("Description", 2)}
                                {renderResizableHeader("Lab Member", 3)}
                                {renderResizableHeader("Licensing Type", 4)}
                                {renderResizableHeader("Collaboration", 5)}
                                {renderResizableHeader("Actions", 6)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {extractedListings.map((listing, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <input
                                            type="text"
                                            value={listing.name}
                                            onChange={(e) =>
                                                handleUpdateListing(
                                                    index,
                                                    "name",
                                                    e.target.value
                                                )
                                            }
                                            className="table-input"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Select
                                            value={{
                                                value: listing.type,
                                                label:
                                                    {
                                                        "specific-item":
                                                            "Specific Item",
                                                        "equipment-rental":
                                                            "Equipment Rental",
                                                        service: "Service",
                                                        "digital-good":
                                                            "Digital Good",
                                                    }[listing.type] ||
                                                    listing.type,
                                            }}
                                            onChange={(selected) =>
                                                handleUpdateListing(
                                                    index,
                                                    "type",
                                                    selected.value
                                                )
                                            }
                                            options={[
                                                {
                                                    value: "specific-item",
                                                    label: "Specific Item",
                                                },
                                                {
                                                    value: "equipment-rental",
                                                    label: "Equipment Rental",
                                                },
                                                {
                                                    value: "service",
                                                    label: "Service",
                                                },
                                                {
                                                    value: "digital-good",
                                                    label: "Digital Good",
                                                },
                                            ]}
                                            className="table-select-dropdown"
                                            classNamePrefix="react-select"
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                }),
                                                menu: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                }),
                                            }}
                                        />
                                        <div style={{ marginTop: 5 }}>
                                            {getListingTypeChip(listing.type)}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <textarea
                                            value={listing.description}
                                            onChange={(e) =>
                                                handleUpdateListing(
                                                    index,
                                                    "description",
                                                    e.target.value
                                                )
                                            }
                                            className="table-textarea"
                                            rows={3}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Select
                                            value={listing.labMember}
                                            onChange={(selected) =>
                                                handleUpdateListing(
                                                    index,
                                                    "labMember",
                                                    selected
                                                )
                                            }
                                            options={labMembers}
                                            className="table-select-dropdown"
                                            classNamePrefix="react-select"
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                }),
                                                menu: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                }),
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Select
                                            value={{
                                                value: listing.licensingType,
                                                label: listing.licensingType,
                                            }}
                                            onChange={(selected) =>
                                                handleUpdateListing(
                                                    index,
                                                    "licensingType",
                                                    selected.value
                                                )
                                            }
                                            options={[
                                                {
                                                    value: "Proprietary",
                                                    label: "Proprietary",
                                                },
                                                {
                                                    value: "Open-source",
                                                    label: "Open-source",
                                                },
                                                {
                                                    value: "3rd-party",
                                                    label: "3rd-party",
                                                },
                                                {
                                                    value: "Subscription",
                                                    label: "Subscription",
                                                },
                                            ]}
                                            className="table-select-dropdown"
                                            classNamePrefix="react-select"
                                            menuPortalTarget={document.body}
                                            styles={{
                                                menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                }),
                                                menu: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                }),
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Checkbox
                                            checked={listing.isCollaborative}
                                            onChange={(e) =>
                                                handleUpdateListing(
                                                    index,
                                                    "isCollaborative",
                                                    e.target.checked
                                                )
                                            }
                                            color="primary"
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Tooltip title="Remove Listing">
                                            <IconButton
                                                onClick={() =>
                                                    handleRemoveListing(index)
                                                }
                                                color="error"
                                                size="small"
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {extractedListings.length > 0 && (
                    <div
                        className="import-actions"
                        style={{
                            position: "relative",
                            zIndex: 9999,
                        }}
                    >
                        <div
                            className="cancel-button"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                console.log(
                                    "Upload Different File button clicked"
                                );
                                setExtractedListings([]);
                                setIsExtracting(false);
                                setIsLoading(false);
                            }}
                            onKeyPress={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                    console.log(
                                        "Upload Different File button clicked via keyboard"
                                    );
                                    setExtractedListings([]);
                                    setIsExtracting(false);
                                    setIsLoading(false);
                                }
                            }}
                            style={{
                                cursor: "pointer",
                                padding: "12px 24px",
                                backgroundColor: "#4a1010",
                                color: "white",
                                fontWeight: "500",
                                borderRadius: "6px",
                                border: "none",
                                textAlign: "center",
                            }}
                        >
                            Upload Different File
                        </div>
                        <div
                            className="import-button"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                console.log("Import Listings button clicked");
                                handleImportListings();
                            }}
                            onKeyPress={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                    console.log(
                                        "Import Listings button clicked via keyboard"
                                    );
                                    handleImportListings();
                                }
                            }}
                            style={{
                                cursor: "pointer",
                                padding: "12px 24px",
                                backgroundColor: "#4caf50",
                                color: "white",
                                fontWeight: "500",
                                borderRadius: "6px",
                                border: "none",
                                textAlign: "center",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "8px",
                            }}
                        >
                            <CheckCircleIcon style={{ fontSize: "20px" }} />
                            <span>
                                Import {extractedListings.length} Listings
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ImportListings;
