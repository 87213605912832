import React, { useEffect, useState } from "react";
import {
    Container,
    Typography,
    Box,
    Button,
    CircularProgress,
    Paper,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { fetchGet } from "../services/data-service";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useSubscription } from "../context/subscription-context";

const SubscriptionSuccess = () => {
    const [loading, setLoading] = useState(true);
    const [subscription, setSubscription] = useState(null);
    const [error, setError] = useState(null);
    const [authChecked, setAuthChecked] = useState(false);
    const [authUser, setAuthUser] = useState(null);
    const { setSubscription: setGlobalSubscription } = useSubscription();
    const navigate = useNavigate();
    const location = useLocation();

    // Listen for auth state changes
    useEffect(() => {
        const auth = getAuth();
        console.log("Setting up auth state listener");

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            console.log(
                "Auth state changed:",
                user ? `User ${user.uid}` : "No user"
            );
            setAuthUser(user);
            setAuthChecked(true);
        });

        return () => unsubscribe();
    }, []);

    // Handle subscription verification after auth is checked
    useEffect(() => {
        // Don't proceed until auth state is checked
        if (!authChecked) {
            console.log("Waiting for auth state to be checked...");
            return;
        }

        // If no user after auth check, set error
        if (!authUser) {
            console.log("No authenticated user found");
            setError("User not authenticated. Please log in and try again.");
            setLoading(false);
            return;
        }

        const verifySubscription = async () => {
            try {
                console.log(
                    "Starting subscription verification with user:",
                    authUser.uid
                );

                // Get the session ID from the URL query parameters
                const params = new URLSearchParams(location.search);
                const sessionId = params.get("session_id");

                if (!sessionId) {
                    setError("No session ID found");
                    setLoading(false);
                    return;
                }

                console.log(
                    "Verifying subscription with session ID:",
                    sessionId
                );

                // Verify the subscription with your backend
                const response = await fetchGet(
                    `/subscriptions/verify?sessionId=${sessionId}`,
                    true
                );

                console.log("Verification response:", response);

                if (response.success) {
                    setSubscription(response.data);
                    // Update the global subscription context
                    setGlobalSubscription(response.data);
                } else {
                    setError(
                        response.message || "Failed to verify subscription"
                    );
                }
            } catch (error) {
                console.error("Error verifying subscription:", error);
                setError("An error occurred while verifying your subscription");
            } finally {
                setLoading(false);
            }
        };

        verifySubscription();
    }, [authChecked, authUser, location.search, setGlobalSubscription]);

    const handleGoToAccount = () => {
        navigate("/account-settings/settings");
    };

    const handleGoToHome = () => {
        navigate("/");
    };

    if (loading) {
        return (
            <Container maxWidth="md" sx={{ py: 8 }}>
                <Paper elevation={3} sx={{ p: 4, textAlign: "center" }}>
                    <CircularProgress size={60} sx={{ mb: 3 }} />
                    <Typography variant="h5" gutterBottom>
                        Verifying your subscription...
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Please wait while we confirm your subscription details.
                    </Typography>
                </Paper>
            </Container>
        );
    }

    if (error) {
        return (
            <Container maxWidth="md" sx={{ py: 8 }}>
                <Paper elevation={3} sx={{ p: 4, textAlign: "center" }}>
                    <Typography variant="h4" gutterBottom color="error">
                        Subscription Verification Failed
                    </Typography>
                    <Typography variant="body1" paragraph>
                        {error}
                    </Typography>
                    <Box
                        sx={{
                            mt: 4,
                            display: "flex",
                            justifyContent: "center",
                            gap: 2,
                        }}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => navigate("/pricing")}
                        >
                            Back to Pricing
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => navigate("/contact")}
                        >
                            Contact Support
                        </Button>
                    </Box>
                </Paper>
            </Container>
        );
    }

    return (
        <Container maxWidth="md" sx={{ py: 8 }}>
            <Paper elevation={3} sx={{ p: 4, textAlign: "center" }}>
                <CheckCircleOutlineIcon
                    sx={{ fontSize: 80, color: "success.main", mb: 2 }}
                />
                <Typography variant="h4" gutterBottom>
                    Subscription Successful!
                </Typography>
                <Typography variant="body1" paragraph>
                    Thank you for subscribing to our {subscription?.tier || ""}{" "}
                    plan. Your subscription is now active and you have full
                    access to all features.
                </Typography>
                <Typography variant="body2" color="text.secondary" paragraph>
                    Subscription ID: {subscription?.subscriptionId || "N/A"}
                </Typography>
                <Box
                    sx={{
                        mt: 4,
                        display: "flex",
                        justifyContent: "center",
                        gap: 2,
                    }}
                >
                    <Button variant="outlined" onClick={handleGoToHome}>
                        Go to Dashboard
                    </Button>
                    <Button variant="contained" onClick={handleGoToAccount}>
                        Manage Subscription
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
};

export default SubscriptionSuccess;
