import { useEffect, useState, useRef } from "react";
import { ModalComponent } from "../../components";
import { useNavigate } from "react-router-dom";
import { fetchPost } from "../../services/data-service";
import { UserAuth } from "../../context/auth-context";
import "../../styles/ImportOrcidPublications.css";

const ImportOrcidPublications = () => {
    const navigate = useNavigate();
    const { user } = UserAuth();

    const [selectedPublications, setSelectedPublications] = useState([]);
    const [totalPublications, setTotalPublications] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const checkboxesRef = useRef([]);

    const updateSelectedPublications = () => {
        const selected = checkboxesRef.current
            .filter((checkbox) => checkbox.checked)
            .map((checkbox) => totalPublications[parseInt(checkbox.value)]);
        setSelectedPublications(selected);
        setSelectAll(selected.length === totalPublications.length);
    };

    const toggleSelectAll = () => {
        const newSelectAll = !selectAll;
        checkboxesRef.current.forEach((checkbox) => {
            checkbox.checked = newSelectAll;
        });
        const newSelectedPublications = newSelectAll
            ? [...totalPublications]
            : [];
        setSelectedPublications(newSelectedPublications);
        setSelectAll(newSelectAll);
    };

    const importPublications = async () => {
        try {
            // Create an array of promises for all publication uploads
            const uploadPromises = selectedPublications.map((publication) => {
                const date = new Date(publication.publicationDate);
                date.setUTCHours(12);
                publication.publicationDate = date;
                publication.creatorName = user.firstName + " " + user.lastName;
                publication.creatorId = user.uid;
                publication.userId = user.uid;
                return fetchPost("/publications", publication);
            });

            // Wait for all uploads to complete
            await Promise.all(uploadPromises);

            // Add a small delay before navigation
            await new Promise((resolve) => setTimeout(resolve, 1000));

            window.location.href = "/account-settings/publications";
        } catch (error) {
            console.error("Error importing publications:", error);
        }
    };

    useEffect(() => {
        const fetchOrcidData = async () => {
            try {
                const response = await fetch(
                    "https://api.labgiant.ca/orcid-data",
                    {
                        credentials: "include", // Important for sending/receiving cookies
                    }
                );

                if (!response.ok) {
                    throw new Error("Failed to fetch ORCID data", response);
                }

                const data = await response.json();
                setTotalPublications(data);
            } catch (error) {
                console.error("Error fetching ORCID data:", error);
                navigate("/account-settings/publications");
            }
        };

        // Only fetch if we were redirected from ORCID authentication
        if (window.location.pathname.includes("import-orcid-publications")) {
            fetchOrcidData();
        }
    }, [navigate]);

    useEffect(() => {
        checkboxesRef.current = Array.from(
            document.querySelectorAll('input[type="checkbox"]')
        );
    }, [totalPublications]);

    return (
        <ModalComponent
            title="Import ORCID Publications"
            isOpen={true}
            onSubmit={() => {
                importPublications();
            }}
            submitText={`Import Selected (${selectedPublications.length})`}
            toggle={() => navigate("/account-settings/publications")}
        >
            <div className="import-orcid-publications-container">
                <div className="import-orcid-publications-subtitle">
                    Select publications from your ORCID profile to import into
                    LabGiant.
                </div>
                <div className="import-orcid-publications-select-all-container">
                    <button onClick={toggleSelectAll}>
                        {selectAll ? "Unselect All" : "Select All"}
                    </button>
                    <div className="import-orcid-publications-selected-count">
                        {selectedPublications.length} of{" "}
                        {totalPublications.length} selected
                    </div>
                </div>
                <ul className="import-orcid-publications-list">
                    {totalPublications.map((data, index) => (
                        <li key={index}>
                            <input
                                type="checkbox"
                                className="import-orcid-publications-checkbox"
                                value={index}
                                onChange={updateSelectedPublications}
                                checked={selectedPublications.includes(
                                    totalPublications[index]
                                )}
                            />
                            <div
                                className="import-orcid-publications-item-content"
                                onClick={() => {
                                    const newSelected =
                                        selectedPublications.includes(
                                            totalPublications[index]
                                        )
                                            ? selectedPublications.filter(
                                                  (pub) =>
                                                      pub !==
                                                      totalPublications[index]
                                              )
                                            : [
                                                  ...selectedPublications,
                                                  totalPublications[index],
                                              ];
                                    setSelectedPublications(newSelected);
                                    setSelectAll(
                                        newSelected.length ===
                                            totalPublications.length
                                    );
                                }}
                            >
                                <div
                                    style={{
                                        color: "black",
                                        fontSize: 14,
                                        fontWeight: 500,
                                    }}
                                >
                                    {data.title}
                                </div>
                                <div>{data.journal}</div>
                                <div>{data.authors.join(", ")}</div>
                                <div>{data.publicationDate}</div>
                                <div>
                                    <span
                                        style={{
                                            color: "black",
                                            paddingRight: "10px",
                                        }}
                                    >
                                        DOI:
                                    </span>
                                    <a
                                        style={{ color: "#71717a" }}
                                        href={data.doi}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {data.doi}
                                    </a>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </ModalComponent>
    );
};

export default ImportOrcidPublications;
