import React, { useRef, useState, useEffect, memo } from "react";
import Select from "react-select";
import { Controller, useFormContext } from "react-hook-form";
import { Checkbox, FormControlLabel } from "@mui/material";
import { ErrorMessages } from "../../helpers/ListingHelpers";
import { Link as LinkIcon } from "@mui/icons-material";
import { licensingTypes } from "../../constants/ListingConstants";
import {
    ListingField,
    ModalComponent,
    ImageUpload,
    Loading,
} from "../../components";
import "../../styles/CreateListingStyles.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const DigitalGood = memo(({ labMembers, listing }) => {
    const {
        register,
        control,
        setValue,
        getValues,
        formState: { errors },
        watch,
        reset,
    } = useFormContext();

    const [linkModalOpen, setLinkModalOpen] = useState(false);
    const [linkDetails, setLinkDetails] = useState({ text: "", url: "" });
    const [selectionRange, setSelectionRange] = useState(null);
    const pageRef = useRef({});
    const [editorContent, setEditorContent] = useState(
        getValues("fields.description") || ""
    );

    const handleEditorChange = (content) => {
        setEditorContent(content);
        setValue("fields.description", content, { shouldDirty: true });
    };

    useEffect(() => {
        const setListingValues = async () => {
            if (listing) {
                reset({
                    fields: {
                        title: listing.title,
                        description: listing.description,
                        labMember: listing.labMember,
                        licensingType: {
                            value: listing.licensingType,
                            label: listing.licensingType,
                        },
                        retrievalMethods: listing.retrievalMethods,
                        relevantLink: listing.relevantLink,
                        isCollaborative: listing.isCollaborative,
                        imgs: [
                            ...(listing.imgs || []),
                            ...Array(3 - (listing.imgs?.length || 0)).fill({}),
                        ].slice(0, 3),
                    },
                });
                setEditorContent(listing.description || "");
            }
        };
        setListingValues();
    }, [listing, reset]);

    useEffect(() => {
        if (!listing) {
            setValue("fields.price.units", "unit");
        }
    }, []);

    const handleAddLink = () => {
        const textarea = document.querySelector(".retrieval-methods-textarea");
        if (textarea) {
            const selectedText = textarea.value.substring(
                textarea.selectionStart,
                textarea.selectionEnd
            );

            if (selectedText) {
                setSelectionRange({
                    start: textarea.selectionStart,
                    end: textarea.selectionEnd,
                });
                setLinkDetails({ text: selectedText, url: "" });
                setLinkModalOpen(true);
            } else {
                alert("Please select some text to add a link");
            }
        }
    };

    const handleLinkSubmit = () => {
        if (linkDetails.url) {
            const linkMarkdown = `[${linkDetails.text}](${linkDetails.url})`;
            const content = getValues("fields.retrievalMethods") || "";

            const newContent =
                content.substring(0, selectionRange.start) +
                linkMarkdown +
                content.substring(selectionRange.end);

            setValue("fields.retrievalMethods", newContent, {
                shouldDirty: true,
            });
        }
        setLinkModalOpen(false);
    };

    if (!Array.isArray(getValues("fields.imgs"))) {
        return <Loading />;
    }

    const imageSlots = [...Array(3).keys()];

    return (
        <>
            <h1 className="create-listing-header">Digital Good</h1>
            <div className="create-listing-container">
                <div className="create-listing-content">
                    <div ref={pageRef.about} className="create-listing-section">
                        <h2 className="create-listing-subheader">
                            Digital Good Overview
                        </h2>
                        <ListingField
                            field="title"
                            title="Digital Good Name"
                            description="What is the name of your digital good?"
                            required={true}
                        />
                        <div className="create-listing-field">
                            <h3>
                                Digital Good Description
                                <span style={{ color: "red" }}>*</span>
                            </h3>
                            <h4>Describe your digital good in details.</h4>
                            <div className="textarea-container">
                                <ReactQuill
                                    value={editorContent}
                                    onChange={handleEditorChange}
                                    modules={{
                                        toolbar: [
                                            [
                                                "bold",
                                                "italic",
                                                "underline",
                                                "strike",
                                            ],
                                            ["blockquote", "code-block"],
                                            [{ header: 1 }, { header: 2 }],
                                            [
                                                { list: "ordered" },
                                                { list: "bullet" },
                                            ],
                                            [
                                                { script: "sub" },
                                                { script: "super" },
                                            ],
                                            [
                                                { indent: "-1" },
                                                { indent: "+1" },
                                            ],
                                            [{ color: [] }, { background: [] }],
                                            ["link"],
                                            ["clean"],
                                        ],
                                        clipboard: {
                                            matchVisual: false,
                                            matchers: [],
                                        },
                                        keyboard: {
                                            bindings: {},
                                        },
                                    }}
                                    formats={[
                                        "bold",
                                        "italic",
                                        "underline",
                                        "strike",
                                        "blockquote",
                                        "code-block",
                                        "header",
                                        "list",
                                        "bullet",
                                        "script",
                                        "indent",
                                        "color",
                                        "background",
                                        "link",
                                    ]}
                                    preserveWhitespace={true}
                                />
                                <div className="link-warning">
                                    Note: For external links, please include the
                                    full URL (e.g., https://www.example.com)
                                </div>
                            </div>
                            {errors?.fields?.description && (
                                <ErrorMessages
                                    message={errors.fields.description.message}
                                />
                            )}
                        </div>
                        <div className="create-listing-field">
                            <h3>
                                Lab Member
                                <span style={{ color: "red" }}>*</span>
                            </h3>
                            <h4>
                                Who is the point of contact for this digital
                                good?
                            </h4>
                            <Controller
                                name="fields.labMember"
                                control={control}
                                rules={{ required: "Lab member is required" }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={labMembers}
                                        className="labmember-select"
                                        placeholder="Select lab member"
                                    />
                                )}
                            />
                            {errors.fields?.labMember && (
                                <ErrorMessages
                                    message={errors.fields.labMember.message}
                                />
                            )}
                        </div>

                        <div className="create-listing-field">
                            <h3>
                                Licensing Type
                                <span style={{ color: "red" }}>*</span>
                            </h3>
                            <h4>
                                What type of license applies to this digital
                                good?
                            </h4>
                            <Controller
                                name="fields.licensingType"
                                control={control}
                                rules={{
                                    required: "Licensing type is required",
                                }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={licensingTypes}
                                        className="licensing-select"
                                        placeholder="Select licensing type"
                                    />
                                )}
                            />
                            {errors.fields?.licensingType && (
                                <ErrorMessages
                                    message={
                                        errors.fields.licensingType.message
                                    }
                                />
                            )}
                        </div>

                        <div className="create-listing-field">
                            <h3>
                                Access Method
                                <span style={{ color: "red" }}>*</span>
                            </h3>
                            <h4>
                                How can users access this digital good? (e.g.,
                                GitHub repository, download link, API endpoint)
                            </h4>
                            <div className="textarea-container">
                                <textarea
                                    className={`input retrieval-methods-textarea ${
                                        errors.fields?.retrievalMethods
                                            ? "input-error"
                                            : ""
                                    }`}
                                    placeholder="Enter access method and instructions..."
                                    {...register("fields.retrievalMethods", {
                                        required: "Access Method are required",
                                    })}
                                />
                                <button
                                    type="button"
                                    className="add-link-button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleAddLink();
                                    }}
                                >
                                    <LinkIcon /> Add Link
                                </button>
                            </div>
                            {errors.fields?.retrievalMethods && (
                                <ErrorMessages
                                    message={
                                        errors.fields.retrievalMethods.message
                                    }
                                />
                            )}
                        </div>

                        <div className="create-listing-field">
                            <h3>Relevant Link</h3>
                            <h4>
                                Add a link to additional information about the
                                digital good (e.g., documentation, GitHub
                                repository)
                            </h4>
                            <Controller
                                name="fields.relevantLink"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className={`input ${
                                            errors.fields?.relevantLink
                                                ? "input-error"
                                                : ""
                                        }`}
                                        type="url"
                                        placeholder="https://"
                                    />
                                )}
                            />
                            {errors.fields?.relevantLink && (
                                <ErrorMessages
                                    message={errors.fields.relevantLink.message}
                                />
                            )}
                        </div>

                        <div className="create-listing-field">
                            <h3>Collaboration</h3>
                            <h4>
                                Collaborative equipment will be shown in the
                                marketplace.
                            </h4>
                            <Controller
                                name="fields.isCollaborative"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                    <FormControlLabel
                                        className="checkbox-label"
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={field.value || false}
                                                onChange={(e) =>
                                                    field.onChange(
                                                        e.target.checked
                                                    )
                                                }
                                                style={{
                                                    color: "var(--primary-color)",
                                                    marginRight: "5px",
                                                    paddingRight: "0",
                                                    paddingTop: "0",
                                                    paddingBottom: "0",
                                                }}
                                            />
                                        }
                                        label="Allow collaboration"
                                    />
                                )}
                            />
                        </div>

                        <div className="create-listing-field">
                            <h3>Listing Images</h3>
                            <h4>
                                Add an image of the digital good. You can add up
                                to 3 images.
                            </h4>
                            <div className="image-input-container">
                                {imageSlots.map((index) => (
                                    <ImageUpload key={index} index={index} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalComponent
                title="Add Link"
                isOpen={linkModalOpen}
                toggle={() => setLinkModalOpen(false)}
                submitText="Insert Link"
                onSubmit={handleLinkSubmit}
            >
                <div className="link-modal-content">
                    <input
                        type="text"
                        placeholder="Link Text"
                        value={linkDetails.text}
                        onChange={(e) =>
                            setLinkDetails({
                                ...linkDetails,
                                text: e.target.value,
                            })
                        }
                    />
                    <input
                        type="url"
                        placeholder="URL (https://...)"
                        value={linkDetails.url}
                        onChange={(e) =>
                            setLinkDetails({
                                ...linkDetails,
                                url: e.target.value,
                            })
                        }
                    />
                </div>
            </ModalComponent>
        </>
    );
});

export default DigitalGood;
