import { toast, Toaster } from "react-hot-toast";

// Toast style configuration
const toastStyle = {
    success: {
        style: {
            background: "#4CAF50",
            color: "white",
            fontWeight: "500",
            padding: "16px",
            borderRadius: "8px",
        },
        iconTheme: {
            primary: "white",
            secondary: "#4CAF50",
        },
        duration: 3000,
    },
    error: {
        style: {
            background: "#F44336",
            color: "white",
            fontWeight: "500",
            padding: "16px",
            borderRadius: "8px",
        },
        iconTheme: {
            primary: "white",
            secondary: "#F44336",
        },
        duration: 4000,
    },
    info: {
        style: {
            background: "#2196F3",
            color: "white",
            fontWeight: "500",
            padding: "16px",
            borderRadius: "8px",
        },
        iconTheme: {
            primary: "white",
            secondary: "#2196F3",
        },
        duration: 3000,
    },
};

// Toast wrapper functions
export const showSuccessToast = (message) => {
    return toast.success(message, toastStyle.success);
};

export const showErrorToast = (message) => {
    return toast.error(message, toastStyle.error);
};

export const showInfoToast = (message) => {
    return toast(message, toastStyle.info);
};

// Toast container component
export const ToastContainer = () => {
    return (
        <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerStyle={{
                top: 40,
            }}
            toastOptions={{
                // Default options that can be overridden by individual toasts
                className: "",
                duration: 3000,
            }}
        />
    );
};

export default {
    showSuccessToast,
    showErrorToast,
    showInfoToast,
    ToastContainer,
};
