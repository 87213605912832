import { useContext, useEffect, useState, useRef } from "react";
import {
    CreateListingContext,
    CreateListingContextProvider,
} from "../context/createlisting-context";
import {
    DigitalGood,
    EquipmentRental,
    Service,
    SpecificItem,
    ImportListings,
} from "./ListingCategories";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { listingCategories } from "../constants/ListingConstants";
import { UserAuth } from "../context/auth-context";
import { getLabMembersByLabId } from "../firebase/crud";
import { fetchMultipart } from "../services/data-service";
import { showSuccessToast, showErrorToast } from "../components/Toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import "../styles/CreateListingsChooseCat.css";

const ListingCreation = () => {
    const { labId } = useParams();
    const [labMembers, setLabMembers] = useState([]);
    const priceValueRef = useRef("0");
    const priceUnitsRef = useRef("unit");

    const location = useLocation();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    useEffect(() => {
        const unsub = async () => {
            const members = await getLabMembersByLabId(labId);
            let membersData = members.map((member) => {
                const memberName = member.firstName + " " + member.lastName;
                return {
                    value: memberName,
                    label: memberName,
                    id: member.id,
                };
            });
            setLabMembers(membersData);
        };
        unsub();
    }, []);

    const { step, updateStep, category, updateCategory } =
        useContext(CreateListingContext);

    const handleCategorySelection = (cat) => {
        updateCategory(cat);
        updateStep(step + 1);
    };

    const { user } = UserAuth();

    const {
        getValues,
        trigger,
        reset,
        formState: { errors },
    } = useFormContext();

    const ChooseListingCategory = () => {
        return (
            step === 1 && (
                <div className="container-categories">
                    <h1 className="title-categories">
                        Which of the following best describe your listing?
                    </h1>
                    <div className="categories">
                        {listingCategories.map((cat, i) => (
                            <div
                                className="category-container"
                                key={cat.id + i}
                                onClick={() => handleCategorySelection(cat.id)}
                            >
                                <img src={cat.image} alt="test" />
                                <input
                                    key={cat.id}
                                    type="radio"
                                    value={cat.id}
                                    name="category"
                                />
                                <div className="category">{cat.value}</div>
                            </div>
                        ))}
                    </div>

                    {/* Bulk Import Option */}
                    <div className="bulk-import-container">
                        <h2 className="bulk-import-title">
                            Looking to add multiple listings at once?
                        </h2>
                        <p className="bulk-import-description">
                            Our AI can analyze documents or webpages containing
                            your listings and automatically create them for you.
                        </p>
                        <button
                            className="bulk-import-button"
                            onClick={() => {
                                updateCategory("import");
                                updateStep(step + 1);
                            }}
                        >
                            Bulk Import from Document or Webpage
                        </button>
                    </div>
                </div>
            )
        );
    };

    const ListingCategory = () => {
        if (category === "specific-item") {
            return <SpecificItem labMembers={labMembers} />;
        } else if (category === "equipment-rental") {
            return <EquipmentRental labMembers={labMembers} />;
        } else if (category === "service") {
            return <Service labMembers={labMembers} />;
        } else if (category === "digital-good") {
            return <DigitalGood labMembers={labMembers} />;
        } else if (category === "import") {
            return <ImportListings labMembers={labMembers} />;
        } else {
            return null;
        }
    };

    const handleSendData = async () => {
        try {
            const addMandatoryFields = (formData) => {
                const mandatoryFields = {
                    description: getValues("fields.description"),
                    isCollaborative: getValues("fields.isCollaborative"),
                    labId: labId,
                    labMember: JSON.stringify(getValues("fields.labMember")),
                    licensingType: getValues("fields.licensingType.value"),
                    listerId: user.uid,
                    pricingType: getValues("fields.pricingType"),
                    title: getValues("fields.title"),
                    type: category,
                };

                Object.entries(mandatoryFields).forEach(([key, value]) => {
                    formData.append(key, value);
                });
            };

            const addOptionalFields = (formData) => {
                const optionalFields = {
                    address: getValues("fields.address"),
                    retrievalMethods: getValues("fields.retrievalMethods"),
                    relevantLink: getValues("fields.relevantLink"),
                    location: getValues("fields.location"),
                    delivery: getValues("fields.delivery"),
                    directBuying: getValues("fields.directBuying"),
                };

                // Only add fields that exist
                Object.entries(optionalFields).forEach(([key, value]) => {
                    if (value) {
                        formData.append(key, value);
                    }
                });

                const pricingType = getValues("fields.pricingType");

                // Use the captured price and units values from refs
                let finalPriceValue =
                    priceValueRef.current !== ""
                        ? parseFloat(priceValueRef.current)
                        : "";

                // If pricing type is CUSTOM and no price is entered, default to 0
                if (pricingType === "CUSTOM" && finalPriceValue === "") {
                    finalPriceValue = 0;
                }

                // Create the price data object using the captured values
                const priceData = {
                    value: finalPriceValue !== "" ? finalPriceValue : 0,
                    units: priceUnitsRef.current || "unit",
                };

                formData.append("price", JSON.stringify(priceData));

                // Only add quantity if it exists
                const quantity = getValues("fields.quantity");
                if (quantity) {
                    formData.append("quantity", quantity);
                }

                // Only add unavailabilities if they exist
                const unavailDates = getValues(
                    "fields.unavailabilities"
                )?.dates;
                const unavailDays = getValues("fields.unavailabilities")?.days;
                if (
                    (unavailDates && unavailDates.length > 0) ||
                    (unavailDays && unavailDays.length > 0)
                ) {
                    formData.append(
                        "unavailabilities",
                        JSON.stringify({
                            dates: unavailDates || [],
                            days: unavailDays || [],
                        })
                    );
                }

                const validImages = getValues("fields.imgs").filter(
                    (img) => img.file
                );
                validImages.forEach((img, index) => {
                    formData.append("images", img.file);
                });
            };

            // Create FormData object
            const formData = new FormData();
            addMandatoryFields(formData);
            addOptionalFields(formData);

            // Use the fetchMultipart function
            await fetchMultipart("/listings", formData);

            // If we reach here, the request was successful (no error was thrown)
            showSuccessToast("Listing created successfully!");
            const currentPath = location.pathname;
            const newPath = currentPath.split("/").slice(0, -1).join("/");
            navigate(newPath);
        } catch (error) {
            console.error("Error creating listing:", error);
            showErrorToast("Error creating listing. Please try again.");
        }
    };

    const { mutate: createListingMutation } = useMutation({
        mutationFn: handleSendData,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["lab", labId, "listings"],
            });
        },
    });

    return (
        <div className="container-create-listing">
            <form
                onSubmit={(e) => {
                    // Prevent form submission for all categories
                    e.preventDefault();
                }}
            >
                <ChooseListingCategory />
                <ListingCategory />
                <div
                    className="button-container-listing"
                    style={{
                        justifyContent: "space-between",
                    }}
                >
                    {step > 1 && category !== "import" && (
                        <button
                            className="button-listing"
                            type="button"
                            onClick={() => {
                                reset({
                                    fields: {
                                        imgs: [
                                            ...Array(3).fill({
                                                url: null,
                                                file: null,
                                            }),
                                        ],
                                    },
                                });
                                updateStep(step - 1);
                            }}
                        >
                            Back
                        </button>
                    )}
                    {step === 2 && category !== "import" && (
                        <button
                            className="button-listing"
                            type="button"
                            onClick={async () => {
                                // Capture price value directly from the input field
                                const priceInput = document.querySelector(
                                    '[data-input-type="price-value"]'
                                );
                                if (priceInput) {
                                    priceValueRef.current =
                                        priceInput.value || "";
                                } else {
                                    // Fallback to name selector
                                    const fallbackInput =
                                        document.querySelector(
                                            'input[name="fields.price.value"]'
                                        );
                                    if (fallbackInput) {
                                        priceValueRef.current =
                                            fallbackInput.value || "";
                                    }
                                }

                                // Capture price units from form state
                                const unitsValue =
                                    getValues("fields.price.units");
                                const customUnitsValue = getValues(
                                    "fields.price.customUnits"
                                );

                                if (unitsValue) {
                                    priceUnitsRef.current =
                                        unitsValue === "other" &&
                                        customUnitsValue
                                            ? customUnitsValue
                                            : unitsValue;
                                }

                                if (await trigger()) {
                                    createListingMutation();
                                } else {
                                    console.error(
                                        "Form validation errors:",
                                        errors
                                    );

                                    // Scroll to the first validation error
                                    const firstErrorField =
                                        document.querySelector(
                                            '.input-error, [aria-invalid="true"]'
                                        );
                                    if (firstErrorField) {
                                        // Find the closest section or field container to scroll to
                                        const errorSection =
                                            firstErrorField.closest(
                                                ".create-listing-section"
                                            ) ||
                                            firstErrorField.closest(
                                                ".create-listing-field"
                                            ) ||
                                            firstErrorField;

                                        errorSection.scrollIntoView({
                                            behavior: "smooth",
                                            block: "center",
                                        });
                                    }
                                }
                            }}
                        >
                            Create Listing
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
};

const CreateListing = () => {
    const methods = useForm();

    useEffect(() => {
        const resetForm = async () => {
            methods.reset({
                fields: {
                    imgs: [...Array(3).fill({})],
                },
            });
        };
        resetForm();
    }, [methods]);
    return (
        <CreateListingContextProvider>
            <FormProvider {...methods}>
                <ListingCreation />
            </FormProvider>
        </CreateListingContextProvider>
    );
};

export default CreateListing;
