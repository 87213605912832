import { createContext, useEffect, useState } from "react";
import { UserAuth } from "./auth-context";
import { fetchGet } from "../services/data-service";
import { getActiveSubscription } from "../services/subscription-service";
import imagePlaceholder from "../assets/image-placeholder.png";

export const AccountSettingsContext = createContext();

export const AccountSettingsProvider = ({ children }) => {
    const [account, setAccount] = useState({
        profile: {
            pictureURL: "",
            profileTags: [],
            description: "",
            projects: [],
        },
        settings: {
            firstName: "",
            lastName: "",
            email: "",
            id: "",
        },
        subscription: {
            status: "inactive",
            tier: null,
            currentPeriodEnd: null,
            cancelAtPeriodEnd: false,
            subscriptionId: null,
            amount: null,
            currency: null,
            interval: null,
            intervalCount: null,
            formattedPrice: null,
            productName: null,
            productDescription: null,
            productMetadata: null,
            productActive: null,
            productId: null,
            startDate: null,
            billingCycleAnchor: null,
            created: null,
            trialEnd: null,
            trialStart: null,
            cancelAt: null,
            isLabMember: false,
            coverageType: null,
            paidBy: null,
            labName: null,
            labId: null,
            piId: null,
            loading: true,
        },
    });
    const { user } = UserAuth();

    useEffect(() => {
        const getData = async () => {
            if (user?.uid) {
                const projects = await fetchGet(
                    `/users/${user.uid}/projects`,
                    true
                );

                // Get subscription data
                console.log("Getting subscription data for user:", user.uid);
                try {
                    const subscriptionData = await getActiveSubscription();
                    console.log("Subscription data:", subscriptionData);

                    setAccount((prev) => ({
                        ...prev,
                        profile: {
                            pictureURL: user.profilePicture || imagePlaceholder,
                            profileTags: user.profileTags || [],
                            description: user.description || "",
                            projects: projects || [],
                        },
                        settings: {
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: user.email,
                            id: user.uid,
                        },
                        subscription: {
                            status: subscriptionData?.status || "inactive",
                            tier: subscriptionData?.tier || null,
                            currentPeriodEnd:
                                subscriptionData?.currentPeriodEnd || null,
                            cancelAtPeriodEnd:
                                subscriptionData?.cancelAtPeriodEnd || false,
                            subscriptionId:
                                subscriptionData?.subscriptionId || null,
                            amount: subscriptionData?.amount || null,
                            currency: subscriptionData?.currency || null,
                            interval: subscriptionData?.interval || null,
                            intervalCount:
                                subscriptionData?.intervalCount || null,
                            formattedPrice:
                                subscriptionData?.formattedPrice || null,
                            productName: subscriptionData?.productName || null,
                            productDescription:
                                subscriptionData?.productDescription || null,
                            productMetadata:
                                subscriptionData?.productMetadata || null,
                            productActive:
                                subscriptionData?.productActive || null,
                            productId: subscriptionData?.productId || null,
                            startDate: subscriptionData?.startDate || null,
                            billingCycleAnchor:
                                subscriptionData?.billingCycleAnchor || null,
                            created: subscriptionData?.created || null,
                            trialEnd: subscriptionData?.trialEnd || null,
                            trialStart: subscriptionData?.trialStart || null,
                            cancelAt: subscriptionData?.cancelAt || null,
                            isLabMember: subscriptionData?.isLabMember || false,
                            coverageType:
                                subscriptionData?.coverageType || null,
                            paidBy: subscriptionData?.paidBy || null,
                            labName: subscriptionData?.labName || null,
                            labId: subscriptionData?.labId || null,
                            piId: subscriptionData?.piId || null,
                            loading: false,
                        },
                    }));
                } catch (error) {
                    console.error("Error getting subscription data:", error);
                    setAccount((prev) => ({
                        ...prev,
                        profile: {
                            pictureURL: user.profilePicture || imagePlaceholder,
                            profileTags: user.profileTags || [],
                            description: user.description || "",
                            projects: projects || [],
                        },
                        settings: {
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: user.email,
                            id: user.uid,
                        },
                        subscription: {
                            status: "inactive",
                            tier: null,
                            currentPeriodEnd: null,
                            cancelAtPeriodEnd: false,
                            subscriptionId: null,
                            amount: null,
                            currency: null,
                            interval: null,
                            intervalCount: null,
                            formattedPrice: null,
                            productName: null,
                            productDescription: null,
                            productMetadata: null,
                            productActive: null,
                            productId: null,
                            startDate: null,
                            billingCycleAnchor: null,
                            created: null,
                            trialEnd: null,
                            trialStart: null,
                            cancelAt: null,
                            isLabMember: false,
                            coverageType: null,
                            paidBy: null,
                            labName: null,
                            labId: null,
                            piId: null,
                            loading: false,
                        },
                    }));
                }
                document.title = `${user.firstName} ${user.lastName} | LabGiant`;
            }
        };
        getData();
    }, [user]);
    return (
        <AccountSettingsContext.Provider value={{ account, setAccount }}>
            {children}
        </AccountSettingsContext.Provider>
    );
};
