/**
 * Determines the appropriate emoji for an entity based on its type and name
 * @param {string} type - The entity type (lab, listing, equipment, user, etc.)
 * @param {string} name - The name of the entity
 * @returns {string} - The emoji character
 */
export const getEmojiForEntity = (type, name) => {
    // Convert name to lowercase for case-insensitive matching
    const nameLower = name?.toLowerCase() || "";

    // Default emojis based on type
    const defaultEmojis = {
        lab: "📍", // Changed from microscope to red pin
        listing: "📦",
        "specific-item": "📦",
        service: "🛠️",
        "digital-good": "💾",
        equipment: "⚙️", // Changed from wrench to gear
        user: "👤",
    };

    // For labs, always return the red pin emoji
    if (type === "lab") {
        return "📍"; // Always use red pin for labs
    }

    // Pattern matching for equipment
    else if (type === "equipment") {
        // Imaging and optical equipment
        if (/microscope|micros|microsc/i.test(nameLower)) return "🔬"; // Changed to microscope emoji
        if (/telescope|lens|optic/i.test(nameLower)) return "🔭";
        if (/spectr|photo/i.test(nameLower)) return "📸";
        if (/laser|light|illuminat/i.test(nameLower)) return "🔆";
        if (/x-ray|xray|radiograph/i.test(nameLower)) return "📡";
        if (/mri|magnetic resonance/i.test(nameLower)) return "🧲";

        // Lab processing equipment
        if (/centrifuge|spinner/i.test(nameLower)) return "🌀";
        if (/mixer|blend|stir/i.test(nameLower)) return "🔄";
        if (/shaker|agitat/i.test(nameLower)) return "♻️";
        if (/homogeniz|disrupt/i.test(nameLower)) return "💥";
        if (/grind|mill|crush/i.test(nameLower)) return "⚒️";
        if (/filter|separat|purif/i.test(nameLower)) return "🧹";

        // Analytical equipment
        if (/spectro|chroma|mass spec/i.test(nameLower)) return "📊";
        if (/nmr|nuclear magnetic/i.test(nameLower)) return "🧪";
        if (/gc|gas chroma/i.test(nameLower)) return "📈";
        if (/hplc|liquid chroma/i.test(nameLower)) return "💧";
        if (/electro|voltage|current/i.test(nameLower)) return "⚡";
        if (/meter|measure|scale|balance/i.test(nameLower)) return "⚖️";
        if (/counter|detect/i.test(nameLower)) return "🔢";
        if (/sensor|probe/i.test(nameLower)) return "📡";
        if (/analyzer|analysis/i.test(nameLower)) return "🔬";

        // Temperature equipment
        if (/incubat|oven|furnace|heat/i.test(nameLower)) return "🔥";
        if (/freez|cold|cryo|refriger/i.test(nameLower)) return "❄️";
        if (/therm|temperature|climate/i.test(nameLower)) return "🌡️";
        if (/bath|water/i.test(nameLower)) return "💦";
        if (/dry|dehydrat/i.test(nameLower)) return "☀️";

        // Computing and automation
        if (/comput|server|process/i.test(nameLower)) return "💻";
        if (/printer|3d print/i.test(nameLower)) return "🖨️";
        if (/robot|automat/i.test(nameLower)) return "🤖";
        if (/control|monitor/i.test(nameLower)) return "🎛️";
        if (/software|program/i.test(nameLower)) return "💿";
    }

    // Return default emoji if no pattern matches
    return defaultEmojis[type] || "📌";
};

/**
 * Helper function to escape HTML characters
 * @param {string} str - Input string to escape
 * @returns {string} - Escaped string
 */
export const escapeHtml = (str) => {
    if (typeof str !== "string") return str;
    return str
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
};

/**
 * Process Markdown text and convert it to HTML
 * @param {string} text - Markdown text to process
 * @returns {string} - HTML-formatted text
 */
export const processMarkdown = (text) => {
    if (!text || text.startsWith("<")) return text;

    let html = text;

    // Headers
    html = html
        .replace(/^### (.*?)$/gm, "<h3>$1</h3>")
        .replace(/^## (.*?)$/gm, "<h2>$1</h2>")
        .replace(/^# (.*?)$/gm, "<h1>$1</h1>");

    // Handle bold and italic with both * and _ styles
    // -- Bold (** and __)
    html = html
        .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
        .replace(/__(.*?)__/g, "<strong>$1</strong>");

    // -- Italic (* and _)
    // First, handle asterisk italic
    html = html.replace(/\*([^\s*][^*]*?[^\s*])\*/g, "<em>$1</em>");
    html = html.replace(/\*([^\s*])\*/g, "<em>$1</em>");

    // Then, handle underscore italic (with lookbehind/lookahead for boundaries)
    // Need to be careful with handling underscores in words
    html = html.replace(
        /(\s|^)_([^_\s][^_]*?[^_\s])_(\s|$|[.,;:!?])/g,
        "$1<em>$2</em>$3"
    );
    html = html.replace(/(\s|^)_([^_\s])_(\s|$|[.,;:!?])/g, "$1<em>$2</em>$3");

    // Add specific patterns for common cases
    html = html.replace(/_([\w\s\-.,;:!?'"\(\)\/&]+)_/g, (match, content) => {
        // Skip if it has HTML tags already
        if (content.includes("<") || content.includes(">")) return match;

        // Skip if it looks like a filename, variable, or has internal underscores
        if (content.includes("_") || /^[a-z0-9_]+$/i.test(content))
            return match;

        // If it looks like a phrase that should be emphasized, convert it
        if (content.includes(" ") && content.length > 3) {
            return `<em>${content}</em>`;
        }

        return match;
    });

    // Lists
    html = html
        .replace(/^\s*[\*\-]\s+(.*?)$/gm, "<li>$1</li>")
        .replace(/^\s*(\d+)\.\s+(.*?)$/gm, '<li value="$1">$2</li>');

    // Group consecutive list items
    html = html.replace(
        /(<li[^>]*>.*?<\/li>)(?:\s*\n\s*)?(<li[^>]*>)/g,
        "$1$2"
    );
    html = html.replace(
        /(<li value="[^"]*">.*?<\/li>(?:\s*\n\s*)?)+/g,
        "<ol>$&</ol>"
    );
    html = html.replace(/(<li>.*?<\/li>(?:\s*\n\s*)?)+/g, "<ul>$&</ul>");

    // Code blocks
    html = html.replace(/```([\s\S]*?)```/g, "<pre><code>$1</code></pre>");

    // Inline code
    html = html.replace(/`([^`]+)`/g, "<code>$1</code>");

    // Blockquotes
    html = html.replace(/^\s*>\s+(.*?)$/gm, "<blockquote>$1</blockquote>");

    // Tables - handle pipe-based tables
    if (html.includes("|")) {
        // Extract potential tables (lines with | that form a group)
        const tableRegex = /((?:^|\n)(?:[^\n]*?\|[^\n]*?(?:\n|$)){2,})/g;

        html = html.replace(tableRegex, (tableText) => {
            if (tableText.includes("<table") || !tableText.includes("|")) {
                return tableText;
            }

            // Split into rows
            const rows = tableText.trim().split("\n");

            // Check for header separator
            const hasHeaderSeparator =
                rows.length > 1 &&
                /^\s*[\|]?[\s-:]*\|[\s-:]*(?:\|[\s-:]*)*$/.test(rows[1]);

            let headerRow = hasHeaderSeparator ? rows[0] : null;
            const dataRows = rows
                .slice(hasHeaderSeparator ? 2 : 0)
                .filter(
                    (row) =>
                        row.includes("|") &&
                        !/^\s*[\|]?[\s-:]*\|[\s-:]*(?:\|[\s-:]*)*$/.test(row)
                );

            if (dataRows.length === 0) return tableText;

            // Build table HTML
            let tableHtml = "<table>";

            // Add header if present
            if (headerRow) {
                const headerCells = headerRow
                    .trim()
                    .split("|")
                    .map((cell) => cell.trim())
                    .filter((cell) => cell !== "");

                if (headerCells.length > 0) {
                    tableHtml += "<thead><tr>";
                    headerCells.forEach((cell) => {
                        tableHtml += `<th>${cell}</th>`;
                    });
                    tableHtml += "</tr></thead>";
                }
            }

            // Add data rows
            tableHtml += "<tbody>";
            dataRows.forEach((row) => {
                if (
                    /^\s*[\|]?[\s-:]*\|[\s-:]*(?:\|[\s-:]*)*$/.test(row) ||
                    !row.includes("|")
                ) {
                    return;
                }

                const cells = row
                    .trim()
                    .split("|")
                    .map((cell) => cell.trim())
                    .filter((cell) => cell !== "");

                if (cells.length > 0) {
                    tableHtml += "<tr>";
                    cells.forEach((cell) => {
                        tableHtml += `<td>${cell}</td>`;
                    });
                    tableHtml += "</tr>";
                }
            });

            tableHtml += "</tbody></table>";
            return tableHtml;
        });
    }

    // Links
    html = html.replace(
        /\[([^\]]+)\]\(([^)]+)\)/g,
        '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>'
    );

    // Line breaks and paragraphs
    html = html.replace(/\n\n+/g, "</p><p>");
    html = html.replace(/\n/g, "<br>");

    // Wrap in paragraph tags if needed
    if (!html.match(/^<(p|h[1-6]|ul|ol|blockquote|pre|table)/i)) {
        html = `<p>${html}</p>`;
    }

    // Fix any internal closing tags that were mangled
    html = html.replace(
        /<(\w+)>[^<>]*?<\/(?!\1)(\w+)>/g,
        (match, open, close) => {
            if (open.length > 1 && close.length > 1) {
                return match.replace(`</${close}>`, `</${open}>`);
            }
            return match;
        }
    );

    return html;
};

/**
 * Parse message text and make entity references clickable
 * @param {string} text - The message text to parse
 * @param {Array} referencedEntities - List of referenced entities (optional)
 * @param {boolean} isLabReference - Whether this is a lab reference (optional)
 * @returns {string} - HTML-formatted text with clickable entities
 */
export const parseMessageWithClickableEntities = (
    text,
    referencedEntities,
    isLabReference = false
) => {
    if (!text) return "";

    // Special case for welcome/error messages with pre-formatted HTML
    if (
        text.startsWith("<strong><a href=") ||
        text.startsWith("Hi there! I'm your Lab Assistant.") ||
        text.includes("please <strong><a href=") ||
        text.includes("free message limit")
    ) {
        return text;
    }

    // 1. Pre-processing: Handle special entity links with standard markdown format
    let processedText = text;

    // Convert [[lab:id:name]] format to markdown links with data attributes
    processedText = processedText.replace(
        /\[\[(lab|listing|equipment|user|specific-item|service|digital-good):([^:]+):([^\]]+)\]\]/g,
        (match, type, id, name) => {
            let url = "";
            let emoji = getEmojiForEntity(type, name);

            switch (type) {
                case "lab":
                    url = `${
                        process.env.REACT_APP_FRONTEND_URL || ""
                    }/lab/${id}/overview`;
                    break;
                case "listing":
                case "specific-item":
                case "service":
                case "digital-good":
                    url = `${
                        process.env.REACT_APP_FRONTEND_URL || ""
                    }/listing/${id}`;
                    break;
                case "equipment":
                    url = `${
                        process.env.REACT_APP_FRONTEND_URL || ""
                    }/listingrental/${id}`;
                    break;
                case "user":
                    url = `${
                        process.env.REACT_APP_FRONTEND_URL || ""
                    }/profile/${id}`;
                    break;
                default:
                    break;
            }

            return `<a href="${url}" class="lab-assistant-chat-entity-link ${type}-link" data-emoji="${emoji}" data-id="${id}" data-type="${type}" target="_blank" rel="noopener noreferrer">${name}</a>`;
        }
    );

    // 2. Process markdown with sanitization
    let htmlOutput = processedText;

    // Hide raw IDs
    htmlOutput = htmlOutput.replace(/\(ID:\s*([a-zA-Z0-9]{20,})\)/g, "()");
    htmlOutput = htmlOutput.replace(/ID:\s*([a-zA-Z0-9]{20,})/g, "");

    // Use the markdown processor
    htmlOutput = processMarkdown(htmlOutput);

    return htmlOutput;
};
