import { useState, useEffect } from "react";
import {
    Groups as GroupsIcon,
    GridView as GridViewIcon,
    List as ListIcon,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { TypeBadge } from "../helpers/ListingHelpers";
import { Loading } from "../components";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "@tanstack/react-query";
import { fetchListings, ListingCacheParams } from "../services/listing-api";
import { CircularProgress } from "@mui/material";
import { Helmet } from "react-helmet";
import "../styles/BrowseListings.css";
import "../styles/LabProfileTabs/LabListings.css";

const stripHtmlTags = (html) => {
    if (!html) return "";
    // First create a DOM element from the HTML string
    const doc = new DOMParser().parseFromString(html, "text/html");
    // Return just the text content
    return doc.body.textContent || "";
};

const BrowseListings = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const searchQuery = queryParams.get("query");
    const { ref, inView } = useInView();

    const {
        data: listings,
        isLoading: listingsLoading,
        fetchNextPage: fetchNextPaginatedListings,
        isFetchingNextPage,
    } = useInfiniteQuery({
        queryKey: ["listings", searchQuery],
        queryFn: ({ pageParam }) =>
            fetchListings({
                pageParam,
                query: searchQuery,
            }),
        ...ListingCacheParams,
        getNextPageParam: (lastPage) =>
            lastPage.hasMore ? lastPage.lastVisible : null,
    });
    const [viewMode, setViewMode] = useState("grid");
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [previousViewMode, setPreviousViewMode] = useState(null);

    useEffect(() => {
        document.title = "Technologies | LabGiant";
        document
            .querySelector('meta[name="description"]')
            .setAttribute(
                "content",
                "Explore cutting-edge technologies in research, development, and innovation."
            );
    }, []);

    // Update resize listener
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);

            if (window.innerWidth <= 765) {
                // Store current view mode before switching to grid
                if (viewMode === "list") {
                    setPreviousViewMode("list");
                }
                setViewMode("grid");
            } else if (window.innerWidth > 765 && previousViewMode === "list") {
                // Restore list view when screen becomes wide enough
                setViewMode("list");
                setPreviousViewMode(null);
            }
        };

        window.addEventListener("resize", handleResize);

        // Initial check
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, [viewMode, previousViewMode]); // Add dependencies

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                fetchNextPaginatedListings();
            }, 500);
        }
    }, [inView]);

    useEffect(() => {
        // Scroll to the top when the search query changes
        window.scrollTo(0, 0);
    }, [searchQuery]);

    const renderListings = () => {
        if (viewMode === "grid") {
            return (
                <div className="lab-listings-grid">
                    {listings.pages.map((page) =>
                        page.documents.map((listing, index) => (
                            <div
                                key={listing.id}
                                className="lab-listings-listing"
                                onClick={() => {
                                    if (
                                        listing.type === "equipment-rental" ||
                                        listing.type === "training-expertise"
                                    ) {
                                        navigate(
                                            `/listingrental/${listing.id}`
                                        );
                                    } else {
                                        navigate(`/listing/${listing.id}`);
                                    }
                                }}
                            >
                                <img
                                    src={listing.img}
                                    className="lab-listings-listing-img"
                                    alt=""
                                />
                                <div className="lab-listings-listing-info">
                                    <div className="lab-listings-listing-name">
                                        {listing.title}
                                    </div>
                                    {listing.pricingType === "CUSTOM" ? (
                                        <div className="lab-listings-listing-price">
                                            Contact for pricing
                                        </div>
                                    ) : listing.type === "digital-good" ? (
                                        <div className="lab-listings-listing-price">
                                            Free access
                                        </div>
                                    ) : !listing.price?.value ? (
                                        <div className="lab-listings-listing-price">
                                            Free access
                                        </div>
                                    ) : (
                                        <div className="lab-listings-listing-price">
                                            <span style={{ fontWeight: 600 }}>
                                                ${listing.price.value}
                                            </span>{" "}
                                            <span style={{ fontSize: "16px" }}>
                                                per{" "}
                                                {listing.price.customUnits
                                                    ? listing.price.customUnits
                                                    : listing.price.units ||
                                                      "unit"}
                                            </span>
                                        </div>
                                    )}
                                    <div className="lab-listings-listing-description">
                                        {stripHtmlTags(
                                            listing.description
                                        ).substring(0, 300)}
                                        {stripHtmlTags(listing.description)
                                            .length > 300
                                            ? "..."
                                            : ""}
                                    </div>
                                    <div className="lab-listings-listing-footer">
                                        <div className="lab-listings-listing-type">
                                            <TypeBadge
                                                type={listing.type}
                                                style={{ fontSize: "16px" }}
                                            />
                                        </div>
                                        <div className="lab-listings-listing-lister">
                                            <GroupsIcon
                                                style={{ fontSize: "16px" }}
                                            />
                                            <span className="lab-listings-listing-lister-name">
                                                {listing.labName}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            );
        }

        return (
            <div className="lab-listings-list">
                {listings.pages.map((page) =>
                    page.documents.map((listing, index) => (
                        <div
                            key={listing.id}
                            className="lab-listings-list-item"
                            onClick={() => {
                                if (
                                    listing.type === "equipment-rental" ||
                                    listing.type === "training-expertise"
                                ) {
                                    navigate(`/listingrental/${listing.id}`);
                                } else {
                                    navigate(`/listing/${listing.id}`);
                                }
                            }}
                        >
                            <img
                                src={listing.img}
                                className="lab-listings-listing-img"
                                alt=""
                            />
                            <div className="lab-listings-listing-info">
                                <div className="lab-listings-listing-header">
                                    <div className="lab-listings-listing-name">
                                        {listing.title}
                                    </div>
                                    {listing.pricingType === "CUSTOM" ? (
                                        <div className="lab-listings-listing-price">
                                            Contact for pricing
                                        </div>
                                    ) : listing.type === "digital-good" ? (
                                        <div className="lab-listings-listing-price">
                                            Free access
                                        </div>
                                    ) : !listing.price?.value ? (
                                        <div className="lab-listings-listing-price">
                                            Free access
                                        </div>
                                    ) : (
                                        <div className="lab-listings-listing-price">
                                            <span style={{ fontWeight: 600 }}>
                                                ${listing.price.value}
                                            </span>{" "}
                                            <span style={{ fontSize: "16px" }}>
                                                per{" "}
                                                {listing.price.customUnits
                                                    ? listing.price.customUnits
                                                    : listing.price.units ||
                                                      "unit"}
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className="lab-listings-listing-description">
                                    {stripHtmlTags(
                                        listing.description
                                    ).substring(0, 500)}
                                    {stripHtmlTags(listing.description).length >
                                    500
                                        ? "..."
                                        : ""}
                                </div>
                                <div className="lab-listings-listing-footer">
                                    <div className="lab-listings-listing-type">
                                        <TypeBadge
                                            type={listing.type}
                                            style={{ fontSize: "16px" }}
                                        />
                                    </div>
                                    <div className="lab-listings-listing-lister">
                                        <GroupsIcon
                                            style={{ fontSize: "16px" }}
                                        />
                                        <span className="lab-listings-listing-lister-name">
                                            {listing.labName}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        );
    };

    if (listingsLoading) {
        return <Loading />;
    }

    return (
        <>
            <Helmet>
                <link
                    rel="canonical"
                    href={`${process.env.REACT_APP_FRONTEND_URL}/listings`}
                />
            </Helmet>
            <div className="browse-listings-container">
                <div className="browse-listings-container-content">
                    {!searchQuery && (
                        <>
                            <h1>Technologies</h1>
                            <h2>
                                With over <strong>1,000 listings</strong> across
                                fields like biotechnology, environmental
                                science, material engineering, physics, computer
                                science, and medical research, our platform
                                makes it{" "}
                                <span style={{ fontWeight: 600 }}>
                                    easier to access the tools you need, reduce
                                    unnecessary purchases, and design better
                                    experiments.
                                </span>
                            </h2>
                        </>
                    )}
                    <div className="lab-listings-content">
                        {searchQuery && (
                            <div className="browse-listings-header">
                                <h2>
                                    Search results for{" "}
                                    <span style={{ fontWeight: 600 }}>
                                        "{searchQuery}"
                                    </span>
                                </h2>
                            </div>
                        )}
                        {/* <Facets isOpen={isOpen} /> */}
                        <div className="view-toggle">
                            <button
                                className={`view-toggle-button ${
                                    viewMode === "grid" ? "active" : ""
                                }`}
                                onClick={() => setViewMode("grid")}
                            >
                                <GridViewIcon style={{ marginRight: "4px" }} />{" "}
                                Grid
                            </button>
                            <button
                                className={`view-toggle-button ${
                                    viewMode === "list" ? "active" : ""
                                }`}
                                onClick={() =>
                                    windowWidth > 765 && setViewMode("list")
                                }
                            >
                                <ListIcon style={{ marginRight: "4px" }} /> List
                            </button>
                        </div>
                        <div className="lab-listings-content">
                            {renderListings()}
                        </div>
                    </div>
                </div>
                <div ref={ref} className="browse-listings-pagination">
                    {isFetchingNextPage && (
                        <div className="browse-listings-pagination-loading">
                            <CircularProgress
                                size={24}
                                style={{
                                    color: "var(--primary-color)",
                                    border: "10px",
                                }}
                            />
                            <span>Loading listings...</span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default BrowseListings;
