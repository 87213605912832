import React, { useContext, useState } from "react";
import { UserAuth } from "../../context/auth-context";
import { fetchPut, fetchPost } from "../../services/data-service";
import { AccountSettingsContext } from "../../context/account-settings-context";
import { Switch } from "../../components";
import {
    Box,
    Typography,
    Button,
    Chip,
    CircularProgress,
    Card,
    CardContent,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import "../../styles/AccountSettings.css";

const MySettings = () => {
    const { user } = UserAuth();
    const { account, setAccount } = useContext(AccountSettingsContext);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);

    const handleUserInfoChange = (e) => {
        const { name, value } = e.target;
        setAccount((prev) => ({
            ...prev,
            settings: { ...prev.settings, [name]: value },
        }));
    };

    const [notifications, setNotifications] = useState({
        receivedMessageNotification: false,
        receivedMessageEmail: false,
        newOrderNotification: false,
        newOrderEmail: false,
        joinLabRequestNotification: false,
        joinLabRequestEmail: false,
        newTechnologyNotification: false,
        newTechnologyEmail: false,
        newPublicationNotification: false,
        newPublicationEmail: false,
    });

    const handleNotificationChange = (setting) => {
        setNotifications((prev) => ({
            ...prev,
            [setting]: !prev[setting],
        }));
    };

    const saveUserData = async () => {
        const { firstName, lastName, email, id } = account.settings;
        if (
            user.uid === id &&
            user.email === email &&
            user.firstName === firstName &&
            user.lastName === lastName
        )
            return;

        await fetchPut("/users", {
            firstName,
            lastName,
            email,
            id,
        });
    };

    // Format date to readable format
    const formatDate = (dateString) => {
        if (!dateString) return "N/A";

        try {
            let date;

            // Handle Firestore Timestamp object with _seconds and _nanoseconds
            if (dateString._seconds) {
                date = new Date(dateString._seconds * 1000);
            }
            // Handle Firestore Timestamp with toDate method
            else if (dateString.toDate) {
                date = dateString.toDate();
            }
            // Handle Firebase formatted string like "April 16, 2025 at 8:23:08 PM UTC-4"
            else if (
                typeof dateString === "string" &&
                dateString.includes(" at ")
            ) {
                // Remove the "at" and timezone information for better parsing
                const cleanedDateString = dateString
                    .replace(" at ", " ")
                    .split(" UTC")[0];
                date = new Date(cleanedDateString);
            }
            // Handle regular date string or timestamp number
            else {
                date = new Date(dateString);
            }

            // Check if date is valid
            if (isNaN(date.getTime())) {
                console.error("Invalid date:", dateString);
                return "N/A";
            }

            return date.toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
            });
        } catch (error) {
            console.error("Error formatting date:", error, dateString);
            return "N/A";
        }
    };

    // Handle subscription cancellation
    const handleCancelSubscription = async () => {
        setActionLoading(true);
        try {
            const response = await fetchPost("/subscriptions/cancel", {});

            if (response.success) {
                setAccount((prev) => ({
                    ...prev,
                    subscription: {
                        ...prev.subscription,
                        cancelAtPeriodEnd: true,
                    },
                }));
            }
        } catch (error) {
            console.error("Error canceling subscription:", error);
        } finally {
            setActionLoading(false);
            setCancelDialogOpen(false);
        }
    };

    // Handle subscription resumption
    const handleResumeSubscription = async () => {
        setActionLoading(true);
        try {
            const response = await fetchPost("/subscriptions/resume", {});

            if (response.success) {
                setAccount((prev) => ({
                    ...prev,
                    subscription: {
                        ...prev.subscription,
                        cancelAtPeriodEnd: false,
                    },
                }));
            }
        } catch (error) {
            console.error("Error resuming subscription:", error);
        } finally {
            setActionLoading(false);
        }
    };

    // Get subscription status chip
    const getStatusChip = () => {
        const { status, cancelAtPeriodEnd } = account.subscription;

        if (status === "active" && !cancelAtPeriodEnd) {
            return (
                <Box
                    className="subscription-status-chip active"
                    sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        borderRadius: "16px",
                        padding: "4px 12px 4px 10px",
                        background:
                            "linear-gradient(90deg, rgba(46,175,125,0.2) 0%, rgba(46,175,125,0.1) 100%)",
                        border: "1px solid rgba(46,175,125,0.4)",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
                        transition: "all 0.2s ease",
                    }}
                >
                    <CheckCircleIcon
                        sx={{
                            color: "#2eaf7d",
                            marginRight: "6px",
                            fontSize: "1.1rem",
                        }}
                    />
                    <Typography
                        sx={{
                            fontSize: "0.875rem",
                            fontWeight: 600,
                            color: "#2eaf7d",
                            letterSpacing: "0.03em",
                        }}
                    >
                        Active
                    </Typography>
                </Box>
            );
        } else if (status === "active" && cancelAtPeriodEnd) {
            return (
                <Box
                    className="subscription-status-chip canceling"
                    sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        borderRadius: "16px",
                        padding: "4px 12px 4px 10px",
                        background:
                            "linear-gradient(90deg, rgba(255,152,0,0.2) 0%, rgba(255,152,0,0.1) 100%)",
                        border: "1px solid rgba(255,152,0,0.4)",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
                        transition: "all 0.2s ease",
                    }}
                >
                    <WarningIcon
                        sx={{
                            color: "#ff9800",
                            marginRight: "6px",
                            fontSize: "1.1rem",
                        }}
                    />
                    <Typography
                        sx={{
                            fontSize: "0.875rem",
                            fontWeight: 600,
                            color: "#ff9800",
                            letterSpacing: "0.03em",
                        }}
                    >
                        Canceling
                    </Typography>
                </Box>
            );
        } else {
            return (
                <Box
                    className="subscription-status-chip inactive"
                    sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        borderRadius: "16px",
                        padding: "4px 12px 4px 10px",
                        background:
                            "linear-gradient(90deg, rgba(211,47,47,0.2) 0%, rgba(211,47,47,0.1) 100%)",
                        border: "1px solid rgba(211,47,47,0.4)",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
                        transition: "all 0.2s ease",
                    }}
                >
                    <CancelIcon
                        sx={{
                            color: "#d32f2f",
                            marginRight: "6px",
                            fontSize: "1.1rem",
                        }}
                    />
                    <Typography
                        sx={{
                            fontSize: "0.875rem",
                            fontWeight: 600,
                            color: "#d32f2f",
                            letterSpacing: "0.03em",
                        }}
                    >
                        Inactive
                    </Typography>
                </Box>
            );
        }
    };

    return (
        <div className="account-settings-account">
            {/* Subscription Section */}
            <div className="account-settings-section">
                <h2 className="account-settings-heading">Your Subscription</h2>

                {account.subscription.loading ? (
                    <Box
                        sx={{ display: "flex", justifyContent: "center", p: 3 }}
                    >
                        <CircularProgress />
                    </Box>
                ) : account.subscription.status === "inactive" ? (
                    <div className="subscription-inactive-message">
                        <Typography variant="h6" gutterBottom>
                            No Active Subscription
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            paragraph
                        >
                            You don't have an active subscription. Subscribe to
                            access premium features.
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            href="/pricing"
                        >
                            View Plans
                        </Button>
                    </div>
                ) : (
                    <>
                        <div className="subscription-status-container">
                            {getStatusChip()}
                            <Typography variant="h6">
                                {account.subscription.productName ||
                                    account.subscription.tier ||
                                    "Individual"}{" "}
                                Plan
                            </Typography>
                        </div>

                        <div className="subscription-details">
                            <div className="subscription-details-item">
                                <div className="subscription-details-label">
                                    Plan Type:
                                </div>
                                <div>
                                    <strong>
                                        {account.subscription.productName ||
                                            account.subscription.tier ||
                                            "Individual"}
                                    </strong>
                                </div>
                            </div>

                            {/* Show lab coverage information when applicable */}
                            {account.subscription.coverageType === "lab" && (
                                <div className="subscription-details-item">
                                    <div className="subscription-details-label">
                                        Coverage:
                                    </div>
                                    <div style={{ color: "#2eaf7d" }}>
                                        <strong>
                                            Paid by your lab
                                            {account.subscription.labName &&
                                                ` (${account.subscription.labName})`}
                                        </strong>
                                    </div>
                                </div>
                            )}

                            {account.subscription.formattedPrice &&
                            !account.subscription.coverageType ? (
                                <div className="subscription-details-item">
                                    <div className="subscription-details-label">
                                        Price:
                                    </div>
                                    <div>
                                        <strong>
                                            {
                                                account.subscription
                                                    .formattedPrice
                                            }{" "}
                                            /{" "}
                                            {account.subscription
                                                .intervalCount > 1
                                                ? account.subscription
                                                      .intervalCount
                                                : ""}
                                            {account.subscription.interval ||
                                                "month"}
                                            {account.subscription
                                                .intervalCount > 1
                                                ? "s"
                                                : ""}
                                        </strong>
                                    </div>
                                </div>
                            ) : (
                                account.subscription.amount &&
                                !account.subscription.coverageType && (
                                    <div className="subscription-details-item">
                                        <div className="subscription-details-label">
                                            Price:
                                        </div>
                                        <div>
                                            <strong>
                                                {account.subscription
                                                    .currency === "usd"
                                                    ? "$"
                                                    : ""}
                                                {account.subscription.amount}
                                                {account.subscription
                                                    .currency !== "usd"
                                                    ? ` ${account.subscription.currency.toUpperCase()}`
                                                    : ""}{" "}
                                                /{" "}
                                                {account.subscription
                                                    .intervalCount > 1
                                                    ? account.subscription
                                                          .intervalCount
                                                    : ""}
                                                {account.subscription
                                                    .interval || "month"}
                                                {account.subscription
                                                    .intervalCount > 1
                                                    ? "s"
                                                    : ""}
                                            </strong>
                                        </div>
                                    </div>
                                )
                            )}

                            {/* For lab-covered users, show that subscription is free */}
                            {account.subscription.coverageType === "lab" && (
                                <div className="subscription-details-item">
                                    <div className="subscription-details-label">
                                        Price:
                                    </div>
                                    <div>
                                        <strong>
                                            Free (covered by lab subscription)
                                        </strong>
                                    </div>
                                </div>
                            )}

                            {/* Display any relevant product metadata */}
                            {account.subscription.productMetadata &&
                                account.subscription.productMetadata
                                    .display_features === "true" && (
                                    <div className="subscription-details-item">
                                        <div className="subscription-details-label">
                                            Plan Features:
                                        </div>
                                        <div>
                                            {
                                                account.subscription
                                                    .productMetadata.features
                                            }
                                        </div>
                                    </div>
                                )}

                            <div className="subscription-details-item">
                                <div className="subscription-details-label">
                                    Current Period Ends:
                                </div>
                                <div>
                                    {formatDate(
                                        account.subscription.currentPeriodEnd
                                    )}
                                </div>
                            </div>

                            {account.subscription.startDate && (
                                <div className="subscription-details-item">
                                    <div className="subscription-details-label">
                                        Started On:
                                    </div>
                                    <div>
                                        {formatDate(
                                            account.subscription.startDate
                                        )}
                                    </div>
                                </div>
                            )}

                            {account.subscription.trialEnd && (
                                <div className="subscription-details-item">
                                    <div className="subscription-details-label">
                                        Trial Ends:
                                    </div>
                                    <div>
                                        {formatDate(
                                            account.subscription.trialEnd
                                        )}
                                    </div>
                                </div>
                            )}

                            <div className="subscription-details-item">
                                <div className="subscription-details-label">
                                    Status:
                                </div>
                                <div>
                                    {account.subscription.coverageType ===
                                        "lab" &&
                                    account.subscription.cancelAtPeriodEnd
                                        ? "Your lab coverage will end on the date shown above."
                                        : account.subscription.cancelAtPeriodEnd
                                        ? "Your subscription will end on the date shown above."
                                        : "Your subscription will automatically renew."}
                                </div>
                            </div>
                        </div>

                        <div className="subscription-actions">
                            {/* For lab-covered users who are not the PI, don't show subscription management buttons */}
                            {account.subscription.coverageType === "lab" &&
                            account.subscription.piId !== user.uid ? (
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{ fontStyle: "italic", mt: 1 }}
                                >
                                    Your subscription is managed by your lab.
                                </Typography>
                            ) : (
                                <>
                                    {account.subscription.cancelAtPeriodEnd ? (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleResumeSubscription}
                                            disabled={actionLoading}
                                        >
                                            {actionLoading ? (
                                                <CircularProgress size={24} />
                                            ) : (
                                                "Resume Subscription"
                                            )}
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="outlined"
                                            color="error"
                                            onClick={() =>
                                                setCancelDialogOpen(true)
                                            }
                                            disabled={actionLoading}
                                        >
                                            Cancel Subscription
                                        </Button>
                                    )}
                                    <Button variant="outlined" href="/pricing">
                                        Change Plan
                                    </Button>
                                </>
                            )}
                        </div>

                        {/* Cancel Subscription Dialog */}
                        <Dialog
                            open={cancelDialogOpen}
                            onClose={() => setCancelDialogOpen(false)}
                        >
                            <DialogTitle>Cancel Subscription</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Are you sure you want to cancel your
                                    subscription? You will continue to have
                                    access until the end of your current billing
                                    period on{" "}
                                    {formatDate(
                                        account.subscription.currentPeriodEnd
                                    )}
                                    .
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => setCancelDialogOpen(false)}
                                >
                                    No, Keep My Subscription
                                </Button>
                                <Button
                                    onClick={handleCancelSubscription}
                                    color="error"
                                    disabled={actionLoading}
                                >
                                    {actionLoading ? (
                                        <CircularProgress size={24} />
                                    ) : (
                                        "Yes, Cancel Subscription"
                                    )}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
            </div>

            <div className="account-settings-section">
                <h2 className="account-settings-heading">About You</h2>
                <div className="account-settings-form">
                    <div className="account-settings-form-group">
                        <div className="account-settings-form-group-row">
                            <label>First Name</label>
                            <input
                                type="text"
                                name="firstName"
                                value={account.settings.firstName}
                                onChange={handleUserInfoChange}
                                className="account-settings-input"
                            />
                        </div>
                        <div className="account-settings-form-group-row">
                            <label>Last Name</label>
                            <input
                                type="text"
                                name="lastName"
                                value={account.settings.lastName}
                                onChange={handleUserInfoChange}
                                className="account-settings-input"
                            />
                        </div>
                        <div className="account-settings-form-group-row">
                            <label>Email</label>
                            <input
                                type="email"
                                name="email"
                                value={account.settings.email}
                                onChange={handleUserInfoChange}
                                className="account-settings-input"
                            />
                        </div>
                    </div>
                </div>
                <button
                    className="account-settings-button"
                    onClick={saveUserData}
                >
                    Save Changes
                </button>
            </div>

            <div className="account-settings-section">
                <h2 className="account-settings-heading">Password</h2>
                <div className="account-settings-form">
                    <div className="account-settings-form-group">
                        <div className="account-settings-form-group-row">
                            <label>Current Password</label>
                            <input
                                type="password"
                                className="account-settings-input"
                            />
                        </div>
                        <div className="account-settings-form-group-row">
                            <label>New Password</label>
                            <input
                                type="password"
                                className="account-settings-input"
                            />
                        </div>
                        <div className="account-settings-form-group-row">
                            <label>Confirm New Password</label>
                            <input
                                type="password"
                                className="account-settings-input"
                            />
                        </div>
                    </div>
                </div>
                <button className="account-settings-button">
                    Change Password
                </button>
            </div>

            <div className="account-settings-section">
                <h2 className="account-settings-heading">Your Notifications</h2>
                <table className="account-settings-notifications-table">
                    <thead>
                        <tr>
                            <th>Settings</th>
                            <th>Notification</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="account-settings-notifications-setting-header">
                                    <h1>Message Alerts</h1>
                                    <p>
                                        Receive alerts when someone sends you a
                                        direct message.
                                    </p>
                                </div>
                            </td>
                            <td>
                                <Switch
                                    checked={
                                        notifications.receivedMessageNotification
                                    }
                                    onChange={() =>
                                        handleNotificationChange(
                                            "receivedMessageNotification"
                                        )
                                    }
                                />
                            </td>
                            <td>
                                <Switch
                                    checked={notifications.receivedMessageEmail}
                                    onChange={() =>
                                        handleNotificationChange(
                                            "receivedMessageEmail"
                                        )
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="account-settings-notifications-setting-header">
                                    <h1>Order Alerts</h1>
                                    <p>
                                        Get alerted when a new order is placed
                                        by someone.
                                    </p>
                                </div>
                            </td>
                            <td>
                                <Switch
                                    checked={notifications.newOrderNotification}
                                    onChange={() =>
                                        handleNotificationChange(
                                            "newOrderNotification"
                                        )
                                    }
                                />
                            </td>
                            <td>
                                <Switch
                                    checked={notifications.newOrderEmail}
                                    onChange={() =>
                                        handleNotificationChange(
                                            "newOrderEmail"
                                        )
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="account-settings-notifications-setting-header">
                                    <h1>Join Lab Request Alerts</h1>
                                    <p>
                                        Be alerted when someone submits a
                                        request to join your lab.
                                    </p>
                                </div>
                            </td>
                            <td>
                                <Switch
                                    checked={
                                        notifications.joinLabRequestNotification
                                    }
                                    onChange={() =>
                                        handleNotificationChange(
                                            "joinLabRequestNotification"
                                        )
                                    }
                                />
                            </td>
                            <td>
                                <Switch
                                    checked={notifications.joinLabRequestEmail}
                                    onChange={() =>
                                        handleNotificationChange(
                                            "joinLabRequestEmail"
                                        )
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="account-settings-notifications-setting-header">
                                    <h1>New Technology Updates</h1>
                                    <p>
                                        Stay informed about new technology
                                        releases from labs you follow.
                                    </p>
                                </div>
                            </td>
                            <td>
                                <Switch
                                    checked={
                                        notifications.newTechnologyNotification
                                    }
                                    onChange={() =>
                                        handleNotificationChange(
                                            "newTechnologyNotification"
                                        )
                                    }
                                />
                            </td>
                            <td>
                                <Switch
                                    checked={notifications.newTechnologyEmail}
                                    onChange={() =>
                                        handleNotificationChange(
                                            "newTechnologyEmail"
                                        )
                                    }
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="account-settings-notifications-setting-header">
                                    <h1>New Publications Updates</h1>
                                    <p>
                                        Receive updates when a new publication
                                        is released from labs you follow.
                                    </p>
                                </div>
                            </td>
                            <td>
                                <Switch
                                    checked={
                                        notifications.newPublicationNotification
                                    }
                                    onChange={() =>
                                        handleNotificationChange(
                                            "newPublicationNotification"
                                        )
                                    }
                                />
                            </td>
                            <td>
                                <Switch
                                    checked={notifications.newPublicationEmail}
                                    onChange={() =>
                                        handleNotificationChange(
                                            "newPublicationEmail"
                                        )
                                    }
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default MySettings;
