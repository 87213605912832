import React, { useState, useEffect } from "react";
import {
    Typography,
    Button,
    Card,
    CardContent,
    CardActions,
    Box,
    Chip,
    CircularProgress,
    Divider,
    Tooltip,
    Snackbar,
    Alert,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StarIcon from "@mui/icons-material/Star";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
    checkoutIndividualSubscription,
    checkoutLabSubscription,
    getActiveSubscription,
} from "../../services/subscription-service";

const PricingTier = ({
    title,
    subtitle,
    price,
    discountedPrice,
    features,
    isComingSoon,
    ctaText,
}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [subscription, setSubscription] = useState(null);

    // Fetch current subscription status when component mounts
    useEffect(() => {
        const fetchSubscription = async () => {
            try {
                const subscriptionData = await getActiveSubscription();
                setSubscription(subscriptionData);
            } catch (err) {
                console.error("Error fetching subscription:", err);
            }
        };

        fetchSubscription();
    }, []);

    const handleSubscribe = async () => {
        if (isComingSoon) return;

        setLoading(true);
        setError(null);

        try {
            // Check if user is trying to subscribe to Individual tier when they already have a Lab subscription
            if (
                title === "Individual" &&
                subscription &&
                subscription.status === "active" &&
                subscription.tier === "Lab"
            ) {
                setError("You already have a Lab plan.");
                setLoading(false);
                return;
            }

            // Use the appropriate subscription function based on tier
            if (title === "Individual") {
                await checkoutIndividualSubscription();
            } else if (title === "Lab") {
                await checkoutLabSubscription();
            } else if (title === "Institution") {
                // For Institution tier, redirect to contact page
                window.location.href = "/contact";
            }
        } catch (err) {
            console.error(`Error subscribing to ${title} tier:`, err);
            setError(err.message || `Failed to subscribe to ${title} tier`);
            setLoading(false);
        }
    };

    // Determine card background pattern based on tier
    const getBackgroundPattern = () => {
        if (title === "Individual") {
            return `radial-gradient(circle at 10% 20%, rgba(63, 5, 5, 0.03) 0%, transparent 8%),
                    radial-gradient(circle at 90% 80%, rgba(63, 5, 5, 0.03) 0%, transparent 8%)`;
        } else if (title === "Lab") {
            return `radial-gradient(circle at 15% 15%, rgba(63, 5, 5, 0.05) 0%, transparent 10%),
                    radial-gradient(circle at 85% 85%, rgba(63, 5, 5, 0.05) 0%, transparent 10%),
                    radial-gradient(circle at 50% 50%, rgba(63, 5, 5, 0.03) 0%, transparent 20%)`;
        } else {
            return `radial-gradient(circle at 80% 10%, rgba(63, 5, 5, 0.03) 0%, transparent 8%),
                    radial-gradient(circle at 20% 90%, rgba(63, 5, 5, 0.03) 0%, transparent 8%)`;
        }
    };

    return (
        <>
            <Card
                className="pricing-card"
                sx={{
                    backgroundImage: getBackgroundPattern(),
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    transition: "all 0.3s ease-in-out",
                    "&:hover": {
                        transform: "translateY(-8px)",
                        boxShadow: "0 8px 30px rgba(63, 5, 5, 0.1)",
                    },
                    ...(title === "Lab" && {
                        transform: "scale(1.05)",
                        zIndex: 2,
                        boxShadow: "0 8px 30px rgba(63, 5, 5, 0.15)",
                        border: "2px solid rgba(63, 5, 5, 0.2)",
                        "&:hover": {
                            transform: "translateY(-12px) scale(1.05)",
                        },
                    }),
                }}
            >
                {title === "Lab" && (
                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            backgroundColor: "rgba(63, 5, 5, 0.9)",
                            color: "white",
                            textAlign: "center",
                            padding: "8px 0",
                            fontWeight: 600,
                            fontSize: "0.8rem",
                            letterSpacing: "1px",
                            fontFamily:
                                '"poppins", Helvetica, Arial, sans-serif',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "6px",
                        }}
                    >
                        <StarIcon sx={{ fontSize: "0.9rem" }} />
                        MOST POPULAR
                        <StarIcon sx={{ fontSize: "0.9rem" }} />
                    </Box>
                )}
                <CardContent
                    sx={{ pt: title === "Lab" ? 5 : 3, pb: 2, flexGrow: 1 }}
                >
                    <Typography
                        variant="h5"
                        component="div"
                        className="pricing-title"
                        sx={{
                            marginBottom: "12px",
                            fontSize: "1.8rem",
                            fontWeight: 700,
                            color: title === "Lab" ? "rgb(63, 05, 05)" : "#333",
                        }}
                    >
                        {title}
                    </Typography>

                    {subtitle && (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: "-12px",
                                marginBottom: "10px",
                            }}
                        >
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    textAlign: "center",
                                    color: "rgb(63, 05, 05)",
                                    fontWeight: 500,
                                    fontSize: "0.85rem",
                                    fontStyle: "italic",
                                    mr: 0.5,
                                }}
                            >
                                {subtitle}
                            </Typography>
                            <Tooltip
                                title="This plan is designed for lab leaders who manage a research team. Principal Investigators can purchase this plan to provide access for their entire lab group."
                                arrow
                                placement="top"
                            >
                                <InfoOutlinedIcon
                                    sx={{
                                        fontSize: "0.9rem",
                                        color: "rgb(63, 05, 05)",
                                        opacity: 0.7,
                                        cursor: "pointer",
                                    }}
                                />
                            </Tooltip>
                        </Box>
                    )}

                    {isComingSoon ? (
                        <Box sx={{ textAlign: "center", my: 3 }}>
                            <Typography
                                variant="h6"
                                className="pricing-coming-soon"
                                sx={{
                                    fontSize: "1.5rem",
                                    fontWeight: 700,
                                    color:
                                        title === "Lab"
                                            ? "rgb(63, 05, 05)"
                                            : "#333",
                                }}
                            >
                                Coming Soon
                            </Typography>
                        </Box>
                    ) : (
                        <>
                            <Box className="pricing-price-container">
                                {discountedPrice && (
                                    <>
                                        <Typography
                                            variant="h4"
                                            className="pricing-price discounted"
                                        >
                                            ${price}/month
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            className="pricing-price"
                                            sx={{
                                                fontSize: "2.5rem",
                                                fontWeight: 700,
                                                color:
                                                    title === "Lab"
                                                        ? "rgb(63, 05, 05)"
                                                        : "#333",
                                            }}
                                        >
                                            ${discountedPrice}/month
                                        </Typography>
                                        <Chip
                                            label="50% OFF"
                                            color="primary"
                                            size="small"
                                            className="pricing-discount-chip"
                                            sx={{
                                                bgcolor: "rgb(63, 05, 05)",
                                                color: "white",
                                                "&:hover": {
                                                    bgcolor: "rgb(83, 25, 25)",
                                                },
                                                fontWeight: 700,
                                                fontSize: "0.75rem",
                                                padding: "0 6px",
                                            }}
                                        />
                                    </>
                                )}
                                {!discountedPrice && price && (
                                    <Typography
                                        variant="h4"
                                        className="pricing-price"
                                        sx={{
                                            fontWeight: 700,
                                        }}
                                    >
                                        ${price}/month
                                    </Typography>
                                )}
                                {!discountedPrice && !price && (
                                    <Typography
                                        variant="h4"
                                        className="pricing-price"
                                    >
                                        Custom Pricing
                                    </Typography>
                                )}
                            </Box>
                        </>
                    )}

                    <Divider
                        sx={{
                            mx: 2,
                            my: 2,
                            borderColor:
                                title === "Lab"
                                    ? "rgba(63, 5, 5, 0.2)"
                                    : "rgba(0, 0, 0, 0.1)",
                        }}
                    />

                    <Box className="pricing-features">
                        {features.map((feature, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    marginBottom: "16px",
                                    paddingLeft: "5px",
                                    ...(index === 0 && {
                                        backgroundColor: "rgba(63, 5, 5, 0.04)",
                                        padding: "8px 10px 8px 5px",
                                        borderRadius: "6px",
                                        marginLeft: "-5px",
                                        marginRight: "-5px",
                                    }),
                                }}
                            >
                                <CheckCircleIcon
                                    sx={{
                                        color: "rgb(63, 05, 05)",
                                        marginRight: "12px",
                                        fontSize:
                                            index === 0 ? "1.3rem" : "1.2rem",
                                        marginTop: "3px",
                                        flexShrink: 0,
                                    }}
                                />
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color:
                                            index === 0
                                                ? "rgb(63, 05, 05)"
                                                : "#444",
                                        lineHeight: 1.5,
                                        fontWeight: index === 0 ? 600 : 400,
                                    }}
                                >
                                    {feature}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </CardContent>
                <CardActions
                    className="pricing-actions"
                    sx={{
                        mt: "auto",
                        padding: "16px 25px 25px",
                        backgroundColor: "rgba(63, 5, 5, 0.03)",
                        borderTop: "1px solid rgba(63, 5, 5, 0.08)",
                    }}
                >
                    {!isComingSoon && (
                        <Button
                            variant="contained"
                            disableElevation
                            size="large"
                            fullWidth
                            onClick={handleSubscribe}
                            disabled={loading}
                            className="pricing-cta-button"
                            sx={{
                                backgroundColor:
                                    title === "Lab"
                                        ? "rgb(63, 05, 05)"
                                        : "#555",
                                color: "white",
                                py: 1.5,
                                fontWeight: 600,
                                borderRadius: "8px",
                                "&:hover": {
                                    backgroundColor:
                                        title === "Lab"
                                            ? "rgb(83, 25, 25)"
                                            : "#333",
                                },
                            }}
                        >
                            {loading ? (
                                <CircularProgress
                                    size={24}
                                    sx={{ color: "white" }}
                                />
                            ) : (
                                ctaText
                            )}
                        </Button>
                    )}
                </CardActions>
            </Card>

            <Snackbar
                open={!!error}
                autoHideDuration={6000}
                onClose={() => setError(null)}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                sx={{
                    zIndex: 9999,
                }}
            >
                <Alert
                    onClose={() => setError(null)}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    {error}
                </Alert>
            </Snackbar>
        </>
    );
};

export default PricingTier;
