import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/auth-context";
import { useSubscription } from "../../context/subscription-context";
import "../../styles/components/LabAssistantChat.css";

// Import components
import MessageInput from "./MessageInput";
import MessageList from "./MessageList";
import Sidebar from "./Sidebar";
import ModalComponent from "../ModalComponent";

// Import icons
import {
    WarningAmber as WarningIcon,
    DeleteForever as DeleteIcon,
} from "@mui/icons-material";

// Import utilities
import { fetchGet, fetchPost, fetchDelete } from "../../services/data-service";

const LabAssistantChat = () => {
    const [chatMessage, setChatMessage] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const [conversationHistory, setConversationHistory] = useState([]);
    const [model, setModel] = useState("flash");
    const [uploadedImage, setUploadedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const messagesEndRef = useRef(null);
    const messagesContainerRef = useRef(null);
    const textareaRef = useRef(null);
    const imageInputRef = useRef(null);
    const [userHasScrolled, setUserHasScrolled] = useState(false);
    const [autoScrollEnabled, setAutoScrollEnabled] = useState(false);
    const { user } = UserAuth();
    const location = useLocation();

    const [messages, setMessages] = useState([]);
    const { subscription, remainingQueries, updateRemainingQueries } =
        useSubscription();
    const navigate = useNavigate();

    // New state variables for chat history
    const [currentChatId, setCurrentChatId] = useState(null);
    const [userChats, setUserChats] = useState([]);
    const [isLoadingChats, setIsLoadingChats] = useState(false);
    const [activeChat, setActiveChat] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [chatToDelete, setChatToDelete] = useState(null);
    const [abortController, setAbortController] = useState(null);

    // New state to track if we're editing a message and which one
    const [editingMessage, setEditingMessage] = useState(null);

    useEffect(() => {
        if (user) {
            setMessages([
                {
                    sender: "ai",
                    text: "Hi there! I'm your Lab Assistant. I can help you find research equipment, labs, or answer questions about scientific resources. You can also provide feedback or suggestions about our platform to help us improve. What can I help you with today?",
                },
            ]);

            // Load user's chat history when component mounts
            loadUserChats();
        } else {
            // Reset chat state if user logs out
            setUserChats([]);
            setCurrentChatId(null);
            setActiveChat(null);

            setMessages([
                {
                    sender: "ai",
                    text: `Hi there! I'm your Lab Assistant. To use this feature, please <strong><a href='${process.env.REACT_APP_FRONTEND_URL}/?mode=signup' rel='noopener noreferrer'>sign up for a free account</a></strong> or <strong><a href='${process.env.REACT_APP_FRONTEND_URL}/?mode=login' rel='noopener noreferrer'>sign in</a></strong>.`,
                },
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    // Add useEffect for initial adjustment when component mounts
    useEffect(() => {
        // Simply scroll to the bottom when component mounts
        if (messagesContainerRef.current) {
            setTimeout(() => {
                messagesContainerRef.current.scrollTop =
                    messagesContainerRef.current.scrollHeight;
            }, 200);
        }
    }, []);

    /**
     * Handle stopping AI generation
     */
    const handleStopGeneration = () => {
        if (abortController) {
            try {
                // Abort the fetch request
                abortController.abort();

                // Update the UI to reflect that generation has stopped
                setIsTyping(false);

                // Do NOT change autoScrollEnabled here, as we want to preserve the user's setting

                // Update the current message to indicate generation was stopped
                setMessages((prev) => {
                    const newMessages = prev.map((msg) => {
                        if (msg.isCurrentResponse) {
                            // If the message is just thinking, replace it with a stopped message
                            if (msg.isThinking) {
                                return {
                                    ...msg,
                                    text: "Generation stopped by user",
                                    isThinking: false,
                                    isCurrentResponse: false,
                                    isGenerating: false,
                                };
                            }

                            // Otherwise, append to the existing text
                            return {
                                ...msg,
                                text: msg.text + " [Generation stopped]",
                                isThinking: false,
                                isCurrentResponse: false,
                                isGenerating: false,
                            };
                        }
                        return msg;
                    });
                    return newMessages;
                });

                console.log("AI generation stopped by user");
            } catch (error) {
                console.error("Error stopping generation:", error);
            } finally {
                // Reset the abort controller
                setAbortController(null);
            }
        }
    };

    // Function to handle editing a message
    const handleEditMessage = (message) => {
        // Stop any ongoing generation
        if (abortController) {
            abortController.abort();
            setAbortController(null);
            setIsTyping(false);
        }

        // Set the message text to the input field
        setChatMessage(message.text);

        // If the message had an image, also set the image
        if (message.image) {
            setImagePreview(message.image);
            // Note: We can't restore the actual uploadedImage File object
            // as it's not stored in the message, only the preview URL
        }

        // Set editing state
        setEditingMessage(message);

        // Focus the textarea
        if (textareaRef.current) {
            textareaRef.current.focus();
        }
    };

    // Function to cancel editing
    const cancelEditing = () => {
        setEditingMessage(null);
        setChatMessage("");
        setImagePreview(null);
        setUploadedImage(null);
    };

    // Function to handle sending a message to the AI
    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!chatMessage.trim() && !uploadedImage) return;

        // Abort any ongoing request before starting a new one
        if (abortController) {
            abortController.abort();
            setAbortController(null);
            setIsTyping(false);
        }

        // Check if trying to send an image with reasoner model
        if (model === "reasoner" && uploadedImage) {
            setMessages((prev) => [
                ...prev,
                {
                    sender: "user",
                    text: chatMessage,
                    image: imagePreview,
                },
                {
                    sender: "ai",
                    text: "I'm sorry, but the DeepThink model cannot process images. Please switch to the standard model or Pro Knowledge to send images.",
                },
            ]);
            setChatMessage("");
            setUploadedImage(null);
            setImagePreview(null);
            return;
        }

        // Check subscription status and remaining queries
        if (
            !user?.isAdmin &&
            subscription?.status !== "active" &&
            remainingQueries <= 0
        ) {
            setMessages((prev) => [
                ...prev,
                {
                    sender: "user",
                    text: chatMessage,
                    image: imagePreview,
                },
                {
                    sender: "ai",
                    text: `You've reached your free message limit. To continue using the Lab Assistant, please subscribe to one of our plans at <strong><a href='${process.env.REACT_APP_FRONTEND_URL}/pricing' rel='noopener noreferrer'>our pricing page</a></strong>.`,
                },
            ]);
            setChatMessage("");
            setUploadedImage(null);
            setImagePreview(null);

            // Add a slight delay before redirecting
            setTimeout(() => {
                navigate("/pricing");
            }, 3000);

            return;
        }

        // If user doesn't have subscription, update remaining queries
        if (subscription?.status !== "active") {
            try {
                await updateRemainingQueries();
            } catch (error) {
                console.error("Error updating query count:", error);
                return;
            }
        }

        // Add additional check to prevent non-logged in users from sending messages
        if (!user) {
            setMessages((prev) => [
                ...prev,
                {
                    sender: "user",
                    text: chatMessage,
                    image: imagePreview,
                },
                {
                    sender: "ai",
                    text: `To use this feature, please <strong><a href='${process.env.REACT_APP_FRONTEND_URL}/?mode=signup' rel='noopener noreferrer'>sign up for a free account</a></strong> or <strong><a href='${process.env.REACT_APP_FRONTEND_URL}/?mode=login' rel='noopener noreferrer'>sign in</a></strong>.`,
                },
            ]);
            setChatMessage("");
            setUploadedImage(null);
            setImagePreview(null);
            return;
        }

        // Handling edited message
        if (editingMessage) {
            // If we're editing a message, we'll truncate the conversation history
            // We need to find the index of the message we're editing
            const editIndex = messages.findIndex(
                (msg) =>
                    msg.sender === editingMessage.sender &&
                    msg.text === editingMessage.text &&
                    msg.image === editingMessage.image
            );

            if (editIndex !== -1) {
                // Remove all messages after the edited message
                const newMessages = messages.slice(0, editIndex);
                setMessages(newMessages);

                // Also trim the conversation history to match
                const historyEditIndex = conversationHistory.findIndex(
                    (msg, idx, arr) => {
                        if (idx < conversationHistory.length - 1) {
                            return (
                                msg.role === "user" &&
                                arr[idx + 1].role === "assistant" &&
                                msg.content === editingMessage.text
                            );
                        }
                        return false;
                    }
                );

                if (historyEditIndex !== -1) {
                    // Keep history up to the edited message
                    const newHistory = conversationHistory.slice(
                        0,
                        historyEditIndex
                    );
                    setConversationHistory(newHistory);
                }
            }

            // Clear editing state
            setEditingMessage(null);
        }

        // Check if this is a new chat (no current chat ID)
        const isNewChat = !currentChatId;

        // Add user message to chat
        const userMessage = {
            id: `user-${Date.now()}-${Math.random()
                .toString(36)
                .substring(2, 9)}`,
            sender: "user",
            text: chatMessage,
            image: imagePreview,
            // Add user metadata to the message if user is logged in
            metadata: user
                ? {
                      userId: user.uid,
                      userName: user.displayName?.split(" ")[0] || "User",
                      userLastName:
                          user.displayName?.split(" ").slice(1).join(" ") || "",
                      userEmail: user.email,
                  }
                : null,
        };

        // Directly use state setter with callback
        setMessages((prev) => {
            const newMessages = [...prev, userMessage];
            return newMessages;
        });

        // Update conversation history
        const updatedHistory = [
            ...conversationHistory,
            {
                role: "user",
                content: chatMessage,
                // Add user metadata to the message if user is logged in
                metadata: user
                    ? {
                          userId: user.uid,
                          userName: user.displayName?.split(" ")[0] || "User",
                          userLastName:
                              user.displayName?.split(" ").slice(1).join(" ") ||
                              "",
                          userEmail: user.email,
                          hasImage: !!uploadedImage,
                      }
                    : null,
            },
        ];
        setConversationHistory(updatedHistory);

        // Clear input and show typing indicator
        const messageToSend = chatMessage;
        setChatMessage("");
        setIsTyping(true);

        // Get image data if available
        let imageData = null;
        let mimeType = null;

        if (uploadedImage) {
            // Convert image to base64
            try {
                const reader = new FileReader();
                const imageDataPromise = new Promise((resolve, reject) => {
                    reader.onload = (e) => resolve(e.target.result);
                    reader.onerror = (e) => reject(e);
                    reader.readAsDataURL(uploadedImage);
                });

                const dataUrl = await imageDataPromise;
                // Format: "data:image/jpeg;base64,/9j/4AAQSkZJRg..."

                mimeType = uploadedImage.type;
                // Extract the base64 part (remove the "data:image/jpeg;base64," prefix)
                imageData = dataUrl.split(",")[1];
            } catch (error) {
                console.error("Error converting image to base64:", error);
            }
        }

        // Clear the uploaded image
        setUploadedImage(null);
        setImagePreview(null);

        // Reset textarea height
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto";
        }

        // Add an immediate thinking message that will be updated with real content
        setMessages((prev) => [
            ...prev,
            {
                id: `ai-thinking-${Date.now()}`,
                sender: "ai",
                text: "I'm thinking about your question...",
                isCurrentResponse: true,
                isThinking: true,
            },
        ]);

        // Explicitly disable auto-scrolling for the new AI response
        setAutoScrollEnabled(false);

        // Start a timer to update the thinking message with more engaging content
        let thinkingStep = 0;
        const thinkingMessages = [
            "I'm searching for relevant information...",
            "Analyzing your request...",
            "Looking through available resources...",
            "Almost there, putting together a helpful response for you...",
        ];

        const thinkingInterval = setInterval(() => {
            thinkingStep = (thinkingStep + 1) % thinkingMessages.length;
            setMessages((prev) => {
                const newMessages = [...prev];
                const thinkingMsgIndex = newMessages.findIndex(
                    (msg) => msg.isThinking && msg.isCurrentResponse
                );

                if (thinkingMsgIndex !== -1) {
                    newMessages[thinkingMsgIndex].text =
                        thinkingMessages[thinkingStep];
                }

                return newMessages;
            });
        }, 2000);

        try {
            // Create a controller to abort the fetch if needed
            const controller = new AbortController();
            const signal = controller.signal;

            // Store the controller in state so it can be accessed by the stop button
            setAbortController(controller);

            // Prepare the request body
            const requestBody = {
                message: messageToSend,
                conversationHistory: conversationHistory,
                model: model,
                generateTitle: isNewChat, // Request title generation for new chats
            };

            // Add image data if available
            if (imageData) {
                requestBody.image = {
                    data: imageData,
                    mimeType: mimeType,
                };
            }

            // Include the current chat ID if there is one
            if (currentChatId) {
                requestBody.chatId = currentChatId;
            }

            // For Server-Sent Events, we need to use the native fetch API
            // as our data service doesn't support SSE streams. SSE requires
            // special handling to process the streaming response data.
            const idToken = await user.getIdToken();
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_BASE_URL || ""}/ai/chat`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "text/event-stream",
                        Authorization: `Bearer ${idToken}`,
                    },
                    body: JSON.stringify(requestBody),
                    signal: signal,
                }
            );

            // Clear the thinking interval once we get a response
            clearInterval(thinkingInterval);

            // Create a reader from the response body stream
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let aiResponse = "";
            let buffer = ""; // Add a buffer to handle split JSON data
            let chatTitle = ""; // Store the AI-generated title

            // Function to process the stream
            const processStream = async () => {
                try {
                    while (true) {
                        const { done, value } = await reader.read();

                        if (done) {
                            console.log(
                                "Stream ended - finalizing any pending responses"
                            );
                            // If we have a current response that hasn't been marked as complete
                            setMessages((prev) => {
                                const hasPendingResponse = prev.some(
                                    (msg) => msg.isCurrentResponse
                                );
                                if (hasPendingResponse) {
                                    console.log(
                                        "Found pending response - marking as complete"
                                    );
                                    return prev.map((msg) => {
                                        if (msg.isCurrentResponse) {
                                            // Always remove thinking flag
                                            return {
                                                ...msg,
                                                isThinking: false,
                                                isCurrentResponse: false,
                                            };
                                        }
                                        return msg;
                                    });
                                }
                                return prev;
                            });
                            break;
                        }

                        // Decode the chunk and add to buffer
                        const chunk = decoder.decode(value, { stream: true });
                        buffer += chunk;

                        // Find complete SSE events and process them
                        const events = buffer.split("\n\n");
                        // Keep the last potentially incomplete event in the buffer
                        buffer = events.pop() || "";

                        for (const event of events) {
                            if (!event.trim() || !event.startsWith("data: "))
                                continue;

                            try {
                                const jsonStr = event.substring(6);
                                const eventData = JSON.parse(jsonStr);

                                if (eventData.status === "connected") {
                                    console.log("SSE connection established");
                                    continue;
                                }

                                // Extract chatId if provided in the response
                                if (eventData.chatId && !currentChatId) {
                                    setCurrentChatId(eventData.chatId);
                                    console.log(
                                        "Set current chat ID to:",
                                        eventData.chatId
                                    );

                                    // Add a temporary chat entry to the sidebar immediately
                                    // This will be updated when the real title is received
                                    const tempChatTitle =
                                        messageToSend.length > 30
                                            ? messageToSend.substring(0, 30) +
                                              "..."
                                            : messageToSend;

                                    setUserChats((prevChats) => {
                                        // Check if this chat already exists in the list
                                        const chatExists = prevChats.some(
                                            (chat) =>
                                                chat.id === eventData.chatId
                                        );
                                        if (chatExists) return prevChats;

                                        // Add new temporary chat at the beginning of the list
                                        // Create a completely new array to ensure React detects the change
                                        const newChats = [
                                            {
                                                id: eventData.chatId,
                                                title: tempChatTitle,
                                                updatedAt: new Date(),
                                                preview: "New conversation",
                                            },
                                            ...prevChats,
                                        ];

                                        return newChats;
                                    });

                                    // Also refresh the chat list to get complete data
                                    // Use setTimeout to ensure our state update happens first
                                    setTimeout(() => loadUserChats(), 500);
                                }

                                // Handle AI-generated title if provided
                                if (eventData.title && isNewChat) {
                                    chatTitle = eventData.title;
                                    console.log(
                                        "AI generated title:",
                                        chatTitle
                                    );

                                    // Update chat title in the database
                                    if (eventData.chatId) {
                                        try {
                                            const response = await fetchPost(
                                                `/ai-chats/${eventData.chatId}/title`,
                                                { title: chatTitle },
                                                true
                                            );
                                            if (response.success) {
                                                console.log(
                                                    "Chat title updated successfully"
                                                );

                                                // Immediately update the title in the local state
                                                // so it appears in the sidebar without needing to refresh
                                                setUserChats((prevChats) => {
                                                    const updatedChats = [
                                                        ...prevChats,
                                                    ];
                                                    const chatIndex =
                                                        updatedChats.findIndex(
                                                            (chat) =>
                                                                chat.id ===
                                                                eventData.chatId
                                                        );

                                                    let newChats;
                                                    if (chatIndex !== -1) {
                                                        // Create a new chat object to ensure React detects the change
                                                        const updatedChat = {
                                                            ...updatedChats[
                                                                chatIndex
                                                            ],
                                                            title: chatTitle,
                                                            // Force update on the timestamp to trigger re-render
                                                            updatedAt:
                                                                new Date(),
                                                        };

                                                        // Create a new array with the updated chat
                                                        newChats = [
                                                            ...updatedChats.slice(
                                                                0,
                                                                chatIndex
                                                            ),
                                                            updatedChat,
                                                            ...updatedChats.slice(
                                                                chatIndex + 1
                                                            ),
                                                        ];
                                                    } else {
                                                        // Add new chat if it doesn't exist yet
                                                        newChats = [
                                                            {
                                                                id: eventData.chatId,
                                                                title: chatTitle,
                                                                updatedAt:
                                                                    new Date(),
                                                                preview:
                                                                    "New conversation",
                                                            },
                                                            ...updatedChats,
                                                        ];
                                                    }

                                                    return newChats;
                                                });

                                                // Still refresh the complete chat list to ensure consistency
                                                // but the UI will already show the updated title
                                                setTimeout(
                                                    () => loadUserChats(),
                                                    1000
                                                );
                                            }
                                        } catch (titleError) {
                                            console.error(
                                                "Error updating chat title:",
                                                titleError
                                            );
                                        }
                                    }
                                }

                                if (eventData.chunk) {
                                    // Only append to aiResponse if it's not a reasoning chunk
                                    if (eventData.chunkType !== "reasoning") {
                                        aiResponse += eventData.chunk;
                                    }

                                    // Update the AI message in real-time as chunks arrive
                                    setMessages((prev) => {
                                        const newMessages = [...prev];
                                        const aiMessageIndex =
                                            newMessages.findIndex(
                                                (msg) =>
                                                    msg.sender === "ai" &&
                                                    msg.isCurrentResponse
                                            );

                                        if (aiMessageIndex !== -1) {
                                            // Update existing message based on chunk type
                                            if (
                                                eventData.chunkType ===
                                                "reasoning"
                                            ) {
                                                // For reasoning content, we replace the entire content
                                                // instead of appending to prevent duplicated words
                                                newMessages[
                                                    aiMessageIndex
                                                ].reasoning = eventData.chunk;
                                                newMessages[
                                                    aiMessageIndex
                                                ].hasReasoning = true;
                                                newMessages[
                                                    aiMessageIndex
                                                ].showReasoning = true; // Show reasoning by default during generation
                                                newMessages[
                                                    aiMessageIndex
                                                ].isGenerating = true; // Mark as still generating
                                            } else {
                                                // Regular content
                                                newMessages[
                                                    aiMessageIndex
                                                ].text = aiResponse;
                                            }
                                            // Remove the thinking flag
                                            newMessages[
                                                aiMessageIndex
                                            ].isThinking = false;
                                        } else {
                                            // Add new AI message
                                            const newMessage = {
                                                id: `ai-${Date.now()}-${Math.random()
                                                    .toString(36)
                                                    .substring(2, 9)}`,
                                                sender: "ai",
                                                text:
                                                    eventData.chunkType ===
                                                    "reasoning"
                                                        ? ""
                                                        : aiResponse,
                                                isCurrentResponse: true,
                                                isGenerating: true, // Mark as still generating
                                            };

                                            // Add reasoning if this is a reasoning chunk
                                            if (
                                                eventData.chunkType ===
                                                "reasoning"
                                            ) {
                                                newMessage.reasoning =
                                                    eventData.chunk;
                                                newMessage.hasReasoning = true;
                                                newMessage.showReasoning = true; // Show reasoning by default during generation
                                            }

                                            newMessages.push(newMessage);
                                        }

                                        return newMessages;
                                    });
                                }

                                if (eventData.status === "complete") {
                                    console.log("Received complete status");

                                    // Mark the response as complete - always remove thinking flag
                                    setMessages((prev) => {
                                        const newMessages = prev.map((msg) => {
                                            if (msg.isCurrentResponse) {
                                                // Always remove thinking flag and mark as not generating anymore
                                                const updatedMsg = {
                                                    ...msg,
                                                    isThinking: false,
                                                    isCurrentResponse: false,
                                                    isGenerating: false, // Mark as done generating
                                                };
                                                return updatedMsg;
                                            }
                                            return msg;
                                        });

                                        return newMessages;
                                    });

                                    // Update conversation history with AI's response
                                    setConversationHistory([
                                        ...updatedHistory,
                                        {
                                            role: "assistant",
                                            content: aiResponse,
                                        },
                                    ]);

                                    // Set typing indicator to false
                                    setIsTyping(false);

                                    // Reset input field height
                                    if (textareaRef.current) {
                                        textareaRef.current.style.height =
                                            "auto";

                                        // Scroll to bottom ONLY if auto-scrolling is enabled
                                        if (autoScrollEnabled) {
                                            setTimeout(() => {
                                                if (
                                                    messagesContainerRef.current
                                                ) {
                                                    messagesContainerRef.current.scrollTop =
                                                        messagesContainerRef.current.scrollHeight;
                                                }
                                            }, 100);
                                        }
                                    }

                                    // Refresh the chat list to show updated titles and previews
                                    if (currentChatId) {
                                        loadUserChats();
                                    }

                                    break;
                                }

                                if (eventData.status === "error") {
                                    // Handle error response
                                    console.error(
                                        "Error from AI service:",
                                        eventData.message
                                    );

                                    // Update the current message to show the error
                                    setMessages((prev) => {
                                        const newMessages = [...prev];
                                        const responseIndex =
                                            newMessages.findIndex(
                                                (msg) => msg.isCurrentResponse
                                            );

                                        if (responseIndex !== -1) {
                                            newMessages[responseIndex] = {
                                                ...newMessages[responseIndex],
                                                text: `Error: ${
                                                    eventData.message ||
                                                    "Something went wrong. Please try again."
                                                }`,
                                                isError: true,
                                                isThinking: false,
                                                isCurrentResponse: false,
                                            };
                                        }

                                        return newMessages;
                                    });

                                    // Set typing indicator to false
                                    setIsTyping(false);
                                }
                            } catch (error) {
                                console.error(
                                    "Error parsing event data:",
                                    error,
                                    "Raw event:",
                                    event
                                );
                            }
                        }
                    }
                } catch (error) {
                    console.error("Error processing stream:", error);

                    // Handle the error in the UI
                    setMessages((prev) => {
                        const newMessages = [...prev];
                        const responseIndex = newMessages.findIndex(
                            (msg) => msg.isCurrentResponse
                        );

                        if (responseIndex !== -1) {
                            newMessages[responseIndex] = {
                                ...newMessages[responseIndex],
                                text: `Error: ${
                                    error.message ||
                                    "Something went wrong. Please try again."
                                }`,
                                isError: true,
                                isThinking: false,
                                isCurrentResponse: false,
                            };
                        }

                        return newMessages;
                    });

                    // Set typing indicator to false
                    setIsTyping(false);
                }
            };

            // Start processing the stream
            processStream();
        } catch (error) {
            // Check if this was an abort error
            if (error.name === "AbortError") {
                console.log("Fetch aborted by user");
                return; // Don't show error message for user-initiated cancellation
            }

            // Update the UI with the error
            setMessages((prev) => {
                const newMessages = [...prev];
                const responseIndex = newMessages.findIndex(
                    (msg) => msg.isCurrentResponse
                );

                if (responseIndex !== -1) {
                    newMessages[responseIndex] = {
                        ...newMessages[responseIndex],
                        text: `Error: ${
                            error.message ||
                            "Something went wrong. Please try again."
                        }`,
                        isError: true,
                        isThinking: false,
                        isCurrentResponse: false,
                    };
                }

                return newMessages;
            });

            // Set typing indicator to false
            setIsTyping(false);
        } finally {
            // Always ensure we reset the abort controller when done
            if (abortController && !abortController.signal.aborted) {
                setAbortController(null);
            }
        }
    };

    // Function to scroll to the bottom of the chat
    const scrollToBottom = () => {
        if (messagesContainerRef.current) {
            // Force a reflow before scrolling
            void messagesContainerRef.current.offsetHeight;

            messagesContainerRef.current.scrollTop =
                messagesContainerRef.current.scrollHeight;

            // For extra reliability, add a backup scroll after a very short delay
            setTimeout(() => {
                if (messagesContainerRef.current) {
                    messagesContainerRef.current.scrollTop =
                        messagesContainerRef.current.scrollHeight;
                }
            }, 50);
        }
    };

    // Handle scrolling in the chat container
    const handleScroll = () => {
        if (messagesContainerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } =
                messagesContainerRef.current;

            // If we're more than 100px from the bottom, consider user has scrolled
            const isAtBottom = scrollTop + clientHeight >= scrollHeight - 100;
            setUserHasScrolled(!isAtBottom);

            // If user manually scrolled to bottom, enable auto-scrolling
            if (isAtBottom && !autoScrollEnabled && isTyping) {
                setAutoScrollEnabled(true);
            }
        }
    };

    // Set up MutationObserver to track message additions to the DOM
    useEffect(() => {
        // Exit early if container ref isn't available
        if (!messagesContainerRef.current) return;

        console.log("Setting up MutationObserver for message scrolling");

        // Function to scroll a user message to viewport top with padding
        const scrollUserMessageToViewportTop = (messageElement) => {
            if (!messageElement || !messagesContainerRef.current) return;

            // Calculate ideal padding (15% of viewport height)
            const viewportHeight = messagesContainerRef.current.clientHeight;
            const paddingTop = Math.max(20, viewportHeight * 0.15);

            // Get message offset
            const messageTop = messageElement.offsetTop;

            // Scroll container
            messagesContainerRef.current.scrollTop = messageTop - paddingTop;
            console.log("MutationObserver: Scrolled user message to top", {
                messageTop,
                paddingTop,
                scrollTop: messagesContainerRef.current.scrollTop,
            });
        };

        // Track if we've seen the first user message already
        let firstUserMessageSeen = false;

        // Create a mutation observer to watch for added nodes
        const observer = new MutationObserver((mutations) => {
            for (const mutation of mutations) {
                if (
                    mutation.type !== "childList" ||
                    mutation.addedNodes.length === 0
                )
                    continue;

                // Check each added node
                mutation.addedNodes.forEach((node) => {
                    if (node.nodeType !== Node.ELEMENT_NODE) return;

                    // Find user messages within added nodes
                    let addedUserMessages = node.querySelectorAll
                        ? node.querySelectorAll(
                              ".lab-assistant-chat-user-message"
                          )
                        : [];

                    if (
                        node.classList &&
                        node.classList.contains(
                            "lab-assistant-chat-user-message"
                        )
                    ) {
                        addedUserMessages = [node, ...addedUserMessages];
                    }

                    // Process any found user messages
                    if (addedUserMessages.length > 0) {
                        // If this is the first user message, just scroll to bottom
                        if (!firstUserMessageSeen) {
                            firstUserMessageSeen = true;
                            scrollToBottom();
                            console.log(
                                "MutationObserver: First user message, scrolled to bottom"
                            );
                        } else {
                            // For subsequent messages, scroll to top
                            scrollUserMessageToViewportTop(
                                addedUserMessages[addedUserMessages.length - 1]
                            );
                        }
                    }
                });
            }
        });

        // Configure the observer to watch for child additions to the messages container
        const messagesWrapper = messagesContainerRef.current.querySelector(
            ".lab-assistant-chat-messages-wrapper"
        );
        if (messagesWrapper) {
            observer.observe(messagesWrapper, {
                childList: true,
                subtree: true,
            });
            console.log("MutationObserver started watching message container");
        } else {
            console.warn(
                "Could not find messages wrapper element for observation"
            );
        }

        // Handle first messages already in DOM
        const existingUserMessages =
            messagesContainerRef.current.querySelectorAll(
                ".lab-assistant-chat-user-message"
            );
        if (existingUserMessages.length === 1) {
            // If there's exactly one user message, assume it's the first one
            firstUserMessageSeen = true;
        } else if (existingUserMessages.length > 1) {
            // If there are multiple, we've seen the first one
            firstUserMessageSeen = true;
        }

        // Cleanup function
        return () => {
            observer.disconnect();
            console.log("MutationObserver disconnected");
        };
    }, [messagesContainerRef.current]); // Only recreate when container ref changes

    // Reset auto-scroll when sending a new message
    useEffect(() => {
        // When typing state changes to true (AI starts generating)
        // disable auto-scrolling by default
        if (isTyping) {
            setAutoScrollEnabled(false);
        }
    }, [isTyping]);

    // Add this effect to handle AI response completion
    useEffect(() => {
        // The intention is to NOT change autoScrollEnabled when an AI response completes
        // This preserves the user's preference for whether auto-scrolling is enabled
        // The user can always enable auto-scrolling again by clicking the scroll button
        // or by manually scrolling to the bottom
    }, [isTyping]);

    // Render "queries remaining" for free tier users
    const renderRemainingQueries = () => {
        if (
            user &&
            subscription?.status !== "active" &&
            remainingQueries !== null
        ) {
            return (
                <span className="lab-assistant-chat-queries-count">
                    ({remainingQueries} messages left)
                </span>
            );
        }
        return null;
    };

    // Function to auto-resize textarea
    const autoResizeTextarea = () => {
        if (textareaRef.current) {
            // Reset height to recalculate
            textareaRef.current.style.height = "auto";

            // Set to scrollHeight to expand properly
            textareaRef.current.style.height =
                textareaRef.current.scrollHeight + "px";

            // Only scroll if auto-scrolling is enabled and not being controlled by user
            if (
                autoScrollEnabled &&
                !userHasScrolled &&
                messagesContainerRef.current
            ) {
                scrollToBottom();
            }
        }
    };

    // Adjust textarea height when content changes
    useEffect(() => {
        autoResizeTextarea();
    }, [chatMessage]);

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage(e);
        }
    };

    // Set up textarea ref handling
    const setTextareaRef = useCallback((element) => {
        if (element) {
            textareaRef.current = element;
            // Initialize with the correct height
            element.style.height = "20px";
        }
    }, []);

    // Handle image upload
    const handleImageUpload = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];

            // Check file size (limit to 5MB)
            if (file.size > 5 * 1024 * 1024) {
                alert("Image size must be less than 5MB");
                return;
            }

            // Check file type
            if (!file.type.match("image.*")) {
                alert("Only image files are allowed");
                return;
            }

            setUploadedImage(file);
            setImagePreview(URL.createObjectURL(file));
        }
    };

    // Remove uploaded image
    const handleRemoveImage = () => {
        setUploadedImage(null);
        setImagePreview(null);
        if (imageInputRef.current) {
            imageInputRef.current.value = "";
        }
    };

    // Handle paste events on textarea to capture images
    const handlePaste = (e) => {
        // Return early if user is not logged in or message is being typed
        // Also prevent image paste if reasoner is selected
        if (!user || isTyping || model === "reasoner") return;

        const clipboardData = e.clipboardData;
        if (!clipboardData || !clipboardData.items) return;

        // Check for image items in the clipboard
        const items = clipboardData.items;
        let imageItem = null;

        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf("image") !== -1) {
                imageItem = items[i];
                break;
            }
        }

        // Process the image if found
        if (imageItem) {
            const file = imageItem.getAsFile();

            // Check file size (limit to 5MB)
            if (file.size > 5 * 1024 * 1024) {
                alert("Image size must be less than 5MB");
                return;
            }

            // Process the pasted image
            setUploadedImage(file);
            setImagePreview(URL.createObjectURL(file));

            // Prevent the default paste behavior for images
            e.preventDefault();
        }
    };

    // Extract lab names from message text
    const extractLabNamesFromMessage = (messageText) => {
        if (!messageText) return [];

        // First, try to extract lab names from bullet point or numbered list format
        // This pattern looks for lines starting with bullet points or numbers followed by lab names
        const bulletPointPattern =
            /(?:^|\n)\s*(?:[•\*-]|\d+\.)\s+(.*?)(?:\s*:|$)/gm;
        let extractedNames = [];

        const bulletMatches = [...messageText.matchAll(bulletPointPattern)];
        bulletMatches.forEach((match) => {
            if (match[1] && match[1].trim().length > 3) {
                // For each bullet point match, extract the lab name part
                // This handles formats like "* McGill Nanofactory : They have..."
                const labNamePart = match[1].split(/\s*:/)[0].trim();
                extractedNames.push(labNamePart);
            }
        });

        // If we didn't find any bullet points, try more general patterns
        if (extractedNames.length === 0) {
            // Common lab name patterns in the AI's response
            const labPatterns = [
                /\b((?:McGill|MIT|Stanford|Harvard|Berkeley|Cornell|Princeton|Yale|Oxford|Cambridge)[\w\s\-&]+(?:Lab|Laboratory|Research|Group|Center|Centre|Institute|Facility|Nanofactory|Engineering|Design)?)\b/g, // Matches university-affiliated labs
                /\b(Lab of [\w\s\-&]+)\b/g, // Matches "Lab of X" pattern
                /\b([\w\s\-&]+(?:Lab|Laboratory|Research|Group|Center|Centre|Institute|Facility|Nanofactory|Engineering|Design))\b/g, // General lab naming patterns
            ];

            // Apply each pattern and collect matches
            labPatterns.forEach((pattern) => {
                const matches = [...messageText.matchAll(pattern)];
                matches.forEach((match) => {
                    if (match[1] && match[1].trim().length > 3) {
                        // Avoid very short matches
                        extractedNames.push(match[1].trim());
                    }
                });
            });
        }

        // Add specific lab names from the example if they're in the message
        const specificLabNames = [
            "McGill Nanofactory",
            "Lab of Biomaterials Engineering",
            "Mongeau Research Group",
            "Cellular Microenvironment Design Lab",
            "McGill Advanced Therapies Research and Education Centre",
            "MATREC",
        ];

        specificLabNames.forEach((labName) => {
            if (messageText.includes(labName)) {
                extractedNames.push(labName);
            }
        });

        // Remove duplicates
        return [...new Set(extractedNames)];
    };

    // Extract equipment names from message text
    const extractEquipmentNamesFromMessage = (messageText) => {
        if (!messageText) return [];

        console.log(
            "Extracting equipment names from:",
            messageText.substring(0, 100) + "..."
        );

        // First, try to extract equipment names from bullet point or numbered list format
        // This pattern looks for lines starting with bullet points or numbers followed by equipment names
        const bulletPointPattern =
            /(?:^|\n)\s*(?:[•\*-]|\d+\.)\s+(.*?)(?:\s*:|$)/gm;
        let extractedNames = [];

        const bulletMatches = [...messageText.matchAll(bulletPointPattern)];
        bulletMatches.forEach((match) => {
            if (match[1] && match[1].trim().length > 3) {
                // For each bullet point match, extract the equipment name part
                // This handles formats like "* Confocal Microscope : This equipment..."
                const equipmentNamePart = match[1].split(/\s*:/)[0].trim();
                extractedNames.push(equipmentNamePart);
            }
        });

        // Common equipment name patterns in the AI's response
        const equipmentPatterns = [
            /\b([\w\s\-&]+(?:Microscope|Spectrometer|Analyzer|Scanner|Imager|Sequencer|Centrifuge|Reactor|Printer|System|Device|Machine|Equipment|Instrument|Apparatus))\b/g, // Matches equipment with common suffixes
            /\b((?:3D|Confocal|Electron|Scanning|Transmission|Fluorescence|Mass|NMR|PCR|HPLC|GC|LC|MS)[\w\s\-&]+)\b/g, // Matches equipment with common prefixes
            /\b([\w\s\-&]+(?:for|to) (?:analysis|imaging|testing|measurement|characterization|synthesis|fabrication|processing))\b/g, // Matches equipment described by function
            /\b([\w\s\-&]+ (?:platform|tool|technology|setup|station|workstation|kit|array|sensor))\b/g, // Additional common equipment terms
            /\b((?:Advanced|High-Resolution|Automated|Integrated|Portable|Digital|Smart|Precision)[\w\s\-&]+)\b/g, // Equipment with quality/feature prefixes
        ];

        // Apply each pattern and collect matches
        equipmentPatterns.forEach((pattern) => {
            const matches = [...messageText.matchAll(pattern)];
            matches.forEach((match) => {
                if (match[1] && match[1].trim().length > 3) {
                    // Avoid very short matches
                    extractedNames.push(match[1].trim());
                }
            });
        });

        // Extract quoted text as potential equipment names
        const quotedTextPattern = /"([^"]+)"|'([^']+)'/g;
        const quotedMatches = [...messageText.matchAll(quotedTextPattern)];
        quotedMatches.forEach((match) => {
            const quotedText = (match[1] || match[2]).trim();
            if (quotedText.length > 3) {
                extractedNames.push(quotedText);
            }
        });

        // Extract text after "such as", "like", "including", "offers", "provides", "features", "using", "with"
        const contextualPatterns = [
            /(?:such as|like|including|offers|provides|features|using|with) ([\w\s\-&,]+?)(?:\.|\n|and|which|that|to|for|$)/gi,
        ];

        contextualPatterns.forEach((pattern) => {
            const matches = [...messageText.matchAll(pattern)];
            matches.forEach((match) => {
                if (match[1]) {
                    // Split by commas to get individual items
                    const items = match[1]
                        .split(/,|\band\b/)
                        .map((item) => item.trim());
                    items.forEach((item) => {
                        if (item.length > 3) {
                            extractedNames.push(item);
                        }
                    });
                }
            });
        });

        // Remove duplicates
        const uniqueNames = [...new Set(extractedNames)];
        console.log("Extracted equipment names:", uniqueNames);
        return uniqueNames;
    };

    // Effect to handle model changes when there's an uploaded image
    useEffect(() => {
        // If the user switches to reasoner model while having an image uploaded, remove the image
        if (model === "reasoner" && uploadedImage) {
            setUploadedImage(null);
            setImagePreview(null);
            if (imageInputRef.current) {
                imageInputRef.current.value = "";
            }
            // Alert the user that images aren't supported with DeepThink
            alert(
                "Images are not supported with DeepThink. Your uploaded image has been removed."
            );
        }
    }, [model, uploadedImage]);

    // Function to get the appropriate emoji for an entity based on its type and name
    const getEmojiForEntity = (type, name) => {
        // Convert name to lowercase for case-insensitive matching
        const nameLower = name.toLowerCase();

        // Default emojis based on type
        const defaultEmojis = {
            lab: "📍", // Changed from microscope to red pin
            listing: "📦",
            "specific-item": "📦",
            service: "🛠️",
            "digital-good": "💾",
            equipment: "⚙️", // Changed from wrench to gear
            user: "👤",
        };

        // For labs, always return the red pin emoji
        if (type === "lab") {
            return "📍"; // Always use red pin for labs
        }

        // Pattern matching for equipment
        else if (type === "equipment") {
            // Imaging and optical equipment
            if (/microscope|micros|microsc/i.test(nameLower)) return "🔬"; // Changed to microscope emoji
            if (/telescope|lens|optic/i.test(nameLower)) return "🔭";
            if (/spectr|photo/i.test(nameLower)) return "📸";
            if (/laser|light|illuminat/i.test(nameLower)) return "🔆";
            if (/x-ray|xray|radiograph/i.test(nameLower)) return "📡";
            if (/mri|magnetic resonance/i.test(nameLower)) return "🧲";

            // Lab processing equipment
            if (/centrifuge|spinner/i.test(nameLower)) return "🌀";
            if (/mixer|blend|stir/i.test(nameLower)) return "🔄";
            if (/shaker|agitat/i.test(nameLower)) return "♻️";
            if (/homogeniz|disrupt/i.test(nameLower)) return "💥";
            if (/grind|mill|crush/i.test(nameLower)) return "⚒️";
            if (/filter|separat|purif/i.test(nameLower)) return "🧹";

            // Analytical equipment
            if (/spectro|chroma|mass spec/i.test(nameLower)) return "📊";
            if (/nmr|nuclear magnetic/i.test(nameLower)) return "🧪";
            if (/gc|gas chroma/i.test(nameLower)) return "📈";
            if (/hplc|liquid chroma/i.test(nameLower)) return "💧";
            if (/electro|voltage|current/i.test(nameLower)) return "⚡";
            if (/meter|measure|scale|balance/i.test(nameLower)) return "⚖️";
            if (/counter|detect/i.test(nameLower)) return "🔢";
            if (/sensor|probe/i.test(nameLower)) return "📡";
            if (/analyzer|analysis/i.test(nameLower)) return "🔬";

            // Temperature equipment
            if (/incubat|oven|furnace|heat/i.test(nameLower)) return "🔥";
            if (/freez|cold|cryo|refriger/i.test(nameLower)) return "❄️";
            if (/therm|temperature|climate/i.test(nameLower)) return "🌡️";
            if (/bath|water/i.test(nameLower)) return "💦";
            if (/dry|dehydrat/i.test(nameLower)) return "☀️";

            // Computing and automation
            if (/comput|server|process/i.test(nameLower)) return "💻";
            if (/printer|3d print/i.test(nameLower)) return "🖨️";
            if (/robot|automat/i.test(nameLower)) return "🤖";
            if (/control|monitor/i.test(nameLower)) return "🎛️";
            if (/software|program/i.test(nameLower)) return "💿";

            // Many more cases here...
        }

        // Return default emoji if no pattern matches
        return defaultEmojis[type] || "📌";
    };

    // Function to parse message text and make listing titles clickable
    const parseMessageWithClickableTitles = (
        text,
        searchResults,
        isLabSearch = false
    ) => {
        if (!text) return "";

        // Special case for welcome/error messages with pre-formatted HTML
        if (
            text.startsWith("<strong><a href=") ||
            text.startsWith("Hi there! I'm your Lab Assistant.") || // Allow initial default message
            text.includes("please <strong><a href=") ||
            text.includes("free message limit")
        ) {
            return text;
        }

        const escapeHtml = (str) => {
            if (typeof str !== "string") return str;
            return str
                .replace(/&/g, "&amp;")
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(/"/g, "&quot;")
                .replace(/'/g, "&#039;");
        };

        // --- Parsing Logic with new Markdown Processing ---
        // Create placeholders for any search results to avoid markdown processing on them
        let processedText = text;
        const placeholders = {};
        let placeholderIndex = 0;

        // Create placeholders for search results if they exist
        if (searchResults && searchResults.length > 0) {
            searchResults.forEach((result) => {
                const regex = new RegExp(`(${result.name})`, "gi");
                processedText = processedText.replace(regex, (match) => {
                    const key = `__ENTITY_${placeholderIndex++}__`;
                    placeholders[key] = {
                        type: result.type,
                        name: match,
                        url: result.url || "",
                        id: result.id || "",
                    };
                    return key;
                });
            });
        }

        // Process Markdown
        let htmlOutput = processMarkdown(processedText);

        // Replace placeholders with links
        Object.keys(placeholders).forEach((key) => {
            const { type, name, url, id } = placeholders[key];
            const emoji = getEmojiForEntity(type, name);
            const linkHtml = `<a href="${escapeHtml(
                url
            )}" class="lab-assistant-chatbot-entity-link ${type}-link" data-emoji="${escapeHtml(
                emoji
            )}" data-id="${escapeHtml(id)}" data-type="${escapeHtml(
                type
            )}" target="_blank" rel="noopener noreferrer">${escapeHtml(
                name
            )}</a>`;

            // Use RegExp to replace globally in case placeholder appears multiple times
            htmlOutput = htmlOutput.replace(new RegExp(key, "g"), linkHtml);
        });

        return htmlOutput;
    };

    // Function to handle clicking on a search result
    const handleSearchResultClick = async (result) => {
        if (!result) return;

        console.log("Clicked search result:", result);
        console.log("Result type:", result.type);

        // For lab results, we can directly navigate
        if (result.type === "lab" || result.name) {
            const url = `/lab/${result.id}/overview`;
            console.log("Using lab URL:", url);
            window.open(url, "_blank");
            return;
        }

        // For listings, verify the type from backend first
        try {
            console.log(
                "Verifying listing type from backend for ID:",
                result.id
            );
            const response = await fetch(
                `${
                    process.env.REACT_APP_API_URL || "http://localhost:8080"
                }/api/ai/verify-listing-type/${result.id}`
            );

            if (!response.ok) {
                throw new Error(
                    `Error verifying listing type: ${response.statusText}`
                );
            }

            const data = await response.json();
            console.log("Verified listing data:", data);

            // Use the URL format provided by the backend
            const url = data.urlFormat;
            console.log("Using verified URL:", url);
            window.open(url, "_blank");
        } catch (error) {
            console.error("Error verifying listing type:", error);

            // Fallback to default URL if verification fails
            let url = `/listingrental/${result.id}`;
            if (
                result.type &&
                result.type !== "equipment-rental" &&
                result.type !== "training-expertise"
            ) {
                url = `/listing/${result.id}`;
            }

            console.log("Using fallback URL:", url);
            window.open(url, "_blank");
        }
    };

    // Function to toggle visibility of reasoning trace for a message
    const toggleReasoningVisibility = (index) => {
        setMessages((prev) => {
            const newMessages = [...prev];
            if (newMessages[index]) {
                newMessages[index] = {
                    ...newMessages[index],
                    showReasoning: !newMessages[index].showReasoning,
                };
            }
            return newMessages;
        });
    };

    // New function to load user's chat history
    const loadUserChats = async () => {
        if (!user) return;

        try {
            setIsLoadingChats(true);
            const data = await fetchGet("/ai-chats", true);

            if (data.success && data.chats) {
                // Process chats to add preview text if not already present
                const processedChats = data.chats.map((chat) => {
                    // Generate preview from the last message if available
                    let preview = "Start a new conversation";

                    if (chat.lastMessage) {
                        preview =
                            chat.lastMessage.role === "assistant"
                                ? chat.lastMessage.content
                                : `You: ${chat.lastMessage.content}`;
                    }

                    // Strip markdown and HTML
                    preview = preview
                        .replace(/\*\*|__/g, "") // Remove bold
                        .replace(/\*|_/g, "") // Remove italic
                        .replace(/`/g, "") // Remove code
                        .replace(/\[([^\]]+)\]\([^)]+\)/g, "$1") // Remove links, keep text
                        .replace(/<[^>]+>/g, ""); // Remove HTML tags

                    return {
                        ...chat,
                        preview,
                        // Add a unique timestamp to ensure React detects changes
                        _lastUpdated: new Date().getTime(),
                    };
                });

                // Force re-render by creating a completely new array
                setUserChats([...processedChats]);
            }
        } catch (error) {
            console.error("Error loading chat history:", error);
        } finally {
            setIsLoadingChats(false);
        }
    };

    // Function to create a new chat
    const createNewChat = async () => {
        if (!user) return;

        try {
            // Clear the current conversation in the UI
            setMessages([
                {
                    sender: "ai",
                    text: "How can I help you?",
                },
            ]);
            setConversationHistory([]);
            setCurrentChatId(null);
            setActiveChat(null);

            // Reset the input
            setChatMessage("");
            setUploadedImage(null);
            setImagePreview(null);

            // Reset auto-scroll settings
            setAutoScrollEnabled(false);

            // Note: We don't need to create an empty chat on the server
            // The chat will be created when the user sends their first message
            // This approach prevents having empty chats in the database
        } catch (error) {
            console.error("Error creating new chat:", error);
        }
    };

    // Function to load a specific chat
    const loadChat = async (chatId) => {
        if (!user) return;

        try {
            setIsTyping(true);

            // Find the chat in our local state first if available
            const cachedChat = userChats.find((chat) => chat.id === chatId);
            if (cachedChat) {
                setActiveChat(cachedChat);
                setCurrentChatId(chatId);
            }

            const data = await fetchGet(`/ai-chats/${chatId}`, true);

            if (data.success && data.chat) {
                // Set the active chat
                setActiveChat(data.chat);
                setCurrentChatId(chatId);

                // Convert the chat messages to the format expected by the UI
                const formattedMessages = data.chat.messages.map((msg) => ({
                    sender: msg.role === "user" ? "user" : "ai",
                    text: msg.content,
                    image: msg.metadata?.hasImage ? null : undefined, // We don't store the actual image
                    timestamp: msg.timestamp,
                }));

                setMessages(formattedMessages);

                // Reset auto-scroll when loading an existing chat
                setAutoScrollEnabled(false);

                // Also convert for conversation history (API format)
                const apiFormatHistory = data.chat.messages.map((msg) => ({
                    role: msg.role,
                    content: msg.content,
                    metadata: msg.metadata,
                }));

                setConversationHistory(apiFormatHistory);

                // Update the chat in our local state with the full data
                setUserChats((prevChats) => {
                    const updatedChats = [...prevChats];
                    const chatIndex = updatedChats.findIndex(
                        (chat) => chat.id === chatId
                    );

                    if (chatIndex !== -1) {
                        updatedChats[chatIndex] = {
                            ...updatedChats[chatIndex],
                            ...data.chat,
                        };
                    }

                    return updatedChats;
                });
            }
        } catch (error) {
            console.error("Error loading chat:", error);
            // Show error message in the chat
            setMessages([
                {
                    sender: "ai",
                    text: "Sorry, there was an error loading this chat. Please try again later.",
                    isError: true,
                },
            ]);
        } finally {
            setIsTyping(false);
        }
    };

    // Function to delete a chat
    const deleteChat = async (chatId, event) => {
        if (!user || !chatId) return;

        // Stop propagation to prevent clicking the thread
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }

        // Open the delete confirmation modal instead of using window.confirm
        setChatToDelete(chatId);
        setIsDeleteModalOpen(true);
    };

    // Function to handle actual deletion after confirmation
    const handleConfirmDelete = async () => {
        if (!user || !chatToDelete) return;

        try {
            const data = await fetchDelete(`/ai-chats/${chatToDelete}`, true);

            if (data.success) {
                // Remove from the list
                setUserChats(
                    userChats.filter((chat) => chat.id !== chatToDelete)
                );

                // If we deleted the current chat, create a new one
                if (currentChatId === chatToDelete) {
                    createNewChat();
                }
            }
        } catch (error) {
            console.error("Error deleting chat:", error);
            alert("Failed to delete chat. Please try again later.");
        } finally {
            // Close the modal and reset the chat to delete
            setIsDeleteModalOpen(false);
            setChatToDelete(null);
        }
    };

    // Function to rename a chat
    const renameChat = async (chatId, newTitle) => {
        if (!user || !chatId || !newTitle) return;

        try {
            const response = await fetchPost(
                `/ai-chats/${chatId}/title`,
                { title: newTitle },
                true
            );

            if (response.success) {
                console.log("Chat title updated successfully");

                // Update the title in the local state
                setUserChats((prevChats) => {
                    const updatedChats = [...prevChats];
                    const chatIndex = updatedChats.findIndex(
                        (chat) => chat.id === chatId
                    );

                    if (chatIndex !== -1) {
                        // Create a new chat object to ensure React detects the change
                        const updatedChat = {
                            ...updatedChats[chatIndex],
                            title: newTitle,
                            // Flag to indicate this chat was just renamed (to hide timestamp)
                            _justRenamed: true,
                            // Force update on the timestamp to trigger re-render
                            updatedAt: new Date(),
                        };

                        // Update the array with the new chat
                        return [
                            ...updatedChats.slice(0, chatIndex),
                            updatedChat,
                            ...updatedChats.slice(chatIndex + 1),
                        ];
                    }

                    return updatedChats;
                });
            } else {
                console.error("Failed to update chat title:", response);
                alert("Failed to rename chat. Please try again.");
            }
        } catch (error) {
            console.error("Error renaming chat:", error);
            alert("Failed to rename chat. Please try again later.");
        }
    };

    // Process markdown formatting in a clean way, handling all emphasis correctly
    const processMarkdown = (text) => {
        if (!text || text.startsWith("<")) return text;

        let html = text;

        // Headers
        html = html
            .replace(/^### (.*?)$/gm, "<h3>$1</h3>")
            .replace(/^## (.*?)$/gm, "<h2>$1</h2>")
            .replace(/^# (.*?)$/gm, "<h1>$1</h1>");

        // Handle bold and italic with both * and _ styles
        // -- Bold (** and __)
        html = html
            .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
            .replace(/__(.*?)__/g, "<strong>$1</strong>");

        // -- Italic (* and _)
        // First, handle asterisk italic
        html = html.replace(/\*([^\s*][^*]*?[^\s*])\*/g, "<em>$1</em>");
        html = html.replace(/\*([^\s*])\*/g, "<em>$1</em>");

        // Then, handle underscore italic (with lookbehind/lookahead for boundaries)
        // Need to be careful with handling underscores in words
        html = html.replace(
            /(\s|^)_([^_\s][^_]*?[^_\s])_(\s|$|[.,;:!?])/g,
            "$1<em>$2</em>$3"
        );
        html = html.replace(
            /(\s|^)_([^_\s])_(\s|$|[.,;:!?])/g,
            "$1<em>$2</em>$3"
        );

        // Add specific patterns for common cases
        html = html.replace(
            /_([\w\s\-.,;:!?'"\(\)\/&]+)_/g,
            (match, content) => {
                // Skip if it has HTML tags already
                if (content.includes("<") || content.includes(">"))
                    return match;

                // Skip if it looks like a filename, variable, or has internal underscores
                if (content.includes("_") || /^[a-z0-9_]+$/i.test(content))
                    return match;

                // If it looks like a phrase that should be emphasized, convert it
                if (content.includes(" ") && content.length > 3) {
                    return `<em>${content}</em>`;
                }

                return match;
            }
        );

        // Lists
        html = html
            .replace(/^\s*[\*\-]\s+(.*?)$/gm, "<li>$1</li>")
            .replace(/^\s*(\d+)\.\s+(.*?)$/gm, '<li value="$1">$2</li>');

        // Group consecutive list items
        html = html.replace(
            /(<li[^>]*>.*?<\/li>)(?:\s*\n\s*)?(<li[^>]*>)/g,
            "$1$2"
        );
        html = html.replace(
            /(<li value="[^"]*">.*?<\/li>(?:\s*\n\s*)?)+/g,
            "<ol>$&</ol>"
        );
        html = html.replace(/(<li>.*?<\/li>(?:\s*\n\s*)?)+/g, "<ul>$&</ul>");

        // Code blocks
        html = html.replace(/```([\s\S]*?)```/g, "<pre><code>$1</code></pre>");

        // Inline code
        html = html.replace(/`([^`]+)`/g, "<code>$1</code>");

        // Blockquotes
        html = html.replace(/^\s*>\s+(.*?)$/gm, "<blockquote>$1</blockquote>");

        // Tables - handle pipe-based tables
        if (html.includes("|")) {
            // Extract potential tables (lines with | that form a group)
            const tableRegex = /((?:^|\n)(?:[^\n]*?\|[^\n]*?(?:\n|$)){2,})/g;

            html = html.replace(tableRegex, (tableText) => {
                if (tableText.includes("<table") || !tableText.includes("|")) {
                    return tableText;
                }

                // Split into rows
                const rows = tableText.trim().split("\n");

                // Check for header separator
                const hasHeaderSeparator =
                    rows.length > 1 &&
                    /^\s*[\|]?[\s-:]*\|[\s-:]*(?:\|[\s-:]*)*$/.test(rows[1]);

                let headerRow = hasHeaderSeparator ? rows[0] : null;
                const dataRows = rows
                    .slice(hasHeaderSeparator ? 2 : 0)
                    .filter(
                        (row) =>
                            row.includes("|") &&
                            !/^\s*[\|]?[\s-:]*\|[\s-:]*(?:\|[\s-:]*)*$/.test(
                                row
                            )
                    );

                if (dataRows.length === 0) return tableText;

                // Build table HTML
                let tableHtml = "<table>";

                // Add header if present
                if (headerRow) {
                    const headerCells = headerRow
                        .trim()
                        .split("|")
                        .map((cell) => cell.trim())
                        .filter((cell) => cell !== "");

                    if (headerCells.length > 0) {
                        tableHtml += "<thead><tr>";
                        headerCells.forEach((cell) => {
                            tableHtml += `<th>${cell}</th>`;
                        });
                        tableHtml += "</tr></thead>";
                    }
                }

                // Add data rows
                tableHtml += "<tbody>";
                dataRows.forEach((row) => {
                    if (
                        /^\s*[\|]?[\s-:]*\|[\s-:]*(?:\|[\s-:]*)*$/.test(row) ||
                        !row.includes("|")
                    ) {
                        return;
                    }

                    const cells = row
                        .trim()
                        .split("|")
                        .map((cell) => cell.trim())
                        .filter((cell) => cell !== "");

                    if (cells.length > 0) {
                        tableHtml += "<tr>";
                        cells.forEach((cell) => {
                            tableHtml += `<td>${cell}</td>`;
                        });
                        tableHtml += "</tr>";
                    }
                });

                tableHtml += "</tbody></table>";
                return tableHtml;
            });
        }

        // Links
        html = html.replace(
            /\[([^\]]+)\]\(([^)]+)\)/g,
            '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>'
        );

        // Fix any internal closing tags that were mangled
        html = html.replace(
            /<(\w+)>[^<>]*?<\/(?!\1)(\w+)>/g,
            (match, open, close) => {
                if (open.length > 1 && close.length > 1) {
                    return match.replace(`</${close}>`, `</${open}>`);
                }
                return match;
            }
        );

        return html;
    };

    // Abort any ongoing requests when unmounting
    useEffect(() => {
        return () => {
            if (abortController) {
                abortController.abort();
                console.log("Aborted AI generation due to component unmount");
            }
        };
    }, [abortController]);

    // Function to scroll the latest user message to the top of the viewport
    const scrollUserMessageToTop = () => {
        // Apply the scroll behavior for ALL user messages except the very first one in a new chat
        // At this point, the user message is already added to the messages array

        // First, check if the new message is the first user message in a new chat
        // This would be the case if we have exactly 2 messages: welcome message + first user message
        const isFirstUserMessage =
            messages.length === 2 &&
            messages[0].sender === "ai" &&
            messages[1].sender === "user";

        if (isFirstUserMessage) {
            // For the first user message, use default scrolling
            scrollToBottom();
            return;
        }

        // For all subsequent messages, position the user message at the top of the viewport
        // Wait longer for the DOM to update with the new message (150ms)
        setTimeout(() => {
            if (messagesContainerRef.current) {
                // Get all user messages
                const userMessages = document.querySelectorAll(
                    ".lab-assistant-chat-user-message"
                );

                // Ensure we have at least one user message
                if (userMessages.length > 0) {
                    // Get the last user message (the one just added)
                    const lastUserMessage =
                        userMessages[userMessages.length - 1];

                    // Get the viewport height
                    const viewportHeight =
                        messagesContainerRef.current.clientHeight;

                    // Calculate scroll position to place the user message at the top with some padding
                    // We want to leave around 15% of the viewport height above the message
                    const topOffset = Math.max(20, viewportHeight * 0.15);
                    const messageTop = lastUserMessage.offsetTop - topOffset;

                    // Scroll to position
                    messagesContainerRef.current.scrollTop = messageTop;

                    // Log for debugging
                    console.log("Positioned user message at top of viewport", {
                        messageOffset: lastUserMessage.offsetTop,
                        topOffset,
                        scrollPosition: messageTop,
                    });
                }
            }
        }, 150); // Increased timeout to ensure DOM has fully updated
    };

    return (
        <div className="lab-assistant-chat-container">
            <Sidebar
                user={user}
                userChats={userChats}
                isLoadingChats={isLoadingChats}
                currentChatId={currentChatId}
                createNewChat={createNewChat}
                loadChat={loadChat}
                deleteChat={deleteChat}
                navigate={navigate}
                renameChat={renameChat}
            />

            <div className="lab-assistant-chat-main">
                <MessageList
                    messages={messages}
                    isTyping={isTyping}
                    toggleReasoningVisibility={toggleReasoningVisibility}
                    messagesEndRef={messagesEndRef}
                    messagesContainerRef={messagesContainerRef}
                    userHasScrolled={userHasScrolled}
                    handleScroll={handleScroll}
                    scrollToBottom={scrollToBottom}
                    handleResourceClick={handleSearchResultClick}
                    handleEditMessage={handleEditMessage}
                    autoScrollEnabled={autoScrollEnabled}
                    setAutoScrollEnabled={setAutoScrollEnabled}
                />

                <MessageInput
                    chatMessage={chatMessage}
                    setChatMessage={setChatMessage}
                    isTyping={isTyping}
                    model={model}
                    setModel={setModel}
                    handleSendMessage={handleSendMessage}
                    imagePreview={imagePreview}
                    handleRemoveImage={handleRemoveImage}
                    textareaRef={textareaRef}
                    handlePaste={handlePaste}
                    user={user}
                    remainingQueries={remainingQueries}
                    subscription={subscription}
                    imageInputRef={imageInputRef}
                    onImageUpload={(file) => {
                        setUploadedImage(file);
                        setImagePreview(URL.createObjectURL(file));
                    }}
                    onStopGeneration={handleStopGeneration}
                    isEditing={!!editingMessage}
                    cancelEditing={cancelEditing}
                    autoScrollEnabled={autoScrollEnabled}
                    setAutoScrollEnabled={setAutoScrollEnabled}
                />
            </div>

            {/* Delete Confirmation Modal */}
            <div className="delete-confirmation-wrapper">
                <ModalComponent
                    title={
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#d32f2f",
                            }}
                        >
                            <WarningIcon
                                style={{ marginRight: "8px", fontSize: "24px" }}
                            />
                            Delete Chat
                        </div>
                    }
                    isOpen={isDeleteModalOpen}
                    toggle={() => setIsDeleteModalOpen(false)}
                    submitText={
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <DeleteIcon
                                style={{ marginRight: "4px", fontSize: "16px" }}
                            />
                            Delete
                        </div>
                    }
                    onSubmit={handleConfirmDelete}
                    cancelText="Cancel"
                >
                    <div
                        style={{
                            padding: "20px 15px",
                            textAlign: "center",
                            maxWidth: "400px",
                            margin: "0 auto",
                        }}
                    >
                        <p
                            style={{
                                fontSize: "16px",
                                margin: "15px 0",
                                fontWeight: "500",
                                lineHeight: "1.5",
                            }}
                        >
                            Are you sure you want to delete this chat?
                        </p>
                        <p
                            style={{
                                fontSize: "14px",
                                color: "#666",
                                marginBottom: "15px",
                                lineHeight: "1.4",
                            }}
                        >
                            This action cannot be undone.
                        </p>
                        <div
                            style={{
                                width: "100%",
                                height: "1px",
                                background: "#eee",
                                margin: "20px 0",
                            }}
                        ></div>
                    </div>
                </ModalComponent>
            </div>
        </div>
    );
};

export default LabAssistantChat;
