import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    sendEmailVerification,
    signInWithEmailAndPassword,
    signOut,
    updateProfile,
} from "@firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase-config";
import { fetchGet, fetchPost } from "../services/data-service";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const sendVerificationEmail = (user) => {
        sendEmailVerification(user)
            .then(() => {
                console.log("Verification email sent.");
            })
            .catch((error) => {
                console.error("Error sending verification email:", error);
            });
    };

    const signupPI = async (userData, labData) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                userData.email,
                userData.password
            );

            await updateProfile(userCredential.user, {
                displayName: userData.firstName,
            });

            const { password, ...userDataWithoutPassword } = userData;
            const { logo, ...labDataWithoutLogo } = labData;
            userDataWithoutPassword.id = userCredential.user.uid;
            labDataWithoutLogo.piId = userCredential.user.uid;
            labDataWithoutLogo.status = "pending";

            const formData = new FormData();
            formData.append("user", JSON.stringify(userDataWithoutPassword));
            formData.append("lab", JSON.stringify(labDataWithoutLogo));
            formData.append("logo", logo);

            await fetchPost(`/users?hasLab=true`, formData, false);

            await sendVerificationEmail(userCredential.user);
        } catch (error) {
            console.error("Error signing up PI:", error);
            throw error; // Re-throw to handle in the component
        }
    };
    const signup = async (userData) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                userData.email,
                userData.password
            );

            await updateProfile(userCredential.user, {
                displayName: userData.firstName,
            });

            const { password, ...userDataWithoutPassword } = userData;
            userDataWithoutPassword.id = userCredential.user.uid;
            userDataWithoutPassword.labId = null;
            await fetchPost(
                `/users`,
                {
                    user: userDataWithoutPassword,
                },
                false
            );

            sendVerificationEmail(userCredential.user);
        } catch (error) {
            console.error("Error signing up:", error);
            throw error; // Re-throw to handle in the component
        }
    };

    const login = async (email, password) => {
        await signInWithEmailAndPassword(auth, email, password);
        console.log("login");
    };

    const logout = () => {
        try {
            signOut(auth);
            navigate("/");
            setUser(null);
        } catch (err) {
            console.log(err.message);
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            try {
                if (currentUser) {
                    // Fetch user data and followed labs in parallel
                    // Softfix: Add a delay to ensure the user document is created
                    await new Promise((resolve) => setTimeout(resolve, 1000));
                    const [userData, followedLabs] = await Promise.all([
                        fetchGet(`/users/${currentUser.uid}`, false),
                        fetchGet(`/users/${currentUser.uid}/followedLabs`),
                    ]);

                    Object.assign(currentUser, userData);
                    currentUser.followedLabs = followedLabs;
                    setUser(currentUser);
                } else {
                    setUser(null);
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
                setUser(null);
            } finally {
                setLoading(false);
            }
        });
        return () => unsubscribe();
    }, []);

    return (
        <AuthContext.Provider
            value={{
                signupPI,
                signup,
                login,
                logout,
                sendVerificationEmail,
                user,
                loading,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const UserAuth = () => {
    return useContext(AuthContext);
};
