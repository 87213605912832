import { fetchGet } from "./data-service";

const FETCH_LIMIT = 12;
const NEWS_FETCH_LIMIT = 6;

export const LabCacheParams = {
    staleTime: 10 * 1000 * 60,
    cacheTime: 15 * 1000 * 60,
};

export const fetchLabs = async ({
    pageParam: lastVisible = null,
    status = "approved",
    query = null,
}) => {
    try {
        let queryParams = new URLSearchParams();
        queryParams.append("limit", FETCH_LIMIT);
        if (status) {
            queryParams.append("status", status);
        }
        if (lastVisible) {
            queryParams.append("lastVisible", lastVisible);
        }
        if (query) {
            queryParams.append("query", query);
        }

        const res = await fetchGet(`/labs?${queryParams.toString()}`);

        for (const lab of res.documents) {
            if (!lab.temporary) {
                const pi = await fetchGet(`/users/${lab.piId}`, false);
                lab.piName = `${pi.firstName} ${pi.lastName}`;
            } else {
                const members = await fetchGet(
                    `/labs/${lab.id}/members`,
                    false
                );
                const pi = members.members[0];
                lab.piName = `${pi.firstName} ${pi.lastName}`;
            }
        }

        return res;
    } catch (error) {
        console.error("Error fetching labs:", error);
        throw error;
    }
};
export const fetchLab = async ({ queryKey }) => {
    const [_, labId, { banner = false } = {}] = queryKey;
    try {
        const res = await fetchGet(`/labs/${labId}?banner=${banner}`);
        return res;
    } catch (error) {
        console.error("Error fetching lab:", error);
        throw error;
    }
};
export const fetchLabMembers = async ({ queryKey }) => {
    const [
        _,
        labId,
        { groupDescription = false, personalDescription = false } = {},
    ] = queryKey;
    try {
        console.log(groupDescription, personalDescription);
        const res = await fetchGet(
            `/labs/${labId}/members?groupDescription=${groupDescription}&personalDescription=${personalDescription}`
        );
        return res;
    } catch (error) {
        console.error("Error fetching lab members:", error);
        throw error;
    }
};

export const fetchLabMember = async ({ queryKey }) => {
    try {
        const [_, labId, , userId] = queryKey;
        const res = await fetchGet(`/labs/${labId}/members/${userId}`);
        return res;
    } catch (error) {
        console.error("Error fetching lab member:", error);
        throw error;
    }
};

export const fetchLabFollowers = async ({ queryKey }) => {
    const [_, labId] = queryKey;
    try {
        const res = await fetchGet(`/labs/${labId}/followers`);
        return res;
    } catch (error) {
        console.error("Error fetching lab followers:", error);
        throw error;
    }
};
export const fetchLabPublications = async ({ queryKey }) => {
    const [_, labId] = queryKey;
    try {
        const res = await fetchGet(`/labs/${labId}/publications`);
        return res;
    } catch (error) {
        console.error("Error fetching lab publications:", error);
        throw error;
    }
};

export const fetchLabRequests = async ({ queryKey }) => {
    const [_, labId, __, { status }] = queryKey;
    try {
        const res = await fetchGet(`/labs/${labId}/requests?status=${status}`);
        return res;
    } catch (error) {
        console.error("Error fetching lab requests:", error);
        throw error;
    }
};

export const fetchLabListings = async ({
    pageParam: lastVisible = null,
    labId = null,
}) => {
    try {
        let res;
        if (labId) {
            let queryParams = new URLSearchParams();
            queryParams.append("limit", FETCH_LIMIT);

            if (lastVisible) {
                queryParams.append("lastVisible", lastVisible);
            }

            res = await fetchGet(
                `/labs/${labId}/listings?${queryParams.toString()}`,
                false
            );
        }
        return res;
    } catch (error) {
        console.error("Error fetching listings:", error);
        throw error;
    }
};

export const fetchLabNews = async ({
    pageParam: lastVisible = null,
    labId = null,
}) => {
    try {
        let res;
        if (labId) {
            let queryParams = new URLSearchParams();
            queryParams.append("limit", NEWS_FETCH_LIMIT);

            if (lastVisible) {
                queryParams.append("lastVisible", lastVisible);
            }

            res = await fetchGet(
                `/labs/${labId}/news?${queryParams.toString()}`,
                false
            );
        }
        return res;
    } catch (error) {
        console.error("Error fetching news:", error);
        throw error;
    }
};
