import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TypeBadge } from "../../helpers/ListingHelpers";
import { Loading } from "../../components";
import { fetchLabListings, LabCacheParams } from "../../services/lab-api";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Handshake as HandshakeIcon } from "@mui/icons-material";
import { useInView } from "react-intersection-observer";
import { CircularProgress } from "@mui/material";
import "../../styles/LabProfileTabs/LabListings.css";

const stripHtmlTags = (html) => {
    if (!html) return "";
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
};

const LabListings = () => {
    const { labId } = useParams();
    const navigate = useNavigate();
    const { ref, inView } = useInView();

    const {
        data: listings,
        isLoading: listingsLoading,
        fetchNextPage: fetchNextPaginatedListings,
        isFetchingNextPage,
    } = useInfiniteQuery({
        queryKey: ["lab", labId, "listings"],
        queryFn: ({ pageParam }) =>
            fetchLabListings({
                pageParam,
                labId,
            }),
        ...LabCacheParams,
        getNextPageParam: (lastPage) =>
            lastPage.hasMore ? lastPage.lastVisible : null,
    });

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                fetchNextPaginatedListings();
            }, 500);
        }
    }, [inView, fetchNextPaginatedListings]);

    if (listingsLoading) {
        return <Loading />;
    }

    return (
        <div className="lab-profile-content">
            <div className="lab-profile-content-listings-section-wrapper">
                <h3>Technologies & Resources</h3>
                <div className="lab-profile-content-listings-grid">
                    {listings.pages.map((page) =>
                        page.documents.map((listing) => (
                            <div
                                key={listing.id}
                                className="lab-listings-listing browse-listings-listing"
                                onClick={() => {
                                    if (
                                        listing.type === "equipment-rental" ||
                                        listing.type === "training-expertise"
                                    )
                                        navigate(
                                            `/listingrental/${listing.id}`
                                        );
                                    else {
                                        navigate(`/listing/${listing.id}`);
                                    }
                                }}
                            >
                                <img
                                    src={listing.img}
                                    className="lab-listings-listing-img"
                                    alt=""
                                />
                                <div className="lab-listings-listing-info">
                                    <div className="lab-listings-listing-name">
                                        {listing.title}
                                    </div>
                                    <div className="lab-listings-listing-description">
                                        {stripHtmlTags(
                                            listing.description
                                        ).substring(0, 300)}
                                        {stripHtmlTags(listing.description)
                                            .length > 300
                                            ? "..."
                                            : ""}
                                    </div>
                                    <div className="lab-listings-listing-footer">
                                        <div className="lab-listings-listing-type">
                                            <TypeBadge
                                                type={listing.type}
                                                style={{ fontSize: "16px" }}
                                            />
                                        </div>
                                        {listing.isCollaborative && (
                                            <div className="lab-listings-listing-collaborative">
                                                <HandshakeIcon
                                                    style={{ fontSize: "16px" }}
                                                />
                                                Collaborative
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
            <div ref={ref} className="lab-listings-pagination">
                {isFetchingNextPage && (
                    <div className="lab-listings-pagination-loading">
                        <CircularProgress
                            size={24}
                            style={{
                                color: "var(--primary-color)",
                                border: "10px",
                            }}
                        />
                        <span>Loading listings...</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LabListings;
