import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UserAuth } from "../../context/auth-context";
import {
    useInfiniteQuery,
    useQueryClient,
    useMutation,
} from "@tanstack/react-query";
import { ModalComponent } from "../../components";
import {
    Add as AddIcon,
    AddPhotoAlternate as AddPhotoAlternateIcon,
    ArrowBackIosNew as ArrowBackIosNewIcon,
    ArrowForwardIos as ArrowForwardIosIcon,
    Close as CloseIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    Link as LinkIcon,
} from "@mui/icons-material";
import { fetchDelete, fetchPost, fetchPut } from "../../services/data-service";
import { fetchLabNews, LabCacheParams } from "../../services/lab-api";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { Loading } from "../../components";
import { useInView } from "react-intersection-observer";
import { CircularProgress } from "@mui/material";
import "../../styles/LabManagerTabs/NewsManager.css";

const NewsManager = () => {
    const { labId } = useParams();
    const { user } = UserAuth();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [newsItem, setNewsItem] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [newsToDelete, setNewsToDelete] = useState(null);
    const queryClient = useQueryClient();
    const { ref, inView } = useInView();

    const methods = useForm();

    const {
        data: news,
        isLoading: newsLoading,
        fetchNextPage: fetchNextPaginatedNews,
        isFetchingNextPage,
    } = useInfiniteQuery({
        queryKey: ["lab", labId, "news"],
        queryFn: ({ pageParam }) =>
            fetchLabNews({
                pageParam,
                labId,
            }),
        ...LabCacheParams,
        getNextPageParam: (lastPage) =>
            lastPage.hasMore ? lastPage.lastVisible : null,
    });

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                fetchNextPaginatedNews();
            }, 500);
        }
    }, [inView, fetchNextPaginatedNews]);

    const { mutate: deleteNewsMutation } = useMutation({
        mutationFn: () => confirmDelete(),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["lab", labId, "news"],
            });
            setDeleteModalOpen(false);
        },
        onError: () => {
            console.error("Failed to follow delete news.");
        },
    });

    const { mutate: saveNewsMutation } = useMutation({
        mutationFn: () => handleSaveNews(),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["lab", labId, "news"],
            });
            setIsModalOpen(false);
        },
        onError: () => {
            console.error("Failed to follow delete news.");
        },
    });

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleEditNews = (newsItem) => {
        const formattedImages = (newsItem.images || []).map((url, idx) => ({
            url: url,
            ref: newsItem.imageRefs?.[idx],
        }));

        setNewsItem({
            ...newsItem,
            images: formattedImages,
        });
        setIsModalOpen(true);
    };

    const handleDeleteNews = (newsItem) => {
        setNewsToDelete(newsItem);
        setDeleteModalOpen(true);
    };

    const confirmDelete = async () => {
        try {
            await fetchDelete(`/labs/${labId}/news/${newsToDelete.id}`, true);
            setDeleteModalOpen(false);
            setNewsToDelete(null);
        } catch (error) {
            console.error("Error deleting news:", error);
        }
    };

    const handleSaveNews = async () => {
        const data = methods.getValues("news");
        const dirtyFields = methods.formState.dirtyFields?.news;
        const { images, ...newsData } = data;

        let dataToSave;
        if (newsData.id && dirtyFields) {
            dataToSave = Object.entries(newsData)
                .filter(([key]) => dirtyFields[key])
                .reduce((acc, [key, value]) => {
                    acc[key] = value;
                    return acc;
                }, {});

            const formData = new FormData();
            let filesToDelete = [];
            for (let img of images) {
                if (img.shouldAdd) {
                    formData.append(img.url, img.file);
                }
                if (img.shouldDelete) {
                    filesToDelete.push(img.ref);
                }
            }
            dataToSave.filesToDelete = filesToDelete;
            dataToSave.updatedAt = new Date();

            formData.append("news", JSON.stringify(dataToSave));

            await fetchPut(`/labs/${labId}/news/${newsData.id}`, formData);
        } else if (!newsData.id) {
            dataToSave = {
                ...newsData,
                timestamp: new Date(),
                createdAt: new Date(),
                updatedAt: new Date(),
                labId: labId,
                type: "news",
                author: user.firstName + " " + user.lastName,
                authorId: user.uid,
            };

            const formData = new FormData();
            formData.append("news", JSON.stringify(dataToSave));
            images.forEach((image, idx) => {
                formData.append(`image_${idx}`, image.file);
            });

            await fetchPost(`/labs/${labId}/news`, formData);
        }
    };

    const handleCloseEditor = () => {
        setIsModalOpen(false);
        methods.reset();
        setNewsItem(null);
    };

    const ImageCarousel = ({ images }) => {
        const [currentIndex, setCurrentIndex] = useState(0);

        if (!images || images.length === 0) return null;
        if (images.length === 1) {
            return (
                <div className="news-item-image-container">
                    <img src={images[0]} alt="" className="carousel-image" />
                </div>
            );
        }

        return (
            <div className="carousel-container">
                <button
                    className="carousel-arrow left"
                    onClick={() =>
                        setCurrentIndex((prev) =>
                            prev === 0 ? images.length - 1 : prev - 1
                        )
                    }
                >
                    <ArrowBackIosNewIcon />
                </button>
                <img
                    src={images[currentIndex]}
                    alt=""
                    className="carousel-image"
                />
                <button
                    className="carousel-arrow right"
                    onClick={() =>
                        setCurrentIndex((prev) =>
                            prev === images.length - 1 ? 0 : prev + 1
                        )
                    }
                >
                    <ArrowForwardIosIcon />
                </button>
            </div>
        );
    };

    // Helper function to format dates consistently
    const formatDate = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    };

    if (newsLoading) {
        return <Loading />;
    }

    return (
        <div className="news-manager">
            <div className="news-manager-header">
                <h1 className="listings-manager-title">Lab News</h1>
                <button
                    className="news-manager-add-button"
                    onClick={handleEditNews}
                >
                    <AddIcon /> Add News
                </button>
            </div>

            <ModalComponent
                title="Confirm Delete"
                isOpen={deleteModalOpen}
                toggle={() => {
                    setDeleteModalOpen(false);
                    setNewsToDelete(null);
                }}
                submitText="Delete"
                onSubmit={deleteNewsMutation}
                isDeleteModal={true}
            >
                <div className="delete-confirmation-content">
                    <p>Are you sure you want to delete this news item?</p>
                    <p className="delete-title">"{newsToDelete?.title}"</p>
                    <p className="delete-warning">
                        This action cannot be undone.
                    </p>
                </div>
            </ModalComponent>

            <FormProvider {...methods}>
                {!isMobile ? (
                    <ModalComponent
                        title={newsItem ? "Edit News" : "Add News"}
                        isOpen={isModalOpen}
                        toggle={handleCloseEditor}
                        submitText="Save News"
                        onSubmit={saveNewsMutation}
                    >
                        <NewsEditorContent newsItem={newsItem} />
                    </ModalComponent>
                ) : (
                    <>
                        <div
                            className={`news-editor-overlay ${
                                newsItem || isModalOpen ? "active" : ""
                            }`}
                            onClick={handleCloseEditor}
                        />
                        <div
                            className={`news-editor-slideup ${
                                newsItem || isModalOpen ? "active" : ""
                            }`}
                        >
                            <div className="news-editor-card">
                                <button
                                    className="news-editor-close-button"
                                    onClick={handleCloseEditor}
                                >
                                    ×
                                </button>
                                <h2>{newsItem ? "Edit News" : "Add News"}</h2>
                                <NewsEditorContent newsItem={newsItem} />
                                <div className="news-editor-footer">
                                    <button onClick={saveNewsMutation}>
                                        Save News
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </FormProvider>

            <div className="news-manager-content">
                {news.pages.map((page) =>
                    page.documents
                        .sort((a, b) => {
                            const dateA = new Date(a.timestamp);
                            const dateB = new Date(b.timestamp);
                            return dateB - dateA;
                        })
                        .map((item) => (
                            <div key={item.id} className="news-item">
                                <div className="news-item-display">
                                    <h3>{item.title}</h3>
                                    <p>{item.content}</p>
                                    <ImageCarousel images={item.images} />
                                    <div className="news-item-actions">
                                        <span className="news-item-date">
                                            {formatDate(item.timestamp)}
                                        </span>
                                        <div className="action-buttons">
                                            <button
                                                onClick={() =>
                                                    handleEditNews(item)
                                                }
                                            >
                                                <EditIcon /> Edit
                                            </button>
                                            <button
                                                onClick={() =>
                                                    handleDeleteNews(item)
                                                }
                                            >
                                                <DeleteIcon /> Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                )}
            </div>
            <div ref={ref} className="news-manager-pagination">
                {isFetchingNextPage && (
                    <div
                        className="news-manager-pagination-loading"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            padding: "20px 0",
                            gap: "10px",
                        }}
                    >
                        <CircularProgress
                            size={24}
                            style={{
                                color: "var(--primary-color)",
                                border: "10px",
                            }}
                        />
                        <span>Loading news...</span>
                    </div>
                )}
            </div>
        </div>
    );
};

// Extract editor content to avoid duplication
const NewsEditorContent = ({ newsItem }) => {
    const { register, reset, watch, setValue } = useFormContext();
    const [linkModalOpen, setLinkModalOpen] = useState(false);
    const [linkDetails, setLinkDetails] = useState({ text: "", url: "" });
    const [selectionRange, setSelectionRange] = useState(null);

    useEffect(() => {
        reset({
            news: {
                ...(newsItem?.id && { id: newsItem?.id }),
                ...(newsItem?.createdAt && { createdAt: newsItem?.createdAt }),
                ...(newsItem?.updatedAt && { updatedAt: newsItem?.updatedAt }),
                title: newsItem?.title || "",
                content: newsItem?.content || "",
                images: newsItem?.images || [],
            },
        });
    }, [newsItem]);

    const images = watch("news.images", []);

    const handleAddLink = () => {
        const textarea = document.querySelector(".news-item-editor textarea");
        const selectedText = textarea.value.substring(
            textarea.selectionStart,
            textarea.selectionEnd
        );

        if (selectedText) {
            setSelectionRange({
                start: textarea.selectionStart,
                end: textarea.selectionEnd,
            });
            setLinkDetails({ text: selectedText, url: "" });
            setLinkModalOpen(true);
        } else {
            alert("Please select some text to add a link");
        }
    };

    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files);
        const newImages = files.map((file) => ({
            file,
            url: URL.createObjectURL(file),
            shouldAdd: true,
        }));

        setValue("news.images", [...images, ...newImages], {
            shouldDirty: true,
        });
    };

    const handleDeleteImage = (index) => {
        let updatedImages = [];

        images.forEach((img, i) => {
            if (i === index) {
                if (img.ref) {
                    updatedImages.push({ ...img, shouldDelete: true });
                }
            } else {
                updatedImages.push(img);
            }
        });

        setValue("news.images", updatedImages, {
            shouldDirty: true,
        });
    };

    const handleLinkSubmit = () => {
        if (linkDetails.url) {
            const linkMarkdown = `[${linkDetails.text}](${linkDetails.url})`;
            const content = watch("news.content") || "";

            const newContent =
                content.substring(0, selectionRange.start) +
                linkMarkdown +
                content.substring(selectionRange.end);

            setValue("news.content", newContent, { shouldDirty: true });
        }
        setLinkModalOpen(false);
    };
    return (
        <>
            <ModalComponent
                title="Add Link"
                isOpen={linkModalOpen}
                toggle={() => setLinkModalOpen(false)}
                submitText="Insert Link"
                onSubmit={handleLinkSubmit}
            >
                <div className="link-modal-content">
                    <input
                        type="text"
                        placeholder="Link Text"
                        value={linkDetails.text}
                        onChange={(e) =>
                            setLinkDetails({
                                ...linkDetails,
                                text: e.target.value,
                            })
                        }
                    />
                    <input
                        type="url"
                        placeholder="URL (https://...)"
                        value={linkDetails.url}
                        onChange={(e) =>
                            setLinkDetails({
                                ...linkDetails,
                                url: e.target.value,
                            })
                        }
                    />
                </div>
            </ModalComponent>
            <div className="news-item-editor">
                <input
                    type="text"
                    placeholder="News Title"
                    {...register("news.title")}
                />
                <div className="textarea-container">
                    <textarea
                        placeholder="News Content"
                        {...register("news.content")}
                    />
                    <button className="add-link-button" onClick={handleAddLink}>
                        <LinkIcon /> Add Link
                    </button>
                </div>
                <div className="news-item-image-upload">
                    {images.map(
                        (image, index) =>
                            /** Did not use filter() because we want to actually keep the deleted images and we need images to retain correct indices */
                            !image.shouldDelete && (
                                <div
                                    key={index}
                                    className="news-item-image-preview"
                                    style={{
                                        backgroundImage: `url(${image.url})`,
                                    }}
                                >
                                    <button
                                        className="delete-image-button"
                                        onClick={() => handleDeleteImage(index)}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </button>
                                </div>
                            )
                    )}
                    {images.filter((image) => !image.shouldDelete).length <
                        9 && (
                        <label className="news-item-image-preview image-upload-placeholder">
                            <input
                                type="file"
                                accept="image/*"
                                multiple
                                style={{ display: "none" }}
                                onChange={handleImageUpload}
                            />
                            <AddPhotoAlternateIcon />
                            <span>Add Image</span>
                        </label>
                    )}
                </div>
            </div>
        </>
    );
};

export default NewsManager;
