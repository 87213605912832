import { createContext, useContext, useState, useEffect } from "react";
import { getActiveSubscription } from "../services/subscription-service";
import { UserAuth } from "./auth-context";
import { fetchPut } from "../services/data-service";

const SubscriptionContext = createContext();

export const useSubscription = () => {
    return useContext(SubscriptionContext);
};

export const SubscriptionContextProvider = ({ children }) => {
    const [subscription, setSubscription] = useState(null);
    const [remainingQueries, setRemainingQueries] = useState(null);
    const { user } = UserAuth();

    // Fetch initial subscription and query data when user loads
    useEffect(() => {
        const fetchUserData = async () => {
            if (!user) {
                setSubscription(null);
                setRemainingQueries(null);
                return;
            }

            try {
                const data = await getActiveSubscription();
                console.log("Subscription data:", data);
                setSubscription(data);
                setRemainingQueries(user.remainingQueries ?? 5);
            } catch (error) {
                console.error("Error fetching user data:", error);
                // Set default values on error
                setSubscription(null);
                setRemainingQueries(5);
            }
        };
        fetchUserData();
    }, [user]);

    // Function to update remaining queries
    const updateRemainingQueries = async () => {
        if (!user || subscription?.status === "active") return;

        try {
            const data = await fetchPut(
                `/users/${user.uid}/subscription/remaining-queries`,
                {
                    decrementQueries: true,
                }
            );
            setRemainingQueries(data.remainingQueries);
            return data.remainingQueries;
        } catch (error) {
            console.error("Error updating query count:", error);
            throw error;
        }
    };

    const value = {
        subscription,
        setSubscription,
        remainingQueries,
        setRemainingQueries,
        updateRemainingQueries,
    };

    return (
        <SubscriptionContext.Provider value={value}>
            {children}
        </SubscriptionContext.Provider>
    );
};

export default SubscriptionContextProvider;
