import React, { useState, useEffect, useCallback } from "react";
import { createApi } from "unsplash-js";
import { useFormContext } from "react-hook-form";
import ModalComponent from "./ModalComponent";
import Spinner from "./Spinner"; // Changed to default import
import "../styles/UnsplashSearchModal.css"; // We will create this CSS file

const UNSPLASH_ACCESS_KEY = process.env.REACT_APP_UNSPLASH_ACCESS_KEY;

const unsplash = createApi({
    accessKey: UNSPLASH_ACCESS_KEY,
});

const UnsplashSearchModal = ({ isOpen, onClose, onSelectImage }) => {
    const { getValues } = useFormContext();
    const [searchTerm, setSearchTerm] = useState("");
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    // Function to fetch images from Unsplash
    const fetchImages = useCallback(async (query, pageNum) => {
        if (!query) return;
        setIsLoading(true);
        setError(null);
        try {
            const result = await unsplash.search.getPhotos({
                query: query,
                page: pageNum,
                perPage: 12, // Adjust as needed
            });

            if (result.errors) {
                console.error("Unsplash API Error:", result.errors);
                setError("Failed to fetch images from Unsplash.");
                setImages([]);
                setTotalPages(0);
            } else {
                const { results, total_pages } = result.response;
                // If it's the first page, replace images, otherwise append
                setImages((prev) =>
                    pageNum === 1 ? results : [...prev, ...results]
                );
                setTotalPages(total_pages);
            }
        } catch (err) {
            console.error("Error fetching from Unsplash:", err);
            setError("An error occurred while fetching images.");
            setImages([]);
            setTotalPages(0);
        }
        setIsLoading(false);
    }, []);

    // Effect to prefill search term and fetch images when modal opens
    useEffect(() => {
        if (isOpen) {
            const title = getValues("fields.title") || "";
            setSearchTerm(title);
            setPage(1); // Reset page count when modal opens
            setImages([]); // Clear previous results
            if (title) {
                fetchImages(title, 1);
            }
        } else {
            // Reset state when modal closes
            setSearchTerm("");
            setImages([]);
            setIsLoading(false);
            setError(null);
            setPage(1);
            setTotalPages(0);
        }
    }, [isOpen, getValues, fetchImages]);

    // Handle search input change
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // Handle search form submission
    const handleSearchSubmit = (event) => {
        event.preventDefault(); // Prevent form submission
        setPage(1); // Reset page to 1 for new search
        setImages([]); // Clear previous results before new search
        fetchImages(searchTerm, 1);
    };

    // Handle selecting an image
    const handleImageSelect = async (image) => {
        if (!image.urls.regular) return; // Ensure URL exists
        setIsLoading(true); // Show loading indicator while fetching blob
        try {
            // Fetch the image data as a blob
            const response = await fetch(image.urls.regular);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const blob = await response.blob();

            // Create a File object
            const fileName = `${image.id}.jpg`; // Or derive from URL/description
            const file = new File([blob], fileName, { type: blob.type });

            // Trigger the Unsplash download endpoint (best practice)
            try {
                await unsplash.photos.trackDownload({
                    downloadLocation: image.links.download_location,
                });
            } catch (trackError) {
                console.warn("Could not track Unsplash download:", trackError);
                // Proceed even if tracking fails
            }

            // Pass the File object back to the parent component
            onSelectImage(file);
        } catch (err) {
            console.error("Error fetching or converting image:", err);
            setError(
                "Could not load the selected image. Please try another one."
            );
        } finally {
            setIsLoading(false); // Hide loading indicator
        }
    };

    // Load more images
    const loadMoreImages = () => {
        if (!isLoading && page < totalPages) {
            const nextPage = page + 1;
            setPage(nextPage);
            fetchImages(searchTerm, nextPage);
        }
    };

    return (
        <ModalComponent
            title="Search Stock Photos (Unsplash)"
            isOpen={isOpen}
            toggle={onClose}
            // No submit button needed in the modal footer itself
            // size="lg" // Consider making the modal larger
        >
            <div className="unsplash-modal-content">
                <form
                    onSubmit={(e) => e.preventDefault()}
                    className="unsplash-search-form"
                >
                    <input
                        type="text"
                        placeholder="Search for images..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="unsplash-search-input"
                        onKeyDown={(e) => {
                            if (e.key === "Enter" && searchTerm) {
                                e.preventDefault();
                                handleSearchSubmit(e);
                            }
                        }}
                    />
                    <button
                        type="button"
                        className="unsplash-search-button"
                        disabled={isLoading || !searchTerm}
                        onClick={handleSearchSubmit}
                    >
                        Search
                    </button>
                </form>

                {error && <p className="unsplash-error">{error}</p>}

                <div className="unsplash-image-grid">
                    {images.map((image) => (
                        <div
                            key={image.id}
                            className="unsplash-image-item"
                            onClick={() =>
                                !isLoading && handleImageSelect(image)
                            }
                        >
                            <img
                                src={image.urls.thumb}
                                alt={image.alt_description || "Unsplash image"}
                                title={
                                    image.alt_description || "Click to select"
                                }
                                loading="lazy"
                            />
                            <div className="unsplash-image-overlay">
                                <p>{image.user.name}</p>
                            </div>
                        </div>
                    ))}
                </div>

                {isLoading && <Spinner />}

                {!isLoading && page < totalPages && (
                    <button
                        onClick={loadMoreImages}
                        className="unsplash-load-more"
                        disabled={isLoading}
                    >
                        Load More
                    </button>
                )}
                {!isLoading && images.length === 0 && searchTerm && !error && (
                    <p>
                        No images found for "{searchTerm}". Try a different
                        search.
                    </p>
                )}
            </div>
        </ModalComponent>
    );
};

export default UnsplashSearchModal;
