import {
    getAuth,
    setPersistence,
    browserLocalPersistence,
} from "firebase/auth";
import { fetchGet, fetchPost } from "./data-service";

/**
 * Creates a Stripe customer for the current user and initiates checkout
 * @param {string} tier - The subscription tier (INDIVIDUAL, LAB)
 * @param {Object} metadata - Additional metadata to include with the checkout
 */
export const checkoutSubscription = async (tier, metadata = {}) => {
    console.log(`Starting checkout process for tier: ${tier}`);

    const auth = getAuth();

    // Set persistence to LOCAL to maintain the auth state during redirects
    try {
        await setPersistence(auth, browserLocalPersistence);
        console.log("Authentication persistence set to LOCAL");
    } catch (error) {
        console.error("Error setting persistence:", error);
    }

    const user = auth.currentUser;

    if (!user) {
        throw new Error(
            `You must be logged in to subscribe to a tier. Please login and try again.`
        );
    }

    try {
        // Check if user already has an active subscription for this tier
        const currentSubscription = await getActiveSubscription();
        if (
            currentSubscription &&
            currentSubscription.status === "active" &&
            currentSubscription.tier &&
            currentSubscription.tier.toUpperCase() === tier
        ) {
            throw new Error(
                `You already have an active ${tier} subscription. You cannot subscribe to the same tier twice.`
            );
        }

        // If attempting to subscribe to LAB tier, verify that the user is a PI
        if (tier === "LAB") {
            try {
                // Get the user's lab information to verify PI status
                const userResponse = await fetchGet(`/users/${user.uid}`, true);
                const labId = userResponse.labId || metadata.labId;

                if (!labId) {
                    throw new Error(
                        "You must be part of a lab to subscribe to the LAB tier."
                    );
                }

                const labResponse = await fetchGet(`/labs/${labId}`, true);

                if (!labResponse || labResponse.piId !== user.uid) {
                    throw new Error(
                        "Only Principal Investigators (PI) can subscribe to the LAB tier."
                    );
                }

                // Ensure labId is in metadata
                metadata.labId = labId;
            } catch (error) {
                console.error("Error verifying PI status:", error);
                throw new Error(
                    error.message ||
                        "Only Principal Investigators can subscribe to the LAB tier."
                );
            }
        }

        // Create checkout session with user information
        const sessionResponse = await fetchPost(
            "/stripe/checkout-session",
            {
                tier,
                email: user.email,
                name: user.displayName || user.email,
                successUrl: `${window.location.origin}/subscription-success`,
                cancelUrl: `${window.location.origin}/pricing`,
                metadata: metadata, // Include metadata in the request
            },
            true
        );

        // Redirect to checkout
        window.location.href = sessionResponse.url;
    } catch (error) {
        console.error("Error during checkout process:", error);
        throw new Error(error.message || "Failed to start checkout process");
    }
};

/**
 * Checks if the current user is eligible to subscribe to the Lab tier
 * @returns {Promise<Object>} Eligibility status and details
 */
export const checkLabTierEligibility = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
        console.log("No user logged in");
        return {
            isEligible: false,
            reason: "You must be logged in to check eligibility.",
        };
    }

    try {
        // Call the backend endpoint to check eligibility
        const response = await fetchGet(
            "/subscriptions/check-lab-eligibility",
            true
        );
        console.log("Lab tier eligibility response:", response);
        return response;
    } catch (error) {
        console.error("Error checking lab tier eligibility:", error);
        return {
            isEligible: false,
            reason: error.message || "Failed to check lab tier eligibility.",
        };
    }
};

/**
 * Handles subscription checkout for Individual tier
 */
export const checkoutIndividualSubscription = async () => {
    await checkoutSubscription("INDIVIDUAL");
};

/**
 * Handles subscription checkout for Lab tier
 */
export const checkoutLabSubscription = async () => {
    // First check if user is eligible
    const eligibilityCheck = await checkLabTierEligibility();

    if (!eligibilityCheck.isEligible) {
        throw new Error(
            eligibilityCheck.reason ||
                "You are not eligible for a Lab tier subscription."
        );
    }

    // Extract labId from eligibility check response
    const labId = eligibilityCheck.labId;

    // If eligible, proceed with checkout
    await checkoutSubscription("LAB", { labId });
};

/**
 * Retrieves active subscription details for the current user
 * @returns {Promise<Object>} Subscription details
 */
export const getActiveSubscription = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
        console.log("No user logged in");
        return null;
    }

    try {
        //console.log("Fetching subscription status for user:", user.uid);
        // Fetch subscription details from the subscription controller endpoint
        const response = await fetchGet("/subscriptions/status", true);
        //console.log("Subscription status response:", response);
        return response.data;
    } catch (error) {
        console.error("Error fetching subscription:", error);
        // Return default inactive subscription status on error
        return {
            status: "inactive",
            tier: null,
        };
    }
};

export default {
    checkoutIndividualSubscription,
    checkoutLabSubscription,
    checkoutSubscription,
    getActiveSubscription,
    checkLabTierEligibility,
};
