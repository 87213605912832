import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Close as CloseIcon, Search as SearchIcon } from "@mui/icons-material";
import imagePlaceholder from "../assets/image-placeholder.png";
import "../styles/CreateListingStyles.css"; // Assuming styles are needed
// TODO: Import UnsplashSearchModal when created
import UnsplashSearchModal from "./UnsplashSearchModal";

const ImageUpload = ({ index }) => {
    const { setValue, getValues, watch } = useFormContext();
    // Local state to manage the specific image URL for display
    const [imageUrl, setImageUrl] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

    // Watch for changes in the specific image within the form state
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            // Check if the change is relevant to this specific image index
            if (name && name.startsWith(`fields.imgs[${index}]`)) {
                const imgData = getValues(`fields.imgs[${index}]`);
                setImageUrl(imgData?.url || null);
            } else if (name === "fields.imgs" && type === "change") {
                // Handle cases where the whole array might be reset or changed
                const imgData = getValues(`fields.imgs[${index}]`);
                setImageUrl(imgData?.url || null);
            }
        });

        // Set initial state
        const initialImgData = getValues(`fields.imgs[${index}]`);
        setImageUrl(initialImgData?.url || null);

        return () => subscription.unsubscribe();
    }, [watch, index, getValues]);

    const handleImageChange = (file) => {
        if (!file) return;
        const currentImages = getValues("fields.imgs") || Array(3).fill({});
        const updatedImages = [...currentImages];
        const existingImg = updatedImages[index] || {};

        // Create a new object to avoid mutating the existing one directly
        const newImgData = { ...existingImg };

        // If the previous image had a ref (meaning it was already saved), mark it for deletion
        if (newImgData.ref) {
            newImgData.shouldDelete = true;
        }
        // Set new file and URL
        newImgData.file = file;
        newImgData.url = URL.createObjectURL(file);

        // Remove shouldDelete if it was set from a previous state but is now being replaced by a new file upload
        // (This scenario might need refinement depending on exact edit logic requirements)
        // if (newImgData.shouldDelete && newImgData.file) {
        //     delete newImgData.shouldDelete;
        // }

        updatedImages[index] = newImgData;
        setValue("fields.imgs", updatedImages, { shouldDirty: true });
        setImageUrl(newImgData.url); // Update local state for immediate display
    };

    const handleImageDelete = () => {
        const currentImages = getValues("fields.imgs") || Array(3).fill({});
        const updatedImages = [...currentImages];
        const imgToDelete = updatedImages[index] || {};

        // Prepare the update: Mark for deletion if it has a ref, otherwise just clear it
        const update = imgToDelete.ref
            ? { shouldDelete: true, ref: imgToDelete.ref }
            : {}; // Keep ref if marking for deletion

        updatedImages[index] = update;
        setValue("fields.imgs", updatedImages, { shouldDirty: true });
        setImageUrl(null); // Update local state
    };

    const handlePasteButton = async () => {
        try {
            const clipboardItems = await navigator.clipboard.read();
            for (const clipboardItem of clipboardItems) {
                for (const type of clipboardItem.types) {
                    if (type.startsWith("image/")) {
                        const blob = await clipboardItem.getType(type);
                        // Convert blob to file
                        const file = new File(
                            [blob],
                            `pasted_image_${Date.now()}.${type.split("/")[1]}`,
                            { type }
                        );
                        handleImageChange(file);
                        return; // Stop after finding the first image
                    }
                }
            }
            alert("No image found in clipboard.");
        } catch (err) {
            console.error("Failed to read clipboard:", err);
            // Show a user-friendly message, e.g., asking for permission or indicating an error.
            alert(
                "Could not paste image. Please ensure you have granted clipboard permissions or try uploading the file directly."
            );
        }
    };

    // Function to handle image selection from the modal
    const handleSelectFromModal = (imageFile) => {
        handleImageChange(imageFile);
        setIsModalOpen(false); // Close modal after selection
    };

    return (
        <>
            <label className="image-upload-label">
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e.target.files[0])}
                    style={{ display: "none" }}
                    // Add a key based on imageUrl to force re-render if the URL is cleared,
                    // allowing the same file to be selected again after deletion.
                    key={imageUrl || `input-${index}`}
                />
                <div
                    className="image-upload-square"
                    style={{
                        backgroundImage: `url(${imageUrl || imagePlaceholder})`,
                        border: imageUrl ? "none" : "2px dashed #ccc", // Keep border for placeholder
                    }}
                >
                    {!imageUrl && (
                        <div className="image-upload-instructions">
                            <span>Click to upload</span>
                        </div>
                    )}
                </div>
                {index === 0 && <span className="image-label">Primary</span>}
                <div className="image-actions">
                    {!imageUrl && (
                        <>
                            <button
                                type="button"
                                className="paste-image-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handlePasteButton();
                                }}
                            >
                                Paste Image
                            </button>
                            {/* Added Search Stock Photos Button */}
                            <button
                                type="button"
                                className="paste-image-button" // Reusing style, consider a unique class if needed
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setIsModalOpen(true); // Open the modal
                                }}
                            >
                                <SearchIcon
                                    fontSize="small"
                                    style={{ marginRight: "4px" }}
                                />
                                Search Stock Photos
                            </button>
                        </>
                    )}
                    {imageUrl && (
                        <button
                            type="button" // Ensure it's type="button" to prevent form submission
                            className="delete-image"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleImageDelete();
                            }}
                        >
                            <CloseIcon style={{ fontSize: "16px" }} />
                        </button>
                    )}
                </div>
            </label>
            {/* Render UnsplashSearchModal */}
            <UnsplashSearchModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSelectImage={handleSelectFromModal}
            />
        </>
    );
};

export default ImageUpload;
