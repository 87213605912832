import React, { useEffect, useRef } from "react";
import "../styles/components/ModalComponent.css";

const ModalComponent = ({
    title,
    isOpen,
    toggle,
    children,
    cancelText = "Cancel",
    onCancel = toggle,
    submitText,
    disabled,
    onSubmit,
}) => {
    const modalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                toggle();
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
            // Remove body scrolling prevention - allow outside scrolling
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            // Always restore body scrolling on component unmount
            document.body.style.overflow = "";
        };
    }, [isOpen, toggle]);

    // Handle wheel events to prevent scroll propagation
    const handleWheelEvent = (e) => {
        // Only prevent propagation, don't stop the event completely
        // This allows the modal to scroll while preventing the outside from scrolling
        // only when the mouse is over the modal
        e.stopPropagation();
    };

    return (
        isOpen && (
            <div className="modal">
                <div
                    className="modal-content"
                    ref={modalRef}
                    onWheel={handleWheelEvent}
                >
                    <div className="modal-header">
                        {title && <h3 className="modal-title">{title}</h3>}
                        <button
                            type="button"
                            className="modal-close-button"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                toggle();
                            }}
                        >
                            &times;
                        </button>
                    </div>
                    <div className="modal-body" onWheel={handleWheelEvent}>
                        <div style={{ flex: 1 }}>{children}</div>
                    </div>
                    {submitText && onSubmit && (
                        <div className="modal-footer">
                            <button
                                className="modal-cancel-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onCancel();
                                }}
                            >
                                {cancelText}
                            </button>
                            <button
                                className="modal-submit-button"
                                disabled={disabled}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onSubmit();
                                }}
                            >
                                {submitText}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        )
    );
};

export default ModalComponent;
