import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useInfiniteQuery } from "@tanstack/react-query";
import { fetchLabNews, LabCacheParams } from "../../services/lab-api";
import { Loading } from "../../components";
import {
    ArrowBackIosNew as ArrowBackIosNewIcon,
    ArrowForwardIos as ArrowForwardIosIcon,
} from "@mui/icons-material";
import { useInView } from "react-intersection-observer";
import { CircularProgress } from "@mui/material";
import "../../styles/LabProfileTabs/News.css";

// Format date to "February 28, 2024" format
const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
};

const NewsImageCarousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    if (!images || images.length === 0) return null;
    if (images.length === 1) {
        return (
            <div className="lab-profile-news-image">
                <img src={images[0]} alt="" />
            </div>
        );
    }

    return (
        <div className="lab-profile-news-image carousel">
            <button
                className="lab-profile-carousel-arrow left"
                onClick={(e) => {
                    e.stopPropagation();
                    setCurrentIndex((prev) =>
                        prev === 0 ? images.length - 1 : prev - 1
                    );
                }}
            >
                <ArrowBackIosNewIcon />
            </button>
            <img src={images[currentIndex]} alt="" />
            <button
                className="lab-profile-carousel-arrow right"
                onClick={(e) => {
                    e.stopPropagation();
                    setCurrentIndex((prev) =>
                        prev === images.length - 1 ? 0 : prev + 1
                    );
                }}
            >
                <ArrowForwardIosIcon />
            </button>
        </div>
    );
};

const News = () => {
    const { labId } = useParams();
    const { ref, inView } = useInView();

    const {
        data: news,
        isLoading: newsLoading,
        fetchNextPage: fetchNextPaginatedNews,
        isFetchingNextPage,
    } = useInfiniteQuery({
        queryKey: ["lab", labId, "news"],
        queryFn: ({ pageParam }) =>
            fetchLabNews({
                pageParam,
                labId,
            }),
        ...LabCacheParams,
        getNextPageParam: (lastPage) =>
            lastPage.hasMore ? lastPage.lastVisible : null,
    });

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                fetchNextPaginatedNews();
            }, 500);
        }
    }, [inView, fetchNextPaginatedNews]);

    const processContent = (content) => {
        const urlRegex = /\[(.*?)\]\((https?:\/\/[^\s)]+)\)/g;
        let lastIndex = 0;
        const parts = [];
        let match;

        while ((match = urlRegex.exec(content)) !== null) {
            // Add text before the match
            if (match.index > lastIndex) {
                parts.push(content.slice(lastIndex, match.index));
            }

            // Add the link component
            parts.push(
                <a
                    key={match.index}
                    href={match[2]}
                    className="lab-profile-news-link"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {match[1]}
                </a>
            );

            lastIndex = match.index + match[0].length;
        }

        // Add remaining text
        if (lastIndex < content.length) {
            parts.push(content.slice(lastIndex));
        }
        return parts;
    };

    if (newsLoading) {
        return <Loading />;
    }

    return (
        <div className="lab-profile-content">
            <div className="lab-profile-content-news-section-wrapper">
                <h3>News</h3>
                <div className="lab-profile-news">
                    {news.pages.map((page) =>
                        page.documents.map((item) => (
                            <div
                                key={item.id}
                                className="lab-profile-news-item"
                            >
                                <div className="lab-profile-news-content">
                                    <h2>{item.title}</h2>
                                    <p>{processContent(item.content)}</p>
                                    <NewsImageCarousel
                                        images={
                                            item.images ||
                                            (item.imageUrl
                                                ? [item.imageUrl]
                                                : [])
                                        }
                                    />
                                    <div className="lab-profile-news-footer">
                                        <span className="lab-profile-news-author">
                                            Posted by {item.author}
                                        </span>
                                        <span className="lab-profile-news-date">
                                            {formatDate(item.timestamp)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
            <div ref={ref} className="lab-news-pagination">
                {isFetchingNextPage && (
                    <div className="lab-news-pagination-loading">
                        <CircularProgress
                            size={24}
                            style={{
                                color: "var(--primary-color)",
                                border: "10px",
                            }}
                        />
                        <span>Loading news...</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default News;
