import React from "react";
import { parseMessageWithClickableEntities, getEmojiForEntity } from "./utils";
import { Edit as EditIcon } from "@mui/icons-material";

/**
 * Message component renders an individual chat message
 */
const Message = ({
    message,
    index,
    toggleReasoningVisibility,
    handleResourceClick,
    handleEditMessage,
}) => {
    return (
        <div
            id={`message-${message.id}`}
            className={`lab-assistant-chat-message ${
                message.sender === "user"
                    ? "lab-assistant-chat-user-message"
                    : "lab-assistant-chat-ai-message"
            } ${message.isError ? "lab-assistant-chat-error-message" : ""} ${
                message.hasReferencedEntities ? "has-referenced-entities" : ""
            }`}
        >
            <div className="lab-assistant-chat-message-content">
                {message.sender === "user" && (
                    <button
                        className="lab-assistant-chat-edit-message-btn"
                        onClick={() => handleEditMessage(message)}
                        aria-label="Edit message"
                    >
                        <EditIcon fontSize="small" />
                    </button>
                )}
                <div
                    className="lab-assistant-chat-message-text"
                    dangerouslySetInnerHTML={{
                        __html: message.isThinking
                            ? message.text
                            : parseMessageWithClickableEntities(
                                  message.text,
                                  message.referencedEntities,
                                  message.hasLabReferences
                              ),
                    }}
                />

                {/* Show image if message has one */}
                {message.image && (
                    <div className="lab-assistant-chat-message-image">
                        <img
                            src={message.image}
                            alt="User uploaded"
                            className="lab-assistant-chat-uploaded-image"
                        />
                    </div>
                )}

                {/* Display reasoning trace if available */}
                {message.hasReasoning && (
                    <div
                        className={`lab-assistant-chat-reasoning ${
                            message.isGenerating
                                ? "lab-assistant-chat-reasoning-generating"
                                : ""
                        }`}
                    >
                        <div
                            className="lab-assistant-chat-reasoning-header"
                            onClick={() => toggleReasoningVisibility(index)}
                        >
                            <span>
                                Reasoning{" "}
                                {message.showReasoning
                                    ? "(Click to hide)"
                                    : "(Click to show)"}
                            </span>
                        </div>
                        {message.showReasoning && (
                            <div className="lab-assistant-chat-reasoning-content">
                                {message.reasoning}
                            </div>
                        )}
                    </div>
                )}

                {/* Show search results if available */}
                {message.isSearchResults &&
                    message.searchResults &&
                    message.searchResults.length > 0 && (
                        <div className="lab-assistant-chat-search-results">
                            {message.searchResults.map((result, idx) => (
                                <div
                                    key={idx}
                                    className="lab-assistant-chat-search-result-item"
                                    onClick={() => handleResourceClick(result)}
                                >
                                    <h4>{result.title}</h4>

                                    {/* Show lab/entity info if available */}
                                    {result.labName && (
                                        <div
                                            className="lab-assistant-chat-result-lab"
                                            data-emoji={getEmojiForEntity(
                                                "lab",
                                                result.labName
                                            )}
                                        >
                                            {result.labName}
                                        </div>
                                    )}

                                    {/* Show matched equipment if available */}
                                    {result.matchedEquipment &&
                                        result.matchedEquipment.length > 0 &&
                                        result.matchedEquipment.map(
                                            (equipment, eqIdx) => (
                                                <div
                                                    key={eqIdx}
                                                    className="lab-assistant-chat-result-equipment"
                                                    data-emoji={getEmojiForEntity(
                                                        "equipment",
                                                        equipment.title
                                                    )}
                                                >
                                                    <strong>
                                                        {equipment.title}
                                                    </strong>
                                                    {equipment.description && (
                                                        <div className="lab-assistant-chat-result-equipment-desc">
                                                            {
                                                                equipment.description
                                                            }
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        )}

                                    {/* Relevance explanation */}
                                    {result.relevance && (
                                        <div className="lab-assistant-chat-result-relevance">
                                            {result.relevance}
                                        </div>
                                    )}

                                    {/* Highlights */}
                                    {result.highlights && (
                                        <div className="lab-assistant-chat-result-highlights">
                                            {result.highlights}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
            </div>
        </div>
    );
};

export default Message;
