import "../styles/Footer.css";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "react-router-dom";

function Footer() {
    let navigate = useNavigate();
    return (
        <footer>
            <div className="footerLinks footerWrapper">
                <div className="footerLogo" onClick={() => navigate("/")}>
                    <p>LABGIANT LOGO</p>
                </div>

                <div className="footerExplore">
                    <h5>Explore</h5>
                    <ul className="footerExploreLinks">
                        <li>
                            <Link to={`/listings`}>View Listings</Link>
                        </li>
                        <li>
                            <Link to={`/labs`}>View Labs</Link>
                        </li>
                        <li>
                            <Link to={`/labsignup`}>Register Your Lab</Link>
                        </li>
                        <li>
                            <Link to={`/?mode=login`}>Login/Sign Up</Link>
                        </li>
                    </ul>
                </div>

                <div className="footerAbout">
                    <h5>About</h5>
                    <ul className="footerAboutLinks">
                        <li>
                            <Link to={`/`}>Our Why</Link>
                        </li>
                        <li>
                            <Link to={`/`}>Our Team</Link>
                        </li>
                        <li>
                            <Link to={`/`}>Careers</Link>
                        </li>
                    </ul>
                </div>

                <div className="footerSupport">
                    <h5>Support</h5>
                    <ul className="footerSupportLinks">
                        <li>
                            <Link to={`/`}>Support Request</Link>
                        </li>
                        <li>
                            <Link to={`/`}>Contact Us</Link>
                        </li>
                    </ul>
                </div>
                <div className="FooterSocials">
                    <h5>Follow Us</h5>
                    <ul className="footerSocialLinks">
                        <li>
                            <Link to={`/`}>
                                <FacebookIcon></FacebookIcon>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/`}>
                                <InstagramIcon></InstagramIcon>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/`}>
                                <LinkedInIcon></LinkedInIcon>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="footerCopyright footerWrapper">
                <p>© 2022 LabGiant</p>
                <a href="/">Privacy Policy</a>
            </div>
        </footer>
    );
}

export default Footer;
