import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    ArrowForward as ArrowRight,
    CheckCircle,
    Person as User,
    ErrorOutline,
} from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { fetchPost } from "../services/data-service";

import "../styles/LabTransfer.css";
import { fetchGet } from "../services/data-service";
import { Loading } from "../components";
import { UserAuth } from "../context/auth-context";

const LabTransferPage = () => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [labInfo, setLabInfo] = useState(null);
    const [tokenStatus, setTokenStatus] = useState("loading"); // "loading", "valid", "expired", "accepted"
    const [errorMessage, setErrorMessage] = useState("");
    const location = useLocation();
    const navigate = useNavigate();
    const { logout } = UserAuth();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            firstName: "",
            lastName: "",
            password: "",
            confirmPassword: "",
        },
    });

    const onSubmit = (data) => {
        // Validate and process form
        const submitLabTransfer = async (data) => {
            const response = await fetchPost(
                `/admin/lab-transfer/accept`,
                {
                    ...data,
                    token: location.search.split("=")[1],
                },
                false
            );
        };
        submitLabTransfer(data);
        setFormSubmitted(true);
    };

    useEffect(() => {
        // Extract token from URL query parameters
        const fetchLabInfo = async () => {
            try {
                const searchParams = new URLSearchParams(location.search);
                const token = searchParams.get("token");

                if (!token) {
                    setTokenStatus("expired");
                    setErrorMessage(
                        "Invalid token. Please check your link and try again."
                    );
                    return;
                }

                const labTransferToken = await fetchGet(
                    `/admin/lab-transfer/token/${token}`
                );

                console.log(labTransferToken);

                // Check if token is expired or already accepted
                if (
                    new Date(labTransferToken.expiresAt) < new Date() ||
                    labTransferToken.status === "expired"
                ) {
                    setTokenStatus("expired");
                    setErrorMessage("This transfer link has expired.");
                    return;
                }

                if (labTransferToken.status === "accepted") {
                    setTokenStatus("accepted");
                    setErrorMessage("This lab has already been transferred.");
                    return;
                }

                // Token is valid, fetch lab info
                const lab = await fetchGet(
                    `/labs/${labTransferToken.labId}?overview=true`
                );
                setLabInfo({ ...lab, email: labTransferToken.email });
                setTokenStatus("valid");
            } catch (error) {
                console.error("Error fetching lab transfer info:", error);
                setTokenStatus("expired");
                setErrorMessage("An error occurred. Please try again later.");
            }
        };
        fetchLabInfo();
    }, [location]);

    const handleReturnToDashboard = () => {
        navigate("/");
    };

    // Show loading state
    if (tokenStatus === "loading") {
        return <Loading />;
    }

    // Show error page for expired or already accepted tokens
    if (tokenStatus === "expired" || tokenStatus === "accepted") {
        return (
            <div className="lab-transfer-container">
                <div className="lab-transfer-card">
                    <div className="lab-transfer-error-message">
                        <ErrorOutline
                            style={{ fontSize: 64, color: "#f44336" }}
                            className="lab-transfer-error-icon"
                        />
                        <h2>
                            {tokenStatus === "expired"
                                ? "Invalid or Expired Link"
                                : "Already Transferred"}
                        </h2>
                        <p>{errorMessage}</p>
                        <button
                            className="lab-transfer-return-button"
                            onClick={handleReturnToDashboard}
                        >
                            Return to Dashboard
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    // Show loading if lab info is not yet available
    if (!labInfo) {
        return <Loading />;
    }

    return (
        <div className="lab-transfer-container">
            <div className="lab-transfer-card">
                <h1 className="lab-transfer-page-title">
                    Lab Transfer Request
                </h1>

                {!formSubmitted ? (
                    <>
                        <div className="lab-transfer-info-section">
                            <div className="lab-transfer-header">
                                <div className="lab-transfer-logo">
                                    <img
                                        src={labInfo.logo || "/placeholder.svg"}
                                        alt="Lab Logo"
                                    />
                                </div>
                                <div className="lab-transfer-title">
                                    <h2>{labInfo.name}</h2>
                                </div>
                            </div>

                            <div className="lab-transfer-description">
                                <div
                                    className="lab-transfer-content"
                                    dangerouslySetInnerHTML={{
                                        __html: labInfo.sections[0].content,
                                    }}
                                />
                            </div>
                        </div>

                        <div className="lab-transfer-visualization">
                            <div className="lab-transfer-from">
                                <div className="lab-transfer-user-circle">
                                    <User size={24} />
                                </div>
                                <span>Current PI</span>
                            </div>

                            <div className="lab-transfer-arrow">
                                <ArrowRight size={32} />
                            </div>

                            <div className="lab-transfer-to">
                                <div className="lab-transfer-user-circle highlight">
                                    <User size={24} />
                                </div>
                                <span>New PI (You)</span>
                            </div>
                        </div>

                        <div className="lab-transfer-form-section">
                            <h3>Create Your Profile</h3>
                            <p className="lab-transfer-form-description">
                                Please provide your information to complete the
                                lab transfer process.
                            </p>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="lab-transfer-form-row">
                                    <div className="lab-transfer-form-group">
                                        <label
                                            className="lab-transfer-label"
                                            htmlFor="firstName"
                                        >
                                            First Name
                                        </label>
                                        <input
                                            className="lab-transfer-input"
                                            type="text"
                                            id="firstName"
                                            aria-invalid={
                                                errors.firstName
                                                    ? "true"
                                                    : "false"
                                            }
                                            {...register("firstName", {
                                                required:
                                                    "First name is required",
                                            })}
                                        />
                                        {errors.firstName && (
                                            <span
                                                className="lab-transfer-error"
                                                role="alert"
                                            >
                                                {errors.firstName.message}
                                            </span>
                                        )}
                                    </div>

                                    <div className="lab-transfer-form-group">
                                        <label
                                            className="lab-transfer-label"
                                            htmlFor="lastName"
                                        >
                                            Last Name
                                        </label>
                                        <input
                                            className="lab-transfer-input"
                                            type="text"
                                            id="lastName"
                                            aria-invalid={
                                                errors.lastName
                                                    ? "true"
                                                    : "false"
                                            }
                                            {...register("lastName", {
                                                required:
                                                    "Last name is required",
                                            })}
                                        />
                                        {errors.lastName && (
                                            <span
                                                className="lab-transfer-error"
                                                role="alert"
                                            >
                                                {errors.lastName.message}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="lab-transfer-form-group">
                                    <label
                                        className="lab-transfer-label"
                                        htmlFor="email"
                                    >
                                        Email Address
                                    </label>
                                    <input
                                        className="lab-transfer-input"
                                        type="email"
                                        id="email"
                                        value={labInfo.email}
                                        disabled
                                    />
                                </div>

                                <div className="lab-transfer-form-group lab-transfer-password-group">
                                    <label
                                        className="lab-transfer-label"
                                        htmlFor="password"
                                    >
                                        Password
                                    </label>
                                    <div className="lab-transfer-password-input">
                                        <input
                                            className="lab-transfer-input"
                                            type={
                                                passwordVisible
                                                    ? "text"
                                                    : "password"
                                            }
                                            id="password"
                                            aria-invalid={
                                                errors.password
                                                    ? "true"
                                                    : "false"
                                            }
                                            {...register("password", {
                                                required:
                                                    "Password is required",
                                                minLength: {
                                                    value: 8,
                                                    message:
                                                        "Password must be at least 8 characters",
                                                },
                                            })}
                                        />
                                        <button
                                            type="button"
                                            className="lab-transfer-toggle-password"
                                            onClick={() =>
                                                setPasswordVisible(
                                                    !passwordVisible
                                                )
                                            }
                                        >
                                            {passwordVisible ? "Hide" : "Show"}
                                        </button>
                                    </div>
                                    {errors.password ? (
                                        <p
                                            className="lab-transfer-error"
                                            role="alert"
                                        >
                                            {errors.password.message}
                                        </p>
                                    ) : (
                                        <p className="lab-transfer-password-hint">
                                            Password must be at least 8
                                            characters
                                        </p>
                                    )}
                                </div>

                                <div className="lab-transfer-form-group">
                                    <label
                                        className="lab-transfer-label"
                                        htmlFor="confirmPassword"
                                    >
                                        Confirm Password
                                    </label>
                                    <input
                                        className="lab-transfer-input"
                                        type="password"
                                        id="confirmPassword"
                                        aria-invalid={
                                            errors.confirmPassword
                                                ? "true"
                                                : "false"
                                        }
                                        {...register("confirmPassword", {
                                            required:
                                                "Please confirm your password",
                                            validate: (value, formValues) =>
                                                value === formValues.password ||
                                                "Passwords do not match",
                                        })}
                                    />
                                    {errors.confirmPassword && (
                                        <span
                                            className="lab-transfer-error"
                                            role="alert"
                                        >
                                            {errors.confirmPassword.message}
                                        </span>
                                    )}
                                </div>

                                <div className="lab-transfer-form-footer">
                                    <button
                                        type="submit"
                                        className="lab-transfer-submit-button"
                                    >
                                        Complete Transfer
                                    </button>
                                </div>
                            </form>
                        </div>
                    </>
                ) : (
                    <div className="lab-transfer-success-message">
                        <CheckCircle
                            style={{ fontSize: 64 }}
                            className="lab-transfer-success-icon"
                        />
                        <h2>Transfer Request Submitted</h2>
                        <p>
                            Your request to transfer "{labInfo.name}" has been
                            submitted successfully. You will receive a
                            confirmation email shortly.
                        </p>
                        <div className="lab-transfer-options">
                            <button
                                className="lab-transfer-return-button"
                                onClick={handleReturnToDashboard}
                            >
                                Return to Newsfeed
                            </button>
                            <button
                                className="lab-transfer-signin-button"
                                onClick={logout}
                            >
                                Sign in to New Account
                                <span className="lab-transfer-button-note">
                                    (Will log out current user)
                                </span>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LabTransferPage;
