import specificItemImage from "../assets/specific-item.png";
import equipmentRentalImage from "../assets/equipment-rental.png";
import serviceImage from "../assets/service.png";
import digitalGoodImage from "../assets/digital-good.png";

export const quantityUnits = [
    "unit",
    "L",
    "kg",
    "bottle",
    "pack",
    "mL",
    "uL",
    "g",
    "mg",
    "ug",
    "cm",
    "cc",
    "cf",
    "m^3",
    "fl oz",
    "gal",
    "g",
    "iu",
    "mg",
    "ml",
    "mm",
    "mmol",
    "nmol",
    "oz",
    "pt",
    "lb",
    "qt",
    "slug",
    "tablet",
    "vial",
    "minute",
    "hour",
    "other",
];

export const timeUnits = ["minute", "hour"];

export const stateOptions = ["Solid", "Liquid", "Gas"];

export const listingCategories = [
    {
        id: "specific-item",
        value: "Specific Item",
        image: specificItemImage,
    },
    {
        id: "equipment-rental",
        value: "Equipment Rental",
        image: equipmentRentalImage,
    },
    {
        id: "service",
        value: "Service",
        image: serviceImage,
    },
    {
        id: "digital-good",
        value: "Digital Good",
        image: digitalGoodImage,
    },
];

export const licensingTypes = [
    {
        value: "Proprietary",
        label: "Proprietary",
    },
    {
        value: "Open-Source",
        label: "Open-Source",
    },
    {
        value: "3rd-Party",
        label: "3rd-party",
    },
    {
        value: "Subscription",
        label: "Subscription",
    },
];

export const fieldsToStringify = [
    "isCollaborative",
    "labMember",
    "price",
    "unavailabilities",
    "directBuying",
];

export const mandatoryListingFields = [
    {
        name: "createdAt",
        type: "date",
    },
    {
        name: "description",
        type: "string",
    },
    {
        name: "isCollaborative",
        type: "boolean",
    },
    {
        name: "labId",
        type: "string",
    },
    {
        name: "labMember",
        type: "string",
    },
    {
        name: "labName",
        type: "string",
    },
    {
        name: "licensingType",
        type: "string",
    },
    {
        name: "title",
        type: "string",
    },
    {
        name: "type",
        type: "string",
    },
];
