import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "@uidotdev/usehooks";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import { fetchGet } from "../../services/data-service";

const Search = () => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState("");
    const debouncedSearchQuery = useDebounce(searchQuery, 300);
    const [searchResults, setSearchResults] = useState({
        labs: [],
        listings: [],
    });

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.target.blur();
            navigate(`/listings?query=${searchQuery}`);
        }
    };

    useEffect(() => {
        const fetchSearchResults = async () => {
            if (debouncedSearchQuery.length < 2) {
                setSearchResults({ labs: [], listings: [] });
                return;
            }

            try {
                const labs = await fetchGet(
                    "/labs/search?query=" + debouncedSearchQuery
                );

                const listings = await fetchGet(
                    "/listings/search?query=" + debouncedSearchQuery
                );

                setSearchResults({ labs, listings });
            } catch (error) {
                console.error("Error fetching search results:", error);
                setSearchResults({ labs: [], listings: [] });
            }
        };
        fetchSearchResults();
    }, [debouncedSearchQuery]);

    return (
        <div className="searchBarWrapper">
            <div className="search">
                <div className="searchIconWrapper">
                    <SearchIcon style={{ fontSize: "18px" }} />
                </div>
                <input
                    value={searchQuery}
                    className="styled-input-base-input"
                    placeholder="Labs, Technologies, Services..."
                    aria-label="search"
                    onChange={handleSearchChange}
                    onKeyDown={handleKeyDown}
                    onFocus={() => {
                        document
                            .querySelector(".pageContent")
                            .classList.add("dimmed");
                        document
                            .querySelector(".search-results-container")
                            .classList.add("visible");
                        document
                            .querySelector(".closeIconWrapper")
                            .classList.add("visible");
                    }}
                    onBlur={() => {
                        document
                            .querySelector(".pageContent")
                            .classList.remove("dimmed");
                        document
                            .querySelector(".search-results-container")
                            .classList.remove("visible");
                        document
                            .querySelector(".closeIconWrapper")
                            .classList.remove("visible");
                    }}
                />
                <div
                    onMouseDown={() => {
                        setSearchQuery("");
                    }}
                    className="closeIconWrapper"
                >
                    <CancelIcon style={{ fontSize: "14px", color: "gray" }} />
                </div>
            </div>
            <div className="search-results-container">
                <div
                    className="search-results-header"
                    onMouseDown={() => navigate(`/labs?query=${searchQuery}`)}
                >
                    Labs
                </div>
                <div className="search-results-list">
                    {searchResults.labs.map((lab) => (
                        <div
                            key={lab.id}
                            onMouseDown={() =>
                                navigate(`/lab/${lab.id}/overview`)
                            }
                        >
                            <SearchIcon style={{ fontSize: "12px" }} />
                            {lab.name}
                        </div>
                    ))}
                    {searchQuery && searchResults.labs.length === 0 && (
                        <div
                            onMouseDown={() =>
                                navigate(`/labs?query=${searchQuery}`)
                            }
                        >
                            <SearchIcon style={{ fontSize: "12px" }} />
                            {searchQuery}
                        </div>
                    )}
                </div>
                <div
                    className="search-results-header"
                    onMouseDown={() =>
                        navigate(`/listings?query=${searchQuery}`)
                    }
                >
                    Technologies, Resources, Services
                </div>
                <div className="search-results-list">
                    {searchResults.listings.map((listing) => (
                        <div
                            key={listing.id}
                            onMouseDown={() =>
                                navigate(`/listing/${listing.id}`)
                            }
                        >
                            <SearchIcon style={{ fontSize: "12px" }} />
                            {listing.title}
                        </div>
                    ))}
                    {searchQuery && searchResults.listings.length === 0 && (
                        <div
                            onMouseDown={() =>
                                navigate(`/listings?query=${searchQuery}`)
                            }
                        >
                            <SearchIcon style={{ fontSize: "12px" }} />
                            {searchQuery}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Search;
