import { auth } from "../firebase-config";
const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL; // Replace with your actual API base URL

// Function to get the current user's ID token
async function getIdToken() {
    const user = auth.currentUser;
    if (user) {
        return user.getIdToken();
    }
    throw new Error("No user is signed in");
}

// Updated generic GET request function
async function fetchGet(endpoint, userAuth = false) {
    try {
        // console.log("Making GET request to:", `${BASE_URL}${endpoint}`);
        const headers = {
            "Content-Type": "application/json",
        };
        if (userAuth) {
            const idToken = await getIdToken();
            console.log("Using auth token:", idToken.substring(0, 10) + "...");
            headers["Authorization"] = `Bearer ${idToken}`;
        }
        // console.log("Request headers:", headers);
        const response = await fetch(`${BASE_URL}${endpoint}`, {
            method: "GET",
            headers: headers,
        });

        // console.log("Response status:", response.status);
        if (!response.ok) {
            const errorText = await response.text();
            console.error("Error response:", errorText);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        //console.log("Response data:", data);
        return data;
    } catch (error) {
        console.error("Error in fetchGet:", error);
        throw error;
    }
}

// Updated generic POST request function
async function fetchPost(endpoint, data, userAuth = true) {
    try {
        const headers = {
            ...(!(data instanceof FormData) && {
                "Content-Type": "application/json",
            }),
        };
        if (userAuth) {
            const idToken = await getIdToken();
            headers["Authorization"] = `Bearer ${idToken}`;
        }
        const response = await fetch(`${BASE_URL}${endpoint}`, {
            method: "POST",
            headers: headers,
            body: data instanceof FormData ? data : JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error in fetchPost:", error);
        throw error;
    }
}

// New generic PUT request function
async function fetchPut(endpoint, data, userAuth = true) {
    try {
        const headers = {
            ...(!(data instanceof FormData) && {
                "Content-Type": "application/json",
            }),
        };

        if (userAuth) {
            const idToken = await getIdToken();
            headers["Authorization"] = `Bearer ${idToken}`;
        }

        const response = await fetch(`${BASE_URL}${endpoint}`, {
            method: "PUT",
            headers,
            body: data instanceof FormData ? data : JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error in fetchPut:", error);
        throw error;
    }
}

// New generic PATCH request function
async function fetchPatch(endpoint, data, userAuth = true) {
    try {
        const headers = {
            "Content-Type": "application/json",
        };
        if (userAuth) {
            const idToken = await getIdToken();
            headers["Authorization"] = `Bearer ${idToken}`;
        }
        const response = await fetch(`${BASE_URL}${endpoint}`, {
            method: "PATCH",
            headers: headers,
            body: data instanceof FormData ? data : JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error in fetchPatch:", error);
        throw error;
    }
}

// New generic DELETE request function
async function fetchDelete(endpoint, userAuth = true) {
    try {
        const headers = {
            "Content-Type": "application/json",
        };
        if (userAuth) {
            const idToken = await getIdToken();
            headers["Authorization"] = `Bearer ${idToken}`;
        }
        const response = await fetch(`${BASE_URL}${endpoint}`, {
            method: "DELETE",
            headers: headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error in fetchDelete:", error);
        throw error;
    }
}

// Generic function for multipart form data requests with file uploads
async function fetchMultipart(
    endpoint,
    formData,
    method = "POST",
    userAuth = true
) {
    try {
        if (!(formData instanceof FormData)) {
            throw new Error("Data must be an instance of FormData");
        }

        const headers = {};
        // Don't set Content-Type for multipart/form-data - browser will set it with boundary

        if (userAuth) {
            const idToken = await getIdToken();
            headers["Authorization"] = `Bearer ${idToken}`;
        }

        const response = await fetch(`${BASE_URL}${endpoint}`, {
            method,
            headers,
            body: formData,
        });

        // Check if the response is ok (status in the range 200-299)
        if (!response.ok) {
            // Try to parse the error response as JSON
            let errorData;
            try {
                errorData = await response.json();
            } catch (e) {
                // If parsing fails, use the status text
                throw new Error(
                    `HTTP error! status: ${response.status} ${response.statusText}`
                );
            }

            // If we have error data, throw a more detailed error
            if (errorData && errorData.error) {
                throw new Error(errorData.error);
            } else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        }

        return await response.json();
    } catch (error) {
        console.error(`Error in fetchMultipart (${method}):`, error);
        throw error;
    }
}

// Generic fetch with authentication for custom requests
async function fetchWithAuth(endpoint, options = {}) {
    try {
        const headers = {
            ...(options.headers || {}),
            // Don't set Content-Type for FormData - browser will set it with boundary
            ...(!options.body || !(options.body instanceof FormData)
                ? { "Content-Type": "application/json" }
                : {}),
        };

        // Add authentication token
        const idToken = await getIdToken();
        headers["Authorization"] = `Bearer ${idToken}`;

        // Prepare the request options
        const requestOptions = {
            ...options,
            headers,
            // If body is an object but not FormData, stringify it
            body:
                options.body instanceof FormData
                    ? options.body
                    : typeof options.body === "object"
                    ? JSON.stringify(options.body)
                    : options.body,
        };

        // Construct the full URL
        const fullUrl = `${BASE_URL}${endpoint}`;
        console.log(`[fetchWithAuth] Sending request to: ${fullUrl}`, {
            method: options.method,
        });

        // Make the request
        const response = await fetch(fullUrl, requestOptions);

        // Check if the response is ok
        if (!response.ok) {
            let errorData;
            try {
                errorData = await response.json();
            } catch (e) {
                throw new Error(
                    `HTTP error! status: ${response.status} ${response.statusText}`
                );
            }

            if (errorData && errorData.error) {
                throw new Error(errorData.error);
            } else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        }

        // Parse and return the response
        return await response.json();
    } catch (error) {
        console.error(`Error in fetchWithAuth:`, error);
        throw error;
    }
}

// Updated export statement
export {
    fetchGet,
    fetchPost,
    fetchPut,
    fetchPatch,
    fetchDelete,
    fetchMultipart,
    fetchWithAuth,
};
