import {
    CancelOutlined as CancelOutlinedIcon,
    PhotoCamera as PhotoCameraIcon,
    Search as SearchIcon,
    Send as SendIcon,
    FlashOn as FlashOnIcon,
    Psychology as PsychologyIcon,
    Stop as StopIcon,
    Close as CloseIcon,
} from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React, { useEffect } from "react";

/**
 * MessageInput component for typing messages and uploading images
 */
const MessageInput = ({
    chatMessage,
    setChatMessage,
    isTyping,
    model,
    setModel,
    handleSendMessage,
    imagePreview,
    handleRemoveImage,
    textareaRef,
    handlePaste,
    user,
    remainingQueries,
    subscription,
    imageInputRef,
    onImageUpload,
    onStopGeneration,
    isEditing,
    cancelEditing,
    autoScrollEnabled,
    setAutoScrollEnabled,
}) => {
    const handleImageUpload = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];

            // Check file size (limit to 5MB)
            if (file.size > 5 * 1024 * 1024) {
                alert("Image size must be less than 5MB");
                return;
            }

            // Check file type
            if (!file.type.match("image.*")) {
                alert("Only image files are allowed");
                return;
            }

            if (typeof onImageUpload === "function") {
                onImageUpload(file);
            }
        }
    };

    // Render remaining queries indicator for free tier users
    const renderRemainingQueries = () => {
        if (!user) return null;

        if (subscription?.status !== "active" && remainingQueries !== null) {
            return (
                <span className="lab-assistant-chat-queries-count">
                    ({remainingQueries} messages left)
                </span>
            );
        }
        return null;
    };

    // New function to handle textarea height and ensure message visibility
    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current;
        const messagesContainer = document.querySelector(
            ".lab-assistant-chat-messages-container"
        );

        if (textarea && messagesContainer) {
            // Reset height before calculating new height
            textarea.style.height = "auto";

            // Set height based on scrollHeight, but limit to max height
            textarea.style.height = `${Math.min(textarea.scrollHeight, 150)}px`;

            // Check if we're near the bottom of the scroll container
            const isNearBottom =
                messagesContainer.scrollHeight -
                    messagesContainer.scrollTop -
                    messagesContainer.clientHeight <
                100;

            // If we're near the bottom, scroll to ensure latest content is visible
            if (isNearBottom) {
                setTimeout(() => {
                    messagesContainer.scrollTop =
                        messagesContainer.scrollHeight;
                }, 10);
            }
        }
    };

    // Call adjustment when input height changes
    useEffect(() => {
        adjustTextareaHeight();

        // Also adjust when image preview changes
        if (imagePreview) {
            adjustTextareaHeight();
        }
    }, [imagePreview]);

    return (
        <div className="lab-assistant-chat-input-container">
            <form onSubmit={handleSendMessage} style={{ width: "100%" }}>
                <div
                    className={`lab-assistant-chat-input-wrapper ${
                        isEditing ? "is-editing" : ""
                    }`}
                >
                    {isEditing && (
                        <div className="lab-assistant-chat-editing-indicator">
                            <span>Editing message</span>
                            <button
                                type="button"
                                className="lab-assistant-chat-cancel-edit-btn"
                                onClick={cancelEditing}
                                title="Cancel editing"
                            >
                                <CloseIcon fontSize="small" />
                            </button>
                        </div>
                    )}

                    {imagePreview && (
                        <div className="lab-assistant-chat-image-preview-container">
                            <img
                                src={imagePreview}
                                alt="Preview"
                                className="lab-assistant-chat-image-preview"
                            />
                            <button
                                type="button"
                                className="lab-assistant-chat-remove-image-btn"
                                onClick={handleRemoveImage}
                                aria-label="Remove image"
                            >
                                <CancelOutlinedIcon />
                            </button>
                        </div>
                    )}
                    <div className="lab-assistant-chat-input-controls">
                        <textarea
                            ref={textareaRef}
                            className="lab-assistant-chat-textarea"
                            value={chatMessage}
                            onChange={(e) => {
                                setChatMessage(e.target.value);
                                // Reset height before calculating new height
                                e.target.style.height = "auto";
                                // Set height based on scrollHeight, but limit to max height
                                e.target.style.height = `${Math.min(
                                    e.target.scrollHeight,
                                    150
                                )}px`;

                                // Call function to adjust padding after textarea height changes
                                adjustTextareaHeight();
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" && !e.shiftKey) {
                                    e.preventDefault();
                                    handleSendMessage(e);
                                } else if (e.key === "Escape" && isEditing) {
                                    // Cancel editing when escape key is pressed
                                    e.preventDefault();
                                    cancelEditing();
                                }
                            }}
                            onPaste={handlePaste}
                            placeholder={
                                isEditing
                                    ? "Edit your message..."
                                    : "Type your message here..."
                            }
                            disabled={!user || isTyping}
                            rows={1}
                        />
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            style={{ display: "none" }}
                            id="image-upload"
                            ref={imageInputRef}
                            disabled={!user || isTyping || model === "reasoner"}
                        />
                        <Tooltip
                            title={
                                model === "reasoner"
                                    ? "Image upload is not available with DeepThink"
                                    : "Upload image"
                            }
                            arrow
                        >
                            <span>
                                <button
                                    type="button"
                                    className="lab-assistant-chat-image-btn"
                                    onClick={() => {
                                        if (imageInputRef.current) {
                                            imageInputRef.current.click();
                                        }
                                    }}
                                    disabled={
                                        !user ||
                                        isTyping ||
                                        imagePreview ||
                                        model === "reasoner"
                                    }
                                >
                                    <PhotoCameraIcon />
                                </button>
                            </span>
                        </Tooltip>
                        {isTyping ? (
                            <Tooltip title="Stop generating" arrow>
                                <button
                                    type="button"
                                    onClick={onStopGeneration}
                                    className="lab-assistant-chat-stop-btn"
                                >
                                    <StopIcon />
                                </button>
                            </Tooltip>
                        ) : (
                            <button
                                type="submit"
                                disabled={
                                    !user ||
                                    isTyping ||
                                    (!chatMessage.trim() && !imagePreview)
                                }
                            >
                                <SendIcon />
                            </button>
                        )}
                    </div>
                </div>
                {renderRemainingQueries()}
                <div className="lab-assistant-chat-model-select">
                    <div
                        className={`lab-assistant-chat-model-option ${
                            model === "flash" ? "active" : ""
                        }`}
                        onClick={() => setModel("flash")}
                    >
                        <FlashOnIcon fontSize="small" /> Flash
                    </div>
                    <div
                        className={`lab-assistant-chat-model-option ${
                            model === "pro" ? "active" : ""
                        }`}
                        onClick={() => setModel("pro")}
                    >
                        <SearchIcon fontSize="small" /> Pro Knowledge
                    </div>
                    <div
                        className={`lab-assistant-chat-model-option ${
                            model === "reasoner" ? "active" : ""
                        }`}
                        onClick={() => setModel("reasoner")}
                    >
                        <PsychologyIcon fontSize="small" /> DeepThink (Very
                        Slow)
                    </div>
                </div>
            </form>
        </div>
    );
};

export default MessageInput;
