import React from "react";
import { Box, Typography, Container, Fade } from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

const PricingHeader = () => {
    return (
        <Box className="pricing-header">
            <Fade in={true} timeout={1000}>
                <Box>
                    <Typography
                        variant="h2"
                        component="h1"
                        className="pricing-main-title"
                        sx={{
                            fontFamily:
                                '"poppins", Helvetica, Arial, sans-serif',
                            fontWeight: 800,
                            fontSize: { xs: "2.5rem", md: "3.5rem" },
                            background:
                                "linear-gradient(45deg, #333 30%, rgb(63, 05, 05) 90%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                            marginBottom: "24px",
                            position: "relative",
                            display: "inline-block",
                        }}
                    >
                        Simple, Transparent Pricing
                    </Typography>
                </Box>
            </Fade>

            <Fade in={true} timeout={1500}>
                <Typography
                    variant="h6"
                    component="h2"
                    className="pricing-subtitle"
                    sx={{
                        fontFamily: '"poppins", Helvetica, Arial, sans-serif',
                        color: "#555",
                        fontSize: { xs: "1.1rem", md: "1.25rem" },
                        fontWeight: 400,
                        maxWidth: "700px",
                        margin: "0 auto 24px",
                        lineHeight: 1.5,
                    }}
                >
                    Choose the plan that's right for you and your research needs
                </Typography>
            </Fade>

            {/* <Fade in={true} timeout={2000}>
                <Box
                    sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        backgroundColor: "rgba(255, 215, 0, 0.15)",
                        padding: "12px 28px",
                        borderRadius: "30px",
                        marginTop: "16px",
                        boxShadow: "0 4px 15px rgba(63, 5, 5, 0.2)",
                        border: "2px solid rgba(63, 5, 5, 0.3)",
                        position: "relative",
                        animation: "pulse 2s infinite",
                        "&::before": {
                            content: '""',
                            position: "absolute",
                            top: "-5px",
                            left: "-5px",
                            right: "-5px",
                            bottom: "-5px",
                            borderRadius: "35px",
                            background:
                                "linear-gradient(45deg, rgba(255, 215, 0, 0.3), transparent)",
                            zIndex: -1,
                        },
                        "@keyframes pulse": {
                            "0%": {
                                transform: "scale(1)",
                                boxShadow: "0 4px 15px rgba(63, 5, 5, 0.2)",
                            },
                            "50%": {
                                transform: "scale(1.03)",
                                boxShadow: "0 6px 20px rgba(63, 5, 5, 0.3)",
                            },
                            "100%": {
                                transform: "scale(1)",
                                boxShadow: "0 4px 15px rgba(63, 5, 5, 0.2)",
                            },
                        },
                    }}
                >
                    <LocalOfferIcon
                        sx={{
                            color: "rgb(180, 0, 0)",
                            marginRight: "12px",
                            fontSize: "1.5rem",
                            animation: "swing 1.5s infinite",
                            "@keyframes swing": {
                                "0%, 100%": {
                                    transform: "rotate(-10deg)",
                                },
                                "50%": {
                                    transform: "rotate(10deg)",
                                },
                            },
                        }}
                    />
                    <Typography
                        variant="body1"
                        className="pricing-early-bird"
                        sx={{
                            fontFamily:
                                '"poppins", Helvetica, Arial, sans-serif',
                            color: "rgb(180, 0, 0)",
                            fontWeight: 700,
                            padding: 0,
                            background: "none",
                            margin: 0,
                            fontSize: "1.15rem",
                            textShadow: "0 1px 2px rgba(0,0,0,0.1)",
                        }}
                    >
                        Early bird pricing -{" "}
                        <Box
                            component="span"
                            sx={{
                                backgroundColor: "rgba(255, 255, 255, 0.7)",
                                padding: "2px 8px",
                                borderRadius: "4px",
                                color: "rgb(180, 0, 0)",
                                fontWeight: 800,
                                boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                            }}
                        >
                            50% OFF
                        </Box>{" "}
                        for a limited time!
                    </Typography>
                </Box>
            </Fade> */}
        </Box>
    );
};

export default PricingHeader;
