import {
    AccountBalance as AccountBalanceIcon,
    Book as BookIcon,
    ImportContacts as ImportContactsIcon,
    Person as PersonIcon,
    Psychology as PsychologyIcon,
    School as SchoolIcon,
} from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import {
    useInfiniteQuery,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { marked } from "marked";
import { useEffect, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useLocation, useNavigate } from "react-router-dom";
import { Loading } from "../components";
import { UserAuth } from "../context/auth-context";
import { postNotification } from "../firebase/crud";
import { fetchPost } from "../services/data-service";
import { fetchLabs, LabCacheParams } from "../services/lab-api";
import { fetchUserLabRequests, UserCacheParams } from "../services/user-api";
import { Helmet } from "react-helmet";
import "../styles/BrowseLabs.css";

const BrowseLabs = () => {
    const location = useLocation();
    const { user } = UserAuth();
    const [selectedLab, setSelectedLab] = useState(null);
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const searchQuery = queryParams.get("query");
    const { ref, inView } = useInView();
    const queryClient = useQueryClient();

    const selectLab = (listing) => {
        if (!selectedLab || selectedLab.name !== listing.name) {
            setSelectedLab(listing);
        } else {
            setSelectedLab(null);
        }
    };

    const {
        data: labs,
        isLoading: labsLoading,
        fetchNextPage: fetchNextPaginatedLabs,
        isFetchingNextPage,
    } = useInfiniteQuery({
        queryKey: ["labs", searchQuery],
        queryFn: ({ pageParam }) =>
            fetchLabs({
                pageParam,
                query: searchQuery,
            }),
        ...LabCacheParams,
        getNextPageParam: (lastPage) =>
            lastPage.hasMore ? lastPage.lastVisible : null,
    });

    const { data: userLabRequests, isLoading: userLabRequestsLoading } =
        useQuery({
            queryKey: ["user", user?.uid, "labRequests", { status: "pending" }],
            queryFn: fetchUserLabRequests,
            enabled: !!user?.uid,
            ...UserCacheParams,
        });

    const labsWithRequests = useMemo(() => {
        // If user is not signed in, just return the labs without request status
        if (!user) {
            return labs?.pages
                .flatMap((page) => page.documents)
                .map((lab) => ({
                    ...lab,
                    hasRequested: false,
                }));
        }

        // If user is signed in but either labs or requests are not loaded, return empty
        if (!userLabRequests || !labs) return [];

        // If user is signed in, check for requests
        return labs.pages
            .flatMap((page) => page.documents)
            .map((lab) => ({
                ...lab,
                hasRequested: userLabRequests.some(
                    (request) => request.labId === lab.id
                ),
            }));
    }, [userLabRequests, labs, user]);

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                fetchNextPaginatedLabs();
            }, 500);
        }
    }, [inView]);

    useEffect(() => {
        // Scroll to the top when the search query changes
        window.scrollTo(0, 0);
    }, [searchQuery]);

    useEffect(() => {
        document.title = "Research Labs | LabGiant";
        document
            .querySelector('meta[name="description"]')
            .setAttribute(
                "content",
                "Find and connect with leading research labs and institutions."
            );
    }, []);

    const requestJoinLab = async (lab) => {
        try {
            const labRequest = await fetchPost(
                `/labs/${lab.id}/requests`,
                {
                    userId: user.uid,
                    labId: lab.id,
                    labName: lab.name,
                    status: "pending",
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    createdAt: new Date(),
                },
                true
            );

            await fetchPost(
                `/users/${user.uid}/labRequests`,
                {
                    labRequestId: labRequest.id,
                    labId: lab.id,
                    labName: lab.name,
                    status: "pending",
                    createdAt: new Date(),
                },
                true
            );

            postNotification({
                userId: lab.piId,
                title: "New Lab Join Request!",
                content: `${user.firstName} ${user.lastName} has requested to join your lab ${lab.name}`,
                url: `/manager/${lab.id}/members`,
            });
        } catch {
            console.error(`Failed to send join request to lab: ${lab.id}`);
        }
    };

    const { mutate: requestJoinLabMutation } = useMutation({
        mutationFn: (lab) => requestJoinLab(lab),
        onSuccess: () => {
            alert("Lab Join Request Sent!");
            queryClient.invalidateQueries({
                queryKey: [
                    "user",
                    user?.uid,
                    "labRequests",
                    { status: "pending" },
                ],
            });
            setSelectedLab((prev) => ({
                ...prev,
                hasRequested: true,
            }));
        },
        onError: () => {
            console.error("Failed to send join request to lab");
        },
    });

    const renderContent = (content) => {
        // Set marked options to match OverviewManager
        marked.setOptions({
            gfm: true,
            breaks: true,
            pedantic: false,
            sanitize: false,
            smartLists: true,
            smartypants: false,
        });

        // Clean up the content
        let cleanContent = content
            .replace(/\\\s/g, " ")
            .replace(/\\([^n])/g, "$1")
            .trim();

        // Convert markdown to HTML
        let html = marked(cleanContent);

        // Add target="_blank" and rel="noopener noreferrer" to links
        html = html.replace(/<a href="([^"]+)">/g, (match, url) => {
            // Ensure URL is absolute
            const absoluteUrl =
                url.startsWith("http://") || url.startsWith("https://")
                    ? url
                    : `https://${url}`;
            return `<a href="${absoluteUrl}" target="_blank" rel="noopener noreferrer">`;
        });

        return { __html: html };
    };

    if (labsLoading || userLabRequestsLoading) {
        return <Loading />;
    }

    const Labs = () => {
        return (
            <ul>
                {labsWithRequests.map((lab) => (
                    <li
                        key={lab.name.toString()}
                        onClick={() => selectLab(lab)}
                        style={
                            lab.name === selectedLab?.name
                                ? {
                                      borderRight: "4px solid #18181b",
                                      outlineOffset: "-4px",
                                  }
                                : {}
                        }
                    >
                        <div className="browse-labs-lab">
                            <div className="browse-labs-content">
                                <div className="browse-labs-logo">
                                    <img src={lab.logo} alt="lab" />
                                </div>
                                <div className="browse-labs-header-text">
                                    <h2>{lab.name}</h2>
                                    <ul className="browse-labs-header-info">
                                        <li>
                                            <PersonIcon
                                                style={{ fontSize: "14px" }}
                                            />
                                            {lab.piName}
                                        </li>
                                        <li>
                                            <AccountBalanceIcon
                                                style={{ fontSize: "14px" }}
                                            />
                                            {lab.institutionName}
                                        </li>
                                        <li>
                                            <SchoolIcon
                                                style={{ fontSize: "14px" }}
                                            />
                                            {lab.facultyName}
                                        </li>
                                        <li>
                                            <ImportContactsIcon
                                                style={{ fontSize: "14px" }}
                                            />
                                            {lab.departmentName}
                                        </li>
                                    </ul>
                                    <div className="browse-labs-description">
                                        <div className="browse-labs-description-section">
                                            <div
                                                className="browse-labs-description-lab-overview"
                                                dangerouslySetInnerHTML={renderContent(
                                                    lab.overview[0]?.content
                                                )}
                                            />
                                        </div>
                                        <div className="browse-labs-description-section">
                                            <div className="browse-labs-description-keywords">
                                                {lab.keywords.map((keyword) => (
                                                    <div key={keyword}>
                                                        {keyword}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        );
    };

    const SelectedLab = () => {
        return (
            <div className="selected-lab-card">
                <button
                    className="selected-lab-close-button"
                    onClick={() => setSelectedLab(null)}
                >
                    ×
                </button>
                <div className="selected-lab-header">
                    <div className="selected-lab-header-text">
                        <h2
                            className="selected-lab-header-lab"
                            onClick={() =>
                                navigate(`/lab/${selectedLab.id}/overview`)
                            }
                        >
                            {selectedLab.name}
                        </h2>
                        <h3 className="selected-lab-header-info">
                            <span>
                                <PersonIcon style={{ fontSize: "14px" }} />
                                {selectedLab.piName}
                            </span>
                            <span>
                                <AccountBalanceIcon
                                    style={{ fontSize: "14px" }}
                                />
                                {selectedLab.institutionName}
                            </span>
                            <span>
                                <SchoolIcon style={{ fontSize: "14px" }} />
                                {selectedLab.facultyName}
                            </span>
                            <span>
                                <ImportContactsIcon
                                    style={{ fontSize: "14px" }}
                                />
                                {selectedLab.departmentName}
                            </span>
                        </h3>
                    </div>

                    <img
                        src={selectedLab.logo}
                        alt="lab"
                        onClick={() =>
                            navigate(`/lab/${selectedLab.id}/overview`)
                        }
                    />
                </div>
                <div className="selected-lab-content">
                    <div className="selected-lab-section">
                        <h4 className="selected-lab-section-title">
                            <BookIcon style={{ fontSize: "20px" }} /> Lab
                            Overview
                        </h4>
                        <div
                            className="selected-lab-section-content"
                            dangerouslySetInnerHTML={renderContent(
                                selectedLab.overview[0]?.content
                            )}
                        />
                    </div>
                    <div className="selected-lab-section">
                        <h4 className="selected-lab-section-title">
                            <PsychologyIcon style={{ fontSize: "20px" }} />{" "}
                            Areas of Research
                        </h4>
                        <div className="selected-lab-section-content">
                            {selectedLab.keywords.join(", ")}
                        </div>
                    </div>
                </div>
                <div className="browse-labs-footer-buttons">
                    <button
                        className="selected-lab-footer-button"
                        onClick={() =>
                            navigate(`/lab/${selectedLab.id}/overview`)
                        }
                    >
                        View Lab
                    </button>

                    {user && !user.labId && (
                        <button
                            className="selected-lab-footer-button"
                            onClick={() => requestJoinLabMutation(selectedLab)}
                            disabled={
                                !user.emailVerified || selectedLab.hasRequested
                            }
                        >
                            {user.emailVerified
                                ? selectedLab.hasRequested
                                    ? "Requested"
                                    : "Request to Join"
                                : "Verify Email to Join"}
                        </button>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            <Helmet>
                <link
                    rel="canonical"
                    href={`${process.env.REACT_APP_FRONTEND_URL}/labs`}
                />
            </Helmet>
            <div className="browse-labs-page">
                {!searchQuery && (
                    <>
                        <h1>Research Labs</h1>
                        <h2>
                            With over <strong>50 research labs</strong> and
                            growing in fields such as biotechnology, science,
                            engineering, physics, computer science, and medical
                            research, our platform helps you{" "}
                            <span
                                style={{
                                    fontWeight: 600,
                                }}
                            >
                                connect with new collaborators, expand your
                                network, and discover valuable expertise.
                            </span>
                        </h2>
                    </>
                )}
                {/* <LabFilter /> */}
                {searchQuery && (
                    <div className="browse-labs-header">
                        <h2>
                            Searching results for{" "}
                            <span style={{ fontWeight: 600 }}>
                                "{searchQuery}"
                            </span>
                        </h2>
                    </div>
                )}
                <div className="browse-labs-container">
                    <div className="browse-labs-list-area">
                        <Labs />
                    </div>
                    {selectedLab && (
                        <>
                            <div
                                className={`selected-listing-overlay ${
                                    selectedLab ? "active" : ""
                                }`}
                                onClick={() => setSelectedLab(null)}
                            />
                            <div
                                className={`selected-listing-area ${
                                    selectedLab ? "active" : ""
                                }`}
                            >
                                <SelectedLab />
                            </div>
                        </>
                    )}
                </div>
                <div ref={ref} className="browse-listings-pagination">
                    {isFetchingNextPage && (
                        <div className="browse-listings-pagination-loading">
                            <CircularProgress
                                size={24}
                                style={{
                                    color: "var(--primary-color)",
                                    border: "10px",
                                }}
                            />
                            <span>Loading labs...</span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default BrowseLabs;
