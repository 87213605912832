import { useFormContext } from "react-hook-form";
import "../styles/CreateListingsChooseCat.css";

export const SelectPricing = ({ isError, fieldName, required = true }) => {
    const { register } = useFormContext();

    // Create a registration object with validation rules
    const registration = register(fieldName, {
        required: required
            ? {
                  value: true,
                  message: "The above price is required.",
              }
            : false,
        min: {
            value: 0,
            message: "Enter a price greater than 0.",
        },
        valueAsNumber: true,
        validate: {
            decimals: (value) => {
                if (value && value.toString().split(".")[1]?.length > 2) {
                    return "Enter a price with up to two decimal places.";
                }
                return true;
            },
        },
    });

    return (
        <div className="price-section">
            <div className="price-input-container">
                <span className="currency">CA$</span>
                <input
                    className={isError && "input-error"}
                    type="number"
                    name={fieldName}
                    id={fieldName}
                    data-input-type="price-value"
                    step="0.01" // Adjusted step to 0.01 for two decimal places
                    min="0"
                    placeholder="0.00"
                    {...registration}
                />
            </div>
        </div>
    );
};

export default SelectPricing;
