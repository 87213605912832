import { Button, Checkbox, Chip, FormControlLabel } from "@mui/material";
import {
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    where,
} from "firebase/firestore";
import * as React from "react";
import { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { UserAuth } from "../context/auth-context";
import { db } from "../firebase-config";
import { ErrorMessages } from "../helpers/ListingHelpers";

import CloseIcon from "@mui/icons-material/Close";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import imagePlaceholder from "../assets/image-placeholder.png";
import { fetchGet } from "../services/data-service";
import { Spinner } from "../components";
import "../styles/CreateLabPi.css";

const libraries = ["places"];

const CreateLabPI = () => {
    const {
        control,
        watch,
        trigger,
        register,
        resetField,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {
            lab: {
                institution: null,
                faculty: null,
                department: null,
                sameAddressShipping: true,
                sameAddressBilling: true,
            },
        },
    });

    let navigate = useNavigate();
    const { user, signupPI } = UserAuth();
    const [emailAlreadyRegistered, setEmailAlreadyRegistered] = useState(false);
    const [institutions, setInstitutions] = useState([]);
    const [faculties, setFaculties] = useState([]);
    const [logo, setLogo] = useState(null);
    const [keywords, setKeywords] = useState({
        current: "",
        all: [],
    });
    const [loading, setLoading] = useState(false);
    const selectedInstitution = watch("lab.institution");
    const selectedFaculty = watch("lab.faculty");

    const [isScriptLoaded, setIsScriptLoaded] = useState(false);

    const handleScriptLoad = () => {
        setIsScriptLoaded(true);
    };

    const handleKeywordChange = (e) => {
        setKeywords((prev) => ({
            ...prev,
            current: e.target.value,
        }));
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            event.stopPropagation();
            addKeyword(event.target.value);
        }
    };

    const addKeyword = (keyword) => {
        if (keyword !== "" && !keywords.all.includes(keyword)) {
            setKeywords((prev) => ({
                ...prev,
                all: [...prev.all, keyword],
            }));

            setKeywords((prev) => ({
                ...prev,
                current: "",
            }));
        }
    };

    const deleteKeyword = (keyword) => {
        setKeywords((prev) => ({
            ...prev,
            all: prev.all.filter((k) => k !== keyword),
        }));
    };

    const handleClearKeywords = () => {
        setKeywords((prev) => ({
            ...prev,
            all: [],
        }));
    };

    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setLogo(URL.createObjectURL(event.target.files[0]));
        }
    };

    const handlePasteImage = () => {
        navigator.clipboard
            .read()
            .then((clipboardItems) => {
                for (const clipboardItem of clipboardItems) {
                    for (const type of clipboardItem.types) {
                        if (type.startsWith("image/")) {
                            clipboardItem.getType(type).then((blob) => {
                                // Create a File object from the blob
                                const file = new File(
                                    [blob],
                                    "pasted-image.png",
                                    {
                                        type: blob.type,
                                    }
                                );

                                // Create a DataTransfer to set the file in the input
                                const dataTransfer = new DataTransfer();
                                dataTransfer.items.add(file);

                                // Update the file input value
                                const fileInput = document.querySelector(
                                    'input[name="lab.logo"]'
                                );
                                if (fileInput) {
                                    fileInput.files = dataTransfer.files;

                                    // Trigger the onChange handler
                                    const event = new Event("change", {
                                        bubbles: true,
                                    });
                                    fileInput.dispatchEvent(event);
                                }

                                // Update the preview
                                setLogo(URL.createObjectURL(blob));
                            });
                            return;
                        }
                    }
                }
            })
            .catch((err) => {
                console.error("Failed to read clipboard:", err);
            });
    };

    const registerPiAndLab = async () => {
        setLoading(true);
        if (await trigger()) {
            const userData = {
                email: getValues("pi.email"),
                password: getValues("pi.password"),
                firstName: getValues("pi.firstName"),
                lastName: getValues("pi.lastName"),
                institutionId: getValues("lab.institution.id"),
                institutionName: getValues("lab.institution.value"),
                facultyId: getValues("lab.faculty.id"),
                facultyName: getValues("lab.faculty.value"),
                departmentName: getValues("lab.department.value"),
            };

            const labData = {
                name: getValues("lab.name"),
                email: getValues("pi.email"),
                addresses: {
                    lab: getValues("lab.addresses.labAddress"),
                    ...(getValues("lab.addresses.shippingAddress") && {
                        shipping: getValues("lab.addresses.shippingAddress"),
                    }),
                    ...(getValues("lab.addresses.billingAddress") && {
                        billing: getValues("lab.addresses.billingAddress"),
                    }),
                },
                institutionName: getValues("lab.institution.value"),
                institutionId: getValues("lab.institution.id"),
                facultyName: getValues("lab.faculty.value"),
                facultyId: getValues("lab.faculty.id"),
                overview: getValues("lab.overview"),
                logo: getValues("lab.logo").item(0),
                departmentName: getValues("lab.department.value"),
                keywords: keywords.all,
                ...(getValues("lab.website") && {
                    website: getValues("lab.website"),
                }),
                status: "pending",
                createdAt: new Date().toISOString(),
            };
            /** Sign up the PI and create the lab registration*/
            try {
                await signupPI(userData, labData);
                setEmailAlreadyRegistered(false);
            } catch (error) {
                console.error("Error signing up PI:", error);
                setEmailAlreadyRegistered(true);
            }
        } else {
            setLoading(false);
            console.log("Form is not valid");
            console.log(errors);
        }
    };

    useEffect(() => {
        if (user) {
            navigate(`/profile/${user.uid}`);
        }
    }, [user]);

    useEffect(() => {
        const getInstitutions = async () => {
            const institutionsData = await fetchGet("/institutions", false);
            const institutions = institutionsData.map((institution) => {
                return {
                    id: institution.id,
                    label: institution.name,
                    value: institution.name,
                    lang: institution.lang,
                };
            });
            setInstitutions(institutions);
        };
        getInstitutions();
    }, []);

    useEffect(() => {
        resetField("lab.faculty");
        resetField("lab.department");

        const getFaculties = async () => {
            const institutionsQuery = query(
                collection(db, "Institutions"),
                where(
                    `name.${selectedInstitution.lang}`,
                    "==",
                    selectedInstitution.value
                )
            );
            const institutionsDocs = await getDocs(institutionsQuery);
            if (!institutionsDocs.empty) {
                const institutionDoc = institutionsDocs.docs[0];
                if (institutionDoc.data().faculties) {
                    const faculties = await Promise.all(
                        institutionDoc.data().faculties.map(async (id) => {
                            const facultyDoc = await getDoc(
                                doc(db, "Faculties", id)
                            );
                            return {
                                id: facultyDoc.id,
                                ...facultyDoc.data(),
                            };
                        })
                    );
                    return faculties;
                }
            } else {
                console.log("No matching institution found");
            }
        };
        if (selectedInstitution) {
            getFaculties().then((faculties) => {
                setFaculties(faculties);
            });
        }
    }, [selectedInstitution]);

    useEffect(() => {
        resetField("lab.department");
    }, [selectedFaculty]);

    return (
        <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            libraries={libraries}
            onLoad={handleScriptLoad}
        >
            {isScriptLoaded && (
                <form>
                    <div className="create-lab-pi-page">
                        <h1 className="create-lab-pi-header">
                            Lab Profile Registration
                        </h1>
                        <div className="create-lab-pi-container">
                            <div className="create-lab-pi-content">
                                <div className="create-lab-pi-section">
                                    <h2 className="create-lab-pi-subheader">
                                        Principal Investigator/Director
                                        Information
                                    </h2>
                                    <div className="create-lab-field">
                                        <h3>
                                            First Name
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>
                                            This is the first name of the
                                            PI/director.
                                        </h4>
                                        <input
                                            className={`input ${
                                                errors?.pi?.firstName &&
                                                "input-error"
                                            }`}
                                            type="text"
                                            {...register("pi.firstName", {
                                                required: true,
                                            })}
                                            placeholder="First Name"
                                        />
                                        {errors?.pi?.firstName && (
                                            <ErrorMessages message="The above field is required." />
                                        )}
                                    </div>
                                    <div className="create-lab-field">
                                        <h3>
                                            Last Name
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>
                                            This is the last name of the
                                            PI/director.
                                        </h4>
                                        <input
                                            className={`input ${
                                                errors?.pi?.lastName &&
                                                "input-error"
                                            }`}
                                            type="text"
                                            {...register("pi.lastName", {
                                                required: true,
                                            })}
                                            placeholder="Last Name"
                                        />
                                        {errors?.pi?.lastName && (
                                            <ErrorMessages message="The above field is required." />
                                        )}
                                    </div>
                                    <div className="create-lab-field">
                                        <h3>
                                            Email
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>
                                            This is the email of the
                                            PI/director.
                                        </h4>
                                        <input
                                            className={`input ${
                                                errors?.pi?.email &&
                                                "input-error"
                                            }`}
                                            type="text"
                                            placeholder="Email"
                                            {...register("pi.email", {
                                                required: true,
                                            })}
                                        />
                                        {errors?.pi?.email && (
                                            <ErrorMessages message="The above field is required." />
                                        )}
                                        {emailAlreadyRegistered && (
                                            <ErrorMessages message="The above email is already registered." />
                                        )}
                                    </div>
                                    <div className="create-lab-field">
                                        <h3>
                                            Password
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>
                                            Use a strong password for security
                                            purposes.
                                        </h4>
                                        <input
                                            className={`input ${
                                                errors?.pi?.password &&
                                                "input-error"
                                            }`}
                                            type="password"
                                            placeholder="Password"
                                            {...register("pi.password", {
                                                required: true,
                                            })}
                                        />
                                        {errors?.pi?.password && (
                                            <ErrorMessages message="The above field is required." />
                                        )}
                                    </div>
                                </div>
                                <div className="create-lab-pi-section">
                                    <h2 className="create-lab-pi-subheader">
                                        Lab Information
                                    </h2>
                                    <div className="create-lab-pi-field">
                                        <h3>
                                            Lab Name
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>
                                            This is the name shown to the
                                            public.
                                        </h4>
                                        <input
                                            className={`input ${
                                                errors?.lab?.name &&
                                                "input-error"
                                            }`}
                                            type="text"
                                            placeholder="Lab Name"
                                            {...register("lab.name", {
                                                required: true,
                                            })}
                                        />
                                        {errors?.lab?.name && (
                                            <ErrorMessages message="The above field is required." />
                                        )}
                                    </div>
                                    <div className="create-lab-pi-field">
                                        <h3>
                                            Lab Overview
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>
                                            Describe your lab in a few
                                            sentences. This is the description
                                            shown to the public.
                                        </h4>
                                        <textarea
                                            className={`input ${
                                                errors?.lab?.overview &&
                                                "input-error"
                                            }`}
                                            placeholder="Lab Overview"
                                            {...register("lab.overview", {
                                                required: true,
                                            })}
                                        />
                                        {errors?.lab?.overview && (
                                            <ErrorMessages message="The above field is required." />
                                        )}
                                    </div>
                                    <div className="create-lab-pi-field">
                                        <h3>
                                            Lab Address
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>
                                            This is the address where the lab
                                            will be located.
                                        </h4>
                                        <StandaloneSearchBox>
                                            <input
                                                type="text"
                                                className={`input ${
                                                    errors?.lab?.addresses
                                                        ?.labAddress &&
                                                    "input-error"
                                                }`}
                                                placeholder="Enter lab address"
                                                {...register(
                                                    "lab.addresses.labAddress",
                                                    {
                                                        required: true,
                                                    }
                                                )}
                                            />
                                        </StandaloneSearchBox>
                                        {errors?.lab?.addresses?.labAddress && (
                                            <ErrorMessages message="The above field is required." />
                                        )}
                                    </div>
                                    <div className="create-lab-pi-field">
                                        <h3>
                                            Institution
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>
                                            This is the institution of the lab.
                                        </h4>
                                        <Controller
                                            name="lab.institution"
                                            control={control}
                                            defaultValue={null}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    className={`create-lab-pi-select select-1 ${
                                                        errors?.lab
                                                            ?.institution &&
                                                        "input-error"
                                                    }`}
                                                    options={institutions.map(
                                                        (institution) => {
                                                            return {
                                                                id: institution.id,
                                                                label: institution
                                                                    .label[
                                                                    institution
                                                                        .lang
                                                                ],
                                                                value: institution
                                                                    .label[
                                                                    institution
                                                                        .lang
                                                                ],
                                                                lang: institution.lang,
                                                            };
                                                        }
                                                    )}
                                                    isSearchable
                                                    placeholder="Choose institution"
                                                    styles={{
                                                        control: (
                                                            provided
                                                        ) => ({
                                                            ...provided,
                                                            border: "none",
                                                            boxShadow: "none",
                                                        }),
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors?.lab?.institution && (
                                            <ErrorMessages message="The above field is required." />
                                        )}
                                    </div>
                                    <div className="create-lab-pi-field">
                                        <h3>
                                            Faculty
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>This is the faculty of the lab.</h4>
                                        <Controller
                                            name="lab.faculty"
                                            control={control}
                                            defaultValue={null}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    disabled={
                                                        !selectedInstitution
                                                    }
                                                    className={`create-lab-pi-select select-2 ${
                                                        errors?.lab?.faculty &&
                                                        "input-error"
                                                    }`}
                                                    options={
                                                        selectedInstitution &&
                                                        faculties
                                                            ? faculties.map(
                                                                  (
                                                                      faculty
                                                                  ) => ({
                                                                      id: faculty.id,
                                                                      label: faculty
                                                                          ?.name[
                                                                          `${selectedInstitution.lang}`
                                                                      ],
                                                                      value: faculty
                                                                          ?.name[
                                                                          `${selectedInstitution.lang}`
                                                                      ],
                                                                      lang: selectedInstitution.lang,
                                                                  })
                                                              )
                                                            : []
                                                    }
                                                    isSearchable
                                                    placeholder="Choose faculty"
                                                    styles={{
                                                        control: (
                                                            provided
                                                        ) => ({
                                                            ...provided,
                                                            border: "none",
                                                            boxShadow: "none",
                                                        }),
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors?.lab?.faculty && (
                                            <ErrorMessages message="The above field is required." />
                                        )}
                                    </div>
                                    <div className="create-lab-pi-field">
                                        <h3>
                                            Department
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>
                                            This is the department of the lab.
                                        </h4>
                                        <Controller
                                            name="lab.department"
                                            control={control}
                                            defaultValue={null}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    disabled={!selectedFaculty}
                                                    className={`create-lab-pi-select select-3 ${
                                                        errors?.lab
                                                            ?.department &&
                                                        "input-error"
                                                    }`}
                                                    options={
                                                        selectedFaculty &&
                                                        (faculties?.filter(
                                                            (faculty) =>
                                                                selectedFaculty.value ===
                                                                faculty.name[
                                                                    `${selectedInstitution.lang}`
                                                                ]
                                                        )[0]?.departments
                                                            ? faculties
                                                                  ?.filter(
                                                                      (
                                                                          faculty
                                                                      ) =>
                                                                          selectedFaculty.value ===
                                                                          faculty
                                                                              .name[
                                                                              `${selectedInstitution.lang}`
                                                                          ]
                                                                  )[0]
                                                                  ?.departments.map(
                                                                      (
                                                                          department
                                                                      ) => ({
                                                                          label: department
                                                                              .name[
                                                                              `${selectedInstitution.lang}`
                                                                          ],
                                                                          value: department
                                                                              .name[
                                                                              `${selectedInstitution.lang}`
                                                                          ],
                                                                          lang: selectedInstitution.lang,
                                                                      })
                                                                  )
                                                            : [
                                                                  {
                                                                      label: "None",
                                                                      value: "None",
                                                                      lang: selectedInstitution.lang,
                                                                  },
                                                              ])
                                                    }
                                                    isSearchable
                                                    placeholder="Choose department"
                                                    styles={{
                                                        control: (
                                                            provided
                                                        ) => ({
                                                            ...provided,
                                                            border: "none",
                                                            boxShadow: "none",
                                                        }),
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors?.lab?.department && (
                                            <ErrorMessages message="The above field is required." />
                                        )}
                                    </div>
                                    <div className="create-lab-pi-field">
                                        <h3>
                                            Areas of Research
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>
                                            Add keywords related to your lab
                                            that will help users find you.
                                        </h4>
                                        <div className="create-lab-pi-keyword-options">
                                            <input
                                                className="input"
                                                value={keywords.current}
                                                onChange={(e) =>
                                                    handleKeywordChange(e)
                                                }
                                                onKeyDown={(e) =>
                                                    handleKeyDown(e)
                                                }
                                                placeholder="Keywords"
                                                variant="outlined"
                                            />
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    addKeyword(
                                                        keywords.current
                                                    );
                                                }}
                                            >
                                                Add keyword
                                            </button>
                                        </div>

                                        {keywords.all.length > 0 && (
                                            <div className="create-lab-pi-keywords">
                                                {(keywords.all || []).map(
                                                    (keyword, idx) => (
                                                        <Fragment key={idx}>
                                                            <Chip
                                                                label={keyword}
                                                                onDelete={(
                                                                    event
                                                                ) => {
                                                                    event.stopPropagation();
                                                                    deleteKeyword(
                                                                        keyword,
                                                                        idx
                                                                    );
                                                                }}
                                                                style={{
                                                                    marginRight:
                                                                        "5px",
                                                                    marginBottom:
                                                                        "10px",
                                                                }}
                                                                className="custom-chip"
                                                            />
                                                            <input
                                                                type="hidden"
                                                                {...register(
                                                                    `lab.keywords[${idx}]`,
                                                                    {
                                                                        value: keyword,
                                                                    }
                                                                )}
                                                            />
                                                        </Fragment>
                                                    )
                                                )}
                                                <Button
                                                    className="clear-all"
                                                    onClick={
                                                        handleClearKeywords
                                                    }
                                                    style={{
                                                        marginBottom: "10px",
                                                    }}
                                                >
                                                    Clear All
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="create-lab-pi-section">
                                    <h2 className="create-lab-pi-subheader">
                                        Shipping & Billing Information
                                    </h2>
                                    <div className="create-lab-pi-field">
                                        <h3>
                                            Shipping Address
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>
                                            This is the address used for
                                            shipping and receiving orders.
                                        </h4>
                                        <FormControlLabel
                                            className="checkbox-label"
                                            control={
                                                <Checkbox
                                                    checked={watch(
                                                        "lab.sameAddressShipping"
                                                    )}
                                                    style={{
                                                        marginRight: "5px",
                                                        paddingRight: "0",
                                                        paddingTop: "0",
                                                        paddingBottom: "0",
                                                    }}
                                                    name="sameAddressShipping"
                                                    id="sameAddressShipping"
                                                    {...register(
                                                        "lab.sameAddressShipping",
                                                        {
                                                            defaultValue: true,
                                                        }
                                                    )}
                                                />
                                            }
                                            style={{
                                                marginBottom: "10px",
                                            }}
                                            label="Same as lab address"
                                        />

                                        {!watch("lab.sameAddressShipping") && (
                                            <StandaloneSearchBox>
                                                <input
                                                    type="text"
                                                    className={`input ${
                                                        errors?.lab?.addresses
                                                            ?.shippingAddress &&
                                                        "input-error"
                                                    }`}
                                                    placeholder="Enter shipping address"
                                                    {...register(
                                                        "lab.addresses.shippingAddress",
                                                        {
                                                            required:
                                                                watch(
                                                                    "lab.sameAddressShipping"
                                                                ) === false,
                                                        }
                                                    )}
                                                />
                                            </StandaloneSearchBox>
                                        )}
                                        {errors?.lab?.addresses
                                            ?.shippingAddress &&
                                            watch("lab.sameAddressShipping") ===
                                                false && (
                                                <ErrorMessages message="The above field is required." />
                                            )}
                                    </div>
                                    <div className="create-lab-pi-field">
                                        <h3>
                                            Billing Address
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>
                                            This is the address used for
                                            billing.
                                        </h4>
                                        <FormControlLabel
                                            className="checkbox-label"
                                            control={
                                                <Checkbox
                                                    checked={getValues(
                                                        "lab.sameAddressBilling"
                                                    )}
                                                    style={{
                                                        marginRight: "5px",
                                                        paddingRight: "0",
                                                        paddingTop: "0",
                                                        paddingBottom: "0",
                                                    }}
                                                    name="sameAddressBilling"
                                                    id="sameAddressBilling"
                                                    {...register(
                                                        "lab.sameAddressBilling",
                                                        {
                                                            value: true,
                                                        }
                                                    )}
                                                />
                                            }
                                            style={{
                                                marginBottom: "10px",
                                            }}
                                            label="Same as lab address"
                                        />
                                        {!watch("lab.sameAddressBilling") && (
                                            <StandaloneSearchBox>
                                                <input
                                                    type="text"
                                                    className={`input ${
                                                        errors?.lab?.addresses
                                                            ?.billingAddress &&
                                                        "input-error"
                                                    }`}
                                                    placeholder="Enter billing address"
                                                    {...register(
                                                        "lab.addresses.billingAddress",
                                                        {
                                                            required:
                                                                watch(
                                                                    "lab.sameAddressBilling"
                                                                ) === false,
                                                        }
                                                    )}
                                                />
                                            </StandaloneSearchBox>
                                        )}
                                        {errors?.lab?.addresses
                                            ?.billingAddress &&
                                            watch("lab.sameAddressBilling") ===
                                                false && (
                                                <ErrorMessages message="The above field is required." />
                                            )}
                                    </div>
                                </div>
                                <div className="create-lab-pi-section">
                                    <h2 className="create-lab-pi-subheader">
                                        Miscellaneous
                                    </h2>
                                    <div className="create-lab-pi-field">
                                        <h3>
                                            Lab Profile Logo
                                            {/* <span style={{ color: "red" }}>*</span> */}
                                        </h3>
                                        <h4>
                                            Add an image logo that will be used
                                            as the lab profile picture.
                                        </h4>
                                        <div className="image-input-container">
                                            <label
                                                className={`image-upload-label ${
                                                    errors?.lab?.logo &&
                                                    "input-error"
                                                }`}
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                            >
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: "none" }}
                                                    {...register("lab.logo", {
                                                        onChange: (e) => {
                                                            handleImageChange(
                                                                e
                                                            );
                                                        },
                                                        required: true,
                                                    })}
                                                />
                                                <div
                                                    className="image-upload-square"
                                                    style={{
                                                        backgroundImage: `url(${
                                                            logo ||
                                                            imagePlaceholder
                                                        })`,
                                                    }}
                                                >
                                                    {!logo && (
                                                        <div className="image-upload-instructions">
                                                            <span>
                                                                Click to upload
                                                            </span>
                                                            <button
                                                                type="button"
                                                                className="paste-image-button"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    handlePasteImage();
                                                                }}
                                                            >
                                                                Paste Image
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                                {logo && (
                                                    <button
                                                        className="delete-image"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setLogo(null);
                                                        }}
                                                    >
                                                        <CloseIcon
                                                            style={{
                                                                fontSize:
                                                                    "16px",
                                                            }}
                                                        />
                                                    </button>
                                                )}
                                            </label>
                                        </div>
                                        {errors?.lab?.logo && (
                                            <ErrorMessages message="The above field is required." />
                                        )}
                                    </div>
                                    <div className="create-lab-pi-field">
                                        <h3>
                                            Lab Website
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                        </h3>
                                        <h4>
                                            Add your lab website to your lab
                                            profile.
                                        </h4>
                                        <input
                                            className={`input ${
                                                errors?.lab?.website &&
                                                "input-error"
                                            }`}
                                            type="text"
                                            placeholder="Lab Website"
                                            {...register("lab.website", {
                                                pattern:
                                                    /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g,
                                            })}
                                        />
                                        {errors?.lab?.website && (
                                            <ErrorMessages message="The above link is invalid." />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="create-lab-pi-footer">
                            <button
                                className="create-lab-pi-button"
                                type="button"
                                onClick={registerPiAndLab}
                            >
                                {loading && <Spinner />}
                                Create Lab Profile
                            </button>
                        </div>
                    </div>
                </form>
            )}
        </LoadScript>
    );
};

export default CreateLabPI;
