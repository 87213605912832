import React, { useState, useEffect } from "react";
import { fetchGet } from "../../services/data-service";
import { useParams } from "react-router-dom";
import { marked } from "marked";

const ResearchOverview = () => {
    const { labId } = useParams();
    const [overview, setOverview] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchGet(`/labs/${labId}?overview=true`);
            setOverview(data.sections);
        };

        fetchData();
    }, [labId]);

    const renderContent = (content) => {
        if (!content) return { __html: "" };

        let html = content.trim();

        // Add target="_blank" and rel attributes to links while preserving other attributes
        html = html.replace(
            /<a ([^>]*)href="([^"]+)"([^>]*)>/g,
            (match, before, url, after) => {
                const absoluteUrl =
                    url.startsWith("http://") || url.startsWith("https://")
                        ? url
                        : `https://${url}`;
                return `<a ${before}href="${absoluteUrl}"${after} target="_blank" rel="noopener noreferrer">`;
            }
        );

        // Preserve code block formatting
        html = html.replace(
            /<pre class="ql-syntax".*?>(.*?)<\/pre>/gs,
            (match, code) => {
                return `<pre class="ql-syntax">${code}</pre>`;
            }
        );

        return { __html: `<div class="quill-content">${html}</div>` };
    };

    return (
        <div className="lab-profile-content">
            {overview.map((section, index) => (
                <div
                    key={index}
                    className="lab-profile-content-overview-section-wrapper"
                >
                    <h3>{section.title}</h3>
                    <div
                        className="markdown-content"
                        dangerouslySetInnerHTML={renderContent(section.content)}
                    />
                    {section.files.length > 0 && (
                        <div className="overview-section-images">
                            {section.files.map((file) => (
                                <div
                                    key={file.url}
                                    className="overview-section-images-container"
                                >
                                    <img src={file.url} alt={file.name} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default ResearchOverview;
